import {IconProp} from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../utils/global'
import {ButtonIcon, ButtonIconProps} from '../buttons/button'
import styles from './twitter-intent-button.module.scss'

type TwitterIntentButtonProps = Omit<ButtonIconProps, 'onclick'> & {
  intentProperty?: string
  intentValue?: string
  text?: string
  url?: string
  hashtags?: string
  related?: string
  icon: IconProp
  intent: 'like' | 'retweet' | 'follow' | 'tweet'
}

const TwitterIntentButton = ({className, intentProperty, intentValue, icon, intent, text, url, hashtags, related, onClick, ...props}: TwitterIntentButtonProps) => {
  const openWindow = async () => {
    const openedWindow = window.open(
      encodeURI(`https://twitter.com/intent/${intent}?original_referer=${window.location}&ref_src=twsrc^tfw|twcamp^buttonembed|twterm^share|twgr^url=/${intentProperty ? `&${intentProperty}=${intentValue}` : ''}${text ? `&text=${text}` : ''}${url ? `&url=${url}` : ''}${hashtags ? `&hashtags=${hashtags}` : ''}${related ? `&related=${related}` : ''}`),
      'Oogy Blast - X',
      'toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left=240,top=212'
    )

    if (openedWindow === null) {
      toast.info('Please disable your adblock to open the X pages')
    }
  }

  return <ButtonIcon
    icon={icon}
    className={formatClassName(styles, `twitter-intent-button no-color ${className}`)}
    onClick={(event) => {
      if (onClick) {
        onClick(event)
      }

      openWindow()
    }}
    {...props}
  />
}

export type TwitterLikeButtonProps = Omit<TwitterIntentButtonProps, 'icon' | 'intent' | 'intentProperty' | 'intentValue'> & {
  tweetId: string
}

export const TwitterLikeButton = ({className, tweetId, ...props}: TwitterLikeButtonProps) =>
  <TwitterIntentButton title="like" icon="heart" intent="like" intentProperty='tweet_id' intentValue={tweetId} className={formatClassName(styles, `like ${className}`)} {...props} />

export type TwitterRetweetButtonProps = Omit<TwitterIntentButtonProps, 'icon' | 'intent' | 'intentProperty' | 'intentValue'> & {
  tweetId: string
}

export const TwitterRetweetButton = ({className, tweetId, ...props}: TwitterRetweetButtonProps) =>
  <TwitterIntentButton title="retweet" icon="retweet" intent="retweet" intentProperty='tweet_id' intentValue={tweetId} className={formatClassName(styles, `retweet ${className}`)} {...props} />

export type TwitterFollowButtonProps = Omit<TwitterIntentButtonProps, 'icon' | 'intent' | 'intentProperty' | 'intentValue'> & {
  targetScreenName: string
}

export const TwitterFollowButton = ({className, targetScreenName, ...props}: TwitterFollowButtonProps) =>
  <TwitterIntentButton title="follow" icon="user-plus" intent="follow" intentProperty='screen_name' intentValue={targetScreenName} className={formatClassName(styles, `follow ${className}`)} {...props} />

export type TwitterTweetButtonProps = Omit<TwitterIntentButtonProps, 'icon' | 'intent' | 'intentProperty' | 'intentValue'> & {
  text?: string
  url?: string
}

export const TwitterTweetButton = ({className, text, url, hashtags, related, ...props}: TwitterTweetButtonProps) =>
  <TwitterIntentButton title="tweet" icon={['fab', 'x-twitter']} intent="tweet" text={text} url={url} hashtags={hashtags} related={related} className={formatClassName(styles, `tweet ${className}`)} {...props} />
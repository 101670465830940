import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { formatClassName } from '../../../../../../../../utils/global'
import { createSoloRoom } from '../../../../../../../../utils/server'
import { unlockMultiplayerRequest } from '../../../../../../../api/hub'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../../state/context'
import { hubState } from '../../../../../../../state/hub'
import { HowToPlay } from '../../../how-to-play/how-to-play'
import { ConfirmClash } from '../confirm-clash/confirm-clash'
import { ConfirmGame } from '../confirm-game/confirm-game'
import { GameType } from '../game-type/game-type'
import { Loadout } from '../loadout/loadout'
import styles from './game-mode.module.scss'

export type GameModeProps = GenericModalProps & {
  onClose: () => void
  openLobby: (mode: 'score' | 'clash', loadout?: number) => void
}

const Content = ({ mode, loadout, openConfirmGame, openLobby, onClose }: {
  mode: 'score' | 'clash'
  loadout?: number
  openConfirmGame: (callback: (confirmed: boolean) => void) => void
  openLobby: (mode: 'score' | 'clash', loadout?: number) => void
  onClose: () => void
}) => {
  const { state: { sessionToken, accountInfo }, dispatch } = useHubContext()

  const launchSolo = async () => {
    if (!sessionToken) return

    openConfirmGame(async (confirmed) => {
      if (confirmed && hubState.showLoading) {
        hubState.showLoading(true)

        try {
          const serverURL = await createSoloRoom(sessionToken, mode, dispatch, loadout)

          dispatch({
            type: 'SET_LAST_SERVER_URL',
            lastServerURL: serverURL
          })
        } catch (err) {
          toast.error('Impossible to create the room, please try again later or contact the support team')
        }

        if (hubState.showLoading) {
          hubState.showLoading(false)
        }
      } else {
        onClose()
      }
    })
  }

  const launchMulti = () => {
    openConfirmGame((confirmed) => {
      if (confirmed) {
        openLobby(mode, loadout)
      } else {
        onClose()
      }
    })
  }

  const [loading, setLoading] = useState(false)

  const unlockMultiplayer = async () => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Unlock multiplayer',
        text: 'Are you sure you want to pay 50 gems to unlock the multiplayer?',
        acceptText: 'yes',
        refuseText: 'no',
        async onAccept() {
          setLoading(true)

          const result = await unlockMultiplayerRequest()

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }
          setLoading(false)
        }
      })
    }
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'single')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/single-mode.png`} alt="multi" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <div>Single player</div>
        <Button onClick={launchSolo}>
          single
        </Button>
      </div>
    </div>
    <div className={formatClassName(styles, 'multi')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/multi-mode.png`} alt="multi" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <div>Multi player</div>
        {
          (accountInfo?.unlockedMultiplayer || mode === 'clash')
            ? <Button onClick={launchMulti}>
              multi
            </Button>
            : <Button className={formatClassName(styles, 'gems gray')} onClick={unlockMultiplayer} loading={loading}>
              <div>UNLOCK FOR</div>
              <div><img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />50</div>
            </Button>
        }
      </div>
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Select a mode
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const GameMode = ({ className, onClose, openLobby, show, ...props }: GameModeProps) => {
  const [showConfirmGame, setShowConfirmGame] = useState(false)
  const [showGameType, setShowGameType] = useState(false)
  const [showLoadout, setShowLoadout] = useState(false)
  const [gameType, setGameType] = useState<'score' | 'clash'>()
  const [loadout, setLoadout] = useState<number>()
  const [showTuto, setShowTuto] = useState(true)
  const [confirmGameCallback, setConfirmGameCallback] = useState<(confirmed: boolean) => void>()

  useEffect(() => {
    if (show) {
      setGameType(undefined)
      setShowGameType(true)
      setLoadout(undefined)
    }
  }, [show])

  const openConfirmGame = (callback: (confirmed: boolean, mode: 'score' | 'clash') => void) => {
    setConfirmGameCallback(() => callback)
    setShowConfirmGame(true)
  }

  const onOpenLobby = (mode: 'score' | 'clash', loadout?: number) => {
    setShowConfirmGame(false)

    openLobby(mode, loadout)
  }

  const selectGameType = (type: 'score' | 'clash') => {
    setGameType(type)

    setShowGameType(false)

    if (type === 'clash') {
      setShowLoadout(true)
    }
  }

  const selectLoadout = (loadout: number) => {
    setLoadout(loadout)

    setShowLoadout(false)
  }

  return (
    <>
      <GenericModal
        className={formatClassName(styles, `game-mode ${className}`)}
        title={<Title onClose={onClose} />}
        content={<Content mode={gameType ?? 'score'} loadout={loadout} openConfirmGame={openConfirmGame} openLobby={onOpenLobby} onClose={onClose} />}
        onClickBackground={onClose}
        show={show}
        {...props}
      />
      <HowToPlay show={show && !localStorage.getItem('tuto') && showTuto} onClose={() => {
        localStorage.setItem('tuto', 'done')
        setShowTuto(false)
      }} />
      <GameType show={showGameType} onClose={() => {
        setShowGameType(false)

        if (!gameType) onClose()
      }} onSelect={selectGameType} />
      <Loadout show={showLoadout} onClose={() => {
        setShowLoadout(false)

        if (loadout !== undefined) onClose()
      }} onSelect={selectLoadout} />
      {
        gameType &&
        <>
          {gameType === 'score'
            ? <ConfirmGame show={showConfirmGame} onClose={() => setShowConfirmGame(false)} callback={confirmGameCallback} />
            : <ConfirmClash show={showConfirmGame} onClose={() => setShowConfirmGame(false)} callback={confirmGameCallback} />
          }
        </>
      }
    </>
  )
}
@import "scss-variables";

.homescreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  gap: 1rem;
  justify-content: end;
  overflow: hidden;

  .bg-top,
  .bg-middle,
  .bg-bottom {
    position: absolute;
    width: 100%;
  }

  .bg-top {
    top: 0;
    height: 50%;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  .bg-middle {
    bottom: 40%;
    height: 50%;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: 2;
  }

  .bg-bottom {
    bottom: 0;
    height: 49%;
    background-size: cover;
    background-position: center center;
    z-index: 3;
  }

  .tab {
    width: 100%;
    height: 100%;
  }
}

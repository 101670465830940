// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';
import { MovingEntitySchema } from './MovingEntitySchema'
import { EntitySchema } from './EntitySchema'

export class SkillSchema extends MovingEntitySchema {
    @type("number") public level!: number;
}

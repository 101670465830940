import axios from 'axios'
import {CDN_URL} from '../utils/constants'

export type MaintenanceData = {
  id: number
  fromDate: string | null
  toDate: string | null
  message: string | null
}

export type MaintenanceStatus = {
  hub: MaintenanceData
}

export const getMaintenanceStatus = async () => {
  const response = await axios.get<MaintenanceStatus>(`${CDN_URL}/maintenance/status.json`)

  if (response.status !== 200) return

  return response.data
}
@import "scss-variables";

.your-entry-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  justify-content: space-evenly;
  overflow-x: hidden;
  overflow-y: auto;

  .line {
    display: flex;
    width: 100%;

    .entry {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      color: #fff;
      text-align: center;
      text-shadow: 0px 2.542px 0px rgba(0, 0, 0, 0.83);
      font-family: Supersonic Rocketship;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.72px;
      text-transform: uppercase;

      img {
        height: 4rem;
      }
    }
  }

  .rewards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    color: #fff;
    text-align: center;
    text-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.83);
    font-family: Supersonic Rocketship;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;

    overflow-y: auto;

    .reward {
      display: flex;
      gap: 1rem;
      padding: 1rem 2rem;
      align-items: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;

      .image {
        width: 5rem;
        height: 5rem;
        border-radius: 0.25rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .no-prizes {
      text-align: center;
    }
  }
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes} from 'react'
import {formatClassName} from '../../../../../../utils/global'
import styles from './background.module.scss'

export type BackgroundProps = HtmlHTMLAttributes<HTMLDivElement>

export const Background = ({className, ...props}: BackgroundProps) => {
  return (
    <div className={formatClassName(styles, `background ${className}`)} {...props}>
      <div
        className={formatClassName(styles, 'bg-top')}
        style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/main-bg-top.png')`}}
      >
      </div>
      <div className={formatClassName(styles, 'gradient')}></div>
      <div
        className={formatClassName(styles, 'bg-middle')}
        style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/main-bg-middle.png')`}}
      >
      </div>
      <div
        className={formatClassName(styles, 'bg-bottom')}
        style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/main-bg-bottom.png')`}}
      >
      </div>
    </div>
  )
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React from 'react'
import {formatClassName} from '../../../../../../../../utils/global'
import {Button} from '../../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../../components/modal/modal'
import styles from './depositing-vessels.module.scss'

export type DepositingVesselsProps = GenericModalProps & {
  nbWaiting: number
  nbValidated: number
  nbFailed: number
  onClose: () => void
}

const Content = ({
  nbWaiting,
  nbValidated,
  nbFailed,
  onClose
}: {
  nbWaiting: number
  nbValidated: number
  nbFailed: number
  onClose: () => void
}) => {
  const total = nbWaiting + nbValidated + nbFailed

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'total')}>
      depositing
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessel" />
      {total}
    </div>

    <div className={formatClassName(styles, 'count')}>
      <div className={formatClassName(styles, 'label')}>
        {nbValidated} V
      </div>
      <div className={formatClassName(styles, 'label')}>
        {nbFailed} X
      </div>
    </div>

    <Button className={formatClassName(styles, 'done')} loading={nbWaiting !== 0} disabled={nbWaiting !== 0} onClick={onClose}>
      DONE
    </Button>
  </div>
}


const Title = () =>
  <div className={formatClassName(styles, 'title')}>
    Depositing vessels...
  </div>

export const DepositingVessels = ({className, onClose, nbWaiting, nbValidated, nbFailed, ...props}: DepositingVesselsProps) => {
  return (
    <>
      <GenericModal
        className={formatClassName(styles, `depositing-vessels ${className}`)}
        title={<Title />}
        content={<Content onClose={onClose} nbWaiting={nbWaiting} nbValidated={nbValidated} nbFailed={nbFailed} />}
        {...props}
      />
    </>
  )
}
import {ITextStyle, Text, TextMetrics, TextStyle, Texture} from 'pixi.js'
import {getWidthRatio} from './utils'

const cachedTextTextures: {
  [key: string]: {
    texture: Texture,
    metrics: TextMetrics
  }
} = {}

export const getTextTexture = (textToShow: string, size = 24, color = 0xFFFFFF, stroke?: number) => {
  const textureKey = `${textToShow}-${size}-${color}-${stroke}`

  if (!cachedTextTextures[textureKey]) {
    const obj: Partial<ITextStyle> = {
      fontFamily: 'Cees Hand',
      fontWeight: '500',
      fill: color,
      fontSize: size,
    }

    if (stroke !== undefined) {
      obj.stroke = stroke
      obj.strokeThickness = 2 * getWidthRatio()
    }

    const style = new TextStyle(obj)

    const text = new Text(textToShow, style)
    const textMetrics = TextMetrics.measureText(text.text, style)

    text.updateText(true)

    cachedTextTextures[textureKey] = {
      texture: text.texture,
      metrics: textMetrics
    }
  }

  return cachedTextTextures[textureKey]
}
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useEffect, useState } from 'react'
import { formatClassName } from '../../../../../../../../utils/global'
import { clubClashRequest } from '../../../../../../../api/hub'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../../state/context'
import { ClashData } from '../../clash/type'
import styles from './game-type.module.scss'

export type GameTypeProps = GenericModalProps & {
  onSelect: (type: 'score' | 'clash') => void
}

const Content = ({ onSelect, onClose }: {
  onSelect: (type: 'score' | 'clash') => void
  onClose: () => void
}) => {
  const { state: { accountInfo }, dispatch } = useHubContext()
  const [loading, setLoading] = useState(false)
  const [clubClashData, setClubClashData] = useState<ClashData>()

  useEffect(() => {
    setLoading(true)
    clubClashRequest(() => console.info).then(response => {
      setLoading(false)

      if (response?.status !== 200) {
        return
      }

      setClubClashData(response.data)
    }).catch(() => { setLoading(false) })
  }, [])

  const launchNormal = async () => {
    onSelect('score')
  }

  const launchClash = () => {
    onSelect('clash')
  }

  const joinAClub = () => {
    dispatch({
      type: 'SET_NAVIGATION',
      tab: 'clash'
    })

    onClose()
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'single')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/single-mode.png`} alt="multi" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <div>CLASSIC</div>
        <Button onClick={launchNormal}>
          PLAY
        </Button>
      </div>
    </div>
    <div className={formatClassName(styles, 'clash')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/clash-mode.png`} alt="clash" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <div>CLUB CLASH</div>
        {
          accountInfo?.clubId
            ? <Button onClick={launchClash} disabled={(clubClashData?.startingAt ?? 0) > Date.now() || (clubClashData?.endingAt ?? 0) < Date.now()}>
              {(clubClashData?.startingAt ?? 0) > Date.now() ? 'NOT STARTED' : (clubClashData?.endingAt ?? 0) < Date.now() ? 'ENDED' : 'PLAY'}
            </Button>
            : <Button className={formatClassName(styles, 'gray')} onClick={joinAClub} loading={loading} disabled={(!loading && !clubClashData) || (clubClashData && (clubClashData.startingAt > Date.now() || clubClashData.endingAt < Date.now()))}>
              {clubClashData ? 'JOIN A CLUB' : 'NO CLUB CLASH'}
            </Button>
        }
      </div>
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Type of room
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const GameType = ({ className, onClose, onSelect, show, ...props }: GameTypeProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `game-type ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onSelect={onSelect} onClose={onClose} />}
      onClickBackground={onClose}
      show={show}
      {...props}
    />
  )
}
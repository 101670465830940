@import "scss-variables";

.wallet {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.5rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;

    .vessel {
      margin-top: 1rem;

      img {
        height: 160%;
        margin-right: 1rem;
      }
    }

    button {
      width: min(100%, 18rem);
    }
  }
}

import React, {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {formatClassName} from '../../../utils/global'
import {checkConnectionRequest} from '../../api/hub'
import {ConnectButton} from '../../components/buttons/connect-button'
import {ConnectModal} from '../../components/connect-modal/connect-modal'
import {useHubContext} from '../../state/context'
import styles from './dashboard.module.scss'

import {hubState} from '../../state/hub'
import {Background} from './sub-pages/game/background/background'

export const DashboardPage = () => {
  const {state: {sessionToken, showConnectModal, accountInfo}, dispatch} = useHubContext()

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>()
  const [termsChecked, setTermsChecked] = useState<boolean>()

  const closeConnectModal = () => {
    dispatch({
      type: 'SET_SHOW_CONNECT_MODAL',
      showConnectModal: false
    })
  }

  useEffect(() => {
    if (!sessionToken) {
      if (hubState.clearGame) hubState.clearGame()

      return
    }

    checkConnectionRequest(() => {
      if (hubState.disconnect) {
        hubState.disconnect()
      }
    }).then((res) => {
      dispatch({
        type: 'SET_TWITTER_USERNAME',
        twitterUsername: res?.twitter
      })

      dispatch({
        type: 'SET_DISCORD_USERNAME',
        discordUsername: res?.discord
      })
    })

    const interval = setInterval(async () => {
      const res = await checkConnectionRequest()

      dispatch({
        type: 'SET_TWITTER_USERNAME',
        twitterUsername: res?.twitter
      })

      dispatch({
        type: 'SET_DISCORD_USERNAME',
        discordUsername: res?.discord
      })
    }, 360000)

    return () => {
      clearInterval(interval)
    }
  }, [sessionToken])

  useEffect(() => {
    if (!accountInfo || timeoutId) return

    const timeDiff = accountInfo.energy.lastUpdate + 7_200_000 - Date.now()

    setTimeoutId(
      setTimeout(async () => {
        if (hubState.refreshAccountInfo) {
          await hubState.refreshAccountInfo()

          setTimeoutId(undefined)
        }
      }, timeDiff + 5000)
    )
  }, [accountInfo, timeoutId])

  const openTerms = () => {
    window.open('https://drive.google.com/file/d/1_yHdH2S2XdiYRTfg613mYH0DOXLBqwx2/view', undefined, 'popup')
  }

  return (
    <div className={formatClassName(styles, 'dashboard')}>
      <div className={formatClassName(styles, 'container limited-height')}>
        <div className={formatClassName(styles, 'content overflow-hidden phone-without-padding')}>
          {
            sessionToken
              ? <Outlet />
              : <div className={formatClassName(styles, 'connection')}>
                <Background />
                <img className={formatClassName(styles, 'logo')} src="https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/logo-atlas.png" alt="logo" />
                <div className={formatClassName(styles, 'button-container')}>
                  Connect to play
                  <ConnectButton className={formatClassName(styles, 'connect-button')} disabled={!termsChecked} />
                  <label className={formatClassName(styles, 'terms')}>
                    <input type="checkbox" checked={termsChecked} onChange={e => setTermsChecked(e.target.checked)} />
                    agree to <a href="#" onClick={openTerms}>T&Cs</a>
                    <span className={formatClassName(styles, 'checkmark')}></span>
                  </label>
                </div>
                {/*<div className={formatClassName(styles, 'developed-by')}>
                  <img src="https://cdn.piratesquadnft.com/hub/logo.png" alt="logo" />
                  <span>
                    Developed by <a href="https://piratesquadnft.com/" target="_blank">Pirate Squad</a>
                  </span>
                </div>*/}
              </div>
          }
        </div>
      </div>
      <ConnectModal show={showConnectModal} onHide={closeConnectModal} onClickBackground={closeConnectModal} />
    </div>
  )
}
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import {useHubContext} from '../../state/context'
import {Button, ButtonIconProps} from './button'

export type ConnectButtonProps = Omit<ButtonIconProps, 'icon'> & {
  icon?: IconProp
}

export const ConnectButton = (props: ConnectButtonProps) => {
  const {dispatch} = useHubContext()

  const openConnectModal = (showConnectModal: boolean) => {
    dispatch({
      type: 'SET_SHOW_CONNECT_MODAL',
      showConnectModal
    })
  }

  return <Button {...props} onClick={() => openConnectModal(true)}>SOL Wallet</Button>
}
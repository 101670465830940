import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../../../utils/global'
import {activateCodeRequest} from '../../../../../../api/hub'
import {Button} from '../../../../../../components/buttons/button'
import {Input} from '../../../../../../components/input/input'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {TwitterLinkButton} from '../../../../../../components/twitter/twitter-link-button'
import {hubState} from '../../../../../../state/hub'
import styles from './settings.module.scss'

export type SettingsProps = GenericModalProps & {
  onClose: () => void
}

const Content = () => {
  const [soundMuted, setSoundMuted] = useState(window.localStorage.getItem('music-muted') === 'true')
  const [code, setCode] = useState<string>()
  const [loading, setLoading] = useState(false)

  const toggleSound = () => {
    Howler.mute(!soundMuted)

    window.localStorage.setItem('music-muted', String(!soundMuted))

    setSoundMuted(!soundMuted)
  }

  const submitCode = async () => {
    if (loading || !code) return

    setLoading(true)

    const response = await activateCodeRequest(code)

    if (response?.status === 200 && hubState.refreshAccountInfo) {
      hubState.refreshAccountInfo()

      toast.success('Code used!')
    }

    setLoading(false)
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'sound')} onClick={toggleSound}>
      Sound
      <FontAwesomeIcon className={formatClassName(styles, soundMuted ? '' : 'enabled')} icon={soundMuted ? 'volume-xmark' : 'volume-low'} />
    </div>
    <div>
      <div className={formatClassName(styles, 'subtitle')}>Promo code</div>
      <div className={formatClassName(styles, 'code')}>
        <Input
          className={formatClassName(styles, 'amount')}
          maxLength={32}
          value={code}
          onChange={e => setCode(e.target.value)}
          placeholder={'Enter a promo code...'}
          disabled={loading}
        />
        <Button className={formatClassName(styles, 'submit xsmall')} loading={loading} onClick={submitCode}>SUBMIT</Button>
      </div>
    </div>
    <TwitterLinkButton className={formatClassName(styles, 'twitter small')} />
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Settings
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const Settings = ({className, onClose, ...props}: SettingsProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `settings ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content />}
      onClickBackground={onClose}
      {...props}
    />
  )

}
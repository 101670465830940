// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {type} from '@colyseus/schema'
import {MovingEntitySchema} from './MovingEntitySchema'

export class ItemSchema extends MovingEntitySchema {
  @type('string') public uid!: string
  @type('string') public type!: string
  @type('string') public visibleForClientId!: string
}

@import "scss-variables";

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(6px);
  font-family: "More Sugar";
  font-size: 24px;
  z-index: 500;
  overflow: hidden;

  .background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 102;
  }

  .container {
    z-index: 103;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    pointer-events: none;

    &.full-size {
      width: 100%;
      height: 100%;
    }

    &.image {
      img {
        width: 50%;
        max-width: 300px;
      }
    }
  }

  &.no-blur {
    backdrop-filter: none;
  }

  @media screen and (min-width: $sm-breakpoint) {
    font-size: 32px;
  }

  &.loading-modal {
    z-index: 100;
  }

  .loading-content {
    color: white;
    text-shadow: 4px 3px 0 #444444;
    text-align: center;
    font-weight: 700;

    z-index: 101;
  }

  .window {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    height: fit-content;
    max-height: calc(100vh - 10rem);
    max-width: calc(480px - 2rem);
    background-color: $color-violet;
    word-wrap: break-word;
    font-size: 1.5rem;
    padding-top: 3rem;
    box-shadow: #1119 0px 8px 16px;
    pointer-events: all;

    &::after {
      content: "";
      text-align: center;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-bottom.png);
      width: 100%;
      position: absolute;
      height: 5px;
      bottom: -5px;
      background-size: contain;
    }

    .title {
      position: absolute;
      padding: 1rem;
      text-align: center;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      box-sizing: border-box;
      top: -3px;
      height: 3.5rem;
      font-family: "Cees Hand";

      .cross {
        position: absolute;
        top: -2px;
        right: 0;
        height: 100%;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 1rem;
      flex: 1;
      font-size: 1.25rem;
      overflow: hidden;
      overflow-y: auto;

      .input {
        width: 100%;
      }
    }

    .footer {
      display: flex;
      padding: 1rem;

      button {
        width: 100%;

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ button {
          &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

.modal-enter {
  opacity: 0;
  transform: scale(0.95);
}
.modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    opacity 150ms,
    transform 150ms;
  transition-timing-function: ease-out;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition:
    opacity 150ms,
    transform 150ms;
  transition-timing-function: ease-in;
}

import {SpinePlayer} from '@esotericsoftware/spine-player'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import Countdown, {CountdownRendererFn} from 'react-countdown'
import {clickButtonSound, hoverButtonSound, playMainMusic} from '../../../../../../../sound/sound'
import {nextMonday} from '../../../../../../../utils/date'
import {formatClassName} from '../../../../../../../utils/global'
import {joinOrReconnect} from '../../../../../../../utils/server'
import {getCharacterSpineAt, getMonsterSpineAt, setCharacterSkin} from '../../../../../../../utils/visual'
import {OverlayModal} from '../../../../../../components/modal/modal'
import {getDailyRewardsData} from '../../../../../../data/daily-rewards'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import {Chests} from '../../chests/chests'
import {WeeklyPrizeMenu} from '../../weekly-prize-menu/weekly-prize-menu'
import styles from './battle.module.scss'
import {DailyRewards} from './daily-rewards/daily-rewards'
import {GameMode} from './game-mode/game-mode'
import {Lobby} from './lobby/lobby'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({days, hours, minutes}) =>
  <span>
    {addZeroToNumber(days)}D {addZeroToNumber(hours)}H {addZeroToNumber(minutes)}M
  </span>

export type BattleTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const BattleTab = ({className, onPointerOver, onPointerUp, ...props}: BattleTabProps) => {
  const {state: {accountInfo, roomSession, sessionToken, lastServerURL}, dispatch} = useHubContext()

  const [showGameMode, setShowGameMode] = useState(false)
  const [showLobby, setShowLobby] = useState(false)
  const [showWeeklyPrizeMenu, setShowWeeklyPrizeMenu] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [spine, setSpine] = useState<SpinePlayer>()

  const battleNowRef = useRef<HTMLImageElement>(null)
  const [animationEnded, setAnimationEnded] = useState(false)
  const [showDailyRewards, setShowDailyRewards] = useState(false)
  const [showUnlockedDailyReward, setShowUnlockedDailyReward] = useState(false)
  const [dailyRewards, setDailyRewards] = useState(0)
  const [gameMode, setGameMode] = useState<'score' | 'clash'>()
  const [loadout, setLoadout] = useState<number>()

  const onPointerOverPrepared = (event: React.PointerEvent<HTMLDivElement>) => {
    if (onPointerOver) onPointerOver(event)

    hoverButtonSound.play()
  }

  const onPointerUpPrepared = (event: React.PointerEvent<HTMLDivElement>) => {
    if (onPointerUp) onPointerUp(event)

    clickButtonSound.play()

    setShowGameMode(true)
  }

  useEffect(() => {
    playMainMusic()

    getCharacterSpineAt(0, 'battle_player-container').then(setSpine)
    getMonsterSpineAt(0, 'battle_monster-container')
  }, [])

  useEffect(() => {
    if (!accountInfo) return

    if (window.localStorage.getItem('daily-rewards-score') && Number(window.localStorage.getItem('daily-rewards-score')) < accountInfo.dailyRewards.score) {
      (async () => {
        const data = await getDailyRewardsData()

        setDailyRewards(data.rewards[Math.min(data.rewards.length - 1, accountInfo.dailyRewards.score)])

        setShowUnlockedDailyReward(true)
      })()
    }

    window.localStorage.setItem('daily-rewards-score', String(accountInfo.dailyRewards.score))
  }, [accountInfo])

  useEffect(() => {
    if (!spine || !accountInfo) return

    setCharacterSkin(spine, {
      FUR: accountInfo.character.cosmetics.FUR.equiped,
      EYES: accountInfo.character.cosmetics.EYES.equiped,
      HEAD: accountInfo.character.cosmetics.HEAD.equiped,
      TOP: accountInfo.character.cosmetics.TOP.equiped,
      MOUTH: accountInfo.character.cosmetics.MOUTH.equiped,
    })
  }, [accountInfo, spine])

  useEffect(() => {
    setInitialized(true)

    if (!initialized && roomSession && sessionToken && lastServerURL && hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Reconnect to the game',
        text: 'We detected a running game. Do you want to reconnect to it?',
        onAccept: () => joinOrReconnect(roomSession.roomAvailable, dispatch, sessionToken, lastServerURL, roomSession, lastServerURL),
        onRefuse: () => dispatch({
          type: 'SET_ROOM_SESSION'
        }),
        refuseText: 'leave'
      })
    }
  }, [roomSession, sessionToken, initialized])

  useEffect(() => {
    if (battleNowRef.current) {
      battleNowRef.current.onanimationend = () => {
        setAnimationEnded(true)
      }
    }
  }, [battleNowRef])

  const openPublic = () => {
    dispatch({
      type: 'SET_NAVIGATION',
      tab: 'weekly-prize'
    })
  }

  const openHolder = () => {
    dispatch({
      type: 'SET_NAVIGATION',
      tab: 'holder-weekly-prize'
    })
  }

  const endOfWeeklyPrize = nextMonday()

  // endOfWeeklyPrize = addMinutes(endOfWeeklyPrize, endOfWeeklyPrize.getTimezoneOffset())

  return (
    <div
      className={formatClassName(styles, `battle-tab reveal-element ${className}`)}
      {...props}
    >
      <GameMode show={showGameMode} onClose={() => setShowGameMode(false)} openLobby={(mode: 'score' | 'clash', loadout?: number) => {
        setShowGameMode(false)
        setGameMode(mode)
        setLoadout(loadout)
        setShowLobby(true)
      }} />
      <Lobby show={showLobby} onClose={() => setShowLobby(false)} mode={gameMode ?? 'score'} loadout={loadout} />
      <DailyRewards show={showDailyRewards} onClose={() => setShowDailyRewards(false)} />
      <WeeklyPrizeMenu show={showWeeklyPrizeMenu} onClose={() => setShowWeeklyPrizeMenu(false)} openPublic={openPublic} openHolder={openHolder} />
      <OverlayModal show={showUnlockedDailyReward} onClickBackground={() => setShowUnlockedDailyReward(false)}>
        <div
          className={formatClassName(styles, 'daily-rewards-modal')}
          style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-modal.png')`}}
        >
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-icon.png`} alt="daily-rewards-icon" />
          <div className={formatClassName(styles, 'title')}>
            DAILY REWARD EARNED
          </div>
          <div className={formatClassName(styles, 'description')}>
            {(accountInfo?.dailyRewards.score ?? 0) + 1} days in a row!
          </div>
          <div className={formatClassName(styles, 'rewards')}>
            +{dailyRewards}<img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          </div>
        </div>
      </OverlayModal>

      <div
        className={formatClassName(styles, 'player')}
        id="battle_player-container"
      ></div>
      <div
        className={formatClassName(styles, 'monster')}
        id="battle_monster-container"
      ></div>

      <div className={formatClassName(styles, 'top-line')}>
        <div
          className={formatClassName(styles, 'tile')}
          style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-bg.png')`}}
          onPointerUp={() => setShowDailyRewards(true)}
        >
          <div
            className={formatClassName(styles, 'icon')}
            style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-icon.png')`}}
          ></div>
          <div className={formatClassName(styles, 'content')}>
            <div className={formatClassName(styles, 'title')}>
              DAILY REWARDS
            </div>
            <div className={formatClassName(styles, 'description')}>
              earn bonus <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
            </div>
          </div>
        </div>
        <div
          className={formatClassName(styles, 'tile')}
          style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weekly-price-bg.png')`}}
          onPointerUp={() => setShowWeeklyPrizeMenu(true)}
        >
          <div
            className={formatClassName(styles, 'icon')}
            style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weekly-prize.png')`}}
          ></div>
          <div className={formatClassName(styles, 'content')}>
            <div className={formatClassName(styles, 'title')}>
              WEEKLY PRIZES
            </div>
            <div className={formatClassName(styles, 'description')}>
              <Countdown date={endOfWeeklyPrize} renderer={renderer} />
            </div>
          </div>
        </div>
      </div>
      <Chests className={formatClassName(styles, 'chests')} />

      <img
        ref={battleNowRef}
        className={formatClassName(styles, `battle ${animationEnded ? 'pulse' : 'bounce'}`)}
        src={`${OOGY_VISUAL_CDN_URL}/ui/battle-now.png`}
        alt="battle-now"
        onPointerOver={onPointerOverPrepared}
        onPointerUp={onPointerUpPrepared}
      />
    </div>
  )
}
@import "scss-variables";

.settings {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;

    .subtitle {
      text-align: left;
      color: #db43a3;
      font-family: "Cees Hand";
      font-size: 0.85rem;
    }

    .sound {
      display: flex;
      justify-content: center;
      gap: 1rem;
      box-sizing: border-box;
      color: #d2cff0;
      font-size: 1.5rem;

      &:hover {
        cursor: pointer;
      }

      svg {
        &.enabled {
          color: #db43a3;
        }
      }
    }

    .code {
      position: relative;

      input {
        width: 100%;
        padding-right: 6rem;
      }

      .submit {
        position: absolute;
        right: 1rem;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .twitter {
      margin: auto;
      width: min(100%, 18rem);
    }
  }
}

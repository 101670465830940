@import "scss-variables";

.chests {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .hover,
  .chest {
    &:hover {
      cursor: pointer;
    }
  }

  .chest {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 6rem;
    min-width: 6rem;
    height: 7rem;
    border-radius: 0.5rem;

    .chest-container {
      position: relative;
      flex: 1;
      pointer-events: none;

      .timer {
        display: flex;
        position: absolute;
        width: 75%;
        height: 1rem;
        top: -0.5rem;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 0.15rem;
        border: 0.1rem solid #b54be7;
        background: #70378b;
        box-shadow: 0px 0px 0px 0.1rem rgba(47, 11, 74, 0.78);
        color: #fff;
        font-family: "Cees Hand";
        font-size: 0.8rem;
        z-index: 4;

        .countdown {
          display: flex;
          justify-content: center;
          flex: 1;
          margin-top: 0.25rem;
        }
      }

      .badge {
        position: absolute;
        top: 25%;
        left: 10%;
        font-size: 0.5rem;
        text-transform: uppercase;
        z-index: 3;
      }

      .light,
      .image {
        position: absolute;
        height: auto;
        left: 0;
        right: 0;
        top: 0.25rem;
        margin: auto;
      }

      .light {
        width: 100%;
        opacity: 0.5;
        top: -0.3rem;
        z-index: 1;
        animation: rotating 3s linear infinite;
      }

      .image {
        height: 80%;
        z-index: 2;
      }

      .open-now {
        position: relative;
        display: flex;
        height: 100%;
        align-items: end;
        justify-content: center;
        color: #65ffed;
        text-shadow: 0px 0.1rem 0px rgba(24, 33, 64, 0.83);
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: 0.8rem;
        z-index: 4;
        margin-bottom: 0.2rem;
      }

      .open-slot {
        position: relative;
        display: flex;
        height: 100%;
        padding: 0.5rem;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        text-shadow: 0px 0.1rem 0px #0b77b4;
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 15px;
        z-index: 4;
      }
    }

    .price {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.2rem;
      color: #fff;
      text-shadow: 0px 0.1rem 0px rgba(24, 33, 64, 0.83);
      font-family: Supersonic Rocketship;
      font-size: 0.85rem;
      font-weight: 400;

      img {
        height: 73%;
        margin-right: 0.1rem;
        //margin-bottom: 0.2rem;
      }

      span {
        margin: 0 0.1rem;
      }
    }

    &.empty {
      &::before {
        content: "";

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0.5rem;
        border: 2px solid #00a3ff;
      }

      box-sizing: border-box;
      border: 1px solid rgba(40, 152, 255, 0.21);
      background: #261435;
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px #00a3ff inset,
        0px 3px 0px 0px #4e1f89;

      &:hover {
        &::before {
          border: 2px solid lighten(#00a3ff, 20);
        }

        .chest-container .image {
          opacity: 1;
        }
      }

      .chest-container .image {
        top: 0;
        bottom: 0;
        height: 60%;
        opacity: 0.8;
      }
    }

    &.violet {
      border: 0.4px solid #c985ff;
      background: linear-gradient(180deg, rgba(206, 143, 255), #7944ea);
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px #e992ff inset,
        0px 3px 0px 0px #4e1f89;

      &:hover {
        background: linear-gradient(180deg, lighten(rgba(206, 143, 255), 4), lighten(#7944ea, 4));
      }
    }

    &.yellow {
      border: 0.4px solid #ffd439;
      background: linear-gradient(180deg, rgba(254, 209, 57), rgba(255, 231, 93), #fee45d, #ffc555);
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px #ffed92 inset,
        0px 3px 0px 0px #a55d08;

      &:hover {
        background: linear-gradient(
          180deg,
          lighten(rgba(254, 209, 57), 8),
          lighten(rgba(255, 231, 93), 8),
          lighten(#fee45d, 8),
          lighten(#ffc555, 8)
        );
      }
    }

    &.pink {
      border: 0.4px solid #ff8fc5;
      background: linear-gradient(180deg, #ff8fc5, #fb98c8, #ff8fc5);
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px #fca5cf inset,
        0px 3px 0px 0px #db43a3;

      &:hover {
        background: linear-gradient(180deg, lighten(#ff8fc5, 4), lighten(#fb98c8, 4), lighten(#ff8fc5, 4));
      }
    }

    &.green {
      border: 0.4px solid #62971e;
      background: linear-gradient(180deg, #62971e, #78a63d, #7ca842, #62971e);
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px #89b154 inset,
        0px 3px 0px 0px #165124;

      &:hover {
        background: linear-gradient(
          180deg,
          lighten(#62971e, 4),
          lighten(#78a63d, 4),
          lighten(#7ca842, 4),
          lighten(#62971e, 4)
        );
      }
    }
  }
}

.selected-chest {
  z-index: 11;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.75);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HtmlHTMLAttributes } from 'react'
import { formatClassName } from '../../../../../../../../../utils/global'
import { hubState } from '../../../../../../../../state/hub'
import { Reward, Rewards } from '../../../../chests/rewards/rewards'
import styles from './about-tab.module.scss'

export type AboutTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  rewards: Reward[]
}

export const AboutTab = ({ className, rewards, ...props }: AboutTabProps) => {
  const openPrizeDistribution = () => {
    if (hubState.showModal) {
      hubState.showModal({
        title: 'Prize distribution',
        text: 'Prizes below will be distributed randomly to the top 50% of performers on the winning Club. All winning Club members will receive the Star Atlas Gun.'
      })
    }
  }

  return (
    <div className={formatClassName(styles, `about-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'prizes-title')}>Prizes <FontAwesomeIcon icon="circle-question" onClick={openPrizeDistribution} /></div>
      <Rewards flipped className={formatClassName(styles, 'big prizes absolute')} rewards={rewards} />
      {/*<img className={formatClassName(styles, 'tba')} src={`${OOGY_VISUAL_CDN_URL}/ui/tba.png`} alt="gem" />*/}
    </div>
  )
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React from 'react'
import {formatClassName} from '../../../../../../../utils/global'
import {Button} from '../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {Reward, Rewards} from '../rewards/rewards'
import styles from './drop-table.module.scss'

export type DropTableProps = GenericModalProps & {
  drops: Reward[]
  onClose: () => void
}

export const DropTable = ({className, drops: rewards, onClose, ...props}: DropTableProps) => {
  const title = <div className={formatClassName(styles, 'title')}>
    Drop Table
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

  const content = <>
    <div className={formatClassName(styles, 'description')}>
      Chest rewards are a random roll across several drop tables. The rarer the chest, the better your odds.You will be guaranteed <span>at least one</span> prize from below.
    </div>
    <Rewards className={formatClassName(styles, 'rewards')} rewards={rewards} flipped />
  </>

  const footer = <>
    <Button className={formatClassName(styles, 'small')} onClick={onClose}>GOT IT</Button>
  </>

  return (
    <GenericModal
      className={formatClassName(styles, `drop-table ${className}`)}
      title={title}
      content={content}
      footer={footer}
      {...props}
    />
  )

}
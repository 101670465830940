import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { HtmlHTMLAttributes, useEffect, useState } from 'react'
import { formatClassName } from '../../../../../../../../../utils/global'
import { getPreviousWeeklyPrize } from '../../../../../../../../api/hub'
import { useHubContext } from '../../../../../../../../state/context'
import { WeeklyPrize } from '../../type'
import styles from './previous-week-tab.module.scss'

export type PreviousWeekTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  holder?: boolean
}

export const PreviousWeekTab = ({ className, holder, ...props }: PreviousWeekTabProps) => {
  const { state: { accountInfo, publicKey } } = useHubContext()

  const [data, setData] = useState<WeeklyPrize>()

  useEffect(() => {
    getPreviousWeeklyPrize(holder, console.error).then(response => setData(response?.data))
  }, [accountInfo])

  return (
    <div className={formatClassName(styles, `previous-week-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'rewards')}>
        {
          data?.rewards?.map((reward, index) =>
            <div
              key={index}
              style={{ backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/${!holder ? 'violet-' : ''}weekly-price-bg.png')` }}
              className={formatClassName(styles, 'reward')}
            >
              <div className={formatClassName(styles, 'image')} style={{ backgroundImage: `url('${reward.imageURL}')` }}></div>
              {reward.name} ({data.winners?.[index]?.username ? (publicKey === data.winners[index].publicKey ? 'YOU WON!' : data.winners[index].username) : 'no winner'})
            </div>
          )
        }
        {
          !data && <div className={formatClassName(styles, 'no-prizes')}>No prizes won last week</div>
        }
      </div>
    </div>
  )
}
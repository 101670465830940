import { PlayOptions, Sound, sound } from '@pixi/sound'
import { SoundName, Weapon } from './enums'
import { playSpatialSound } from './sound'
import { gameState } from './state'

export const weaponFiring = (clientId: string) => {
  const localPlayer = gameState.level?.state.localPlayer
  const player = gameState.references.players.get(clientId)

  if (!player || !localPlayer) return

  let weaponSound: Sound | undefined = undefined
  const options: PlayOptions = {}

  switch (player.schema.weapon.type) {
    case Weapon.CommonBlaster:
    case Weapon.EpicBlaster:
    case Weapon.FabledBlaster:
    case Weapon.MachineGun:
    case Weapon.Carrot:
    case Weapon.GanAutoRifle:
    case Weapon.XMAS23:
      weaponSound = sound.find(SoundName.WeaponBlaster)
      break
    case Weapon.NinjaStar:
      weaponSound = sound.find(SoundName.WeaponNinjaStar)
      break
    case Weapon.Shotgun:
      weaponSound = sound.find(SoundName.WeaponShotgun)
      break
    case Weapon.Flamethrower:
      weaponSound = sound.find(SoundName.WeaponFlame)
      options.loop = true
      break
  }

  if (weaponSound && weaponSound.instances.length < 3 && (!weaponSound.instances[weaponSound.instances.length - 1] || weaponSound.instances[weaponSound.instances.length - 1].progress > 0.25)) {
    playSpatialSound(localPlayer, player.schema, weaponSound, localPlayer.clientId === player.schema.clientId, options)
  }
}
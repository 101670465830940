import { Spine } from '@pixi-spine/runtime-4.1'
import { Sound, sound } from '@pixi/sound'
import { Graphics, Sprite } from 'pixi.js'
import { Bullet, Skill, SoundName } from './enums'
import { BulletSchema } from './server/schema/BulletSchema'
import { playSpatialSound } from './sound'
import { gameState } from './state'
import { segmentAngle } from './utils'

/**
 * Create a new bullet
 * @param bulletSchema The bullet schema
 * @returns The bullet object
 */
export const initBullet = (bulletSchema: BulletSchema, launchingElement?: Spine): Sprite | Graphics | Spine | undefined => {
  let element
  let ratio: number

  switch (bulletSchema.type) {
    case Bullet.Yellow:
    case Bullet.Blue:
    case Bullet.Pink:
    case Bullet.Red:
    case Bullet.XSMAS:
    case Bullet.Riffle:
    case Bullet.Slime:
      element = new Sprite(gameState.resources[`${bulletSchema.type}-bullet`])

      ratio = Math.max(bulletSchema.width / element.width, bulletSchema.height / element.height)

      element.pivot.set(0, element.height / 2)

      element.scale.set(ratio, ratio)

      element.position.set(launchingElement ? launchingElement.x : bulletSchema.x, launchingElement ? launchingElement.y : bulletSchema.y)

      element.zIndex = 10

      break
    case Bullet.Carrot:
      element = new Sprite(gameState.resources[`${bulletSchema.type}-bullet`])

      ratio = Math.max(bulletSchema.width / element.width, bulletSchema.height / element.height) * 1.45

      element.pivot.set(0, element.height / 2)

      element.scale.set(ratio, ratio)

      element.position.set(launchingElement ? launchingElement.x : bulletSchema.x, launchingElement ? launchingElement.y : bulletSchema.y)

      element.zIndex = 10

      break
    case Bullet.Card:
      element = new Sprite(gameState.resources[`${bulletSchema.type}-bullet`])

      ratio = Math.max(bulletSchema.width / element.width, bulletSchema.height / element.height)

      element.pivot.set(element.width / 2, element.height / 2)

      element.scale.set(ratio, ratio)

      element.position.set(launchingElement ? launchingElement.x : bulletSchema.x, launchingElement ? launchingElement.y : bulletSchema.y)

      element.zIndex = 10

      break
    case Bullet.Boss1:
    case Bullet.Rocket:
      element = new Spine(gameState.resources[`${bulletSchema.type}-bullet`].spineData)

      ratio = Math.max(bulletSchema.width / element.width, bulletSchema.height / element.height)

      //element.pivot.set(element.width / 2, element.height / 2)

      element.scale.set(ratio, ratio)

      element.position.set(bulletSchema.x, bulletSchema.y)

      element.state.setAnimationByName(0, 'IDLE', true)

      element.zIndex = Number.MAX_SAFE_INTEGER - 2

      break
    case Bullet.Boss2:
    case Bullet.Yeti:
      element = new Sprite(gameState.resources[`${bulletSchema.type}-bullet`])

      ratio = Math.max(bulletSchema.width / element.width, bulletSchema.height / element.height)

      element.pivot.set(element.width / 2, element.height / 2)

      element.scale.set(ratio, ratio)

      element.position.set(bulletSchema.x, bulletSchema.y)

      element.zIndex = Number.MAX_SAFE_INTEGER - 2

      break
  }

  return element
}

export const addBullet = (bulletSchema: BulletSchema) => {
  if (!gameState.viewport || !gameState.level) return

  let launchingElement: Spine | undefined

  if (bulletSchema.fromPlayerId) {
    const playerState = gameState.references.players.get(bulletSchema.fromPlayerId)

    if (bulletSchema.type === Bullet.Rocket) {
      launchingElement = gameState.references.skills.get(bulletSchema.fromPlayerId)?.get(Skill.RocketDrone)?.element
    } else {
      launchingElement = gameState.references.guns.get(bulletSchema.fromPlayerId)
    }

    if (playerState && bulletSchema.type !== Bullet.Rocket && bulletSchema.fromPlayerId && bulletSchema.fromPlayerId === gameState.level.state.localPlayer?.clientId) {
      playerState.lastShootingTime = Date.now()
    }
  }

  const bulletList = gameState.references.bullets

  const bullet = initBullet(bulletSchema, launchingElement)

  if (!bullet) return

  const data = {
    element: bullet,
    schema: bulletSchema
  }

  bulletList.set(bulletSchema.uid, data)

  gameState.viewport.addChild(bullet)

  return data
}

/**
 * Remove a bullet
 * @param bullet The bullet destroy
 * @param sound The sound to play 
 */
export const removeBullet = (bullet: BulletSchema, sound?: Sound) => {
  if (!gameState.references.bullets) return

  if (sound) sound.play()

  const localBullet = gameState.references.bullets.get(bullet.uid)

  if (localBullet) {
    gameState.references.bullets.delete(bullet.uid)

    localBullet.element.destroy()
  }
}

export const explosion = (x: number, y: number, width: number, height: number) => {
  if (!gameState.viewport) return

  const explosion = new Spine(gameState.resources['rocket-explosion'].spineData)

  const explosionRatio = width / explosion.width

  explosion.scale.set(explosionRatio, explosionRatio)
  explosion.x = x
  explosion.y = y

  explosion.zIndex = Number.MAX_SAFE_INTEGER

  explosion.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'EXPLOSION') {
        setTimeout(() => {
          try {
            explosion.destroy()
            // eslint-disable-next-line no-empty
          } catch { }
        })
      }
    }
  })

  explosion.state.setAnimation(0, 'EXPLOSION')

  gameState.viewport.addChild(explosion)

  const localPlayer = gameState.level?.state.localPlayer

  if (!localPlayer) return

  const explosionSound = sound.find(SoundName.SkillRocketDrone)

  if (explosionSound) {
    playSpatialSound(localPlayer, explosion, explosionSound)
  }
}

export const bulletCollided = (x: number, y: number) => {
  const localPlayer = gameState.level?.state.localPlayer

  if (!localPlayer) return

  const impactSound = sound.find(SoundName.Impact)

  if (impactSound) {
    playSpatialSound(localPlayer, { x, y }, impactSound)
  }
}


/**
 * Update the bullets positions
 * @param delta The delta (time passed since previous update)
 */
export const updateBullets = (delta: number) => {
  const bulletsList = gameState.references.bullets

  if (!gameState.viewport || !gameState.level || !bulletsList) return

  bulletsList.forEach(bullet => {
    if (!bullet) return

    const delta01 = delta * (bullet.schema.ellipticMovement ? 0.2 : 0.02)

    const {
      x,
      y
    } = bullet.element

    const {
      dx,
      dy
    } = bullet.schema

    let newX = bullet.schema.x - bullet.schema.boundingWidth / 2
    let newY = bullet.schema.y - bullet.schema.boundingHeight / 2

    if (bullet.schema.type === Bullet.Boss1 || bullet.schema.type === Bullet.Boss2 || bullet.schema.type === Bullet.Yeti) {
      newX = bullet.schema.x + bullet.schema.boundingWidth / 2
      newY = bullet.schema.y + bullet.schema.boundingHeight / 2
    } else {
      newX = bullet.schema.x - bullet.schema.boundingWidth / 2
      newY = bullet.schema.y - bullet.schema.boundingHeight / 2
    }

    let computedX = x + dx * delta
    let computedY = y + dy * delta

    const differenceX = newX - computedX
    const differenceY = newY - computedY

    computedX += differenceX * delta01
    computedY += differenceY * delta01

    //if (Math.abs(differenceX) > 100 || Math.abs(differenceX) < 0.01) {
    //  computedX = newX
    //}

    //if (Math.abs(differenceY) > 100 || Math.abs(differenceY) < 0.01) {
    //  computedY = newY
    //}

    if (bullet.schema.type === Bullet.Card) {
      bullet.element.rotation += 0.2 * delta
    } else if (bullet.schema.type !== Bullet.Boss1 && bullet.schema.type !== Bullet.Boss2 && bullet.schema.type !== Bullet.Yeti) {
      const angle = segmentAngle(0, 0, dx, dy)
      bullet.element.rotation = angle

      if (bullet.schema.type === Bullet.Rocket) {
        bullet.element.rotation += Math.PI / 2
      }
    } else if (bullet.schema.type === Bullet.Boss2 || bullet.schema.type === Bullet.Yeti) {
      bullet.element.rotation += Math.PI / 100
    }

    //const graphic = new Graphics()
    //  .beginFill(0xff0000).drawRect(bullet.schema.x, bullet.schema.y, bullet.schema.width, bullet.schema.height).endFill()
    //  .beginFill(0x00ff00).drawRect(bullet.schema.x + bullet.schema.boundingX, bullet.schema.y + bullet.schema.boundingY, bullet.schema.boundingWidth, bullet.schema.boundingHeight).endFill()
    //graphic.zIndex = 12
    //gameState.viewport!.addChild(graphic)

    bullet.element.position.set(
      computedX,
      computedY
    )
  })
}
@import "scss-variables";

.input {
  display: flex;
  font-family: "More Sugar";
  font-size: 1rem;
  border: none;
  padding: 0.5rem 1.5rem;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: $text-color;
  background-color: transparent;
  line-height: 2rem;

  &::placeholder {
    font-size: 0.9em;
    color: #d2cff0;
  }

  &:focus {
    outline: none;
  }
}

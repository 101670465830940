@import "scss-variables";

.confirm-clash {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;

    button {
      img {
        height: 60%;
        margin-right: 0.5rem;
      }
    }

    .balance {
      display: flex;
      align-items: center;
      border-radius: 13px;
      background: linear-gradient(180deg, rgba(176, 111, 227, 0.98) 0%, #713fdb 100%);
      box-shadow:
        0px 4px 7px 0px rgba(233, 146, 255, 0.86) inset,
        0px 3px 0px 0px #582499;
      height: 5rem;
      gap: 2rem;
      padding: 1rem;
      margin-bottom: 1rem;
      width: 55%;
      min-width: 12rem;

      img {
        width: auto;
        height: 80%;
      }

      .description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: #fff;
        font-family: "Cees Hand";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        div {
          text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.83);
          font-family: "Supersonic Rocketship";
          letter-spacing: 0.84px;
        }
      }
    }

    .balance-entries {
      display: flex;
      justify-content: center;
      gap: 2rem;

      .balance-entry {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #fff;
        text-shadow: 0px 2.605px 0px #000;
        font-family: "Supersonic Rocketship";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        img {
          height: 2rem;
        }
      }
    }

    .cost {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      width: 100%;

      button {
        width: min(100%, 18rem);
      }

      .free {
        font-size: 1.2rem;

        >img {
          height: 140%;
        }

        >div {
          flex: 1;
        }
      }
    }
  }

  .gems {
    width: min(100%, 18rem);

    .error {
      color: #ff6262;
    }

    img {
      height: 100%;
      margin-right: 1rem;
    }
  }
}
@import "scss-variables";

.rewards {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  padding: 1rem;

  &.absolute {
    .image-container {
      position: absolute;
      left: 0;
      right: 0;
    }

    .text {
      position: absolute;
      bottom: 0.5rem;
    }
  }

  &.big {
    .reward {
      min-width: min(7.5rem, 25vw);
      min-height: min(9rem, 30vw);
    }
  }

  .reward {
    position: relative;

    min-width: min(6rem, 20vw);
    min-height: min(7rem, 25vw);
    border-radius: 0.5rem;

    >.front,
    >.back {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 0.5s;
      transition-property: transform, opacity;
      background: linear-gradient(180deg, rgba(176, 111, 227, 0.98) 0%, #713fdb 100%);
      box-shadow:
        0px 4px 0px 0px rgba(0, 0, 0, 0.25),
        0px 4px 7px 0px rgba(233, 146, 255, 0.86) inset,
        0px 3px 0px 0px #582499;
    }

    >.front {
      transform: rotateY(0deg);

      &.special {
        background: none;
        box-shadow: none;
      }
    }

    >.back {
      position: absolute;
      opacity: 0;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      transform: rotateY(-180deg);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.5rem;
      box-sizing: border-box;

      &.common {
        background: linear-gradient(180deg, rgba(222, 210, 242, 0.98) 0%, #9c7ce1 100%);
        box-shadow:
          0px 4px 0px 0px rgba(0, 0, 0, 0.25),
          0px 4px 7px 0px rgba(81, 70, 84, 0.86) inset,
          0px 3px 0px 0px #423d48;
      }

      &.rare {
        background: linear-gradient(180deg, rgba(220, 250, 196, 0.98) 0%, #eee507 100%);
        box-shadow:
          0px 4px 0px 0px rgba(0, 0, 0, 0.25),
          0px 4px 7px 0px rgba(253, 255, 230, 0.86) inset,
          0px 3px 0px 0px #b2b515;
      }

      &.fabled {
        background: linear-gradient(180deg, rgba(173, 255, 245, 0.98) 0%, #8a1f7f 100%);
        box-shadow:
          0px 4px 0px 0px rgba(0, 0, 0, 0.25),
          0px 4px 7px 0px rgba(158, 138, 152, 0.86) inset,
          0px 3px 0px 0px #eba9fb;
      }

      &.weapon {
        background: linear-gradient(180deg, rgba(250, 185, 148, 0.98) 0%, #a7ea87 100%);
        box-shadow:
          0px 4px 0px 0px rgba(0, 0, 0, 0.25),
          0px 4px 7px 0px rgba(239, 158, 235, 0.86) inset,
          0px 3px 0px 0px #bdd97f;
      }

      &.special {
        background: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/metallic-bg.png);
        background-position: center;
        background-size: cover;
        box-shadow: none;
      }

      &.tokens {
        background: linear-gradient(180deg,
            rgba(227, 174, 111, 0.98) 0%,
            rgba(227, 194, 111, 0.98) 0.01%,
            #a86d28 100%);
        box-shadow:
          0px 4px 0px 0px rgba(0, 0, 0, 0.25),
          0px 4px 7px 0px rgba(255, 224, 146, 0.86) inset,
          0px 3px 0px 0px #7c4b1e;
      }

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        flex: 1;

        .image {
          width: 80%;
          max-height: 100%;

          &.generic {
            width: 100%;
            border-radius: 0.5rem;
          }
        }
      }

      .text {
        color: #fff;
        text-align: center;
        text-shadow: 0px 0.2rem 0px #201728;
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
      }

      .rate {
        position: absolute;
        color: #555;
        text-align: center;
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: 0.8rem;
        letter-spacing: 0.05rem;
        padding: 0.25rem 0.5rem;
        left: 0.5rem;
        top: 0.5rem;
        background-color: #ffffffaa;
        border-radius: 0.5rem;
        backdrop-filter: blur(6px);
      }
    }

    &.show {
      >.front {
        transform: rotateY(180deg);
      }

      >.back {
        opacity: 1;
        transform: rotateY(0deg);
      }
    }
  }
}
import axios from 'axios'
import {Weapon} from 'oogy-blast/src/enums'
import {OOGY_DATA_CDN_URL} from 'oogy-blast/src/utils/constants'
import {CosmeticData} from './cosmetics'

export type CharacterTrait = {
  levels: {
    value: number
    priceToUnlock: {
      gems: number
    }
  }[]
}

export type CharacterStatisticsData = {
  width: number
  height: number
  bounding: {
    x: number
    y: number
    width: number
    height: number
  }
  colors: string[]
  levels: number[]
  reviveCost: {
    gems?: number
    sol?: number
  }
  stats: {
    speed: CharacterTrait
    health: CharacterTrait
    defense: CharacterTrait
    criticalChance: CharacterTrait
    dodge: CharacterTrait
  }
}

export type CharacterInfo = {
  stats: {
    [name in keyof CharacterStatisticsData['stats']]: number
  }
  cosmetics: {
    [type in keyof CosmeticData]: {
      equiped?: string
      unlocked: string[]
    }
  }
  weapons: {
    equiped: Weapon
    unlocked: {
      [name in Weapon]?: number
    }
  }
}

let CACHE: CharacterStatisticsData
let CACHE_TIME = 0

export const getCharacterData = async () => {
  if (CACHE_TIME < Date.now() - 600000) {
    if (CACHE) CACHE_TIME = Date.now()

    const response = await axios.get<CharacterStatisticsData>(`${OOGY_DATA_CDN_URL}/character/character.json`)

    if (response.status === 200) {
      CACHE_TIME = Date.now()
      CACHE = response.data
    }
  }

  return CACHE
}
import gsap from 'gsap'
import {TilingSprite} from 'pixi.js'
import {gameState} from './state'

export const initBackground = (name: string) => {
  if (!gameState.viewport || !gameState.resources || !gameState.level) return

  const sprite = new TilingSprite(gameState.resources[`level-bg-${name}`], gameState.level.state.worldSize, gameState.level.state.worldSize)

  sprite.zIndex = 1
  //sprite.alpha = 0.7

  if (gameState.references.ui.level.background) {
    sprite.zIndex = 0

    gsap.to(gameState.references.ui.level.background, {
      duration: 1,
      alpha: 0
    }).then(() => {
      gameState.references.ui.level.background?.destroy()

      gameState.references.ui.level.background = sprite

      sprite.zIndex = 1
    })
  } else {
    gameState.references.ui.level.background = sprite
  }

  gameState.viewport.addChild(sprite)
}
import {WalletSignMessageError} from '@solana/wallet-adapter-base'
import {useWallet} from '@solana/wallet-adapter-react'
import {Transaction} from '@solana/web3.js'
import {AxiosResponse} from 'axios'
import base58 from 'bs58'
import {useCallback, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {setSessionInfo} from '../../utils/session'
import {connectToServer, generateNonce} from '../api/hub'
import {useHubContext} from '../state/context'
import {hubState} from '../state/hub'
import {ConnectResponseType} from './types'
import {useHub} from './use-hub'

export const useSolanaWallet = () => {
  const {disconnect: disconnectHub} = useHub()
  const {dispatch, state: {publicKey: hubPublicKey, isLedger}} = useHubContext()
  const {signMessage, signTransaction, sendTransaction, signAllTransactions, disconnect, publicKey, connected, wallet} = useWallet()

  const [loading, setLoading] = useState(false)

  const walletConnected = !!wallet

  const connect = useCallback(async (): ConnectResponseType => {
    if (!publicKey) return

    try {
      setLoading(true)

      let response: AxiosResponse<{
        sessionToken: string
        username: string
        userUuid: string
        twitter?: {
          username?: string
        } | undefined
        discord?: {
          username?: string
          serverJoined?: boolean
        } | undefined
      }, any>

      const {nonce, tx: createdTx} = await generateNonce(isLedger, publicKey.toString())

      if (!nonce) {
        toast.error('Impossible to get a nonce, please try again later or contact the support team.')
        setLoading(false)

        return
      }

      if (isLedger) {
        if (!signTransaction) return

        if (!createdTx) {
          toast.error('Impossible to get the transaction to sign with your Ledger, please try again later or contact the support team.')
          setLoading(false)

          return
        }

        const tx = Transaction.from(Buffer.from(createdTx, 'base64'))

        const signedTx = await signTransaction(tx)

        response = await connectToServer({
          publicKey: publicKey.toString(),
          signedTx: signedTx.serialize().toString('base64'),
          nonce
        })
      } else {
        if (!signMessage) return

        const message = 'wallet-ownership-verification'

        const signature = await signMessage(new TextEncoder().encode(`${message}_${nonce}`))

        response = await connectToServer({
          publicKey: publicKey.toString(),
          signature: base58.encode(signature),
          message,
          nonce
        })
      }

      if (response.status === 401) {
        toast.error('We cannot verify your wallet ownership, please try again later or contact the support team.')

        disconnectHub()
      } else if (response.status === 404) {
        toast.error('Request error, please try again later or contact the support team.')

        disconnectHub()
      } else {
        const data = response.data

        if (data && data.sessionToken) {
          dispatch({type: 'SET_SESSION_TOKEN', sessionToken: data.sessionToken})
          dispatch({type: 'SET_PUBLIC_KEY', publicKey: publicKey.toString()})

          setSessionInfo({
            sessionToken: data.sessionToken,
            publicKey: publicKey.toString(),
            twitterUsername: data.twitter?.username,
            discordUsername: data.discord?.username,
            discordServerJoined: data.discord?.serverJoined,
            username: data.username,
            isLedger
          })

          hubState.sessionToken = data.sessionToken

          if (data.twitter?.username) {
            dispatch({type: 'SET_TWITTER_USERNAME', twitterUsername: data.twitter.username})
          }

          if (data.discord?.username) {
            dispatch({type: 'SET_DISCORD_USERNAME', discordUsername: data.discord.username})
          }

          if (data.discord?.serverJoined) {
            dispatch({type: 'SET_DISCORD_SERVER_JOINED', discordServerJoined: data.discord.serverJoined})
          }

          if (data.username) {
            dispatch({type: 'SET_USERNAME', username: data.username})
          }

          if (data.userUuid) {
            dispatch({type: 'SET_USER_UUID', userUuid: data.userUuid})
          }

          if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()

          setLoading(false)

          return data
        } else {
          toast.error('Impossible to find the sessionToken, please try again later or contact the support team')

          disconnectHub()
        }
      }
    } catch (err) {
      if (err instanceof WalletSignMessageError && err.error.code === -32002) {
        return
      }

      toast.info((err as any)?.response?.data?.message ?? 'Wallet change or request error, try to connect again or contact the support team')

      disconnectHub()

      console.info(err)
    }

    setLoading(false)
  }, [publicKey, signMessage])

  useEffect(() => {
    if (connected && hubPublicKey && (hubPublicKey !== publicKey?.toString())) {
      disconnectHub()

      if (hubState.showModal) {
        hubState.showModal({
          title: 'Disconnected',
          text: 'You have been disconnected'
        })
      }
    }
  }, [disconnectHub, connected, publicKey, hubPublicKey])

  return {
    connect,
    disconnect,
    signMessage,
    signTransaction,
    signAllTransactions,
    sendTransaction,
    connected,
    walletConnected,
    loading,
    publicKey: publicKey?.toString()
  }
}
@import "scss-variables";

.navigation {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 4.8rem;
  font-size: 0.9rem;

  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 2px;

  .hover {
    &:hover {
      cursor: pointer;
    }
  }

  .bg,
  .active-bg,
  .tabs {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .bg,
  .active-bg {
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    margin-top: 1px;
  }

  .bg {
    z-index: 0;
  }

  .active-bg {
    z-index: 1;
  }

  .tabs {
    z-index: 2;

    .tab {
      display: flex;
      align-items: end;
      justify-content: center;
      position: relative;
      flex: 1;
      height: 100%;

      &.ranking .icon {
      }
      &.battle .icon {
        height: 100%;
        left: 15%;
      }
      &.character .icon {
        top: -0.75rem;
        height: 75%;
      }

      .title {
        color: white;
        text-shadow: 0px 0.1rem 0px rgba(24, 33, 64, 0.83);
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: 0.9rem;
        visibility: hidden;
      }

      .icon {
        position: absolute;
        top: -1rem;
        left: 0;
        right: 0;
        margin: auto;
        height: 80%;
        transition: all 300ms;
      }

      &.active {
        .arrow-left,
        .arrow-right {
          visibility: visible;
        }

        .icon {
          transform: scale(1.15);
        }

        .title {
          visibility: visible;
        }
      }

      .arrow-left,
      .arrow-right {
        visibility: hidden;
        position: absolute;
        height: 22%;
        top: 35%;
      }

      .arrow-left {
        top: 35%;
        left: 15%;
      }

      .arrow-right {
        right: 15%;
        transform: scaleX(-1);
      }
    }
  }
}

import axios from 'axios'
import {OOGY_DATA_CDN_URL} from 'oogy-blast/src/utils/constants'

export type DailyRewardsData = {
  rewards: number[]
}

let CACHE: DailyRewardsData
let CACHE_TIME = 0

export const getDailyRewardsData = async () => {
  if (CACHE_TIME < Date.now() - 600000) {
    if (CACHE) CACHE_TIME = Date.now()

    const response = await axios.get<DailyRewardsData>(`${OOGY_DATA_CDN_URL}/daily-rewards/daily-rewards.json`)

    if (response.status === 200) {
      CACHE_TIME = Date.now()
      CACHE = response.data
    }
  }

  return CACHE
}
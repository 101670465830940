import { AccountMeta } from '@solana/web3.js'
import axios, { AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import { AccountInfo } from '../data/account'
import { CosmeticData } from '../data/cosmetics'
import { Reward } from '../pages/dashboard/sub-pages/game/chests/rewards/rewards'
import { ClashData } from '../pages/dashboard/sub-pages/game/tabs/clash/type'
import { WeeklyPrize } from '../pages/dashboard/sub-pages/game/tabs/weekly-prize/type'
import { hubState } from '../state/hub'

export const tryOrReconnect = async <T = object>(requestConfig: AxiosRequestConfig, onError?: (err: unknown) => void) => {
  let sessionToken = hubState.sessionToken

  if (!sessionToken) {
    toast.error('Impossible to retrieve the sessionToken, please refresh the page.')
    return
  }

  let result

  try {
    result = await axios.request<T>({
      ...requestConfig,
      headers: {
        'Authorization': `Bearer ${sessionToken}`
      }
    })
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 401) {
      try {
        const response = await axios.post<{ sessionToken: string }>('/api/v1/hub/refresh', undefined, {
          withCredentials: true
        })

        if (response && hubState.setSessionToken) {
          sessionToken = response.data.sessionToken

          hubState.sessionToken = sessionToken
          hubState.setSessionToken(sessionToken)

          result = await axios.request<T>({
            ...requestConfig,
            headers: {
              'Authorization': `Bearer ${sessionToken}`
            }
          })

          return result
        }
      } catch (err) {
        console.log(err)
      }

      try {
        toast.info('Your token is out of date, please sign again the authentification message')

        if (hubState.disconnect) {
          hubState.disconnect()
        }
      } catch (err) {
        console.log(err)
        if (typeof err === 'string') {
          toast.error(err)
        } else {
          toast.error('Unknown error, please try again or contact the support team')
        }
      }
    } else if (onError) {
      onError(err)
    } else if (axios.isAxiosError(err) && typeof err.response?.data === 'string') {
      toast.error(err.response?.data)
    } else if (typeof err === 'string') {
      toast.error(err)
    } else {
      toast.error('Unknown error, please try again or contact the support team')
    }

    console.log(err)
  }

  return result
}

export const generateNonce = async (ledger?: boolean, publicKey?: string) => {
  const response = await axios<{
    nonce: string
    tx?: string
  }>({
    url: '/api/v1/hub/nonce',
    method: 'get',
    params: {
      ledger,
      publicKey
    }
  })

  return response.data
}

export const connectToServer = async (params: {
  publicKey: string
  nonce: string
  signature?: string
  message?: string
  signedTx?: string
}) => {
  const {
    publicKey,
    nonce,
    signature,
    message,
    signedTx
  } = params

  const response = await axios.get<{
    sessionToken: string
    username: string
    userUuid: string
    twitter?: {
      username?: string
    },
    discord?: {
      username?: string
    }
  }>('/api/v1/hub/connect', {
    params: {
      publicKey,
      nonce,
      signature,
      message,
      signedTx
    }
  })

  return response
}

export const connectLedgerToServer = async (publicKey: string, signedTx: string, nonce: string) => {
  return await connectToServer({
    publicKey,
    signedTx,
    nonce
  })
}

export const changeUsernameRequest = async (username: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/username',
    method: 'POST',
    data: {
      username
    }
  }, onError)

  return result
}

export const checkConnectionRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    twitter?: string
    discord?: string
  }>({
    url: '/api/v1/hub/check-connection',
    method: 'GET'
  }, onError)

  return result?.data
}

export const getServersList = async (onError?: (err: unknown) => void) => {
  // localhost
  if (window.location.hostname.includes('localhost')) {
    return {
      status: 200,
      data: {
        local: 1
      }
    }
  }

  const result = await tryOrReconnect<{
    [name: string]: number
  }>({
    url: '/api/v1/hub/servers-list',
    method: 'GET'
  }, onError)

  return result
}

export const pingServer = async (serverName: string) => {
  const ping: number[] = []

  for (let i = 0; i < 4; i++) {
    try {
      const pingTime = Date.now()

      const pingResponse = await axios.get(`https://${serverName}-i0-blast.oogy.io/ping`, {
        timeout: 500
      })

      if (pingResponse.status === 200) {
        ping.push(Date.now() - pingTime)
      }
    } catch (err) { /* empty */ }
  }

  if (ping.length > 1) {
    return Math.ceil(ping.slice(1).reduce((previous, current) => previous + current, 0) / (ping.length - 1))
  }
}

export const getEstimatedLeaderboard = async (score: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    actualRank: number
    nextRank: number
    score: number
    scoreDifference: number
  }>({
    url: '/api/v1/hub/estimated-leaderboard',
    method: 'GET',
    params: {
      score
    }
  }, onError)

  return result
}

export const getGlobalLeaderboard = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    top250: {
      publicKey: string
      score: number
      username: string
    }[]
    rank: number
    score: number
  }>({
    url: '/api/v1/hub/global-leaderboard',
    method: 'GET'
  }, onError)

  return result
}

export const getWeeklyLeaderboard = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    top250: {
      publicKey: string
      score: number
      username: string
    }[]
    rank: number
    score: number
  }>({
    url: '/api/v1/hub/weekly-leaderboard',
    method: 'GET'
  }, onError)

  return result
}

export const getClashLeaderboard = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    top: {
      publicKey: string
      highestScore: number
      dailyScore: number
      score: number
      username: string
      clubId: string
    }[]
    rank: number
    score: number
    highestScore: number
  }>({
    url: '/api/v1/hub/clash-leaderboard',
    method: 'GET'
  }, onError)

  return result
}

//export const getDailyLeaderboard = async (onError?: (err: unknown) => void) => {
//  const result = await tryOrReconnect<{
//    top250: {
//      publicKey: string
//      score: number
//    }[]
//    rank: number
//    score: number
//  }>({
//    url: '/api/v1/hub/daily-leaderboard',
//    method: 'GET'
//  }, onError)

//  return result
//}

export const getCreditsRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    credits: number
  }>({
    url: '/api/v1/hub/get-credits',
    method: 'GET'
  }, onError)

  return result?.data
}

export const getAccountInfoRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<AccountInfo>({
    url: '/api/v1/hub/account-info',
    method: 'GET'
  }, onError)

  return result?.data
}

export const getHoldedNFTsRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<number>({
    url: '/api/v1/hub/holded-nfts',
    method: 'GET'
  }, onError)

  return result
}

export const getCurrentWeeklyPrize = async (holder = false, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<WeeklyPrize>({
    url: '/api/v1/hub/current-weekly-prize',
    method: 'GET',
    params: {
      holder
    }
  }, onError)

  return result
}

export const getPreviousWeeklyPrize = async (holder = false, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<WeeklyPrize>({
    url: '/api/v1/hub/previous-weekly-prize',
    method: 'GET',
    params: {
      holder
    }
  }, onError)

  return result
}

export const getInvoiceIdRequest = async (gems: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    invoiceId: string
    gems: number
    price: number
    receiverPublicKey: string
  }>({
    url: '/api/v1/hub/invoice-id',
    method: 'GET',
    params: {
      gems
    }
  }, onError)

  return result
}

export const checkInvoicePaymentRequest = async (invoiceId: string, signature: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect({
    url: '/api/v1/hub/check-invoice-payment',
    method: 'GET',
    params: {
      invoiceId,
      signature
    }
  }, onError)

  return result
}

export const openChestRequest = async (chestIndex: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<Reward[]>({
    url: '/api/v1/hub/open-chest',
    method: 'POST',
    data: {
      chestIndex
    }
  }, onError)

  return result
}

export const getCharacterInfo = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    stats: {
      speed: number
      health: number
      defense: number
      criticalChance: number
      dodge: number
    }
    cosmetics: {
      eyes: {
        equiped?: string
        unlocked: string[]
      }
      fur: {
        equiped: string
        unlocked: string[]
      }
      top: {
        equiped?: string
        unlocked: string[]
      }
      head: {
        equiped?: string
        unlocked: string[]
      }
    }
    weapons: {
      equiped: string
      unlocked: string[]
    }
  }>({
    url: '/api/v1/hub/character-info',
    method: 'GET'
  }, onError)

  return result
}

export const upgradeCharacterRequest = async (name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/upgrade-character',
    method: 'POST',
    data: {
      name
    }
  }, onError)

  return result
}

export const buyCosmeticRequest = async (type: keyof CosmeticData, name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/buy-cosmetic',
    method: 'POST',
    data: {
      type,
      name
    }
  }, onError)

  return result
}

export const equipCosmeticRequest = async (type: keyof CosmeticData, name?: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/equip-cosmetic',
    method: 'POST',
    data: {
      type,
      name
    }
  }, onError)

  return result
}

export const buyWeaponRequest = async (name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/buy-weapon',
    method: 'POST',
    data: {
      name
    }
  }, onError)

  return result
}

export const equipWeaponRequest = async (name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/equip-weapon',
    method: 'POST',
    data: {
      name
    }
  }, onError)

  return result
}

export const upgradeWeaponRequest = async (name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/upgrade-weapon',
    method: 'POST',
    data: {
      name
    }
  }, onError)

  return result
}

export const openChestSlotRequest = async (slotId: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/open-chest-slot',
    method: 'POST',
    data: {
      slotId
    }
  }, onError)

  return result
}

export const buyEnergyRequest = async (mode: 'gems' | 'vessels', onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/buy-energy',
    method: 'POST',
    data: {
      mode
    }
  }, onError)

  return result
}

export const payGameRequest = async (mode: 'paid' | 'free', onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    paidGames: number
    freeGames: number
  }>({
    url: '/api/v1/hub/pay-game',
    method: 'POST',
    data: {
      mode
    }
  }, onError)

  return result
}

export const burnChestRequest = async (index: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/burn-chest',
    method: 'POST',
    data: {
      index
    }
  }, onError)

  return result
}

export const storeChestRequest = async (index: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/store-chest',
    method: 'POST',
    data: {
      index
    }
  }, onError)

  return result
}

export const cleanWonChests = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/clean-won-chests',
    method: 'POST'
  }, onError)

  return result
}

export const unlockMultiplayerRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/unlock-multiplayer',
    method: 'POST'
  }, onError)

  return result
}

export const confirmClubRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/confirm-club',
    method: 'POST',
  }, onError)

  return result
}

export const clubClashRequest = async (onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<ClashData>({
    url: '/api/v1/hub/club-clash',
    method: 'GET'
  }, onError)

  return result
}

export const depositVesselsRequest = async (numberOfVessels: number, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    transactionData: {
      accounts: {
        merkleTree: string
        treeAuthority: string
        leafOwner: string
        leafDelegate: string
        newLeafOwner: string
        logWrapper: string
        compressionProgram: string
        anchorRemainingAccounts: AccountMeta[]
      }
      args: {
        root: number[]
        dataHash: number[]
        creatorHash: number[]
        nonce: number
        index: number
      }
      programId: string
    }
    assetId: string
  }[]>({
    url: '/api/v1/hub/deposit-vessels',
    method: 'POST',
    data: {
      numberOfVessels
    }
  }, onError)

  return result
}

export const verifyDepositVesselsRequest = async (assetId: string, signature: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<{
    id: string
  }>({
    url: '/api/v1/hub/verify-deposit-vessels',
    method: 'GET',
    params: {
      assetId,
      signature
    }
  }, onError)

  return result
}

export const activateCodeRequest = async (name: string, onError?: (err: unknown) => void) => {
  const result = await tryOrReconnect<boolean>({
    url: '/api/v1/hub/activate-code',
    method: 'POST',
    data: {
      name
    }
  }, onError)

  return result
}
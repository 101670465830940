import {Spine} from '@pixi-spine/runtime-4.1'
import {PlayOptions, Sound, sound} from '@pixi/sound'
import gsap from 'gsap'
import {Graphics, Sprite} from 'pixi.js'
import {Item, SoundName} from './enums'
import {ItemSchema} from './server/schema'
import {playSpatialSound} from './sound'
import {gameState} from './state'

///**
// * Create a new item with the specified type
// * @param type The type name
// * @returns The item Spine object
// */
//export const initItem = (type: ItemType): Spine | undefined => {
//  if (!gameState.resources?.item.spineData) return

//  const item = new Spine(gameState.resources.item.spineData)

//  item.skeleton.setSkinByName(skin)
//  item.state.setAnimation(0, 'Idle', true)

//  return item
//}

/**
 * Create a new item with the specified type
 * @param type The type name
 * @returns The item object
 */
export const initItem = (item: ItemSchema) => {
  if (!gameState.viewport || !gameState.resources) return

  let element
  let ratio

  switch (item.type) {
    case Item.ChestCommon:
    case Item.ChestRare:
    case Item.ChestFabled:
    case Item.ChestZombie:
      element = new Spine(gameState.resources['chests'].spineData)

      ratio = Math.max(item.width / element.width, item.height / element.height)

      element.scale.set(ratio, ratio)
      element.state.setAnimation(0, 'IDLE', true)
      element.skeleton?.setSkinByName(`CHEST/${item.type.split('-')[1].toUpperCase()}`)

      element.position.set(item.x, item.y)

      element.zIndex = Math.max(13, element.y + element.height)
      break
    case Item.MysteryBox:
      element = new Spine(gameState.resources['item-mystery-box'].spineData)

      ratio = Math.max(item.width / element.width, item.height / element.height)

      element.scale.set(ratio, ratio)
      element.state.setAnimation(0, 'IDLE', true)

      element.position.set(item.x, item.y)

      element.zIndex = Math.max(13, element.y + element.height)
      break
    default:
      element = new Sprite(gameState.resources[`item-${item.type}`])

      ratio = Math.max(item.width / element.width, item.height / element.height)

      element.scale.set(ratio, ratio)

      element.position.set(item.x, item.y)

      element.zIndex = Math.max(13, element.y + element.height * 1.5)
  }

  //setTimeout(() => {
  //  const graphic = new Graphics()
  //    .beginFill(0xff0000).drawRect(item.x, item.y, item.width, item.height).endFill()
  //    .beginFill(0x00ff00).drawRect(item.x + item.boundingX, item.y + item.boundingY, item.boundingWidth, item.boundingHeight).endFill()
  //  graphic.zIndex = 12
  //  gameState.viewport!.addChild(graphic)
  //}, 2000)

  return element
}

export const addItem = (itemSchema: ItemSchema) => {
  if (!gameState.viewport || !gameState.level) return

  const itemsList = gameState.references.items

  const item = initItem(itemSchema)

  if (item) {
    const data = {
      schema: itemSchema,
      element: item
    }

    itemsList.set(itemSchema.uid, data)

    gameState.viewport.addChild(item)

    return data
  }
}

/**
 * Remove an item
 * @param item The item Spine object to destroy
 */
export const removeItem = (schema: ItemSchema) => {
  if (!gameState.references.items) return

  const localItem = gameState.references.items.get(schema.uid)

  if (localItem) {
    gameState.references.items.delete(schema.uid)

    const element = localItem.element

    switch (localItem.schema.type) {
      case Item.MysteryBox:
        openMysteryBox(localItem.element as Spine)
        break
      case Item.Bomb:
        bombExplode()
        element.destroy()
        break
      default:
        element.destroy()
    }
  }
}

export const itemTouched = (clientId: string, type: Item) => {
  const localPlayer = gameState.level?.state.localPlayer
  const player = gameState.references.players.get(clientId)

  if (!player || !localPlayer) return

  let itemSound: Sound | undefined = undefined
  const options: PlayOptions = {}

  switch (type) {
    case Item.Bomb:
      itemSound = sound.find(SoundName.ItemBomb)
      break
    case Item.MysteryBox:
      itemSound = sound.find(SoundName.ItemBomb)
      options.start = 0.6
      break
  }

  if (itemSound && itemSound.instances.length < 3 && (!itemSound.instances[itemSound.instances.length - 1] || itemSound.instances[itemSound.instances.length - 1].progress > 0.25)) {
    playSpatialSound(localPlayer, player.schema, itemSound, localPlayer.clientId === player.schema.clientId, options)
  }
}

const openMysteryBox = (element: Spine) => {
  (element as Spine).state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'OPEN') {
        setTimeout(() => {
          try {
            element.destroy()
            // eslint-disable-next-line no-empty
          } catch {}
        })
      }
    }
  });

  (element as Spine).state.setAnimation(0, 'OPEN')
}

const bombExplode = () => {
  if (!gameState.viewport) return

  const flash = new Graphics()
    .beginFill(0xffffff)
    .drawRect(0, 0, gameState.viewport.worldWidth, gameState.viewport.worldHeight)
    .endFill()

  flash.alpha = 0.5

  flash.zIndex = Number.MAX_SAFE_INTEGER

  gameState.viewport.addChild(flash)

  gsap.to(flash, {
    duration: 1,
    alpha: 0,
    onComplete() {
      flash.destroy()
    }
  })
}


/**
 * Update the item positions
 * @param delta The delta (time passed since previous update)
 */
export const updateItems = (delta: number) => {
  const itemsList = gameState.references.items

  if (!gameState.viewport || !gameState.level || !itemsList) return

  const delta01 = delta * 0.01

  itemsList.forEach(item => {
    if (!item) return

    const {
      x,
      y
    } = item.element

    const {
      x: newX,
      y: newY,
      dx,
      dy
    } = item.schema

    let computedX = x + dx * delta
    let computedY = y + dy * delta

    const differenceX = newX - computedX
    const differenceY = newY - computedY

    computedX += differenceX * delta01
    computedY += differenceY * delta01

    item.element.position.set(
      computedX,
      computedY
    )
  })
}

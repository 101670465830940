
export const isSolanaInstalled = (window as any).solana?.isPhantom || (window as any).solana?.isBraveWallet

const regex = /(undefined|false)/g

export const formatClassName = (styles: any, className: string) =>
  className.replaceAll(regex, '').trim().split(/\s+/).reduce<string>((acc, className) => {
    return `${acc ? `${acc} ` : ''}${styles[className] ?? className}`
  }, '')

export const shuffle = (array: any[]) => {
  let currentIndex = array.length, randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

export function makeid(length: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const replaceUpperCaseBy = (text: string, replace: string) => {
  const generated: string[] = []

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i)

    if (char.toUpperCase() === char) {
      generated.push(replace)
    }

    generated.push(char.toLowerCase())
  }

  return generated.join('')
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const numberFormater = (number: number) => new Intl.NumberFormat('en-EN').format(number)
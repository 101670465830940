@import "scss-variables";

.continue-menu {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    overflow: hidden;
    gap: 1rem;

    .player {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 8rem;
      rotate: -90deg;
      pointer-events: none;
    }

    .shadow {
      position: absolute;
      width: 136px;
      height: 36.615px;
      border-radius: 100%;
      opacity: 0.5;
      background: #ec2727;
      top: 0;
      bottom: 0;
      margin-top: 5rem;
    }

    .gems {
      display: flex;
      position: relative;

      .error {
        color: #ff6262;
      }

      img {
        position: absolute;
        left: 1rem;
        height: 60%;
      }

      > div {
        flex: 1;
      }
    }

    button {
      width: min(18rem, 100%);
    }
  }
}

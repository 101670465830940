import {Spine} from '@pixi-spine/runtime-4.1'
import {Container, Graphics, Sprite, Texture} from 'pixi.js'
import {gameState} from '../state'

export const distanceBetween = (x1: number, y1: number, x2: number, y2: number) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2)

export const radialGradient = (from: string, to: string, x: number, y: number, r0: number, r1: number, width: number, height: number) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height
  const ctx = c.getContext('2d')!
  const grd = ctx.createRadialGradient(x, y, r0, x, y, r1)
  grd.addColorStop(0, from)
  grd.addColorStop(0.8, to)
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, width, height)
  return Texture.from(c)
}

export const linearGradient = (from: string, to: string, x1: number, y1: number, x2: number, y2: number, width: number, height: number) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height
  const ctx = c.getContext('2d')!
  const grd = ctx.createLinearGradient(x1, y1, x2, y2)
  grd.addColorStop(0, from)
  grd.addColorStop(1, to)
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, width, height)
  return Texture.from(c)
}

export const drawProgressBar = (width: number, height: number, gap: number, backgroundColor: number, barColor: number, rounded?: boolean) => {
  let background

  if (rounded) {
    background = new Graphics().beginFill(backgroundColor).drawRoundedRect(0, 0, width, height, height).endFill()
  } else {
    background = new Graphics().beginFill(backgroundColor).drawRect(0, 0, width, height).endFill()
  }

  background.cacheAsBitmap = true

  let bar: Graphics

  if (rounded) {
    bar = new Graphics().beginFill(barColor).drawRoundedRect(width / 2 + gap, gap, width - 2 * gap, height - 2 * gap, height).endFill()
  } else {
    bar = new Graphics().beginFill(barColor).drawRect(width / 2 + gap, gap, width - 2 * gap, height - 2 * gap).endFill()
  }

  bar.pivot.set(width / 2 + gap, 0)
  bar.x = gap

  const container = new Container()
  container.addChild(background, bar)

  return {
    container,
    background,
    bar
  }
}

export const degreesToRadians = (degrees: number) => degrees * (Math.PI / 180)
export const radiansToDegrees = (radians: number) => radians * (180 / Math.PI)

export const getWidthRatio = () => getGameWidth() / 480
export const getHeightRatio = () => getGameHeight() / 969

export const getGameWidth = () => {
  if (!gameState.app) return 0

  return (gameState.app.view.parentNode as HTMLElement).offsetWidth
}

export const getGameHeight = () => {
  if (!gameState.app) return 0

  return (gameState.app.view.parentNode as HTMLElement).offsetHeight
}

export const segmentAngle = (x1: number, y1: number, x2: number, y2: number) => {
  return -Math.atan2(x2 - x1, y2 - y1) + Math.PI / 2
}

export const computeMovement = (element: Spine | Graphics | Sprite, data: {
  x: number
  y: number
  dx: number
  dy: number
}, delta: number) => {
  const {
    x,
    y
  } = element

  const {
    x: newX,
    y: newY,
  } = data

  const {
    dx,
    dy
  } = data

  let computedX = x + dx * delta
  let computedY = y + dy * delta

  const differenceX = newX - computedX
  const differenceY = newY - computedY

  computedX += differenceX * delta
  computedY += differenceY * delta

  return {
    computedX,
    computedY
  }
}

export const computeVelocityToReach = (a: {
  x: number
  y: number
}, b: {
  x: number
  y: number
}) => {
  let dx = a.x - b.x
  let dy = a.y - b.y

  const dist = Math.hypot(dx, dy)

  dx /= dist
  dy /= dist

  return {
    dx,
    dy
  }
}
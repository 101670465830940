@import "scss-variables";

.hub-layout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
  font-family: "Cees Hand";

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #333333;
    text-align: center;
    max-width: 300px;

    p {
      font-size: 32px;

      .purple {
        color: $color-violet;
      }
    }

    small {
      font-size: 18px;
    }
  }
  .custom-toast {
    //background-color: #ffffff88;
    //backdrop-filter: blur(1rem);

    img {
      width: 10px;
      height: 10px;
    }
  }
}

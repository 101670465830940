import React, { HtmlHTMLAttributes } from 'react'
import { formatClassName } from '../../../utils/global'
import styles from './badge.module.scss'

export type BadgeProps = HtmlHTMLAttributes<HTMLDivElement>

export const Badge = ({ className, children, ...props }: BadgeProps) => {
  return (
    <div className={formatClassName(styles, `badge ${className ?? ''}`)} {...props}>
      {children}
    </div>
  )
}
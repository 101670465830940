// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {Schema, type} from '@colyseus/schema'


export class CosmeticsSchema extends Schema {
  @type('string') public eyes!: string
  @type('string') public fur!: string
  @type('string') public head!: string
  @type('string') public top!: string
  @type('string') public mouth!: string
  @type('string') public outfits?: string
}

import axios from 'axios'
import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_DATA_CDN_URL} from 'oogy-blast/src/utils/constants'
import {Rarity} from '../pages/dashboard/sub-pages/game/chests/rewards/rewards'

export type ChestsData = {
  chests: {
    [type in ChestType]: {
      cost: {
        sol: number
        gems: number
      }
      gemsDrop?: {
        rarity: Rarity
        quantity: number
        rate: number
      }[]
    }
  }
  openSlotPrice: {
    gems: number
  }
  speedUpPrice: {
    [type in ChestType]: number
  }
  numberOfSlots: number
}

let CACHE: ChestsData
let CACHE_TIME = 0

export const getChestsData = async () => {
  if (CACHE_TIME < Date.now() - 600000) {
    if (CACHE) CACHE_TIME = Date.now()

    const response = await axios.get<ChestsData>(`${OOGY_DATA_CDN_URL}/chests/chests.json`)

    if (response.status === 200) {
      CACHE_TIME = Date.now()
      CACHE = response.data
    }
  }

  return CACHE
}

import {Weapon} from 'oogy-blast/src/enums'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useState} from 'react'
import {formatClassName, replaceUpperCaseBy} from '../../../../../../../../../../utils/global'
import {buyWeaponRequest, equipWeaponRequest, upgradeWeaponRequest} from '../../../../../../../../../api/hub'
import {Button} from '../../../../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../../../../components/modal/modal'
import {AccountInfo} from '../../../../../../../../../data/account'
import {WeaponsData, WeaponsEntry} from '../../../../../../../../../data/weapons'
import {useHubContext} from '../../../../../../../../../state/context'
import {hubState} from '../../../../../../../../../state/hub'
import styles from './buy-or-upgrade.module.scss'

export type BuyOrUpgradeWeaponProps = GenericModalProps & {
  onClose: () => void
  data?: WeaponsData
  name?: Weapon
}

export const BuyOrUpgradeWeapon = ({className, onClose, data, name, ...props}: BuyOrUpgradeWeaponProps) => {
  const {state: {accountInfo}, dispatch} = useHubContext()

  const [loading, setLoading] = useState(false)
  const [equipying, setEquipying] = useState(false)

  const entry = name ? data?.[name] : undefined
  const unlocked = accountInfo?.character.weapons.unlocked[name ?? Weapon.CommonBlaster] !== undefined
  const equiped = accountInfo?.character.weapons.equiped === name
  const actualLevelIndex = accountInfo?.character.weapons.unlocked[name ?? Weapon.CommonBlaster] ?? 0

  const actualLevel = entry?.levels[actualLevelIndex]
  const nextLevel = entry?.levels[actualLevelIndex + 1]
  const maxLevelReached = actualLevelIndex !== undefined && nextLevel === undefined

  const neededGems = unlocked ? maxLevelReached ? 0 : nextLevel?.priceToUnlock.gems : entry?.levels[0].priceToUnlock.gems
  const notEnoughGems = neededGems !== undefined && accountInfo?.gems !== undefined && neededGems > accountInfo.gems

  const title = <div className={formatClassName(styles, 'title')}>
    {name?.replaceAll('_', ' ').toLowerCase()} (level {actualLevelIndex + 1})
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

  const onUpgrade = async () => {
    if (notEnoughGems || maxLevelReached || !name || !nextLevel) return

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Upgrade weapon',
        text: `Do you really want to upgrade the weapon for ${nextLevel.priceToUnlock.gems} gems?`,
        acceptText: 'Yes',
        refuseText: 'No',
        async onAccept() {
          setLoading(true)

          const result = await upgradeWeaponRequest(name)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setLoading(false)
        }
      })
    }
  }

  const onBuy = () => {
    if (!name || !entry) return

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Buy weapon',
        text: `Do you really want to buy the weapon for ${entry.levels[0].priceToUnlock.gems} gems?`,
        acceptText: 'Yes',
        refuseText: 'No',
        async onAccept() {
          setLoading(true)

          const result = await buyWeaponRequest(name)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setLoading(false)
        }
      })
    }
  }

  const onEquip = async () => {
    if (!name) return

    setEquipying(true)

    const result = await equipWeaponRequest(name)

    if (result?.data) {
      const copy = JSON.parse(JSON.stringify(accountInfo)) as AccountInfo

      copy.character.weapons.equiped = name

      dispatch({
        type: 'SET_ACCOUNT_INFO',
        accountInfo: copy
      })
    }

    setEquipying(false)
  }

  const content = <div className={formatClassName(styles, 'content')}>
    {/*<div className={formatClassName(styles, 'tile')}>
      <div className={formatClassName(styles, 'bg')} ></div>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'icon')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/character/${name}.png`} alt={`${name}`} />
          <div className={formatClassName(styles, 'level')}>
            {(actualLevelIndex ?? 1) - 1}
          </div>
        </div>
        <div className={formatClassName(styles, 'info')}>
          <div className={formatClassName(styles, 'name')}>{name}</div>
          <div className={formatClassName(styles, 'value')}>{actualLevel?.value ?? '...'}</div>
        </div>
      </div>
    </div>*/}
    <div className={formatClassName(styles, 'weapon')}>
      <div className={formatClassName(styles, 'pew-pew-1')}>pew pew</div>
      <div className={formatClassName(styles, 'pew-pew-2')}>pew pew</div>
      <div className={formatClassName(styles, 'pew-pew-3')}>pew pew</div>
      <div className={formatClassName(styles, 'pew-pew-4')}>pew pew</div>
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/weapons/${(name ?? '').toLowerCase()}.png`} />
      <div className={formatClassName(styles, 'shadow')}></div>
    </div>
    <div className={formatClassName(styles, 'stats')}>
      <div className={formatClassName(styles, 'title')}>{unlocked && 'upgrade '}weapon stats</div>
      <div className={formatClassName(styles, 'grid')}>
        {Object.entries(actualLevel?.characteristics ?? {}).map(([name, value]) => {
          if (name === 'lifetime') return

          const difference = (nextLevel?.characteristics[name as keyof WeaponsEntry['levels'][0]['characteristics']] ?? 0) - value
          return <div key={`stats-${name}`} className={formatClassName(styles, 'tile')}>
            <div className={formatClassName(styles, 'bg')} ></div>
            <div className={formatClassName(styles, 'container')}>
              <div className={formatClassName(styles, 'icon')}>
                <img src={`${OOGY_VISUAL_CDN_URL}/ui/weapons/characteristics/${name}.png`} alt={`${name}`} />
              </div>
              <div className={formatClassName(styles, 'info')}>
                <div className={formatClassName(styles, 'name')}>{replaceUpperCaseBy(name, ' ')}</div>
                <div className={formatClassName(styles, 'value')}>
                  {value}
                  {(unlocked && !maxLevelReached && Math.abs(difference) > 0) && <span> {difference < 0 ? '-' : '+'} {Math.abs(difference)}</span>}
                </div>
              </div>
            </div>
          </div>
        }
        )}
      </div>
    </div>
    {
      unlocked
        ? maxLevelReached
          ? <Button className={formatClassName(styles, 'gray gems small')} disabled>
            <div>
              <div>MAX LEVEL</div>
              <div>REACHED</div>
            </div>
          </Button>
          : <Button className={formatClassName(styles, `${notEnoughGems ? 'gray' : 'blue'} gems small`)} onClick={onUpgrade} loading={loading} disabled={notEnoughGems}>
            {!notEnoughGems && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
            <div>
              <div>UPGRADE FOR</div>
              <div className={formatClassName(styles, `${notEnoughGems ? 'error' : ''}`)}>{nextLevel?.priceToUnlock.gems ?? '...'} GEMS</div>
            </div>
            {!notEnoughGems && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
          </Button>
        : <Button className={formatClassName(styles, `${notEnoughGems ? 'gray' : 'blue'} gems small`)} onClick={onBuy} loading={loading} disabled={notEnoughGems}>
          {!notEnoughGems && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
          <div>
            <div>UNLOCK FOR</div>
            <div className={formatClassName(styles, `${notEnoughGems ? 'error' : ''}`)}>{entry?.levels[0].priceToUnlock.gems ?? '...'} GEMS</div>
          </div>
          {!notEnoughGems && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
        </Button>
    }
    {(!equiped && unlocked) && <Button className='small' onClick={onEquip} loading={equipying}>
      EQUIP
    </Button>}
  </div>

  return (
    <GenericModal
      className={formatClassName(styles, `buy-or-upgrade ${className}`)}
      title={title}
      content={content}
      onClickBackground={onClose}
      {...props}
    />
  )

}
// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {type} from '@colyseus/schema'
import {LivingEntitySchema} from './LivingEntitySchema'

export class MonsterSchema extends LivingEntitySchema {
  @type('string') public uid!: string
  @type('string') public type!: string
  @type('boolean') public isElite!: boolean
  @type('boolean') public isBoss!: boolean
}

@import "scss-variables";

.create-room-modal {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;
    height: 80vh;

    button {
      img {
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    .form {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1rem;
      width: 100%;

      input {
        width: 100%;
      }

      .entry {
        .title {
          color: #db43a3;
          font-family: "Cees Hand";
          font-size: 0.8rem;
          font-weight: 700;
          text-align: left;
        }
      }

      .multi-button {
        display: flex;
        gap: 1rem;
        width: 100%;

        > div {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          height: 3rem;

          &:hover {
            cursor: pointer;
          }

          &.selected {
            .bg {
              filter: brightness(0) saturate(100%) invert(44%) sepia(27%) saturate(5020%) hue-rotate(296deg)
                brightness(89%) contrast(91%);
            }
          }

          .bg {
            position: absolute;
            inset: 0;
            box-sizing: border-box;
            background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/multi-button.svg);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 1;
          }

          .text {
            z-index: 2;
          }
        }
      }
    }

    .create-room-button {
      width: 100%;
    }
  }
}

@import "scss-variables";

.upgrade {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.5rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;

    button {
      width: min(100%, 18rem);
    }

    .arrow-down {
      height: 2rem;
    }

    .tile {
      position: relative;
      width: fit-content;
      height: 4rem;

      cursor: pointer;

      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        margin: auto;
        background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(0) saturate(100%) invert(23%) sepia(52%) saturate(1527%) hue-rotate(243deg) brightness(92%)
          contrast(94%);
      }

      .container {
        display: flex;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem;
        gap: 0.5rem;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 1;
          border-radius: 4px;
          background: #532a77;
          width: 3rem;

          img {
            height: 90%;
          }

          .level {
            font-size: 0.8rem;
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 0.2rem;
            left: 0.2rem;
            border-radius: 4px;
            background: rgba(137, 79, 188, 0.55);
            backdrop-filter: blur(2px);
            text-shadow: 0px 0.5px 0px #000;
            display: flex;
            padding: 2px 1px 1px 0px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          position: relative;
          line-height: 1.3rem;
          letter-spacing: 1px;
          z-index: 1;

          .name {
            font-family: "Cees Hand";
            color: #d4a4ff;
            font-size: 1rem;
            text-shadow: none;
            white-space: nowrap;
          }

          .value {
            color: #fff;
            text-shadow: 0px 0.5px 0px #000;
            font-family: "Supersonic Rocketship";
            font-weight: 400;
            font-size: 1.2rem;
          }
        }
      }
    }

    .gems {
      display: flex;
      position: relative;
      font-size: 1.2rem;
      width: min(100%, 18rem);
      gap: 1rem;

      .error {
        color: #ff6262;
      }

      img {
        height: 100%;
      }

      > div {
        flex: 1;
      }
    }

    .not-enough-gems {
      color: #d2cff0;
      font-family: "Cees Hand";
      font-size: 1rem;
    }
  }
}

import {State} from './types'

export let gameState: State = {
  loading: false,
  exited: false,
  orientation: {},
  references: {
    bullets: new Map(),
    guns: new Map(),
    items: new Map(),
    monsters: new Map(),
    skills: new Map(),
    players: new Map(),
    particles: new Map(),
    ui: {
      level: {
        auras: new Map(),
        healthBars: new Map(),
        shootingBars: new Map(),
        usernames: new Map(),
        aoe: new Map(),
        pointers: new Map(),
        revives: new Map(),
        shadows: new Map(),
        waitingDots: new Map(),
        icecube: new Map(),
        teamView: new Map(),
        countdowns: [],
        sticks: {}
      }
    }
  },
  latency: {
    pingTime: 0,
    history: []
  },
  external: {
    getSessionToken: () => undefined,
  } as unknown as any
}

export const initGameState = () => {
  gameState = {
    ...gameState,
    loading: false,
    exited: false,
    orientation: {},
    references: {
      bullets: new Map(),
      guns: new Map(),
      items: new Map(),
      monsters: new Map(),
      skills: new Map(),
      players: new Map(),
      particles: new Map(),
      ui: {
        level: {
          auras: new Map(),
          aoe: new Map(),
          usernames: new Map(),
          healthBars: new Map(),
          shootingBars: new Map(),
          pointers: new Map(),
          revives: new Map(),
          shadows: new Map(),
          waitingDots: new Map(),
          icecube: new Map(),
          teamView: new Map(),
          countdowns: [],
          sticks: {}
        }
      }
    },
    latency: {
      pingTime: 0,
      history: []
    }
  }
}
@import "scss-variables";

.room-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  width: 100%;

  .room-info {
    border-radius: 13px;
    background: linear-gradient(180deg, rgba(176, 111, 227, 0.98) 0%, #713fdb 100%);
    box-shadow:
      0px 4px 7px 0px rgba(233, 146, 255, 0.86) inset,
      0px 3px 0px 0px #582499;

    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 0.5rem;

      > div {
        font-family: "Cees Hand";
        font-size: 1.1rem;

        &:first-child {
          color: #ecd3ff;
          font-size: 0.9rem;
        }
      }
    }
  }

  .players {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;

    .player {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 4rem;
      padding: 1rem;

      .player-name {
        flex: 1;
        text-align: left;
      }

      .player-ready {
        &.ready {
          color: #00ff47;
        }
      }
    }
  }

  .timer {
    text-align: center;
  }

  .actions {
    display: flex;
    gap: 1rem;

    .exit-button {
      color: #333;

      &:hover:not(:disabled),
      &.selected {
        background-color: #33333311 !important;
      }
    }

    .ready-button {
      flex: 1;
    }
  }
}

import {Client, RoomAvailable as OldRoomAvailable} from 'colyseus.js'
import {RoomInfo} from 'oogy-blast'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../../../../utils/global'
import {createClassicRoom, joinOrReconnect} from '../../../../../../../../utils/server'
import {getServersList, pingServer} from '../../../../../../../api/hub'
import {Button, ButtonIcon} from '../../../../../../../components/buttons/button'
import {Input} from '../../../../../../../components/input/input'
import {GenericModal, GenericModalProps} from '../../../../../../../components/modal/modal'
import {HubContextType, useHubContext} from '../../../../../../../state/context'
import {hubState} from '../../../../../../../state/hub'
import {CreateRoomModal} from './create-room-modal/create-room-modal'
import styles from './lobby.module.scss'
import {RoomDetails} from './room-details/room-details'

export type NewRoomAvailable = OldRoomAvailable & {
  name: string
  createdAt: Date
  publicAddress: string
}

export type LobbyProps = GenericModalProps & {
  mode: 'score' | 'clash'
  loadout?: number
  onClose: () => void
}

const Content = ({mode, loadout, onClose, setShowCreateRoom}: {
  mode: 'score' | 'clash'
  loadout?: number
  onClose: () => void
  setShowCreateRoom: (show: boolean) => void
}) => {
  const {state: {sessionToken, room}, dispatch} = useHubContext()
  const [loadingRooms, setLoadingRooms] = useState(true)
  const [roomsAvailable, setRoomsAvailable] = useState<NewRoomAvailable[]>([])
  const [name, setName] = useState<string>('')
  const [connected, setConnected] = useState(false)
  const [ping, setPing] = useState<{
    [serverName: string]: number
  }>({})

  const [joining, setJoining] = useState(false)

  const onJoinOrReconnect = async (room: typeof roomsAvailable[0], roomSession?: HubContextType['roomSession'], password?: string) => {
    if (joining || !sessionToken) {
      return
    }

    setJoining(true)

    if (hubState.showLoading) {
      hubState.showLoading(true)
    }

    try {
      const address = room.publicAddress.includes('localhost') ? `ws://${room.publicAddress}` : `wss://${room.publicAddress}`

      await joinOrReconnect(room, dispatch, sessionToken, address, roomSession, password, loadout)

      dispatch({
        type: 'SET_LAST_SERVER_URL',
        lastServerURL: address
      })
    } catch (err) {
      toast.error('Impossible to join the room, please try again later or contact the support team')
    } finally {
      if (hubState.showLoading) {
        hubState.showLoading(false)
      }
    }

    setJoining(false)
  }

  useEffect(() => {
    if (connected) return

    setConnected(true);

    (async () => {
      const response = await getServersList()

      if (response?.status !== 200) {
        toast.error('Impossible to retrieve the servers info')

        return
      }

      const servers = response.data

      const availableServers = new Set(Object.keys(servers).map(serverName => serverName.split('-')[0]))

      let allRooms: typeof roomsAvailable[0][] = []

      const pingList: typeof ping = {}

      await Promise.all(
        Array.from(availableServers).map(async (server) => {
          const serverURL = server === 'local' ? 'ws://localhost:3000' : `${server}-i0-blast.oogy.io`

          if (!pingList[server]) {
            pingList[server.split('-')[0]] = (await pingServer(server)) ?? Number.MAX_SAFE_INTEGER

            setPing(pingList)
          }

          try {
            const client = new Client(server === 'local' ? serverURL : `wss://${serverURL}`)

            if (!client) {
              toast.error('Impossible to create a client, verify your internet connection or contact the support team')

              return
            }

            const lobby = await client.joinOrCreate('lobby')

            lobby.onMessage('rooms', (rooms) => {
              allRooms.push(...rooms)

              setRoomsAvailable([...allRooms])

              setLoadingRooms(false)
            })

            lobby.onMessage('+', ([roomId, room]) => {
              const roomIndex = allRooms.findIndex((room) => room.roomId === roomId)
              if (roomIndex !== -1) {
                allRooms[roomIndex] = room

              } else {
                allRooms.push(room)
              }

              setRoomsAvailable([...allRooms])
            })

            lobby.onMessage('-', (roomId) => {
              allRooms = allRooms.filter((room) => room.roomId !== roomId)

              setRoomsAvailable([...allRooms])
            })

            lobby.onLeave((code) => {
              if (code === 1000) return

              if (hubState.showModal) {
                hubState.showModal({
                  title: 'Disconnected',
                  text: 'You have been disconnected from the lobby'
                })
              }

              onClose()
            })
          } catch (err) {
            console.info('Problem to sync lobby with server ', serverURL)
          }
        })
      )
    })()
  }, [onClose, connected])

  useEffect(() => {
    dispatch({
      type: 'SET_ROOM'
    })

    dispatch({
      type: 'SET_ROOM_METADATA'
    })
  }, [])

  const nameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value)
  }

  const selectedRoom = roomsAvailable.find((roomAvailable) => roomAvailable.roomId === room?.id)

  const roomsOpened = roomsAvailable.filter((roomAvailable) => roomAvailable.clients < roomAvailable.maxClients && !roomAvailable.metadata.started)

  console.log(roomsOpened)

  return <div className={formatClassName(styles, 'content')}>
    {room && selectedRoom
      ? <RoomDetails roomAvailable={selectedRoom} />
      : <div className={formatClassName(styles, 'room-list')}>
        <Input type="text" maxLength={32} placeholder="Search by room's name..." value={name} onChange={nameChange} />
        <div className={formatClassName(styles, 'rooms')}>
          <div className={formatClassName(styles, 'headers')}>
            <div className={formatClassName(styles, 'header-name')}>Name</div>
            <div className={formatClassName(styles, 'header-players')}>Players</div>
            <div className={formatClassName(styles, 'header-ping')}>Ping</div>
            <div className={formatClassName(styles, 'header-empty')}></div>
          </div>
          {
            roomsOpened.length > 0 &&
            roomsOpened.filter(room => room.metadata.roomName.includes(name) && room.metadata.mode === mode).map((room, index) =>
              <div key={`room-${index}`} className={formatClassName(styles, 'room')}>
                <div className={formatClassName(styles, 'room-name')}>{room.metadata.roomName}</div>
                <div className={formatClassName(styles, 'room-players')}>{room.clients}/{room.maxClients}</div>
                <div className={formatClassName(styles, 'room-ping')}>{ping[room.publicAddress.split('-')[0]] ?? '-'}</div>
                <div className={formatClassName(styles, 'room-button')}>
                  <Button className='xsmall' disabled={room.clients === room.maxClients || room.metadata.started || joining} onClick={() => onJoinOrReconnect(room)}>join</Button>
                </div>
              </div>
            )
          }
          <div className={formatClassName(styles, 'status')}>{loadingRooms ? 'Loading rooms...' : roomsOpened.length === 0 && 'No rooms available, create one!'}</div>
        </div>
        <div className={formatClassName(styles, 'actions')}>
          <ButtonIcon icon="circle-plus" className={formatClassName(styles, 'create-room-button')} onClick={() => setShowCreateRoom(true)}>Create a room</ButtonIcon>
        </div>
      </div>
    }
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Rooms
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const Lobby = ({className, onClose: onCloseRaw, mode, loadout, ...props}: LobbyProps) => {
  const {state: {sessionToken, room}, dispatch} = useHubContext()

  const [showCreateRoom, setShowCreateRoom] = useState(false)

  const onCreateClassicRoom = async (roomInfo: RoomInfo, password?: string) => {
    if (!sessionToken) return

    await createClassicRoom({...roomInfo, mode}, sessionToken, dispatch, password, undefined, loadout)

    setShowCreateRoom(false)
  }

  const onClose = async () => {
    if (room) {
      if (hubState.showConfirm) {
        hubState.showConfirm({
          title: 'Exit room?',
          text: 'Do you really want to exit the room?',
          async onAccept() {
            await room.leave(true)

            dispatch({
              type: 'SET_ROOM'
            })

            dispatch({
              type: 'SET_ROOM_METADATA'
            })

            dispatch({
              type: 'SET_ROOM_SESSION'
            })

            onCloseRaw()
          },
          acceptText: 'Exit',
          refuseText: 'Stay'
        })
      }
    } else {
      onCloseRaw()
    }
  }

  return <>
    <GenericModal
      className={formatClassName(styles, `lobby ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onClose={onClose} mode={mode} loadout={loadout} setShowCreateRoom={setShowCreateRoom} />}
      {...props}
    />
    <CreateRoomModal show={showCreateRoom} onClose={() => setShowCreateRoom(false)} onCreate={onCreateClassicRoom} />
  </>
}
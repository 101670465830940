@import "scss-variables";

.hover:hover {
  cursor: pointer;
}

.hide {
  opacity: 0 !important;
}

.chest {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;

  .rewards-container {
    position: absolute;
    inset: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    opacity: 0;
    pointer-events: none;
    animation: appear 300ms linear 200ms forwards;
    padding: 1rem;

    .rewards {
      overflow-y: auto;
      max-height: 40%;
      pointer-events: all;
      justify-content: center;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 4.5rem 1rem 3rem 1rem;
    z-index: 4;

    .image {
      position: absolute;
      margin: auto;
      pointer-events: none;

      width: 100%;
      height: 100%;
      left: 0;
      top: -4%;
    }

    .middle {
      z-index: 1;

      .chest-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        z-index: 4;
        margin-top: 1rem;
        opacity: 0;
        animation: appear 150ms linear forwards;

        .timer {
          display: flex;
          position: absolute;
          width: min(50%, 8rem);
          height: min(1.7rem, 3.5vh);
          top: 56%;
          left: 0;
          right: 0;
          margin: auto;
          border-radius: 0.15rem;
          border: 0.1rem solid #b54be7;
          background: #70378b;
          box-shadow: 0px 0px 0px 0.1rem rgba(47, 11, 74, 0.78);
          color: #fff;
          font-family: "Cees Hand";
          font-size: min(1.5rem, 2.5vh);
          z-index: 4;

          .countdown {
            display: flex;
            justify-content: center;
            flex: 1;
            margin-top: 0.25rem;
          }
        }

        .badge {
          position: absolute;
          top: 34%;
          left: 15%;
          font-size: min(1.5rem, 3vh);
          text-transform: uppercase;
          z-index: 3;
          transition: all 500ms;
        }

        .light {
          position: absolute;
          margin: auto;
          width: 70%;
          opacity: 0.3;
          animation: rotating 3s linear infinite;
          transition: all 500ms;
        }

        .image {
          z-index: 2;
        }
      }

      .price-container {
        margin-top: 8vh;
        transition: all 500ms;

        .price-text {
          color: #fff;
          text-shadow: 0px 0.2rem 0px #303060;
          font-family: "Cees Hand";
          font-size: min(1.75rem, 3vh);
          font-weight: 400;
          text-align: center;
        }

        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          height: min(5rem, 8vh);
          color: #fff;
          text-shadow: 0px 0.5rem 0px rgba(24, 33, 64, 0.83);
          font-family: Supersonic Rocketship;
          font-size: min(3.5rem, 5vh);
          font-weight: 400;

          img {
            height: 73%;
            margin-right: 0.1rem;
            //margin-bottom: 0.2rem;
          }

          span {
            margin: 0 0.8rem;
          }
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;
      gap: 1rem;
      flex: 1;
      margin-top: 1rem;

      > button {
        width: min(100%, 22rem);
        height: min(4.5rem, 8vh);
        text-shadow: 0px 0.2rem 0px rgba(0, 0, 0, 0.83);
        font-family: Supersonic Rocketship;
        font-weight: 400;
        font-size: min(2rem, 4vh);
        letter-spacing: 0.1rem;
        text-transform: uppercase;

        img {
          height: 80%;
          margin-left: 0.2rem;
        }
      }

      .drop-table {
        color: #fff;
        text-shadow: 0px 0.3rem 0px #000;
        font-family: "Cees Hand";
        font-size: min(1.5rem, 3vh);

        img {
          height: min(1.5rem, 3vh);
          margin-left: 0.5rem;
        }
      }
    }

    .back {
      color: #d2cff0;
      font-family: "Cees Hand";
      font-size: 1rem;

      img {
        height: 1rem;
        margin-left: 0.5rem;
      }
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.75);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

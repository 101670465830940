
import {Client, Room} from 'colyseus.js'
import gsap from 'gsap'
import {SoundName} from '../enums'
import {clearReferences} from '../level'
import {playMusic} from '../sound'
import {gameState} from '../state'
import {Leaderboard} from '../types'
import {ClassicRoomState, PlayerSchema} from './schema'
import {onConnect, onJoinError, onLeave} from './server-handler'

//initGame(document.getElementById('game') as HTMLCanvasElement, {
//  tryOrReconnect: fakeTryOrReconnect,
//  getSessionToken: () => undefined,
//  clearGame: () => undefined,
//  getAccountInfo: () => Promise.resolve({} as any),
//  getCreditsRequest: () => undefined as any,
//  getEstimatedLeaderboard: () => undefined as any,
//  showLoading: () => undefined,
//  showModal: () => undefined as any,
//  showHomescreen: () => console.log('HOMESCREEN'),
//  showPauseMenu: () => console.log('PAUSE'),
//  showContinueMenu: () => console.log('CONTINUE'),
//  showScoreMenu: () => console.log('SCORE')
//})

export const startClassicGame = async (room: Room<ClassicRoomState>) => {
  clearReferences()

  gsap.ticker.wake()

  gameState.external.showHomescreen(false)

  gameState.room = room

  try {
    console.log(room.sessionId, ' joined classic room ', room.name)

    room.onStateChange.once(async (state) => {
      const player = state.players.get(room.sessionId)

      console.log('PLAY MUSIC', state.soundName)

      if (player) {
        playMusic(state.soundName ?? SoundName.MainTheme)

        onConnect(room, player)
      }
    })

    room.onLeave((code) => {
      onLeave(code)

      if (code !== 4000) {
        gameState.external.showModal({
          title: 'Disconnected',
          text: 'You have been disconnected from the server, check your connection'
        })

        gameState.external.clearGame()

        gameState.external.showHomescreen(true)
      }

      console.log('leave', code)
    })

    room.onError((code, message) => {
      console.log('ERROR: ', code, ' [', message, ']')
    })

    room.onMessage<Leaderboard>('leaderboard', async (leaderboard) => {
      if (!leaderboard || !room) return

      const rank = leaderboard.findIndex((leader) => leader.clientId === room.sessionId) + 1

      const strRank = rank === 1 ? '1st' : rank === 2 ? '2nd' : rank === 3 ? '3rd' : `${rank}th`

      //setRank(strRank)

      console.log('rank', strRank)
    })

    return room
  } catch (err) {
    onJoinError(err)
  }
}

export const createSoloLevelRoom = async ({
  options,
  onConnect,
  onLeave,
  onJoinError
}: {
  options: {
    clientWidth: number
    clientHeight: number
    sessionToken?: string
    clubClash?: boolean
    clubClashLoadout?: number
    mode?: 'score' | 'clash'
  },
  onConnect: (room: Room, player: PlayerSchema) => void
  onLeave: (code: number) => void
  onJoinError: (err: unknown) => void
}) => {
  try {
    const colyseusClientURL = 'ws://localhost:3000'

    const colyseusClient = new Client(colyseusClientURL)

    const room = await colyseusClient.joinOrCreate<ClassicRoomState>('classic', {
      ...options,
      roomInfo: {
        name: 'testtest',
        maxPlayers: 1,
        mode: options.mode ?? 'score'
      }
    })

    gameState.room = room

    console.log(room.sessionId, ' joined classic room ', room.name)

    if (options.mode === 'clash') {
      playMusic(SoundName.MainTheme2)
    } else {
      playMusic(SoundName.MainTheme)
    }

    room.onStateChange.once(async (state) => {
      const player = state.players.get(room.sessionId)

      if (player) {
        //sound.find(SoundName.selectLevel).play()

        onConnect(room, player)
      }
    })

    room.onLeave((code) => {
      onLeave(code)

      if (code !== 4000) {
        gameState.external.showModal({
          title: 'Disconnected',
          text: 'You have been disconnected from the server, check your connection'
        })
      }

      gameState.external.clearGame()
      gameState.external.showHomescreen(true)

      console.log('leave', code)
    })

    room.onError((code, message) => {
      console.log('ERROR: ', code, ' [', message, ']')
    })

    room.onMessage<Leaderboard>('leaderboard', async (leaderboard) => {
      if (!leaderboard || !room) return

      const rank = leaderboard.findIndex((leader) => leader.clientId === room.sessionId) + 1

      const strRank = rank === 1 ? '1st' : rank === 2 ? '2nd' : rank === 3 ? '3rd' : `${rank}th`

      //setRank(strRank)

      console.log('rank', strRank)
    })

    return room
  } catch (err) {
    onJoinError(err)
  }
}

//setTimeout(async () => {
//  createSoloLevelRoom({
//    options: {
//      clientWidth: window.innerWidth,
//      clientHeight: window.innerHeight,
//      mode: 'score'
//    },
//    onConnect,
//    onLeave,
//    onJoinError
//  })
//}, 3000)
// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';
import { MovingEntitySchema } from './MovingEntitySchema'
import { EntitySchema } from './EntitySchema'

export class AOESchema extends MovingEntitySchema {
    @type("string") public uid!: string;
    @type("string") public type!: string;
    @type("number") public maxWaves!: number;
    @type("number") public waveCooldown!: number;
    @type("number") public damages!: number;
    @type("string") public fromPlayerId!: string;
    @type("number") public waveCount!: number;
}

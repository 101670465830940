import {FlipProp, IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome'
import React, {ButtonHTMLAttributes} from 'react'
import {clickButtonSound, clickRefusedButtonSound, hoverButtonSound} from '../../../sound/sound'
import {formatClassName} from '../../../utils/global'
import styles from './button.module.scss'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  loadingWithChildren?: boolean
}

export const Button = ({children, loading, loadingWithChildren = false, className, disabled, onPointerOver, onPointerUp, ...props}: ButtonProps) => {
  const onPointerOverPrepared = (event: React.PointerEvent<HTMLButtonElement>) => {
    if (onPointerOver) onPointerOver(event)
    hoverButtonSound.play()
  }

  const onPointerUpPrepared = (event: React.PointerEvent<HTMLButtonElement>) => {
    if (onPointerUp) onPointerUp(event)

    if (disabled) {
      clickRefusedButtonSound.play()
    } else {
      clickButtonSound.play()
    }
  }

  return <button
    className={formatClassName(styles, `button ${loading ? 'loading' : ''} ${className ?? ''}`)}
    disabled={disabled}
    onPointerOver={onPointerOverPrepared}
    onPointerUp={onPointerUpPrepared}
    {...props}
  >
    {
      loading
        ? <div className={formatClassName(styles, `content ${loadingWithChildren ? '' : 'floating'}`)}>
          <FontAwesomeIcon spin icon="circle-notch" />
          <div className={formatClassName(styles, 'children')}>
            {children}
          </div>
        </div>
        : children
    }
  </button>
}

export type ButtonIconProps = ButtonProps & {
  icon: IconProp
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>
  flip?: FlipProp
  size?: SizeProp
}

export const ButtonIcon = ({icon, flip, size = '1x', className, loadingWithChildren, children, loading, iconProps, ...props}: ButtonIconProps) => {
  return <Button className={formatClassName(styles, `icon ${className}`)} loading={loading} loadingWithChildren={loadingWithChildren} {...props}>
    {!loading && <FontAwesomeIcon flip={flip} icon={icon} size={size} {...iconProps} />}
    {children}
  </Button>
}
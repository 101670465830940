import {Room} from 'colyseus.js'
import {gameState} from '../state'
import {ClassicRoomState} from './schema'

export const zoomMessage = (room: Room<ClassicRoomState>, scaleX: number, scaleY: number) => {
  room.send('zoom', {
    scaleX,
    scaleY
  })
}

export const moveMessage = (room: Room<ClassicRoomState>, moving: boolean, angle: number) => {
  room.send('move', {
    moving,
    angle
  })
}

export const pauseMessage = (room: Room<ClassicRoomState>, pause: boolean) => {
  room.send('pause', pause)
}

export const payToReviveMessage = (room: Room<ClassicRoomState>) => {
  room.send('pay-to-revive')
}

export const fireAtMessage = (room: Room<ClassicRoomState>, angle: number) => {
  room.send('fire-at', angle)
}

export const validateNewLevelMessage = (room: Room<ClassicRoomState>, selectedIndex: number) => {
  room.send('validate-new-level', selectedIndex)
}

export const pingMessage = (room: Room<ClassicRoomState>) => {
  gameState.latency.pingTime = new Date().getTime()

  room.send('ping')
}

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../utils/global'
import {getCharacterSpineAt} from '../../../../../../utils/visual'
import {Button} from '../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../components/modal/modal'
import {useHubContext} from '../../../../../state/context'
import {hubState} from '../../../../../state/hub'
import styles from './pause-menu.module.scss'

export type PauseMenuProps = GenericModalProps & {
  onClose: () => void
  onQuit: () => void
  muteCallback?: (muted: boolean) => void
}

const Content = ({onClose, onQuit, muteCallback}: {
  onClose: () => void
  onQuit: () => void
  muteCallback?: (muted: boolean) => void
}) => {
  const {state: {accountInfo}, dispatch} = useHubContext()

  const [soundMuted, setSoundMuted] = useState(window.localStorage.getItem('music-muted') === 'true')

  const toggleSound = () => {
    Howler.mute(!soundMuted)

    if (muteCallback) muteCallback(!soundMuted)

    window.localStorage.setItem('music-muted', String(!soundMuted))

    setSoundMuted(!soundMuted)
  }

  useEffect(() => {
    getCharacterSpineAt(0, 'pause_player-container', {
      FUR: accountInfo?.character.cosmetics.FUR.equiped,
      EYES: accountInfo?.character.cosmetics.EYES.equiped,
      HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
      TOP: accountInfo?.character.cosmetics.TOP.equiped,
      MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped
    })
  }, [accountInfo])

  const onQuitPrepared = () => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Quit game?',
        text: 'Are you sure you want to leave the game?',
        acceptText: 'quit',
        refuseText: 'stay',
        onAccept() {
          if (hubState.clearGame) hubState.clearGame()

          dispatch({
            type: 'SET_ROOM'
          })

          dispatch({
            type: 'SET_ROOM_METADATA'
          })

          dispatch({
            type: 'SET_ROOM_SESSION'
          })

          onClose()

          onQuit()
        }
      })
    }
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'sound')} onClick={toggleSound}>
      Sound
      <FontAwesomeIcon className={formatClassName(styles, soundMuted ? '' : 'enabled')} icon={soundMuted ? 'volume-xmark' : 'volume-low'} />
    </div>
    <div
      className={formatClassName(styles, 'player')}
      id="pause_player-container"
    ></div>
    <Button className={formatClassName(styles, 'small')} onClick={onQuitPrepared}>
      QUIT GAME
    </Button>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Menu
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const PauseMenu = ({className, onClose, onQuit, muteCallback, ...props}: PauseMenuProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `pause-menu ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onClose={onClose} onQuit={onQuit} muteCallback={muteCallback} />}
      onClickBackground={onClose}
      {...props}
    />
  )

}
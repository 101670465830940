import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {formatClassName, replaceUpperCaseBy} from '../../../../../../../../../utils/global'
import {CharacterStatisticsData, CharacterTrait, getCharacterData} from '../../../../../../../../data/character'
import {useHubContext} from '../../../../../../../../state/context'
import styles from './statistics-tab.module.scss'
import {UpgradeStat} from './upgrade/upgrade'

export type CharacterStatisticsTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const CharacterStatisticsTab = ({className, ...props}: CharacterStatisticsTabProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [data, setData] = useState<CharacterStatisticsData>()
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [selectedName, setSelectedName] = useState<keyof CharacterStatisticsData['stats']>()
  const [selectedLevel, setSelectedLevel] = useState<number>()

  useEffect(() => {
    getCharacterData().then(setData)
  }, [])

  const levelUp = (name: keyof CharacterStatisticsData['stats'], level?: number) => {
    if (level === undefined) {
      toast.error('Impossible to upgrade this trait')

      return
    }

    setSelectedName(name)
    setSelectedLevel(level + 1)
    setShowUpgrade(true)
  }

  return (
    <>
      <div className={formatClassName(styles, `statistics-tab reveal-element ${className}`)} {...props}>
        {
          ((Object.entries(data?.stats ?? {})) as [keyof CharacterStatisticsData['stats'], CharacterTrait][]).map(([name]) => {
            const level = accountInfo?.character.stats[name]
            const entry = data?.stats?.[name]
            const actualLevel = entry?.levels[level ?? 0]

            return <div key={`info-${name}`} className={formatClassName(styles, 'tile')} onClick={() => levelUp(name, level)}>
              <div className={formatClassName(styles, 'bg')} ></div>
              <div className={formatClassName(styles, 'container')}>
                <div className={formatClassName(styles, 'icon')}>
                  <img src={`${OOGY_VISUAL_CDN_URL}/ui/character/${name}.png`} alt={`${name}`} />
                  <div className={formatClassName(styles, 'level')}>
                    {(level ?? 0) + 1}
                  </div>
                </div>
                <div className={formatClassName(styles, 'info')}>
                  <div className={formatClassName(styles, 'name')}>{replaceUpperCaseBy(name, ' ')}</div>
                  <div className={formatClassName(styles, 'value')}>{actualLevel?.value ?? '...'}</div>
                </div>
              </div>
            </div>
          })
        }
      </div>
      <UpgradeStat show={showUpgrade} onExited={() => {
        setSelectedName(undefined)
        setSelectedLevel(undefined)
      }} onClose={() => setShowUpgrade(false)} name={selectedName} level={selectedLevel} data={data} />
    </>
  )
}
@import "scss-variables";

.top-bar {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  height: 4rem;
  padding: 1rem;
  font-size: 0.9rem;

  .hover {
    &:hover {
      cursor: pointer;
    }

    * {
      pointer-events: none;
    }
  }

  .element-left,
  .element-right {
    display: flex;
    flex: 1;
    gap: 1rem;
  }

  .element-left {
    .buy {
      width: 1rem;
      z-index: 1;
      color: white;
      height: 54%;
      font-size: 1rem;
    }
  }

  .element-right {
    justify-content: end;

    .element {
      width: 6.5rem;
      text-align: right;

      .quantity {
        padding-top: 0.1rem;
        padding-left: 0.75rem;
        padding-right: 0.25rem;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3.746px;
        background: rgba(126, 100, 164, 0.4);
        width: 2rem;

        img {
          width: 80%;
        }
      }
    }

    .settings,
    .bag {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3.746px;
      background: rgba(126, 100, 164, 0.4);
      width: 2rem;

      img {
        width: 80%;
      }
    }
  }

  .element {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 5rem;

    .icon {
      position: relative;
      display: flex;
      justify-content: center;
      z-index: 1;
      width: 30%;
      height: 95%;

      img {
        object-fit: contain;
      }
    }

    .quantity {
      display: flex;
      justify-content: center;
      height: 54%;
      color: white;
      z-index: 1;
      flex: 1;
    }

    svg {
      position: absolute;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: auto;
    }
  }
}

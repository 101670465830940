// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {MapSchema, Schema, type} from '@colyseus/schema'
import {SoundName} from '../../enums'
import {LevelSchema} from './LevelSchema'
import {PlayerSchema} from './PlayerSchema'

export class ClassicRoomState extends Schema {
  @type(LevelSchema) public level: LevelSchema = new LevelSchema();
  @type({map: PlayerSchema}) public players: MapSchema<PlayerSchema> = new MapSchema<PlayerSchema>();
  @type('boolean') public started!: boolean
  @type('boolean') public ended!: boolean
  @type('boolean') public won!: boolean
  @type('number') public wave!: number
  @type('number') public waveTimeLeft!: number
  @type('number') public remainingMonsters!: number
  @type('string') public soundName!: SoundName
}

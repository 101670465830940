@import "scss-variables";

.clash-tab {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #1e0f2c99;
  z-index: 5;

  .clash {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 11rem);
    padding-top: 5rem;
    gap: 1rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      box-sizing: border-box;
      height: 4rem;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 1.75rem;

      img {
        height: 135%;
      }

      .countdown-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        color: #fff;
        text-shadow: 0px 1px 0px #000;
        font-family: More Sugar;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        img {
          height: 2rem;
        }

        .countdown {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .container {
      display: flex;
      width: calc(100% - 2rem);
      height: 0;
      flex-direction: column;
      position: relative;
      flex: 1;
      gap: 1rem;

      color: #fff;
      font-family: "Cees Hand";
      font-size: 1.1rem;
      font-weight: 700;

      overflow-x: auto;

      .no-clash {
        width: 100%;
        text-align: center;
      }

      .sub-title {
        color: #fff;
        font-family: "Cees Hand";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        padding-top: 2px;
      }

      .clubs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: relative;

        .overlay-button {
          position: absolute;
          inset: 0;
          z-index: 10;

          button {
            position: absolute;
            inset: 0;
            margin: auto 4rem auto 4rem;
          }
        }
      }

      .clubs-top {
        display: flex;
        gap: 1rem;
        align-items: end;

        >div {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          gap: 0.5rem;
          padding: 0.25rem;

          .image {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 3.5rem;
            }
          }

          .name {
            color: #fff;
            text-align: center;
            text-shadow: 0px 1.5px 0px #000;
            font-family: "Supersonic Rocketship";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.6px;
            text-transform: uppercase;
          }

          .score {
            color: #fff;
            text-align: center;
            text-shadow: 0px 1px 0px #000;
            font-family: "Cees Hand";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .first {
          border-radius: 13.562px;
          border: 0.678px solid #ffbf85;
          background: linear-gradient(180deg, rgba(247, 255, 149, 0.98) 0%, #cd7f37 100%);
          box-shadow:
            0px 6.781px 0px 0px rgba(0, 0, 0, 0.25),
            0px 6.781px 11.867px 0px #fffb92 inset,
            0px 5.086px 0px 0px #8f783f;
          height: 10rem;
        }

        .second {
          border-radius: 13.562px;
          border: 0.678px solid #cecece;
          background: linear-gradient(180deg, rgba(210, 210, 210, 0.98) 0%, #7e7e7e 100%);
          box-shadow:
            0px 6.781px 0px 0px rgba(0, 0, 0, 0.25),
            0px 6.781px 11.867px 0px #fdfdfd inset,
            0px 5.086px 0px 0px #535353;
          height: 9rem;
        }

        .third {
          border-radius: 13.562px;
          border: 0.678px solid #b78455;
          background: linear-gradient(180deg, rgba(217, 141, 72, 0.98) 0%, #7b4b28 100%);
          box-shadow:
            0px 6.781px 0px 0px rgba(0, 0, 0, 0.25),
            0px 6.781px 11.867px 0px #f2ac5b inset,
            0px 5.086px 0px 0px #64412d;
          height: 8rem;
        }
      }

      .show-all {
        cursor: pointer;
        text-align: center;

        img {
          height: 1.2rem;
          rotate: 115deg;
          opacity: 0.7;

          &.up {
            rotate: -64deg;
          }
        }
      }

      .tabs {
        display: flex;
        color: #666666;
        gap: 0.5rem;

        .tab {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          cursor: pointer;

          button {
            width: 100%;
            padding: 0.25rem 0.5rem;
            font-size: 1.1rem;
          }
        }
      }

      .player {
        pointer-events: none;
        height: 35%;
      }
    }
  }
}

.daily-rewards-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1rem;

  .icon {
    height: 5rem;
  }

  .title {
    color: #fff;
    text-align: center;
    text-shadow: 0px 1px 0px #000;
    font-family: Supersonic Rocketship;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.66px;
    text-transform: uppercase;
  }

  .rewards {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    text-align: center;
    color: #fff;
    text-shadow: 0px 1px 0px #000;
    font-family: Supersonic Rocketship;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.96px;

    img {
      height: 2rem;
    }
  }
}
import {SpinePlayer} from '@esotericsoftware/spine-player'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../utils/global'
import {getCharacterSpineAt, setCharacterSkin} from '../../../../../../../utils/visual'
import {Button} from '../../../../../../components/buttons/button'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import {Background} from '../../background/background'
import styles from './character.module.scss'
import {CharacterCosmeticsTab} from './tabs/cosmetics/cosmetics-tab'
import {CharacterStatisticsTab} from './tabs/statistics/statistics-tab'
import {CharacterWeaponsTab} from './tabs/weapons/weapons-tab'

export type CharacterTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const CharacterTab = ({className, ...props}: CharacterTabProps) => {
  const [tab, setTab] = useState<'stats' | 'cosmetics' | 'weapons'>('stats')
  const {state: {accountInfo}} = useHubContext()

  //const [loading, setLoading] = useState(false)
  const [spinePlayer, setSpinePlayer] = useState<SpinePlayer>()

  useEffect(() => {
    if (!spinePlayer) {
      getCharacterSpineAt(0, 'character_player-container', {
        FUR: accountInfo?.character.cosmetics.FUR.equiped,
        EYES: accountInfo?.character.cosmetics.EYES.equiped,
        HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
        TOP: accountInfo?.character.cosmetics.TOP.equiped,
        MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped,
      }).then(setSpinePlayer)
    } else {
      setCharacterSkin(spinePlayer, {
        FUR: accountInfo?.character.cosmetics.FUR.equiped,
        EYES: accountInfo?.character.cosmetics.EYES.equiped,
        HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
        TOP: accountInfo?.character.cosmetics.TOP.equiped,
        MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped
      })
    }
  }, [accountInfo, spinePlayer, tab])

  useEffect(() => {
    if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
  }, [])

  return (
    <div className={formatClassName(styles, `character-tab ${className}`)} {...props}>
      <Background className='high' />
      <div className={formatClassName(styles, 'character')}>
        <div className={formatClassName(styles, 'title')}>
          Character
        </div>
        <div className={formatClassName(styles, 'container')}>
          <div className={formatClassName(styles, 'tabs')}>
            <div className={formatClassName(styles, 'tab')} onClick={() => {setTab('stats')}}>
              <Button className={formatClassName(styles, `xsmall ${tab === 'stats' ? '' : 'gray'}`)}>STATS</Button>
            </div>
            <div className={formatClassName(styles, 'tab')} onClick={() => {setTab('cosmetics')}}>
              <Button className={formatClassName(styles, `xsmall ${tab === 'cosmetics' ? '' : 'gray'}`)}>COSMETICS</Button>
            </div>
            <div className={formatClassName(styles, 'tab')} onClick={() => {setTab('weapons')}}>
              <Button className={formatClassName(styles, `xsmall ${tab === 'weapons' ? '' : 'gray'}`)}>WEAPONS</Button>
            </div>
          </div>
          <div
            className={formatClassName(styles, 'player')}
            id="character_player-container"
          ></div>
          {tab === 'stats' && <CharacterStatisticsTab />}
          {tab === 'cosmetics' && <CharacterCosmeticsTab spinePlayer={spinePlayer} />}
          {tab === 'weapons' && <CharacterWeaponsTab />}
        </div>
      </div>
    </div>
  )
}
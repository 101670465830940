@import "scss-variables";

.about-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  min-height: 200px;

  .prizes-title {
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: "Cees Hand";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      cursor: pointer;
    }
  }

  .tba {
    margin: 0 auto;
    width: 65%;
  }

  .prizes {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
}

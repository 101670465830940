@import "scss-variables";

.cosmetics-tab {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;

  .multi-button {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    color: #fff;
    text-align: center;
    text-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.83);
    font-family: "Supersonic Rocketship";
    font-weight: 400;
    font-size: 0.75rem;
    letter-spacing: 0.36px;
    text-transform: uppercase;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 2rem;

      &:hover {
        cursor: pointer;
      }

      &.selected {
        .bg {
          filter: brightness(0) saturate(100%) invert(44%) sepia(27%) saturate(5020%) hue-rotate(296deg) brightness(89%)
            contrast(91%);
        }
      }

      .bg {
        position: absolute;
        inset: 0;
        box-sizing: border-box;
        background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/multi-button.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }

      .text {
        z-index: 2;
      }
    }
  }

  .list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.5rem;
    height: 100%;
    padding-bottom: 0.1rem;

    .tile {
      position: relative;
      aspect-ratio: 1/1;
      display: flex;
      align-items: end;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }

      .bg {
        position: absolute;
        inset: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/square-bg.svg);
      }

      .image {
        position: absolute;
        inset: 0;
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center 35%;
      }

      .info {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        position: relative;
        z-index: 1;
        height: 2rem;

        color: #fff;
        font-family: "Supersonic Rocketship";
        font-weight: 400;
        font-size: 1.25rem;
        text-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.83);
        letter-spacing: 1px;

        img {
          height: 1.2rem;
        }
      }
    }
  }
}

import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useState } from 'react'
import Countdown, { CountdownRendererFn } from 'react-countdown'
import { toast } from 'react-toastify'
import { formatClassName } from '../../../../../../../../utils/global'
import { payGameRequest } from '../../../../../../../api/hub'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../../state/context'
import { hubState } from '../../../../../../../state/hub'
import { BuyEnergy } from '../../../top-bar/buy-energy/buy-energy'
import styles from './confirm-game.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) =>
  <span>
    {
      days === 0 && hours === 0 && minutes === 0 && seconds === 0
        ? 'available'
        : <>{addZeroToNumber(hours + days * 24)}H{addZeroToNumber(minutes)}M</>
    }
  </span>


export type ConfirmGameProps = GenericModalProps & {
  onClose: () => void
  callback?: (confirmed: boolean) => void
}

const Content = ({ setShowBuyEnergy, callback }: { setShowBuyEnergy: (show: boolean) => void, callback?: (confirmed: boolean) => void }) => {
  const { state: { accountInfo } } = useHubContext()

  const [loading, setLoading] = useState<'paid' | 'free' | undefined>()

  const freeGameAvailable = accountInfo && accountInfo.freeGameLastUsage + 86_400_000 < Date.now()

  const play = async (mode: 'paid' | 'free') => {
    if (mode === 'free' && !freeGameAvailable) {
      if (!accountInfo?.freeGames) {
        toast.error('No free game detected')

        return
      } else if (accountInfo.freeGames > 0) {
        if (callback) callback(true)

        return
      }
    }

    setLoading(mode)

    const result = await payGameRequest(mode)

    if (result?.status === 200 && result?.data && hubState.refreshAccountInfo) {
      if (mode === 'free' && result.data.freeGames === 0) {
        toast.info('You don\'t have any Oogy on this wallet')
      } else if (mode === 'paid' && result.data.paidGames === 0) {
        toast.info('You don\'t have any paid games, please contact the support team')
      } else {
        hubState.refreshAccountInfo()

        if (callback) callback(true)
      }
    }

    setLoading(undefined)
  }

  //const tensor = () => {
  //  window.open('https://www.tensor.trade/trade/spicevessels', undefined, 'popup')
  //}

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'balance')}>
      <div>Your balance</div>
      <div className={formatClassName(styles, 'balance-entries')}>
        <div className={formatClassName(styles, 'balance-entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/energy.png`} alt="energy" />
          {accountInfo?.energy.quantity ?? '...'}
        </div>
        {/*<div className={formatClassName(styles, 'balance-entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessels" />
          {accountInfo?.vessels ?? '...'}
        </div>*/}
      </div>
      <div className={formatClassName(styles, 'balance-buttons')}>
        <Button className='xsmall' onClick={() => setShowBuyEnergy(true)}>BUY ENERGY</Button>
        {/*<Button className='xsmall' onClick={tensor}><img src={`${OOGY_VISUAL_CDN_URL}/ui/tensor.png`} alt="tensor" /> BUY VESSELS</Button>*/}
      </div>
    </div>
    <div className={formatClassName(styles, 'cost')}>
      Cost to play
      <div className={formatClassName(styles, 'balance-entries')}>
        <div className={formatClassName(styles, 'balance-entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/energy.png`} alt="energy" />
          {(accountInfo?.paidGames ?? 0) > 0 ? 0 : '-3'}
        </div>
        {/*<div className={formatClassName(styles, 'balance-entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessels" />
          {(accountInfo?.paidGames ?? 0) > 0 ? 0 : '-1'}
        </div>*/}
      </div>
    </div>
    <div className={formatClassName(styles, 'buttons')}>
      <Button className='small' onClick={() => play('paid')} loading={loading === 'paid'} disabled={(accountInfo?.energy.quantity ?? 0) < 3 && (accountInfo?.paidGames ?? 0) === 0 && (accountInfo?.freeGames ?? 0) === 0}>
        {(accountInfo?.paidGames ?? 0) > 0 ? 'PLAY FOR FREE' : 'PLAY'}
      </Button>
      <Button className={formatClassName(styles, 'free pink')} onClick={() => play('free')} loading={loading === 'free'}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/oogy-logo.png`} alt="oogy-logo" />
        <div>
          <div>FREE TO PLAY</div>
          <div>
            {accountInfo?.freeGames !== undefined && accountInfo.freeGames > 0
              ? `${accountInfo.freeGames} games left`
              : accountInfo?.freeGameLastUsage !== undefined
                ? <Countdown date={accountInfo.freeGameLastUsage + 86_400_000} onComplete={() => { }} renderer={renderer} />
                : '...'}
          </div>
        </div>
      </Button>
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Confirm game
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const ConfirmGame = ({ className, onClose, callback, ...props }: ConfirmGameProps) => {
  const [showBuyEnergy, setShowBuyEnergy] = useState(false)

  return (
    <>
      <GenericModal
        className={formatClassName(styles, `confirm-game ${className}`)}
        title={<Title onClose={onClose} />}
        content={<Content callback={callback} setShowBuyEnergy={setShowBuyEnergy} />}
        onClickBackground={onClose}
        {...props}
      />
      <BuyEnergy show={showBuyEnergy} onClose={() => setShowBuyEnergy(false)} />
    </>
  )
}
@import "scss-variables";

.how-to-play {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    overflow: hidden;
    gap: 1rem;

    .subtitle {
      color: #fff;
      font-family: "More Sugar";
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }

    .tuto {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 2rem;

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        flex: 1;

        .text {
          flex: 1;
        }

        img {
          height: 3.5rem;
        }
      }
    }

    button {
      width: min(12rem, 100%);
    }
  }
}

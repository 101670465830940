import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../utils/global'
import {getGlobalLeaderboard} from '../../../../../../api/hub'
import styles from './leaderboard.module.scss'

export type LeaderboardTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const LeaderboardTab = ({className, ...props}: LeaderboardTabProps) => {
  const [leaderboard, setLeaderboard] = useState<'weekly' | 'global'>('global')
  const [stats, setStats] = useState<Partial<Awaited<ReturnType<typeof getGlobalLeaderboard>>>>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    if (leaderboard === 'global') {
      getGlobalLeaderboard().then((res) => {
        setStats(res)

        setLoading(false)
      }).catch(console.error)
    }
    // else if (leaderboard === 'weekly') {
    //  getWeeklyLeaderboard().then((res) => {
    //    setStats(res)

    //    setLoading(false)
    //  }).catch(console.error)
    //}
  }, [leaderboard])

  const formatRank = (rank?: number) => {
    if (rank === 1) {
      return 'first'
    } else if (rank === 2) {
      return 'second'
    } else if (rank === 3) {
      return 'third'
    }
  }

  return (
    <div className={formatClassName(styles, `leaderboard-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'leaderboard')}>
        <div className={formatClassName(styles, 'title')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/tab-ranking.png`} alt="ranking" />
          Leaderboard
        </div>
        <div className={formatClassName(styles, 'container')}>
          <div className={formatClassName(styles, 'entry my')}>
            <div className={formatClassName(styles, 'bg')} ></div>
            <div className={formatClassName(styles, `rank ${formatRank(stats?.data?.rank)}`)} >
              {stats?.data?.rank === 0 ? '-' : stats?.data?.rank}
            </div>
            <div className={formatClassName(styles, 'key')}>
              You
            </div>
            <div className={formatClassName(styles, 'score')}>
              {stats?.data?.score === 0 ? 'no score' : stats?.data?.score.toLocaleString()}
            </div>
          </div>
          {/*<div className={formatClassName(styles, 'tabs')}>
            <div className={formatClassName(styles, 'tab')} onClick={() => {setLeaderboard('weekly')}}>
              <Button className={formatClassName(styles, `xsmall ${leaderboard === 'weekly' ? '' : 'gray'}`)}>WEEKLY</Button>
            </div>
            <div className={formatClassName(styles, 'tab')} onClick={() => {setLeaderboard('global')}}>
              <Button className={formatClassName(styles, `xsmall ${leaderboard === 'global' ? '' : 'gray'}`)}>GLOBAL</Button>
            </div>
          </div>*/}
          <div className={formatClassName(styles, 'list-container')}>
            <div className={formatClassName(styles, 'list')}>
              {
                !loading && stats
                  ? (stats.data?.top250 ?? []).map((entry, index) =>
                    <div className={formatClassName(styles, 'entry')} key={entry.publicKey}>
                      <div className={formatClassName(styles, 'bg')} ></div>
                      <div className={formatClassName(styles, `rank ${formatRank(index + 1)}`)} key={`${entry.publicKey}-rank`}>
                        {index + 1}
                      </div>
                      <div className={formatClassName(styles, 'key')} key={`${entry.publicKey}-key`}>
                        {entry.username}
                      </div>
                      <div className={formatClassName(styles, 'score')} key={`${entry.publicKey}-score`}>
                        {entry.score.toLocaleString()}
                      </div>
                    </div>
                  )
                  : <div className={formatClassName(styles, 'loader')}>Loading...</div>
              }
            </div>
          </div>
          <div className={formatClassName(styles, 'info')}>
            the leaderboard is computed each minute
          </div>
        </div>
      </div>
    </div>
  )
}
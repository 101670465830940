import {Emitter} from '@pixi/particle-emitter'
import {Container, Texture} from 'pixi.js'

export const fireParticles = (container: Container, x: number, y: number, textures: (Texture | undefined)[]) => {
  //const triangle = new Graphics()
  //  .beginFill(0xe8e8e8)
  //  .drawPolygon(x, y, x - 100 * ratio, y - 200 * ratio, x + 100 * ratio, y - 200 * ratio)
  //  .endFill()

  //container.addChild(triangle)

  return new Emitter(
    container,
    {
      lifetime: {
        min: 0.2,
        max: 0.48
      },
      frequency: 0.0005,
      maxParticles: 3000,
      addAtBack: false,
      pos: {
        x,
        y
      },
      behaviors: [
        {
          type: 'alpha',
          config: {
            alpha: {
              list: [
                {
                  time: 0,
                  value: 0.62
                },
                {
                  time: 1,
                  value: 0
                }
              ]
            }
          }
        },
        {
          type: 'moveSpeedStatic',
          config: {
            min: 500,
            max: 750
          }
        },
        {
          type: 'scale',
          config: {
            scale: {
              list: [
                {
                  time: 0,
                  value: 0.5
                },
                {
                  time: 1,
                  value: 0.75
                }
              ]
            },
            minMult: 1
          }
        },
        {
          type: 'color',
          config: {
            color: {
              list: [
                {
                  time: 0,
                  value: 'fff191'
                },
                {
                  time: 1,
                  value: 'ff622c'
                },
                //{
                //  time: 1,
                //  value: '666666'
                //}
              ]
            }
          }
        },
        {
          type: 'rotation',
          config: {
            accel: 0,
            minSpeed: 50,
            maxSpeed: 200,
            minStart: 74,
            maxStart: 105
          }
        },
        {
          type: 'textureRandom',
          config: {
            textures
          }
        },
        //{
        //  type: 'spawnBurst',
        //  config: {
        //    spacing: 5,
        //    start: 0,
        //    distance: 1,
        //  }
        //},
        {
          type: 'spawnShape',
          config: {
            type: 'torus',
            data: {
              x: 0,
              y: 0,
              radius: 10,
              innerRadius: 0,
              affectRotation: false
            }
          }
        }
      ]
    }
  )
}
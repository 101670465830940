import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React from 'react'
import {formatClassName} from '../../../../../../utils/global'
import {Button} from '../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../components/modal/modal'
import styles from './weekly-prize-menu.module.scss'

export type WeeklyPrizeMenuProps = GenericModalProps & {
  openPublic: () => void
  openHolder: () => void
}

const Content = ({openPublic, openHolder, onClose}: {
  openPublic: () => void
  openHolder: () => void
  onClose: () => void
}) => {
  const launchPublic = async () => {
    openPublic()

    onClose()
  }

  const launchHolder = () => {
    openHolder()

    onClose()
  }

  const tensor = () => {
    const params = [
      'height=' + screen.height,
      'width=' + screen.width,
      'fullscreen=yes'
    ].join(',')

    window.open('https://www.tensor.trade/trade/oogy', undefined, params)
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'holder')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/multi-mode.png`} alt="holder" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <Button onClick={launchHolder}>
          holder
        </Button>
      </div>
    </div>
    <div className={formatClassName(styles, 'public')}>
      <div className={formatClassName(styles, 'image')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/weekly-prize-public.png`} alt="public" />
      </div>
      <div className={formatClassName(styles, 'button-container')}>
        <Button className='violet' onClick={launchPublic}>
          public
        </Button>
      </div>
    </div>
    <Button className={formatClassName(styles, 'violet buy')} onClick={tensor}><img src={`${OOGY_VISUAL_CDN_URL}/ui/tensor.png`} alt="tensor" /> BUY OOGY</Button>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Weekly Prizes
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const WeeklyPrizeMenu = ({className, onClose, openPublic, openHolder, show, ...props}: WeeklyPrizeMenuProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `weekly-prize-menu ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content openPublic={openPublic} openHolder={openHolder} onClose={onClose} />}
      onClickBackground={onClose}
      show={show}
      {...props}
    />
  )
}
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { HtmlHTMLAttributes, useEffect, useState } from 'react'
import Countdown, { CountdownRendererFn } from 'react-countdown'
import { toast } from 'react-toastify'
import { formatClassName } from '../../../../../../../utils/global'
import { clubClashRequest, getClashLeaderboard } from '../../../../../../api/hub'
import { Button } from '../../../../../../components/buttons/button'
import { OverlayModal } from '../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../state/context'
import { Background } from '../../background/background'
import { Reward } from '../../chests/rewards/rewards'
import styles from './clash.module.scss'
import { ClubEntry } from './club/club-entry'
import { ConfirmClub } from './confirm-club/confirm-club'
import { AboutTab } from './tabs/about/about-tab'
import { LeaderboardTab } from './tabs/leaderboard/leaderboard-tab'
import { ClashData, Club } from './type'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({ days, hours, minutes }) =>
  <span>
    {addZeroToNumber(days)}D {addZeroToNumber(hours)}H {addZeroToNumber(minutes)}M
  </span>

export type ClashTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const ClashTab = ({ className, ...props }: ClashTabProps) => {
  const { state: { accountInfo, publicKey } } = useHubContext()

  const [tab, setTab] = useState<'about' | 'leaderboard'>('about')
  const [showConfirm, setShowConfirm] = useState(false)
  const [showFullClubList, setShowFullClubList] = useState(false)
  const [showGemsReward, setShowGemsReward] = useState(false)
  const [gemsWon, setGemsWon] = useState(0)
  const [clubClash, setClubClash] = useState<ClashData>()
  const [clubTop, setClubTop] = useState<(Club & {
    score: number
    clubId: string
  })[]>()
  const [rewards, setRewards] = useState<Reward[]>([])
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState<Partial<Awaited<ReturnType<typeof getClashLeaderboard>>>>()

  const refreshClubClash = () => {
    setLoading(true)

    clubClashRequest().then((response) => {
      if (response?.status === 200) {
        setClubClash(response.data)
        setRewards([
          // {
          //   name: `${(response.data.gems ?? 0)} GEMS`,
          //   imageURL: 'https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/gem.png',
          //   quantity: 1,
          //   rarity: 'special',
          //   type: 'generic'
          // },
          ...response.data.rewards.map(reward => ({
            ...reward,
            quantity: 1,
            rarity: 'special',
            type: 'generic'
          }))
        ] as Reward[])
      }
    }).catch(() => {
      toast.error('Impossible to retrieve the club clash info')
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    refreshClubClash()
  }, [])

  useEffect(() => {
    if (!clubClash) return

    getClashLeaderboard().then((res) => {
      setStats(res)

      if (!res) return

      const clubTop: (Club & {
        score: number
        clubId: string
      })[] = Object.entries(clubClash.clubs).map(([clubId, data]) => {
        const entries = res.data.top.filter(entry => entry.clubId === clubId).sort((a, b) => b.score - a.score).slice(0, 20)

        return {
          ...data,
          clubId,
          score: entries.length === 0 ? 0 : entries.reduce((previousValue, entry) => previousValue + entry.score, 0)
        }
      }).sort((a, b) => b.score - a.score)

      setClubTop(clubTop)
    }).catch(console.error)
  }, [clubClash])

  useEffect(() => {
    if (!clubClash || !clubClash.ended || !clubClash.winners) return

    const found = clubClash.winners.find(winner => winner.publicKey === publicKey)

    if (!found) return

    setGemsWon(found.gems)
    setShowGemsReward(true)
  }, [clubClash, publicKey])

  const onParticipate = () => {
    if (!accountInfo?.clubId) {
      setShowConfirm(true)
    }
  }

  const clubClashStarted = !!clubClash && clubClash.startingAt < Date.now()

  return (
    <>
      <Background className='high' />
      <div className={formatClassName(styles, `clash-tab ${className}`)} {...props}>
        <div className={formatClassName(styles, 'clash')}>
          <div className={formatClassName(styles, 'title')}>
            Club Clash
            {clubClash &&
              <div className={formatClassName(styles, 'countdown-container')}>
                <img src={`${OOGY_VISUAL_CDN_URL}/ui/clock.png`} alt="clock" />
                <div className={formatClassName(styles, 'countdown')}>
                  {(clubClash.startingAt ?? 0) > Date.now() ? 'Starting in' : (clubClash.endingAt ?? 0) > Date.now() ? 'Ending in' : 'Ended'}
                  {(!loading && clubClash.endingAt > Date.now()) &&
                    <Countdown date={(clubClash.startingAt ?? 0) > Date.now() ? clubClash.startingAt : clubClash.endingAt} renderer={renderer} onComplete={refreshClubClash} />
                  }
                </div>
              </div>
            }
          </div>
          <div className={formatClassName(styles, 'container')}>
            {
              clubClash
                ? <>
                  {
                    !clubClashStarted &&
                    <div className={formatClassName(styles, 'sub-title')}>
                      Club list
                    </div>
                  }
                  {
                    (clubClashStarted && !showFullClubList) && <div className={formatClassName(styles, 'clubs-top')}>
                      <div className={formatClassName(styles, 'second')}>
                        {clubTop?.[1] &&
                          <>
                            <div className={formatClassName(styles, 'image')}><img src={clubTop[1].imageURL} alt={clubTop[1].name} /></div>
                            <div className={formatClassName(styles, 'name')}>{clubTop[1].name}</div>
                            <div className={formatClassName(styles, 'score')}>{clubTop[1].score}</div>
                          </>
                        }
                      </div>
                      <div className={formatClassName(styles, 'first')}>
                        {clubTop?.[0] &&
                          <>
                            <div className={formatClassName(styles, 'image')}><img src={clubTop[0].imageURL} alt={clubTop[0].name} /></div>
                            <div className={formatClassName(styles, 'name')}>{clubTop[0].name}</div>
                            <div className={formatClassName(styles, 'score')}>{clubTop[0].score}</div>
                          </>
                        }
                      </div>
                      <div className={formatClassName(styles, 'third')}>
                        {clubTop?.[2] &&
                          <>
                            <div className={formatClassName(styles, 'image')}><img src={clubTop[2].imageURL} alt={clubTop[2].name} /></div>
                            <div className={formatClassName(styles, 'name')}>{clubTop[2].name}</div>
                            <div className={formatClassName(styles, 'score')}>{clubTop[2].score}</div>
                          </>
                        }
                      </div>
                    </div>
                  }
                  {
                    (!clubClashStarted || showFullClubList) && <div className={formatClassName(styles, 'clubs')}>
                      {
                        accountInfo?.clubId === undefined &&
                        <div className={formatClassName(styles, 'overlay-button')} >
                          <Button onClick={onParticipate}>Join Atlas Clash</Button>
                        </div>
                      }
                      {
                        (clubClash ? Object.entries(clubClash.clubs) : []).sort(([, a], [, b]) => b.members - a.members).map(([id, club]) =>
                          <ClubEntry
                            key={id}
                            className={`${accountInfo?.clubId === id ? 'selected' : ''} ${accountInfo?.clubId ? 'disabled' : ''} ${accountInfo?.clubId === undefined ? 'transparent' : ''}`}
                            clubId={id}
                            club={club}
                            score={clubTop?.find(club => club.clubId === id)?.score ?? 0}
                          />
                        )
                      }
                    </div>
                  }
                  {
                    clubClashStarted && <div className={formatClassName(styles, 'show-all')} onClick={() => setShowFullClubList(!showFullClubList)}>
                      {showFullClubList ? 'hide' : 'show'} all
                      <img className={formatClassName(styles, showFullClubList ? 'up' : '')} src={`${OOGY_VISUAL_CDN_URL}/ui/open-arrow.png`} alt="arrow" />
                    </div>
                  }
                  {clubClashStarted &&
                    <div className={formatClassName(styles, 'tabs')}>
                      <div className={formatClassName(styles, 'tab')} onClick={() => { setTab('about') }}>
                        <Button className={formatClassName(styles, `xsmall ${tab === 'about' ? '' : 'gray'}`)}>ABOUT</Button>
                      </div>
                      <div className={formatClassName(styles, 'tab')} onClick={() => { setTab('leaderboard') }}>
                        <Button className={formatClassName(styles, `xsmall ${tab === 'leaderboard' ? '' : 'gray'}`)}>LEADERBOARD</Button>
                      </div>
                    </div>
                  }
                  {tab === 'about' && <AboutTab rewards={rewards} />}
                  {tab === 'leaderboard' && <LeaderboardTab clubClash={clubClash} stats={stats} />}
                </>
                : <div className={formatClassName(styles, 'no-clash')}>
                  {loading ? 'loading...' : 'No upcoming club clash'}
                </div>
            }
          </div>
        </div>
      </div>
      <ConfirmClub show={showConfirm} onClose={() => {
        setShowConfirm(false)
        refreshClubClash()
      }} />
      <OverlayModal show={showGemsReward} onClickBackground={() => setShowGemsReward(false)}>
        <div
          className={formatClassName(styles, 'daily-rewards-modal')}
          style={{ backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-modal.png')` }}
        >
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-icon.png`} alt="daily-rewards-icon" />
          <div className={formatClassName(styles, 'title')}>
            GEMS EARNED
          </div>
          <div className={formatClassName(styles, 'rewards')}>
            +{gemsWon}<img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          </div>
        </div>
      </OverlayModal>
    </>
  )
}
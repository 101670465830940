@import "scss-variables";

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 0;

  .gradient {
    position: absolute;
    background: linear-gradient(180deg, rgba(184, 78, 187, 0) 0%, rgba(193, 81, 192, 0.5) 100%);
    mix-blend-mode: lighten;
    bottom: 47%;
    opacity: 0.6;
    width: 100%;
    height: 25%;
    z-index: 2;
  }

  .bg-top,
  .bg-middle,
  .bg-bottom {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
  }

  .bg-top {
    top: 0;
    height: 50%;
    background-size: cover;
    background-position: center center;
    z-index: 1;
  }

  .bg-middle {
    bottom: 40%;
    height: 50%;
    background-size: contain;
    background-position: center bottom;
    z-index: 2;
  }

  .bg-bottom {
    bottom: 0;
    height: 46%;
    background-size: cover;
    background-position: left;
    z-index: 3;
  }

  &.high {
    .bg-middle {
      bottom: 58%;
    }

    .bg-bottom {
      height: 65%;
    }
  }
}

import React, {HtmlHTMLAttributes} from 'react'
import {formatClassName} from '../../../../../../utils/global'
import {useHubContext} from '../../../../../state/context'
import {BattleTab} from '../tabs/battle/battle'
import {CharacterTab} from '../tabs/character/character'
import {ClashTab} from '../tabs/clash/clash'
import {LeaderboardTab} from '../tabs/leaderboard/leaderboard'
import {WeeklyPrizeTab} from '../tabs/weekly-prize/weekly-prize'
import styles from './homescreen.module.scss'

export type HomescreenProps = HtmlHTMLAttributes<HTMLDivElement>

export const Homescreen = ({className, ...props}: HomescreenProps) => {
  const {state: {tab}} = useHubContext()

  return (
    <>
      <div className={formatClassName(styles, `homescreen ${className}`)} {...props}>
        <div className={formatClassName(styles, 'tab')}>
          {tab === 'leaderboard' && <LeaderboardTab />}
          {tab === 'clash' && <ClashTab />}
          {tab === 'battle' && <BattleTab />}
          {tab === 'character' && <CharacterTab />}
          {tab === 'weekly-prize' && <WeeklyPrizeTab />}
          {tab === 'holder-weekly-prize' && <WeeklyPrizeTab holder />}
        </div>
      </div>
    </>
  )
}
import React from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {HubLayout} from './layouts/hub-layout'
import {WalletLayout} from './layouts/wallet-layout'
import {DashboardPage} from './pages/dashboard/dashboard'
import {Player} from './pages/dashboard/sub-pages/game/player'
import {HubProvider} from './state/context'

export const Hub = () =>
  <HubProvider>
    <BrowserRouter>
      <Routes>
        <Route element={<HubLayout />} >
          <Route element={<WalletLayout />}>
            <Route path="dashboard" element={<DashboardPage />}>
              <Route path="game" element={<Player />} />
              <Route path="*" element={<Navigate to="game" replace />} />
              <Route index element={<Navigate to="game" replace />} />
            </Route>
          </Route>

          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </HubProvider>
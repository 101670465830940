@import "scss-variables";

.confirm-club {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;

    .club {
      width: 100%;
    }

    .text {
      color: #d2cff0;
      text-align: center;
      font-family: "Cees Hand";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .gems {
      width: min(100%, 18rem);

      img {
        height: 100%;
        margin-right: 1rem;
      }
    }
  }
}

import React, {InputHTMLAttributes} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './input.module.scss'

export type InputProps = InputHTMLAttributes<HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({className, ...props}, ref) => {
  return <input
    ref={ref}
    className={formatClassName(styles, `input ${className}`)}
    {...props}
  />
})
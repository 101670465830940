import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React from 'react'
import {formatClassName} from '../../../../../../../utils/global'
import {Button} from '../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {useHub} from '../../../../../../hooks/use-hub'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import styles from './wallet.module.scss'

export type WalletProps = GenericModalProps & {
  openDepositVessels: () => void
  onClose: () => void
}

const Content = ({openDepositVessels}: {openDepositVessels: () => void}) => {
  const {disconnect} = useHub()
  const {state: {publicKey, accountInfo}} = useHubContext()

  const onDisconnect = () => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Disconnect',
        text: 'Do you really want to disconnect your wallet?',
        acceptText: 'Yes',
        refuseText: 'No',
        onAccept: disconnect
      })
    }
  }

  return <div className={formatClassName(styles, 'content')}>
    {`${publicKey?.slice(0, 4)} . . . ${publicKey?.slice(-4)}`}
    <Button className={formatClassName(styles, 'vessel yellow')}
    //onClick={openDepositVessels}
    >
      You have {accountInfo?.vessels ?? 0}<img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessel" />
    </Button>
    <Button className={formatClassName(styles, 'disconnect small gray')} onClick={onDisconnect}>DISCONNECT WALLET</Button>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Wallet
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const Wallet = ({className, onClose, openDepositVessels, ...props}: WalletProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `wallet ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content openDepositVessels={openDepositVessels} />}
      onClickBackground={onClose}
      {...props}
    />
  )
}
import {createTransferInstruction} from '@metaplex-foundation/mpl-bubblegum'
import {WalletContextState} from '@solana/wallet-adapter-react'
import {Connection, PublicKey, Transaction} from '@solana/web3.js'
import {toast} from 'react-toastify'
import {depositVesselsRequest, verifyDepositVesselsRequest} from '../../../../api/hub'

export const depositVessels = async (
  numberOfVessels: number,
  connection: Connection,
  publicKey: string,
  signAllTransactions: WalletContextState['signAllTransactions'],
  callback: (show: boolean, nbWaiting: number, nbValidated: number, nbFailed: number) => void
) => {
  if (!signAllTransactions) return

  toast.info('Creating the transaction...')

  const result = await depositVesselsRequest(numberOfVessels)

  const data = result?.data

  if (data) {
    const transactions: {
      assetId: string
      transaction: Transaction
      status: 'waiting' | 'validated' | 'failed'
      signature?: string
    }[] = []

    const {
      context: {slot: minContextSlot},
      value: {blockhash, lastValidBlockHeight}
    } = await connection.getLatestBlockhashAndContext()

    data.forEach(({assetId, transactionData}) => {
      const transaction = new Transaction()
      transaction.recentBlockhash = blockhash
      transaction.lastValidBlockHeight = lastValidBlockHeight
      transaction.minNonceContextSlot = minContextSlot
      transaction.feePayer = new PublicKey(publicKey)

      const prepareData = {
        accounts: {
          merkleTree: new PublicKey(transactionData.accounts.merkleTree),
          treeAuthority: new PublicKey(transactionData.accounts.treeAuthority),
          leafOwner: new PublicKey(transactionData.accounts.leafOwner),
          leafDelegate: new PublicKey(transactionData.accounts.leafDelegate),
          newLeafOwner: new PublicKey(transactionData.accounts.newLeafOwner),
          logWrapper: new PublicKey(transactionData.accounts.logWrapper),
          compressionProgram: new PublicKey(transactionData.accounts.compressionProgram),
          anchorRemainingAccounts: transactionData.accounts.anchorRemainingAccounts.map(entry => (
            {
              ...entry,
              pubkey: new PublicKey(entry.pubkey)
            }
          ))
        },
        args: transactionData.args,
        programId: new PublicKey(transactionData.programId)
      }

      const transferTx = createTransferInstruction(
        prepareData.accounts,
        prepareData.args,
        prepareData.programId
      )

      transaction.add(transferTx)

      transactions.push({
        assetId,
        transaction,
        status: 'waiting'
      })
    })

    const signedTransactions = await signAllTransactions(transactions.map(entry => entry.transaction))

    await Promise.all(
      signedTransactions.map(async (transaction, index) => {
        const signature = await connection.sendRawTransaction(transaction.serialize(), {
          minContextSlot
        })

        transactions[index].signature = signature
      })
    )

    callback(
      true,
      transactions.filter(entry => entry.status === 'waiting').length,
      transactions.filter(entry => entry.status === 'validated').length,
      transactions.filter(entry => entry.status === 'failed').length,
    )

    let verifyCount = 0

    do {
      await Promise.all(
        transactions.filter(entry => entry.status === 'waiting').map(async transaction => {
          if (!transaction.signature) return

          const verifyResponse = await verifyDepositVesselsRequest(transaction.assetId, transaction.signature)

          if (verifyResponse?.status === 200) {
            transaction.status = 'validated'
          }

          callback(
            true,
            transactions.filter(entry => entry.status === 'waiting').length,
            transactions.filter(entry => entry.status === 'validated').length,
            transactions.filter(entry => entry.status === 'failed').length,
          )
        })
      )

      if (verifyCount++ >= 0) {
        transactions.forEach(entry => {
          if (entry.status === 'waiting') {
            entry.status = 'failed'
          }
        })
      }

      callback(
        true,
        transactions.filter(entry => entry.status === 'waiting').length,
        transactions.filter(entry => entry.status === 'validated').length,
        transactions.filter(entry => entry.status === 'failed').length,
      )
    } while (transactions.find(entry => entry.status === 'waiting'))
  }
}
@import "scss-variables";

.buy-gems {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    color: #fff;
    text-shadow: 0px 4px 0px #201728;
    font-family: "Supersonic Rocketship";
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 3px;

    .tile {
      display: flex;
      border-radius: 13px;
      background: linear-gradient(180deg, rgba(227, 153, 111, 0.98) 0%, #fa8efc 100%);
      box-shadow:
        0px 4px 7px 0px rgba(233, 146, 255, 0.86) inset,
        0px 3px 0px 0px #582499;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      cursor: pointer;

      .details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;
        border-radius: 16px;
        background: linear-gradient(180deg, rgba(211, 171, 242, 0.98) 7.29%, #bea5f3 60.94%);
        box-shadow:
          0px 5px 0px 0px rgba(0, 0, 0, 0.25),
          0px 5px 8.75px 0px rgba(233, 146, 255, 0.86) inset,
          0px 3.75px 0px 0px #582499;
        text-align: center;
        font-size: 20px;
        border: 1.5px solid #6633a3;
        width: 7.5rem;

        .gems {
          position: relative;
          height: 3rem;

          &.gems-1 img {
            position: absolute;
            height: 100%;
            inset: 0;
            margin: auto;
          }

          &.gems-2 {
            img:nth-child(1) {
              position: absolute;
              height: 100%;
              inset: 0;
              margin: auto;
              z-index: 1;
            }

            img:nth-child(2) {
              position: absolute;
              height: 80%;
              bottom: 0;
              left: 0;
            }
          }

          &.gems-3 {
            img:nth-child(1) {
              position: absolute;
              height: 100%;
              inset: 0;
              margin: auto;
              z-index: 1;
            }

            img:nth-child(2) {
              position: absolute;
              height: 80%;
              bottom: 0;
              left: 0;
            }

            img:nth-child(3) {
              position: absolute;
              height: 80%;
              bottom: 0;
              right: 0;
            }
          }
        }
      }

      .price {
        position: relative;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        img {
          height: 2rem;
        }

        .discount {
          z-index: 10;
          position: absolute;
          background: #7399c7;
          padding: 0.5rem;
          border-radius: 1rem;
          left: 75%;
          top: 0.5rem;
          font-size: 16px;
        }
      }
    }
  }
}
// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {type} from '@colyseus/schema'
import {EntitySchema} from './EntitySchema'

export class MovingEntitySchema extends EntitySchema {
  @type('number') public dx!: number
  @type('number') public dy!: number
  @type('number') public speed!: number
  @type('number') public maxSpeed!: number
  @type('number') public boundingX!: number
  @type('number') public boundingY!: number
  @type('number') public boundingWidth!: number
  @type('number') public boundingHeight!: number
  @type('string') public orientation!: string
}

import { Howl } from 'howler'
import { OOGY_SOUND_CDN_URL } from 'oogy-blast/src/utils/constants'

// Main

Howler.mute(window.localStorage.getItem('music-muted') === 'true')

const musicSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/music/oasys.mp3`],
  volume: 0.10,
  preload: true,
  loop: true
})

export const playMainMusic = () => {
  if (!musicSound.playing()) {
    musicSound.stop().play()
  }
}

// Buttons

export const hoverButtonSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/button/hover.mp3`],
  volume: 0.2,
  preload: true
})

export const clickButtonSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/button/click.mp3`],
  volume: 0.2,
  preload: true
})

export const clickRefusedButtonSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/button/click-refused.mp3`],
  volume: 0.2,
  preload: true
})

export const switchButtonSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/button/switch.mp3`],
  volume: 0.2,
  preload: true
})

// Chests

export const openChestSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/open-chest.mp3`],
  volume: 0.15,
  preload: true,
  sprite: {
    open: [650, 8000]
  }
})

export const cardFlipSound = new Howl({
  src: [`${OOGY_SOUND_CDN_URL}/ui/card-flip.mp3`],
  volume: 0.1,
  preload: true
})
import {sound} from '@pixi/sound'
import {Room} from 'colyseus.js'
import {addAOE, removeAOE} from '../aoe'
import {addAura, removeAura} from '../aura'
import {initBackground} from '../backgrounds'
import {addBullet, bulletCollided, explosion, removeBullet} from '../bullet'
import {showIceWave} from '../effects/icewave'
import {showLightning} from '../effects/lightning'
import {showSpike} from '../effects/spike'
import {Aura, Item, Skill, SoundName} from '../enums'
import {addItem, itemTouched, removeItem} from '../item'
import {initLevelAfterServer, initLevelBeforeServer} from '../level'
import {hideIcecube, hideReviveCircular, hideSkillMenu, hideWaitingDots, resetPlayerAfterRevive, setGemsCount, setLevelCount, setLevelMask, setLifeProgress, setMonsterCount, setMonsterLifeProgress, setScoreCount, setTime, showContinueMenu, showDeadPlayer, showIcecube, showRevive, showReviveCircular, showScoreMenu, showSkillMenu, showTarget, showTextOnElement, showWaitingDots, showWave} from '../level-ui'
import {addMonster, killMonster, setMonsterAnimation} from '../monster'
import {initPlayer} from '../player'
import {addSkill, removeSkill} from '../skill'
import {playSpatialSound} from '../sound'
import {gameState} from '../state'
import {weaponFiring} from '../weapon'
import {ClassicRoomState, PlayerSchema} from './schema'

export const onConnect = (room: Room<ClassicRoomState>, localPlayer: PlayerSchema) => {
  console.log('on connect')

  const level = room.state.level

  gameState.level = {
    state: {
      lastPauseTimestamp: 0,
      totalTime: 0,
      canMove: level.canMove,
      ended: false,
      won: false,
      worldSize: level.worldSize,
      waveStarted: false,
      localPlayer
    }
  }

  initLevelBeforeServer(room)

  initServerListener(room, localPlayer)

  initLevelAfterServer()

  initServerMessage(room)
}

const initServerListener = (room: Room<ClassicRoomState>, localPlayer: PlayerSchema) => {
  room.state.players.onAdd((player) => {
    const playerInfo = initPlayer(player)

    player.listen('dx', (value) => {
      if (!playerInfo || player.clientId === localPlayer.clientId) return

      if (Math.abs(value) > 0.1) {
        playerInfo.playerSpine.state.setAnimation(0, 'WALK', true)
        playerInfo.gunSpine.state.setAnimation(0, 'GUN_WALK', true)
      } else {
        playerInfo.playerSpine.state.setAnimation(0, 'IDLE', true)
        playerInfo.gunSpine.state.setAnimation(0, 'GUN_IDLE', true)
      }
    })

    player.listen('dy', (value) => {
      if (!playerInfo || player.clientId === localPlayer.clientId) return

      if (Math.abs(value) > 0.1) {
        playerInfo.playerSpine.state.setAnimation(0, 'WALK', true)
        playerInfo.gunSpine.state.setAnimation(0, 'GUN_WALK', true)
      } else {
        playerInfo.playerSpine.state.setAnimation(0, 'IDLE', true)
        playerInfo.gunSpine.state.setAnimation(0, 'GUN_IDLE', true)
      }
    })

    player.listen('life', (currentValue, previousValue) => {
      const difference = previousValue - currentValue

      setLifeProgress(player, difference)
    })

    if (player !== localPlayer) {
      player.listen('shootingAngle', (angle) => {
        if (!playerInfo) return

        const preparedAngle = angle + Math.PI / 2

        playerInfo.playerSpine.skeleton.scaleX = preparedAngle < Math.PI
          ? Math.abs(playerInfo.playerSpine.skeleton.scaleX)
          : preparedAngle > Math.PI
            ? -Math.abs(playerInfo.playerSpine.skeleton.scaleX)
            : playerInfo.playerSpine.skeleton.scaleX

        if (preparedAngle < Math.PI) {
          playerInfo.gunSpine.skeleton.scaleX = 1
        } else {
          playerInfo.gunSpine.skeleton.scaleX = -1
        }

        playerInfo.gunSpine.rotation = (angle < Math.PI / 2) ? angle : angle - Math.PI

        if (gameState.level) {
          const emitter = gameState.references.particles.get(player.clientId)?.emitter
          if (emitter) {
            emitter.rotate(angle - Math.PI / 2 - 0.1)
          }
        }
      })
    }

    player.auras.onAdd((_, type) => {
      addAura(player, type as Aura)
    })

    player.auras.onRemove((_, type) => {
      removeAura(player, type as Aura)
    })

    player.skills.onAdd((skillSchema, type) => {
      addSkill(player, skillSchema, type as Skill)
    })

    player.skills.onRemove((_, type) => {
      removeSkill(player, type as Skill)
    })

    player.listen('waitingSkillUnlock', (waitingSkillUnlock) => {
      if (waitingSkillUnlock) {
        showWaitingDots(player)
      } else {
        hideWaitingDots(player)
      }
    })

    player.listen('frozen', (frozen) => {
      if (!playerInfo) return

      if (frozen) {
        playerInfo.playerSpine.state.setAnimation(0, 'IDLE', false)
        playerInfo.gunSpine.state.setAnimation(0, 'GUN_IDLE', false)

        showIcecube(player)
      } else {
        hideIcecube(player)
      }
    })
  })

  room.state.level.items.onAdd((item) => {
    if (!item.visibleForClientId || item.visibleForClientId === localPlayer.clientId) {
      addItem(item)
    }
  })

  room.state.level.items.onRemove((item) => {
    removeItem(item)
  })

  room.state.level.aoe.onAdd((aoe) => {
    addAOE(aoe)
  })

  room.state.level.aoe.onRemove((aoe) => {
    removeAOE(aoe)
  })

  room.state.level.monsters.onAdd((monster) => {
    addMonster(monster)

    monster.listen('life', () => {
      setMonsterLifeProgress(monster)
    })
  })

  room.state.level.monsters.onRemove((monster) => {
    killMonster(monster)
  })

  room.state.level.bullets.onAdd((bullet) => {
    addBullet(bullet)
  })

  room.state.level.bullets.onRemove((bullet) => {
    removeBullet(bullet)
  })

  room.state.level.listen('canMove', (value) => {
    if (!gameState.level) return

    gameState.level.state.canMove = value
  })

  room.state.level.listen('background', (value) => {
    initBackground(value)
  })

  room.state.listen('ended', (value) => {
    if (!gameState.level) return

    gameState.level.state.ended = value

    console.log('ended', value)
  })

  room.state.listen('wave', (value) => {
    console.log('wave', value)
  })

  room.state.listen('waveTimeLeft', (value) => {
    setTime(value)
  })

  room.state.listen('remainingMonsters', (value) => {
    setMonsterCount(value)
  })

  // ONLY FOR LOCAL PLAYER

  localPlayer.listen('score', (value) => {
    setScoreCount(value)
  })

  localPlayer.listen('gems', (value) => {
    setGemsCount(value)
  })

  localPlayer.listen('level', (currentValue) => {
    setLevelCount(currentValue + 1)
  })

  localPlayer.listen('experience', (currentValue) => {
    setLevelMask(currentValue / localPlayer.nextLevelExperience)
  })
}

const initServerMessage = (room: Room<ClassicRoomState>) => {
  room.onMessage<{
    name: string
    type: 'normal' | 'boss' | 'zombie'
    monsterCount: number
  }>('start-wave', ({
    name,
    type,
    monsterCount
  }) => {
    if (!gameState.level) return

    gameState.level.state.waveStarted = true
  })

  room.onMessage<{
    name: string
    type: 'normal' | 'boss' | 'zombie'
    monsterCount: number
  }>('new-wave', ({
    name,
    type,
    monsterCount
  }) => {
    if (!gameState.level) return

    gameState.level.state.waveStarted = false

    showWave(name, type, monsterCount)
  })

  room.onMessage<{
    clientId: string
    type: Item
  }>('item-touched', ({
    clientId,
    type
  }) => {
    itemTouched(clientId, type)
  })

  room.onMessage<{
    x: number
    y: number
    width: number
    height: number
  }>('explosion', ({
    x,
    y,
    width,
    height
  }) => {
    explosion(x, y, width, height)
  })

  room.onMessage<{
    clientId: string
  }>('weapon-firing', ({
    clientId
  }) => {
    weaponFiring(clientId)
  })

  room.onMessage<{
    x: number
    y: number
  }>('bullet-collided', ({
    x,
    y
  }) => {
    bulletCollided(x, y)
  })

  room.onMessage<{
    monsterUid: string
  }>('monster-killed', ({
    monsterUid
  }) => {
    const monster = gameState.references.monsters.get(monsterUid)

    if (!monster) return

    setMonsterLifeProgress(monster.schema)
  })

  room.onMessage<{
    monsterUid: string
    damages: number
    critical: boolean
  }>('hit-monster', ({
    monsterUid,
    damages,
    critical
  }) => {
    const monster = gameState.references.monsters.get(monsterUid)

    if (!monster) return

    if (damages > 0) {
      if (monster.schema.isBoss) {
        showTextOnElement({
          x: monster.container.x,
          y: monster.container.y,
          width: 0,
          height: 0
        }, `${damages}`, critical ? 0xEC2727 : 0x666666, 0xffffff, critical ? 1.5 : 1)
      } else {
        showTextOnElement(monster.container, `${damages}`, critical ? 0xEC2727 : 0x666666, 0xffffff, critical ? 1.5 : 1)
      }
    }

    setMonsterLifeProgress(monster.schema)
  })

  room.onMessage<{
    clientId: string
    revived: boolean
  }>('revive', ({
    clientId,
    revived
  }) => {
    if (revived) {
      resetPlayerAfterRevive(clientId)
    }
  })

  room.onMessage<{
    soundName: SoundName
    volume: number
    from: {
      x: number
      y: number
    }
  }>('play-sound', ({
    soundName,
    volume,
    from
  }) => {
    const localPlayer = gameState.level?.state.localPlayer

    if (!localPlayer) return

    const playedSound = sound.find(soundName)

    if (playedSound) {
      console.log(localPlayer, from, playedSound, false, {
        volume: volume ?? 1
      })
      playSpatialSound(localPlayer, from, playedSound, false, {
        volume: volume ?? 1
      })
    }
  })

  room.onMessage<{
    clientId: string
    score: number
    gems: number
  }>('need-to-be-revive', ({
    clientId,
    score,
    gems
  }) => {
    if (room.state.players.size === 1 && gameState.level?.state.localPlayer.clientId === clientId) {
      showContinueMenu(score, gems)
    } else {
      showRevive(clientId)
    }
  })

  room.onMessage<{
    clientId: string
    max: number
    left: number
  }>('start-reviving', ({
    clientId,
    max,
    left
  }) => {
    showReviveCircular(clientId, max, left)
  })

  room.onMessage<{
    clientId: string
  }>('stop-reviving', ({
    clientId
  }) => {
    hideReviveCircular(clientId)
  })

  room.onMessage<{
    clientId: string
    score: number
    gems: number
    won: number
  }>('dead', ({
    clientId,
    score,
    gems,
    won
  }) => {
    showDeadPlayer(clientId)

    if (gameState.level?.state.localPlayer.clientId === clientId) {
      //sound.stopAll().play(SoundName.GameWin)

      showWave(won ? 'Mission accomplished' : 'Game Over', 'normal')

      hideSkillMenu()

      setTimeout(() => {
        showScoreMenu(score, gems)
      }, 4000)
    }
  })

  room.onMessage<{
    effect: string
    x: number
    y: number
    params: any
  }>('effect', ({
    effect,
    x,
    y,
    params
  }) => {
    if (effect.includes('lightning')) {
      showLightning(x, y, effect.includes('green') ? 'green' : 'normal')
    } else if (effect.includes('icewave')) {
      showIceWave(x, y, params)
    } else if (effect.includes('spike')) {
      showSpike(x, y, params)
    }
  })

  room.onMessage<{
    x: number
    y: number
    radius: number
    duration: number
  }>('show-target', ({
    x,
    y,
    radius,
    duration
  }) => {
    showTarget(x, y, radius, duration)
  })

  room.onMessage<{
    monsterUid: string
    animation: string
    loop?: boolean
    playPreviousAnimationAfterCompletion?: boolean
  }>('set-monster-animation', ({
    monsterUid,
    animation,
    loop,
    playPreviousAnimationAfterCompletion
  }) => {
    setMonsterAnimation(monsterUid, animation, loop, playPreviousAnimationAfterCompletion)
  })

  room.onMessage<{
    level: number
    skills: {
      name: string
      description: string
      level: number
    }[]
  }>('new-level', (message) => {
    showSkillMenu(message.level, message.skills)
  })

  room.onMessage('new-level-forced', () => {
    hideSkillMenu()
  })

  //room.onMessage('pong', () => {
  //  if (!gameState.level) return

  //  const tmp = new Date().getTime()

  //  gameState.latency.history = [...gameState.latency.history.slice(0, 9), tmp - gameState.latency.pingTime]

  //  if (gameState.level.state.paused) {
  //    setTimeout(() => {
  //      pingMessage(room)
  //    }, 100)
  //  }

  //  updateLatencyCount()

  //  //console.log('DEAD, score:', score)
  //})
}

export const onJoinError = (err: any) => {
  gameState.external.showLoading(false)

  gameState.external.showModal({
    title: 'Impossible to connect to the game',
    text: err.message ? `(code ${err.code}) ${err.message}. You request has been refused, please try to refresh the page or unlock your wallet again` : ''
  })

  console.error(err)
}

export const onLeave = (code: any) => {
  if (code !== 1000) {
    gameState.room = undefined

    //gameState.external.showModal({
    //  title: 'Disconnected',
    //  text: 'You have been disconnected from the Oogy Blast game server'
    //})
  }
}

export const exitRoom = async () => {
  if (!gameState.room) return

  try {
    await gameState.room.leave(true)
  } catch (err) {
    console.error(err)
  }

  gameState.room = undefined
}
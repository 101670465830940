import CssFilterConverter from 'css-filter-converter'
import { Weapon } from 'oogy-blast/src/enums'
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { HtmlHTMLAttributes, useEffect, useState } from 'react'
import { formatClassName } from '../../../../../../../../../utils/global'
import { WeaponsData, getWeaponsData } from '../../../../../../../../data/weapons'
import { useHubContext } from '../../../../../../../../state/context'
import { BuyOrUpgradeWeapon } from './upgrade/buy-or-upgrade'
import styles from './weapons-tab.module.scss'

const COLOR_CACHE: {
  [color: string]: string
} = {}

export type CharacterWeaponsTabProps = HtmlHTMLAttributes<HTMLDivElement>

export const CharacterWeaponsTab = ({ className, ...props }: CharacterWeaponsTabProps) => {
  const { state: { accountInfo } } = useHubContext()

  const [showBuyOrUpgrade, setShowBuyOrUpgrade] = useState(false)
  const [data, setData] = useState<WeaponsData>()
  const [selectedName, setSelectedName] = useState<Weapon>()

  useEffect(() => {
    getWeaponsData().then(setData)
  }, [])

  const onTileClick = (name: Weapon) => {
    setSelectedName(name)
    setShowBuyOrUpgrade(true)
  }

  const colorToFilter = (color: string) => {
    if (!COLOR_CACHE[color]) {
      COLOR_CACHE[color] = CssFilterConverter.hexToFilter(color).color ?? 'none'
    }

    return COLOR_CACHE[color]
  }

  return (
    <>
      <div className={formatClassName(styles, `weapons-tab reveal-element ${className}`)} {...props}>
        <div className={formatClassName(styles, 'list')}>
          {Object.entries(data ?? {})
            .sort(([, a], [, b]) => a.order - b.order)
            //.sort(([, a], [, b]) => a.levels[0].priceToUnlock.gems - b.levels[0].priceToUnlock.gems)
            .filter(([name, entry]) => !entry.availableFrom || (accountInfo?.character.weapons.unlocked[name as Weapon] !== undefined) || (!entry.hiddenInShop && Date.now() >= entry.availableFrom && Date.now() <= (entry.availableTo ?? 0)))
            .map(([name, weapon]) =>
              <div
                key={name}
                className={formatClassName(styles, 'tile')}
                onClick={() => onTileClick(name as Weapon)}
              >
                <div
                  className={formatClassName(styles, 'bg')}
                  style={{
                    filter: `brightness(0) saturate(100%) ${colorToFilter(weapon.backgroundColor)}` ?? 'none',
                  }}
                ></div>
                <div
                  className={formatClassName(styles, 'image')}
                  style={{
                    backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/weapons/${name.toLowerCase()}.png')`,
                  }}
                ></div>
                <div className={formatClassName(styles, 'info')}>
                  {
                    accountInfo?.character.weapons.unlocked[name as Weapon] === undefined
                      ? <>
                        <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
                        {weapon.levels[0].priceToUnlock.gems}
                      </>
                      : accountInfo?.character.weapons.equiped === name
                      && <img src={`${OOGY_VISUAL_CDN_URL}/ui/check.png`} alt="check" />
                  }
                </div>
              </div>
            )}
        </div>
      </div>
      <BuyOrUpgradeWeapon show={showBuyOrUpgrade} onExited={() => {
        setSelectedName(undefined)
      }} onClose={() => setShowBuyOrUpgrade(false)} name={selectedName} data={data} />
    </>
  )
}
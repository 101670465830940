import {Room} from 'colyseus.js'
import {isMobile} from 'pixi.js'
import {createViewport} from './game'
import {hideReviveCircular, initLevelUI, initTeamView, moveStick, removeStick, showStick} from './level-ui'
import {fireAtMessage, moveMessage} from './server/message'
import {isMusicMuted, toggleMuteMusic} from './sound'
import {gameState} from './state'
import {degreesToRadians, getWidthRatio, radiansToDegrees, segmentAngle} from './utils'

export const initLevelBeforeServer = (room: Room) => {
  if (!gameState.level) return

  createViewport(true, gameState.level.state.worldSize, gameState.level.state.worldSize)

  initLevelSounds()

  //initBackground('')

  initLevelUI(room)

  initLevelListeners()

  //gameState.level.state.players.forEach((player) => initPlayer(player.schema))

  // Init initial elements
  initPlayers()
  initItems()
  initMonsters()

  //gameState.viewport!.moveCenter(getGameWidth() / 2, -getGameHeight() / 2)

  gameState.external.showLoading(false)

  gameState.loading = false

  // TEST
  //gameState.level.state.ended = true
  //showScoreMenu()
}

export const initLevelAfterServer = () => {
  initTeamView()
}

const initPlayers = () => {
  if (!gameState.references.players) return

  gameState.references.players.forEach(info => {
    if (!gameState.viewport || !info?.element) return

    gameState.viewport.addChild(info.element)
  })
}

const initItems = () => {
  if (!gameState.references.items) return

  gameState.references.items.forEach(info => {
    if (!gameState.viewport || !info?.element) return

    gameState.viewport.addChild(info.element)
  })
}

const initMonsters = () => {
  if (!gameState.references.monsters) return

  gameState.references.monsters.forEach(monster => {
    if (!gameState.viewport || !monster?.container) return

    gameState.viewport.addChild(monster.container)
  })
}

export const clearReferences = () => {
  gameState.references.players.forEach((player) => {
    if (player) {
      try {
        player.element.destroy()
      } catch { /* empty */}
    }
  })

  gameState.references.monsters.forEach((monster) => {
    if (monster) {
      try {
        monster.container.destroy()
      } catch { /* empty */}
    }
  })

  gameState.references.items.forEach((item) => {
    if (item) {
      try {
        item.element.destroy()
      } catch { /* empty */}
    }
  })

  gameState.references.bullets.forEach((bullet) => {
    if (bullet) {
      try {
        bullet.element.destroy()
      } catch { /* empty */}
    }
  })

  gameState.references.guns.forEach((gun) => {
    if (gun) {
      try {
        gun.destroy()
      } catch { /* empty */}
    }
  })

  gameState.references.skills.forEach((guns) => {
    guns.forEach(gun => {
      try {
        gun.element?.destroy()
      } catch { /* empty */}
    })
  })

  gameState.references.ui.level.auras.forEach((auras) => {
    auras.forEach(aura => {
      try {
        aura.destroy()
      } catch { /* empty */}
    })
  })

  gameState.references.ui.level.healthBars.forEach((healthBar) => {
    try {
      healthBar.container.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.shootingBars.forEach((shootingBar) => {
    try {
      shootingBar.container.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.revives.forEach((revive) => {
    try {
      revive.container?.destroy()
      revive.text?.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.pointers.forEach((pointer) => {
    try {
      pointer.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.shadows.forEach((shadow) => {
    try {
      shadow.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.waitingDots.forEach((waitingDots) => {
    try {
      waitingDots.destroy()
    } catch { /* empty */}
  })

  gameState.references.ui.level.countdowns.forEach((countdown) => {
    try {
      countdown.graphics.destroy()
    } catch { /* empty */}
  })

  gameState.references.players = new Map()
  gameState.references.monsters = new Map()
  gameState.references.items = new Map()
  gameState.references.bullets = new Map()
  gameState.references.guns = new Map()
  gameState.references.skills = new Map()
  gameState.references.ui.level.auras = new Map()
  gameState.references.ui.level.healthBars = new Map()
  gameState.references.ui.level.shootingBars = new Map()
  gameState.references.ui.level.revives = new Map()
  gameState.references.ui.level.pointers = new Map()
  gameState.references.ui.level.shadows = new Map()
  gameState.references.ui.level.waitingDots = new Map()
  gameState.references.ui.level.countdowns = []
  gameState.references.ui.level.sticks = []
}

export const initLevelSounds = () => {
  if (!gameState.level) return

  toggleMuteMusic(isMusicMuted())
}

const movement = new Set<'l' | 'r' | 't' | 'b'>()
let shootingAngleTimeout: number
let previousShootingAngle = 0

/**
 * Creating the listeners of the level
 */
export const initLevelListeners = () => {
  if (!gameState.viewport || !gameState.room) return

  const room = gameState.room

  if (isMobile.any) {
    gameState.viewport.on('pointerdown', (event) => {
      if (!gameState.viewport || !gameState.level) return

      const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
      const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

      if (!playerSpine || !gunSpine) return

      const id = gameState.viewport.screenWidth / 2 > event.x ? 0 : 1

      if (id === 0) {
        playerSpine.state.setAnimation(0, 'WALK', true)
        gunSpine.state.setAnimation(0, 'GUN_WALK', true)
      }

      showStick(event.x, event.y, event.pointerId, id)
    })

    gameState.viewport.on('pointermove', (event) => {
      if (!gameState.level) return

      const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

      if (gameState.level.state.ended || !gunSpine || !gameState.viewport) return

      const result = moveStick(event.x, event.y, event.pointerId)

      if (!result) return

      const x = gunSpine.x
      const y = gunSpine.y

      let {x: x2, y: y2} = gameState.viewport.toLocal(event)

      x2 -= result.coords.x - x
      y2 -= result.coords.y - y

      if (result.id === 0) {
        updateMovementMobile(segmentAngle(x, y, x2, y2))
      } else {
        aimTo(x, y, x2, y2, room)
      }
    })

    gameState.viewport.on('pointerup', (event) => {
      if (!gameState.viewport || !gameState.level) return

      const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
      const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

      if (!playerSpine || !gunSpine) return

      const id = removeStick(event.pointerId)

      if (id === 0) {
        moveMessage(room, false, previousMobileMovementAngle)
        playerSpine.state.setAnimation(0, 'IDLE', true)
        gunSpine.state.setAnimation(0, 'GUN_IDLE', true)
      }
    })

    gameState.viewport.on('pointerupoutside', (event) => {
      if (!gameState.viewport) return

      const id = removeStick(event.pointerId)

      if (id === 0) {
        moveMessage(room, false, previousMobileMovementAngle)
      }
    })
  } else {
    gameState.viewport.on('pointermove', (event) => {
      if (!gameState.level) return

      const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
      const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

      if (gameState.level.state.ended || !playerSpine || !gunSpine || !gameState.viewport) return

      const x = gunSpine.x
      const y = gunSpine.y

      const {x: x2, y: y2} = gameState.viewport.toLocal(event)

      //sound.play(SoundName.Shoot)

      aimTo(x, y, x2, y2, room)
    })

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)
  }
}

export const aimTo = (x: number, y: number, x2: number, y2: number, room: Room) => {
  if (!gameState.level) return

  const playerSchema = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.schema
  const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
  const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

  if (gameState.level.state.ended || !playerSpine || !gunSpine || !playerSchema || !gameState.viewport) return

  if (playerSchema.frozen) return

  //sound.play(SoundName.Shoot)

  const angle = segmentAngle(x, y, x2, y2)

  if (Math.abs(Math.abs(angle) - Math.abs(previousShootingAngle)) > 0.02) {

    clearTimeout(shootingAngleTimeout)

    previousShootingAngle = angle

    fireAtMessage(room, angle)
  } else {
    clearTimeout(shootingAngleTimeout)

    shootingAngleTimeout = setTimeout(() => {
      previousShootingAngle = angle

      fireAtMessage(room, angle)
    }, 100)
  }

  const preparedAngle = angle + Math.PI / 2

  if (preparedAngle < Math.PI) {
    playerSpine.skeleton.scaleX = 1
    gunSpine.skeleton.scaleX = 1
  } else {
    playerSpine.skeleton.scaleX = -1
    gunSpine.skeleton.scaleX = -1
  }

  gunSpine.rotation = (angle < Math.PI / 2) ? angle : angle - Math.PI

  const emitter = gameState.references.particles.get(gameState.level.state.localPlayer.clientId)?.emitter
  if (emitter) {
    emitter.rotate(angle - Math.PI / 2 - 0.1)
  }
}

/**
 * Update the countdowns
 */
export const updateCountdowns = () => {
  if (!gameState.level) return

  const player = gameState.references.players.get(gameState.level.state.localPlayer.clientId)

  if (!player) return

  const shootingBar = gameState.references.ui.level.shootingBars.get(player.schema.clientId)

  if (!shootingBar) return

  // Shooting progress
  const progress = (Date.now() - player.lastShootingTime) / player.schema.weapon.shootingCooldown
  shootingBar.bar.scale.x = Math.min(1, progress)

  // Circular countdown
  gameState.references.ui.level.revives.forEach(({
    container,
    max,
    left,
    graphics
  }, clientId) => {
    if (!gameState.app || !max || !left || !container || !graphics) return

    const completion = 1 - left / max

    if (completion >= 1 || left <= 0) {
      hideReviveCircular(clientId)
    } else {
      graphics
        .clear()
        .lineStyle(3 * getWidthRatio(), 0x601a15, 0.5)
        .arc(0, 0, 20 * getWidthRatio(), 0, 360 * (1 - completion) * Math.PI / 180, false)
    }
  })
}

const onKeyDown = (event: KeyboardEvent) => {
  switch (event.code) {
    case 'KeyW':
    case 'ArrowUp':
      movement.add('t')
      movement.delete('b')
      break
    case 'KeyA':
    case 'ArrowLeft':
      movement.add('l')
      movement.delete('r')
      break
    case 'KeyS':
    case 'ArrowDown':
      movement.add('b')
      movement.delete('t')
      break
    case 'KeyD':
    case 'ArrowRight':
      movement.add('r')
      movement.delete('l')
      break
  }

  updateMovementKeyboard()
}

const onKeyUp = (event: KeyboardEvent) => {
  if (!gameState.level) return

  switch (event.code) {
    case 'KeyW':
    case 'ArrowUp':
      movement.delete('t')
      break
    case 'KeyA':
    case 'ArrowLeft':
      movement.delete('l')
      break
    case 'KeyS':
    case 'ArrowDown':
      movement.delete('b')
      break
    case 'KeyD':
    case 'ArrowRight':
      movement.delete('r')
      break
    //case 'KeyP':
    //  if (gameState.level.state.paused) {
    //    hidePauseMenu()
    //  } else {
    //    showPauseMenu()
    //  }
    //  break
  }

  updateMovementKeyboard()
}

const updateMovementKeyboard = () => {
  if (!gameState.room || !gameState.level) return

  const playerSchema = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.schema
  const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
  const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

  if (!playerSpine || !gunSpine || !playerSchema) return

  let angle = 0

  if (movement.has('t')) {
    angle = 270

    if (movement.has('l')) {
      angle -= 45
    } else if (movement.has('r')) {
      angle += 45
    }
  } else if (movement.has('b')) {
    angle = 90

    if (movement.has('l')) {
      angle += 45
    } else if (movement.has('r')) {
      angle -= 45
    }
  } else if (movement.has('l')) {
    angle = 180
  } else if (movement.has('r')) {
    angle = 0
  }

  if (playerSchema.frozen) {
    return
  }

  if (movement.size > 0) {
    if (playerSpine.state.tracks[0]?.animation?.name === 'IDLE') {
      playerSpine.state.setAnimation(0, 'WALK', true)
      gunSpine.state.setAnimation(0, 'GUN_WALK', true)
    }
  } else if (playerSpine.state.tracks[0]?.animation?.name === 'WALK') {
    playerSpine.state.setAnimation(0, 'IDLE', true)
    gunSpine.state.setAnimation(0, 'GUN_IDLE', true)
  }

  moveMessage(gameState.room, movement.size > 0, degreesToRadians(angle))
}

let previousMobileMovementAngle = 0
const updateMovementMobile = (angle: number) => {
  if (!gameState.room || !gameState.level) return

  const playerSchema = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.schema
  const playerSpine = gameState.references.players.get(gameState.level.state.localPlayer.clientId)?.element
  const gunSpine = gameState.references.guns.get(gameState.level.state.localPlayer.clientId)

  if (!playerSpine || !gunSpine || !playerSchema) return

  const degrees = radiansToDegrees(angle) + 90

  if (!playerSchema.frozen && Math.abs(previousMobileMovementAngle - degrees) > 5) {
    previousMobileMovementAngle = degrees

    moveMessage(gameState.room, true, angle)
  }
}

export const clearListeners = async () => {
  if (!gameState.level || !gameState.app) return

  gameState.app.stage.removeAllListeners()
  gameState.viewport?.removeAllListeners()

  document.removeEventListener('keydown', onKeyDown)
  document.removeEventListener('keyup', onKeyUp)
}
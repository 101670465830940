import {Room} from 'colyseus.js'
import {ClassicRoomState} from 'oogy-blast'
import React, {createContext, useContext, useReducer} from 'react'
import {getSessionInfo} from '../../utils/session'
import {AccountInfo} from '../data/account'
import {Tabs} from '../pages/dashboard/sub-pages/game/navigation/tabs'
import {NewRoomAvailable} from '../pages/dashboard/sub-pages/game/tabs/battle/lobby/lobby'
import {Actions} from './actions'
import {hubState} from './hub'
import {hubReducer} from './reducer'

export type HubContextType = {
  isLedger?: boolean
  showConnectModal?: boolean
  sessionToken?: string
  twitterUsername?: string
  discordUsername?: string
  discordServerJoined?: boolean
  username?: string
  publicKey?: string
  userUuid?: string
  lastServerURL?: string
  room?: Room<ClassicRoomState>
  roomMetadata?: any
  roomSession?: {
    sessionId: string
    roomId: string
    roomAvailable: NewRoomAvailable
    reconnectionToken: string
  }
  accountInfo?: AccountInfo
  tab?: Tabs
  disconnectWallet?: () => Promise<void>
}

let initialState: HubContextType = {
  tab: 'battle'
}

const info = getSessionInfo()

if (info) {
  initialState = {
    ...initialState,
    ...info
  }

  hubState.sessionToken = info.sessionToken
}

export const HubContext = createContext<{
  state: HubContextType
  dispatch: React.Dispatch<Actions>
}>({
  state: initialState,
  dispatch: () => null
})

export const HubProvider = ({children}: {
  children: JSX.Element
}) => {
  const [state, dispatch] = useReducer(hubReducer, initialState)

  return (
    <HubContext.Provider value={{state, dispatch}}>
      {children}
    </HubContext.Provider>
  )
}

export const useHubContext = () => {
  return useContext(HubContext)
}
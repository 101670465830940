import {SpinePlayer} from '@esotericsoftware/spine-player'
import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import Countdown, {CountdownRendererFn} from 'react-countdown'
import {openChestSound} from '../../../../../../../sound/sound'
import {formatClassName} from '../../../../../../../utils/global'
import {getChestSpineAt, resetChestSpine} from '../../../../../../../utils/visual'
import {openChestRequest} from '../../../../../../api/hub'
import {Badge} from '../../../../../../components/badge/badge'
import {Button} from '../../../../../../components/buttons/button'
import {AccountInfo} from '../../../../../../data/account'
import {getChestsData} from '../../../../../../data/chests'
import {CosmeticData, getCosmeticsData} from '../../../../../../data/cosmetics'
import {WeaponsData, getWeaponsData} from '../../../../../../data/weapons'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import {Background} from '../../background/background'
import {chestColor} from '../chests'
import {DropTable} from '../drop-table/drop-table'
import {Reward, Rewards} from '../rewards/rewards'
import styles from './chest.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({days, hours, minutes}) =>
  <span>
    {addZeroToNumber(hours + days * 24)}H{addZeroToNumber(minutes)}M
  </span>

export type ChestProps = HtmlHTMLAttributes<HTMLDivElement> & {
  chest: AccountInfo['chests'][0]
  index: number
  onHide: () => void
}

export const Chest = ({className, chest, index, onHide, ...props}: ChestProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [openingChest, setOpeningChest] = useState(false)
  //const [burningChest, setBurningChest] = useState(false)
  const [showDropTable, setShowDropTable] = useState(false)
  const [chestOpened, setChestOpened] = useState(false)
  const [chestSpine, setChestSpine] = useState<SpinePlayer>()
  const [rewards, setRewards] = useState<Reward[]>([])
  const [drops, setDrops] = useState<Reward[]>([])

  useEffect(() => {
    if (!chest.type) return

    getChestSpineAt(0, 'chest_chest-container', chest.type).then(setChestSpine)

    return () => {
      resetChestSpine()
    }
  }, [chest])

  useEffect(() => {
    // Drops
    (async () => {
      if (!chest.type || !accountInfo) return

      const drops = [
        ...Object.entries(await getCosmeticsData()).map(([category, data]) =>
          Object.entries(data).map(([, entry]) => {
            if (!chest.type || !entry.chestDropRates?.[chest.type]) return

            return {
              type: 'cosmetic',
              category,
              name: entry.name,
              rarity: entry.rarity,
              quantity: 1,
              rate: entry.chestDropRates[chest.type]
            }
          }).filter(entry => !!entry && !accountInfo.character.cosmetics[category as keyof CosmeticData].unlocked.includes(entry.name))
        ).flat(),
        ...Object.entries(await getWeaponsData()).map(([name, entry]) => {
          if (!chest.type || !entry.chestDropRates?.[chest.type]) return

          return {
            type: 'weapon',
            name,
            quantity: 1,
            rarity: entry.rarity,
            rate: entry.chestDropRates[chest.type]
          }
        }).filter(entry => !!entry && accountInfo.character.weapons.unlocked[entry.name as keyof WeaponsData] === undefined),
        ...((await getChestsData()).chests[chest.type].gemsDrop?.map(gemDrop => ({
          type: 'gems',
          ...gemDrop
        })) ?? [])
      ] as Reward[]

      setDrops([
        ...drops.filter(drop => drop.type === 'gems').sort((a, b) => (b.rate ?? 0) - (a.rate ?? 0)),
        ...drops.filter(drop => drop.type === 'cosmetic').sort((a, b) => (b.rate ?? 0) - (a.rate ?? 0)),
        ...drops.filter(drop => drop.type === 'weapon').sort((a, b) => (b.rate ?? 0) - (a.rate ?? 0))
      ])
    })()
  }, [chest, accountInfo])

  const openChest = async () => {
    if (!chest.type) return

    const fct = async () => {
      if (!chest.type || !chestSpine?.animationState) return

      setOpeningChest(true)

      openChestSound.play('open')

      chestSpine.animationState.addListener({
        complete(entry) {
          if (entry.animation?.name === 'OPEN') {
            setChestOpened(true)

            setTimeout(resetChestSpine, 100)
          }
        }
      })

      chestSpine.setAnimation('OPEN', false)

      const response = await openChestRequest(index)

      if (response?.status === 200) {
        setRewards([
          ...response.data.filter(reward => reward.type === 'gems'),
          ...response.data.filter(reward => reward.rarity === 'common' && reward.type !== 'gems'),
          ...response.data.filter(reward => reward.rarity === 'rare' && reward.type !== 'gems'),
          ...response.data.filter(reward => reward.rarity === 'fabled' && reward.type !== 'gems')
        ])

        if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
      }
    }

    if (chest.timer !== undefined && chest.timer > Date.now() - 86_400_000) {
      if (hubState.showConfirm) {
        const speedUpPrice = (await getChestsData()).speedUpPrice[chest.type]

        hubState.showConfirm({
          title: 'Opening cost',
          text: `Opening this chest without waiting 24h costs ${speedUpPrice} gems`,
          acceptText: 'open',
          refuseText: 'cancel',
          onAccept: fct
        })
      }
    } else {
      fct()
    }
  }

  //const burnChest = async () => {
  //  if (!chest.type || !chestSpine?.animationState) return

  //  if (hubState.showConfirm) {
  //    hubState.showConfirm({
  //      title: `Burning the "${chest.type}" chest`,
  //      text: `Are you sure you want to burn the "${chest.type}" chest?`,
  //      acceptText: 'burn',
  //      refuseText: 'cancel',
  //      onAccept: async () => {
  //        setBurningChest(true)

  //        await burnChestRequest(index)

  //        setBurningChest(false)

  //        if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()

  //        onHide()
  //      }
  //    })
  //  }
  //}

  return (
    <div className={formatClassName(styles, `chest ${className}`)} {...props}>
      <Background />
      <DropTable show={showDropTable} drops={drops} onClickBackground={() => setShowDropTable(false)} onClose={() => setShowDropTable(false)} />
      {(chestOpened && rewards) &&
        <div className={formatClassName(styles, 'rewards-container')}>
          <Rewards className={formatClassName(styles, 'rewards')} rewards={rewards} />
        </div>
      }
      <div className={formatClassName(styles, 'container')}>
        {!chestOpened && <div id="chest_chest-container" className={formatClassName(styles, 'image')}></div>}
        <div className={formatClassName(styles, 'back hover')} onClick={onHide}>
          back <img src={`${OOGY_VISUAL_CDN_URL}/ui/back.png`} alt="arrow" />
        </div>
        <div className={formatClassName(styles, 'middle')}>
          <div className={formatClassName(styles, `price-container ${openingChest ? 'hide' : ''}`)}>
            <div className={formatClassName(styles, 'price-text')}>Cost to open</div>
            <div className={formatClassName(styles, 'price')}>
              {chest.cost?.gems !== undefined &&
                <>
                  <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
                  {chest.cost.gems}
                </>
              }
              {(chest.cost?.gems !== undefined && chest.cost.sol !== undefined) && <span>+</span>}
              {chest.cost?.sol !== undefined &&
                <>
                  <img src={`${OOGY_VISUAL_CDN_URL}/ui/sol.png`} alt="sol" />
                  {chest.cost.sol}
                </>
              }
            </div>
          </div>
          <div className={formatClassName(styles, 'chest-container')}>
            {
              (chest.timer !== undefined && chest.timer > Date.now() - 86_400_000) &&
              <div className={formatClassName(styles, `timer ${openingChest ? 'hide' : ''}`)}>
                <img className={formatClassName(styles, 'lock')} src={`${OOGY_VISUAL_CDN_URL}/ui/lock.png`} alt="lock" />

                <div className={formatClassName(styles, 'countdown')}>
                  <Countdown date={chest.timer + 86_400_000} renderer={renderer} />
                </div>
              </div>
            }
            <Badge className={`${chestColor[chest.type ?? ChestType.Common]} ${formatClassName(styles, `badge ${openingChest ? 'hide' : ''}`)}`}>{chest.type}</Badge>
          </div>
        </div>
        <div className={formatClassName(styles, 'bottom')}>
          {openingChest
            ? <Button onClick={onHide}>CONTINUE</Button>
            : <>
              <div className={formatClassName(styles, 'drop-table hover')} onClick={() => setShowDropTable(true)}>
                See drop table <img src={`${OOGY_VISUAL_CDN_URL}/ui/open-arrow.png`} alt="arrow" />
              </div>
              <Button onClick={openChest}>OPEN</Button>
              {/*<Button className="blue" onClick={burnChest} loading={burningChest}>BURN FOR 10 <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" /></Button>*/}
            </>
          }
        </div>
      </div>
    </div>
  )
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../../../utils/global'
import {getCurrentWeeklyPrize, getHoldedNFTsRequest} from '../../../../../../../../api/hub'
import {useHubContext} from '../../../../../../../../state/context'
import {WeeklyPrize} from '../../type'
import styles from './your-entry-tab.module.scss'

export type YourEntryTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  holder?: boolean
}

export const YourEntryTab = ({className, holder, ...props}: YourEntryTabProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [data, setData] = useState<WeeklyPrize>()
  const [holdedNFTs, setHoldedNFTs] = useState<number>()

  useEffect(() => {
    getCurrentWeeklyPrize(holder, console.error).then(response => setData(response?.data))
  }, [holder])

  useEffect(() => {
    getHoldedNFTsRequest(console.error).then(response => setHoldedNFTs(response?.data))
  }, [])

  const weekString = accountInfo?.actualWeek ?? ''

  const percentage = data?.totalTokens !== undefined ? Math.floor(((accountInfo?.tokens[weekString] ?? 0) / data.totalTokens) * 10000) / 100 : 0

  return (
    <div className={formatClassName(styles, `your-entry-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'line')}>
        <div className={formatClassName(styles, 'entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/token.png`} alt="token" />
          {accountInfo ? ((holder && (holdedNFTs ?? 0) > 0) || !holder) ? accountInfo.tokens[weekString] ?? 0 : 0 : '...'} ENTRIES
        </div>
        <div className={formatClassName(styles, 'entry')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/trophy.png`} alt="trophy" />
          {holder ? (holdedNFTs ?? 0) > 0 ? percentage : 0 : percentage}% WIN CHANCE
        </div>
      </div>

      <div className={formatClassName(styles, 'rewards')}>
        {
          data?.rewards?.map((reward, index) =>
            <div
              key={index}
              style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/${!holder ? 'violet-' : ''}weekly-price-bg.png')`}}
              className={formatClassName(styles, 'reward')}
            >
              <div className={formatClassName(styles, 'image')} style={{backgroundImage: `url('${reward.imageURL}')`}}></div>
              {reward.name}
            </div>
          )
        }
        {
          (!data || !data.rewards) && <div className={formatClassName(styles, 'no-prizes')}>No prizes for this week</div>
        }
      </div>
    </div>
  )
}
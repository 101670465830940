import {sound} from '@pixi/sound'
import {Room} from 'colyseus.js'
import gsap, {Linear} from 'gsap'
import * as PIXI from 'pixi.js'
import {Container, Graphics, Sprite, Text, TextMetrics, TextStyle, isMobile} from 'pixi.js'
import {SoundName} from './enums'
import {updateShadow} from './player'
import {payToReviveMessage, validateNewLevelMessage, zoomMessage} from './server/message'
import {MonsterSchema, PlayerSchema} from './server/schema'
import {toggleMuteMusic} from './sound'
import {gameState} from './state'
import {getTextTexture} from './text'
import {computeVelocityToReach, distanceBetween, drawProgressBar, getGameHeight, getGameWidth, getHeightRatio, getWidthRatio, linearGradient} from './utils'
import {LEVEL_UI_PADDING} from './utils/constants'

export const initLevelUI = (room: Room) => {
  if (!gameState.app || !gameState.viewport || !gameState.resources) return

  if (isMobile.any) {
    gameState.viewport.zoom(350)
  } else {
    gameState.viewport.zoom(200)
  }

  zoomMessage(room, gameState.viewport.scale.x, gameState.viewport.scale.y)

  // Main container
  const topContainer = initTopContainer()

  if (!topContainer) return

  // Clock
  initClock(topContainer)

  // Menu button
  initMenuButton(topContainer)

  // Score
  const scoreSprite = initScore(topContainer)

  if (!scoreSprite) return

  // Kills
  const killContainer = initGems(topContainer, scoreSprite)

  if (!killContainer) return

  // Level progress
  initLevelProgress(topContainer, killContainer)

  topContainer.zIndex = 20

  gameState.app.stage.addChild(topContainer)

  gameState.references.topContainer = topContainer
}

const initTopContainer = () => {
  if (!gameState.viewport) return undefined

  const container = new Container()

  container.x = 0
  container.y = 0
  //container.sortableChildren = true

  gameState.references.ui.level.container = container

  return container
}

const initMenuButton = (container: Container) => {
  if (!gameState.viewport || !gameState.resources) return

  const menuButton = new PIXI.Sprite(gameState.resources.menu)

  menuButton.eventMode = 'static'
  menuButton.cursor = 'pointer'
  menuButton.on('pointertap', (event) => {
    sound.find(SoundName.GamePause).play()
    showPauseMenu()
    event.stopPropagation()
  })

  const ratio = (Math.min(40, 40 * getWidthRatio())) / menuButton.height

  menuButton.scale.set(ratio, ratio)
  menuButton.x = getGameWidth() - menuButton.width - LEVEL_UI_PADDING * getHeightRatio()
  menuButton.y = LEVEL_UI_PADDING * getHeightRatio()

  container.addChild(menuButton)
}

const initClock = (container: Container) => {
  if (!gameState.viewport || !gameState.resources) return

  // Container
  const clockContainer = new PIXI.Sprite(gameState.resources['clock-container'])

  const ratioClockContainer = (Math.min(40, 40 * getWidthRatio())) / clockContainer.height

  clockContainer.scale.set(ratioClockContainer, ratioClockContainer)
  clockContainer.x = getGameWidth() / 2 - clockContainer.width / 2
  clockContainer.y = LEVEL_UI_PADDING * getHeightRatio()

  // Sub container
  const timeSubContainer = new Container()
  const bg = new Graphics()
    .beginFill(0xffffff, 0.00001)
    .drawRect(0, 0, (clockContainer.width * 0.38) / ratioClockContainer, (clockContainer.height * 0.65) / ratioClockContainer)
    .endFill()
  timeSubContainer.addChild(bg)
  timeSubContainer.x = clockContainer.width * 0.1 / ratioClockContainer
  timeSubContainer.y = (clockContainer.height / ratioClockContainer) * 0.15

  // Clock
  const clock = new PIXI.Sprite(gameState.resources.clock)

  const ratioClock = timeSubContainer.height / clock.height

  clock.scale.set(ratioClock, ratioClock)

  timeSubContainer.addChild(clock)

  // Time

  const timeStyle = new TextStyle({
    fontFamily: 'More Sugar',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 38,
    align: 'center'
  })

  const timeText = new Text('60', timeStyle)

  const timeMetrics = TextMetrics.measureText(timeText.text, timeText.style)

  timeText.style = timeStyle
  timeText.x = clock.width * 1.2
  timeText.y = timeSubContainer.height / 2 - timeMetrics.height / 2

  timeSubContainer.addChild(timeText)

  gameState.references.ui.level.time = timeText

  // Sub container
  const monsterSubContainer = new Container()
  const bgMonster = new Graphics()
    .beginFill(0xffffff, 0.00001)
    .drawRect(0, 0, (clockContainer.width * 0.38) / ratioClockContainer, (clockContainer.height * 0.65) / ratioClockContainer)
    .endFill()
  monsterSubContainer.addChild(bgMonster)
  monsterSubContainer.x = (clockContainer.width * 0.52) / ratioClockContainer
  monsterSubContainer.y = (clockContainer.height / ratioClockContainer) * 0.15

  // Monster
  const monster = new PIXI.Sprite(gameState.resources.monster)

  const ratioMonster = monsterSubContainer.height / monster.height

  monster.scale.set(ratioMonster, ratioMonster)

  monsterSubContainer.addChild(monster)

  // Monster count

  const monsterStyle = new TextStyle({
    fontFamily: 'More Sugar',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 38,
    align: 'center'
  })

  const monsterText = new Text('0', monsterStyle)

  const monsterMetrics = TextMetrics.measureText(monsterText.text, monsterText.style)

  monsterText.style = monsterStyle
  monsterText.x = monster.width * 1.2
  monsterText.y = monsterSubContainer.height / 2 - monsterMetrics.height / 2

  monsterSubContainer.addChild(monsterText)

  gameState.references.ui.level.monsterCount = monsterText

  // End

  clockContainer.addChild(timeSubContainer, monsterSubContainer)

  container.addChild(clockContainer)

  return clockContainer
}

const initScore = (container: Container) => {
  if (!gameState.viewport || !gameState.resources) return

  // Container
  const scoreContainer = new PIXI.Sprite(gameState.resources['score-container'])

  const ratioScoreContainer = (Math.min(40, 30 * getWidthRatio())) / scoreContainer.height

  scoreContainer.scale.set(ratioScoreContainer, ratioScoreContainer)
  scoreContainer.x = LEVEL_UI_PADDING * 1.5 * getHeightRatio()
  scoreContainer.y = LEVEL_UI_PADDING * getHeightRatio()

  // Trophy
  const trophy = new PIXI.Sprite(gameState.resources.trophy)

  const ratioTrophy = (scoreContainer.height / trophy.height) * 1.2

  trophy.scale.set(ratioTrophy, ratioTrophy)
  trophy.x = scoreContainer.x - Math.min(10, 10 * getHeightRatio())
  trophy.y = scoreContainer.y + scoreContainer.height / 2 - trophy.height / 2

  // Score

  const scoreCountStyle = new TextStyle({
    fontFamily: 'More Sugar',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 20 * ratioScoreContainer,
    align: 'center'
  })

  const scoreCountText = new Text('0', scoreCountStyle)

  const scoreCountMetrics = TextMetrics.measureText(scoreCountText.text, scoreCountText.style)

  scoreCountText.style = scoreCountStyle
  scoreCountText.x = scoreContainer.x + ((scoreContainer.width - trophy.width + 15) / 2) + trophy.width / 2
  scoreCountText.y = scoreContainer.y + (scoreContainer.height / 1.70 - scoreCountMetrics.height / 2)

  gameState.references.ui.level.scoreCount = scoreCountText

  container.addChild(scoreContainer, trophy, scoreCountText)

  return scoreContainer
}

const initGems = (container: Container, scoreContainer: Sprite) => {
  if (!gameState.viewport || !gameState.resources) return

  // Container
  const gemsContainer = new PIXI.Sprite(gameState.resources['score-container'])

  const ratioGemsContainer = (Math.min(40, 30 * getWidthRatio())) / gemsContainer.height

  gemsContainer.scale.set(ratioGemsContainer, ratioGemsContainer)
  gemsContainer.x = LEVEL_UI_PADDING * 1.5 * getHeightRatio()
  gemsContainer.y = scoreContainer.y + scoreContainer.height + LEVEL_UI_PADDING * getHeightRatio()

  // Gem
  const gem = new PIXI.Sprite(gameState.resources.gem)

  const ratioGem = (gemsContainer.height / gem.height) * 1.2

  gem.scale.set(ratioGem, ratioGem)
  gem.x = gemsContainer.x - Math.min(10, 10 * getWidthRatio())
  gem.y = gemsContainer.y + gemsContainer.height / 2 - gem.height / 2

  // Gems
  const gemsCountStyle = new TextStyle({
    fontFamily: 'More Sugar',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 20 * ratioGemsContainer,
    align: 'center'
  })

  const gemsCountText = new Text('0', gemsCountStyle)

  const gemsCountMetrics = TextMetrics.measureText(gemsCountText.text, gemsCountText.style)

  gemsCountText.style = gemsCountStyle
  gemsCountText.x = gemsContainer.x + ((gemsContainer.width - gem.width + 15) / 2) + gem.width / 2
  gemsCountText.y = gemsContainer.y + (gemsContainer.height / 1.70 - gemsCountMetrics.height / 2)

  gameState.references.ui.level.gemsCount = gemsCountText

  container.addChild(gemsContainer, gem, gemsCountText)

  return gemsContainer
}

const initLevelProgress = (container: Container, killContainer: Sprite) => {
  if (!gameState.viewport || !gameState.resources) return

  // Container
  const levelProgress = new PIXI.Sprite(gameState.resources['level-progress'])

  const ratioLevelProgress = (Math.min(40, 30 * getWidthRatio())) / levelProgress.height

  levelProgress.scale.set(ratioLevelProgress * Math.min(1, getWidthRatio() * 1.2), ratioLevelProgress)
  levelProgress.x = getGameWidth() / 2 - levelProgress.width / 2
  levelProgress.y = killContainer.y

  // Star
  const star = new PIXI.Sprite(gameState.resources.star)

  const ratioStar = (levelProgress.height / star.height) * 1.1

  star.scale.set(ratioStar, ratioStar)
  star.x = levelProgress.x + levelProgress.width - star.width + Math.min(10, 10 * getWidthRatio())
  star.y = levelProgress.y + levelProgress.height / 2 - star.height / 2

  // Texture
  const barTexture = new PIXI.Sprite(gameState.resources['level-bar-bg'])

  const ratioBarTextureWidth = (levelProgress.width / barTexture.width) * 0.72
  const ratioBarTextureHeight = (levelProgress.height / barTexture.height) * 0.52

  barTexture.scale.set(ratioBarTextureWidth, ratioBarTextureHeight)
  barTexture.x = levelProgress.x + levelProgress.width - barTexture.width - Math.min(15, 15 * getWidthRatio())
  barTexture.y = levelProgress.y + levelProgress.height * 0.34

  barTexture.alpha = 0.15

  gameState.references.ui.level.levelBars = barTexture

  // Violet

  const violetBg = new Sprite(linearGradient('#D803A9', '#FF5FDC', 0, 0, barTexture.width, 0, barTexture.width, barTexture.height))

  violetBg.position.set(barTexture.x, barTexture.y)

  // Mask

  const levelMask = new Graphics()

  levelMask.position.set(barTexture.x, barTexture.y)

  barTexture.mask = levelMask
  violetBg.mask = levelMask

  gameState.references.ui.level.levelMask = levelMask

  // Flask
  const flask = new PIXI.Sprite(gameState.resources['level-flask'])

  const ratioFlask = (levelProgress.height / flask.height) * 1.2

  flask.scale.set(ratioFlask, ratioFlask)
  flask.x = barTexture.x - flask.width / 3
  flask.y = levelProgress.y + levelProgress.height / 2 - flask.height * 0.54

  // Level

  const levelStyle = new TextStyle({
    fontFamily: 'More Sugar',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 20 * ratioLevelProgress,
    align: 'center'
  })

  const levelText = new Text('Lv.1', levelStyle)

  const levelMetrics = TextMetrics.measureText(levelText.text, levelText.style)

  levelText.style = levelStyle
  levelText.x = levelProgress.x + Math.min(10, 10 * getWidthRatio())
  levelText.y = levelProgress.y + (levelProgress.height / 2 - levelMetrics.height / 2)

  gameState.references.ui.level.levelCount = levelText

  container.addChild(levelProgress, violetBg, barTexture, levelMask, star, flask, levelText)
}

export const initTeamView = () => {
  if (!gameState.viewport || !gameState.resources) return

  let i = 1

  if (gameState.references.players.size <= 1) return

  gameState.references.players.forEach((player) => {
    if (!player) return

    const index = i++

    const container = new Container()

    // Background
    const background = new PIXI.Sprite(gameState.resources['score-container'])

    const ratioBackground = (Math.min(40, 30 * getWidthRatio())) / background.height

    background.scale.set(ratioBackground, ratioBackground)
    background.x = getGameWidth() - LEVEL_UI_PADDING * 1.5 * getHeightRatio() - background.width
    background.y = LEVEL_UI_PADDING * getHeightRatio() + (background.height + LEVEL_UI_PADDING) * index

    // Color
    const color = new Graphics()
      .beginFill(player.schema.color)
      .drawCircle(background.x + background.width * 0.15, background.y + background.height / 2, background.height * 0.35)
      .endFill()

    // Username
    const usernameStyle = new TextStyle({
      fontFamily: 'More Sugar',
      fontWeight: '400',
      fill: 0xffffff,
      fontSize: 16 * ratioBackground,
      align: 'center'
    })

    const usernameText = new Text(`${player.schema.name.slice(0, 9)}${player.schema.name.length > 9 ? '...' : ''}`, usernameStyle)

    const usernameMetrics = TextMetrics.measureText(usernameText.text, usernameText.style)

    usernameText.style = usernameStyle
    usernameText.x = background.x + background.width * 0.3
    usernameText.y = background.y + (background.height / 1.8 - usernameMetrics.height / 2)

    // Health

    const width = background.width * 0.65
    const height = Math.min(12, 12 * getWidthRatio())
    const gap = Math.min(3, 3 * getWidthRatio())

    const {container: healthContainer, bar: healthBar} = drawProgressBar(
      width,
      height,
      gap,
      0x111111,
      player.schema.color === '#000000' ? 0x33ff33 : parseInt(player.schema.color.replace(/^#/, ''), 16),
      true
    )

    healthContainer.position.set(background.x + background.width * 0.325, background.y - healthContainer.height / 2)

    // Revive

    const styleRevive = new TextStyle({
      fontFamily: 'Supersonic Rocketship',
      fontWeight: '400',
      letterSpacing: 3,
      fill: 0xEC2727,
      fontSize: Math.min(18, 14 * getWidthRatio()),
      dropShadow: true,
      dropShadowDistance: 2,
      dropShadowColor: 0xffffff,
      dropShadowAlpha: 0.9,
      dropShadowAngle: 1.5,
      align: 'center',
      stroke: 0xffffff,
      strokeThickness: Math.min(3, 2 * getWidthRatio())
    })

    const reviveText = new Text('REVIVE', styleRevive)

    const reviveMetrics = TextMetrics.measureText(reviveText.text, reviveText.style)

    reviveText.style = styleRevive
    reviveText.x = healthContainer.x + healthContainer.width / 2 - reviveMetrics.width / 2
    reviveText.y = healthContainer.y + healthContainer.height / 2 - reviveMetrics.height / 2

    reviveText.alpha = 0

    container.addChild(background, color, usernameText, healthContainer)

    gameState.references.ui.level.teamView.set(player.schema.clientId, {
      container,
      healthBar,
      reviveText
    })

    gameState.app?.stage.addChild(container, reviveText)
  })
}

export const showTarget = (x: number, y: number, radius: number, duration: number) => {
  if (!gameState.viewport) return

  const graphics = new Graphics()
    .beginFill(0x000000)
    .drawCircle(0, 0, radius)
    .endFill()

  graphics.position.set(x, y)
  graphics.zIndex = 12
  graphics.alpha = 0

  gsap.to(graphics, {
    duration: duration / 1000,
    alpha: 0.1
  }).then(() => {
    graphics.destroy()
  })

  gameState.viewport.addChild(graphics)
}

export const updateLatencyCount = () => {
  if (!gameState.references.ui.level.latencyCount) return

  const latencyText = gameState.references.ui.level.latencyCount
  latencyText.text = `${Math.round(gameState.latency.history.reduce((acc, value) => acc + value) / gameState.latency.history.length)} ms`
}

export const setTime = (count: number) => {
  if (!gameState.references.ui.level.time) return

  const timeText = gameState.references.ui.level.time

  timeText.style.fill = count <= 15 ? 0xFF0000 : count <= 30 ? 0xFFB822 : 0xFFFFFF

  timeText.text = `${count}`
}

export const setMonsterCount = (count: number) => {
  if (!gameState.references.ui.level.monsterCount) return

  const timeText = gameState.references.ui.level.monsterCount

  timeText.text = `${count}`
}

export const setScoreCount = (count: number) => {
  if (!gameState.references.ui.level.scoreCount) return

  const counterText = gameState.references.ui.level.scoreCount

  const previousMetrics = TextMetrics.measureText(`${counterText.text}`, counterText.style as TextStyle)

  const sizeDifference = String(count).length - counterText.text.length

  counterText.text = `${count}`

  const newMetrics = TextMetrics.measureText(`${counterText.text}`, counterText.style as TextStyle)

  if (sizeDifference > 0) {
    counterText.x -= (newMetrics.width - previousMetrics.width) / 2
  }
}

export const setGemsCount = (count: number) => {
  if (!gameState.references.ui.level.gemsCount) return

  const counterText = gameState.references.ui.level.gemsCount

  const previousMetrics = TextMetrics.measureText(`${counterText.text}`, counterText.style as TextStyle)

  const sizeDifference = String(count).length - counterText.text.length

  counterText.text = `${count}`

  const newMetrics = TextMetrics.measureText(`${counterText.text}`, counterText.style as TextStyle)

  if (sizeDifference > 0) {
    counterText.x -= (newMetrics.width - previousMetrics.width) / 2
  }
}

export const setLevelCount = (count: number) => {
  if (!gameState.references.ui.level.levelCount) return

  const counterText = gameState.references.ui.level.levelCount

  const previousMetrics = TextMetrics.measureText(`Lv.${counterText.text}`, counterText.style as TextStyle)

  const sizeDifference = String(count).length - counterText.text.length

  counterText.text = `Lv.${count}`

  const newMetrics = TextMetrics.measureText(`${counterText.text}`, counterText.style as TextStyle)

  if (sizeDifference > 0) {
    counterText.x -= (newMetrics.width - previousMetrics.width) / 2
  }
}

export const setLevelMask = (progress: number) => {
  if (!gameState.references.ui.level.levelMask || !gameState.references.ui.level.levelBars) return

  const bars = gameState.references.ui.level.levelBars
  const mask = gameState.references.ui.level.levelMask

  bars.alpha = progress === 0 ? 0 : 0.15

  mask.clear()
    .beginFill(0xffffff)
    .drawRoundedRect(0, 0, bars.width * progress, bars.height, bars.height)
    .endFill()
}

export const setLifeProgress = (playerSchema: PlayerSchema, difference: number) => {
  if (!gameState.level || !gameState.viewport) return

  if (difference > 0) {
    // damage
    showTextOnElement(playerSchema, `${difference}`, 0xff0000)
  } else if (difference < 0) {
    // heal
    showTextOnElement(playerSchema, `${difference}`, 0x4C9900)
  }

  const healthBar = gameState.references.ui.level.healthBars.get(playerSchema.clientId)

  if (healthBar) {
    healthBar.bar.scale.x = Math.max(playerSchema.life / playerSchema.maxLife, 0)
  }

  const teamView = gameState.references.ui.level.teamView.get(playerSchema.clientId)

  if (teamView) {
    teamView.healthBar.scale.x = Math.max(playerSchema.life / playerSchema.maxLife, 0)
  }
}

export const setMonsterLifeProgress = (monster: MonsterSchema) => {
  if (!gameState.level || !gameState.viewport) return

  const healthBar = gameState.references.ui.level.healthBars.get(monster.uid)

  if (healthBar) {
    healthBar.bar.scale.x = Math.max(monster.life / monster.maxLife, 0)
  }
}

export const showWave = (name: string, type: 'normal' | 'boss' | 'zombie', monsterCount?: number) => {
  const color = type === 'boss' ? 0x9D6EDF : type === 'zombie' ? 0x59D79A : 0xEA6ADC

  if (monsterCount) setMonsterCount(monsterCount)

  const style = new TextStyle({
    fontFamily: 'Supersonic Rocketship',
    fontWeight: '400',
    letterSpacing: 3,
    fill: 0xffffff,
    fontSize: Math.min(Math.max(44, 22 * getWidthRatio()), 60),
    dropShadow: true,
    dropShadowDistance: 4,
    dropShadowColor: 0x000000,
    dropShadowAlpha: 0.9,
    dropShadowAngle: 1.5,
    align: 'center',
    stroke: 0x000000,
    strokeThickness: Math.max(4, 2 * getWidthRatio())
  })

  const text = new Text(name.toUpperCase(), style)

  const metrics = TextMetrics.measureText(text.text, text.style)

  text.style = style
  text.x = getGameWidth() / 2 - metrics.width / 2
  text.y = getGameHeight() / 4 - metrics.height / 2

  gameState.app?.stage.addChild(text)

  gsap.to(text, {
    duration: 1,
    alpha: 1
  }).then(() => {
    gsap.to(text, {
      delay: 1,
      duration: 1,
      alpha: 0
    }).then(() => {
      text.destroy()
    })
  })
}

export const showRevive = (clientId: string) => {
  const player = gameState.references.players.get(clientId)

  if (!player) return

  showDeadPlayer(clientId)

  const style = new TextStyle({
    fontFamily: 'Supersonic Rocketship',
    fontWeight: '400',
    letterSpacing: 3,
    fill: 0xEC2727,
    fontSize: Math.min(Math.max(22, 11 * getWidthRatio()), 30),
    dropShadow: true,
    dropShadowDistance: 2,
    dropShadowColor: 0xffffff,
    dropShadowAlpha: 0.9,
    dropShadowAngle: 1.5,
    align: 'center',
    stroke: 0xffffff,
    strokeThickness: Math.max(2, 1 * getWidthRatio())
  })

  const text = new Text('REVIVE', style)

  const metrics = TextMetrics.measureText(text.text, text.style)

  text.style = style
  text.x = player.element.x - metrics.height * 2.3
  text.y = player.element.y - player.element.height

  text.zIndex = Number.MAX_SAFE_INTEGER - 5

  gameState.references.ui.level.revives.set(clientId, {
    ...gameState.references.ui.level.revives.get(clientId),
    text
  })

  text.alpha = 0

  gameState.viewport?.addChild(text)

  gsap.to(text, {
    duration: 1,
    alpha: 1,
    repeat: -1,
    yoyo: true
  })
}

export const showReviveCircular = (clientId: string, max: number, left: number) => {
  const player = gameState.references.players.get(clientId)

  if (!gameState.viewport || !player) return

  if (!gameState.references.ui.level.revives.get(clientId)?.graphics) {
    const container = new Container()
    const graphics = new PIXI.Graphics()

    graphics.x = 10 * getWidthRatio()
    graphics.y = 10 * getWidthRatio()

    container.x = player.element.x - player.element.height * 0.8
    container.y = player.element.y - player.element.width * 0.4

    container.addChild(graphics)

    gameState.references.ui.level.revives.set(clientId, {
      ...gameState.references.ui.level.revives.get(clientId),
      graphics,
      max,
      left,
      container
    })

    container.zIndex = 11

    gameState.viewport.addChild(container)
  } else {
    gameState.references.ui.level.revives.set(clientId, {
      ...gameState.references.ui.level.revives.get(clientId),
      max,
      left
    })
  }
}

export const hideReviveCircular = (clientId: string) => {
  const obj = gameState.references.ui.level.revives.get(clientId)

  if (!obj) return

  obj.container?.destroy()

  gameState.references.ui.level.revives.set(clientId, {
    ...gameState.references.ui.level.revives.get(clientId),
    graphics: undefined,
    max: undefined,
    left: undefined,
    container: undefined
  })
}

export const resetPlayerAfterRevive = (clientId: string) => {
  const player = gameState.references.players.get(clientId)
  const gun = gameState.references.guns.get(clientId)
  const healthBar = gameState.references.ui.level.healthBars.get(clientId)
  const shootingBar = gameState.references.ui.level.shootingBars.get(clientId)
  const obj = gameState.references.ui.level.revives.get(clientId)
  const teamView = gameState.references.ui.level.teamView.get(clientId)

  if (!obj || !player || !gun || !healthBar || !teamView) return

  player.element.state.setAnimationByName(0, 'IDLE', true)
  player.element.rotation = 0
  gun.alpha = 1
  healthBar.container.alpha = 1

  if (shootingBar) {
    shootingBar.container.alpha = 1
  }

  teamView.container.alpha = 1
  teamView.reviveText.alpha = 0

  obj.text?.destroy()

  hideReviveCircular(clientId)

  const particleContainer = gameState.references.particles.get(clientId)?.container
  if (particleContainer) {
    particleContainer.alpha = 1
  }

  gameState.references.ui.level.revives.delete(clientId)
}

export const showDeadPlayer = (clientId: string) => {
  const player = gameState.references.players.get(clientId)
  const gun = gameState.references.guns.get(clientId)
  const healthBar = gameState.references.ui.level.healthBars.get(clientId)
  const shootingBar = gameState.references.ui.level.shootingBars.get(clientId)
  const teamView = gameState.references.ui.level.teamView.get(clientId)

  if (!player || !gun || !healthBar || !teamView) return

  player.element.state.clearTrack(0)
  player.element.rotation = -Math.PI / 2
  gun.alpha = 0
  healthBar.container.alpha = 0

  if (shootingBar) {
    shootingBar.container.alpha = 0
  }

  teamView.container.alpha = 0.3

  if (player.schema.dead) {
    teamView.reviveText.alpha = 0
  } else {
    teamView.reviveText.alpha = 1
  }

  const particleContainer = gameState.references.particles.get(clientId)?.container
  if (particleContainer) {
    particleContainer.alpha = 0
  }

  updateShadow(clientId)
}

/**
 * Show text on a element position
 * @param element The element
 * @param text Text to show
 * @param color Color of the text
 */
export const showTextOnElement = (element: {
  x: number
  y: number
  width: number
  height: number
}, text: string, color: number, stroke?: number, scaleSize = 1) => {
  if (!gameState.viewport) return

  const textTexture = getTextTexture(
    text,
    Math.max(6 * getWidthRatio(), isMobile.any ? 24 : 6) * scaleSize,
    color,
    stroke ?? 0xffffff
  )

  const scoreText = new Sprite(textTexture.texture)
  scoreText.x = element.x + element.width / 2 - textTexture.metrics.width / 2
  scoreText.y = element.y + element.height / 2 - textTexture.metrics.height / 2

  scoreText.zIndex = Number.MAX_SAFE_INTEGER - 1

  gameState.viewport.addChild(scoreText)

  gsap.to(scoreText, {
    duration: 1,
    y: scoreText.y - textTexture.metrics.height,
    alpha: 0,
    onComplete() {
      scoreText.destroy()
    }
  })
}

const stickRadius = 50
export const showStick = (x: number, y: number, pointerId: number, id: number) => {
  if (!gameState.app || Object.values(gameState.references.ui.level.sticks).find(stick => stick.id === id)) return

  // bg

  const background = new Graphics()
    .lineStyle(2, 0xffffff, 0.3)
    .beginFill(0x000000, 0.3)
    .drawCircle(0, 0, stickRadius)
    .endFill()

  background.eventMode = 'none'
  background.position.set(x, y)

  // stick

  const stick = new Graphics()
    .lineStyle(2, 0xffffff, 1)
    .beginFill(id === 0 ? 0xDD47A7 : 0x00B2FF)
    .drawCircle(x, y, stickRadius * 0.4)
    .endFill()

  stick.alpha = 0.75

  stick.eventMode = 'none'

  const oogy = new Sprite(gameState.resources['white-oogy'])

  const oogyRatio = stickRadius * 0.4 / oogy.width

  oogy.scale.set(oogyRatio, oogyRatio)
  oogy.position.set(x - oogy.width / 2, y - oogy.height / 2)

  stick.addChild(oogy)
  stick.cacheAsBitmap = true

  background.addChild(stick)

  // obj

  gameState.references.ui.level.sticks[pointerId] = {
    id,
    background,
    stick
  }

  gameState.app.stage.addChild(background, stick)
}

export const moveStick = (x: number, y: number, pointerId: number) => {
  if (!gameState.app || !gameState.viewport || !gameState.references.ui.level.sticks[pointerId]) return

  const {background, stick, id} = gameState.references.ui.level.sticks[pointerId]

  if (stick) {
    const {dx, dy} = computeVelocityToReach({x, y}, {x: background.x, y: background.y})

    const distance = Math.min(stickRadius, distanceBetween(x, y, background.x, background.y))

    stick.x = dx * distance
    stick.y = dy * distance
  }

  return {
    id,
    coords: gameState.viewport.toLocal(background)
  }
}

export const removeStick = (pointerId: number) => {
  if (!gameState.app) return

  const {id} = gameState.references.ui.level.sticks[pointerId]

  const obj = Object.values(gameState.references.ui.level.sticks).find(stick => stick.id === id)

  if (!obj) return

  obj.background.destroy()
  obj.stick.destroy()

  delete gameState.references.ui.level.sticks[pointerId]

  return id
}

export const addCountdown = (name: string, sprite: Sprite, time: number) => {
  if (!gameState.app) return

  const entry = gameState.references.ui.level.countdowns?.find(countdown => countdown.name === name)

  if (entry) {
    entry.startingTime = new Date().getTime()
    entry.endingTime = entry.startingTime + time
  } else {
    const container = new Container()
    const graphics = new PIXI.Graphics()

    graphics.x = 10 * getWidthRatio()
    graphics.y = 10 * getWidthRatio()

    container.x = 20 * getWidthRatio()
    container.y = (gameState.references.ui.level.countdowns ?? []).length * 50 + getGameHeight() * 0.15

    sprite.x = 10 * getWidthRatio() - sprite.width / 2
    sprite.y = 10 * getWidthRatio() - sprite.height / 2

    container.addChild(sprite)
    container.addChild(graphics)

    if (!gameState.references.ui.level.countdowns) {
      gameState.references.ui.level.countdowns = []
    }

    gameState.references.ui.level.countdowns.push({
      name,
      graphics,
      startingTime: new Date().getTime(),
      endingTime: new Date().getTime() + time,
      container
    })

    gameState.app.stage.addChild(container)
  }
}

export const showSkillMenu = async (level: number, skills: {
  name: string
  description: string
  level: number
}[]) => {
  if (!gameState.app) return

  const container = new Container()

  container.alpha = 0

  gsap.to(container, {
    duration: 1,
    alpha: 1
  })

  // Title

  const title = new Sprite(gameState.resources['skills-title'])

  const ratioTitle = Math.min(468, getGameWidth()) / title.width

  title.scale.set(ratioTitle, ratioTitle)

  title.y = getGameHeight() * 0.15

  container.x = getGameWidth() / 2 - title.width / 2

  // Title text

  const style = new TextStyle({
    fontFamily: 'Cees Hand',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: Math.min(Math.max(30, 15 * getWidthRatio()), 36),
    align: 'center'
  })

  const text = new Text('Choice of skill', style)

  const metrics = TextMetrics.measureText(text.text, text.style)

  text.x = title.x + title.width / 2 - metrics.width / 2
  text.y = title.y + title.height / 2 - metrics.height / 2

  // Level text

  const levelStyle = new TextStyle({
    fontFamily: 'Cees Hand',
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: Math.min(Math.max(20, 20 * getWidthRatio()), 26),
    align: 'center'
  })

  const levelText = new Text(`Level ${level}`, levelStyle)

  const metricsLevel = TextMetrics.measureText(levelText.text, levelText.style)

  levelText.x = title.x + title.width / 2 - metricsLevel.width / 2
  levelText.y = title.y - metricsLevel.height / 2

  // Star
  const star = new Sprite(gameState.resources['skills-star'])

  const ratioStar = Math.min(80, 80 * getWidthRatio()) / star.width

  star.scale.set(ratioStar, ratioStar)

  star.x = levelText.x + metricsLevel.width / 2 - star.width / 2
  star.y = levelText.y - star.height * 1.25

  // Star light
  const starLight = new Sprite(gameState.resources['star-light'])

  const ratioStarLight = star.width * 1.5 / starLight.width

  starLight.pivot.set(starLight.width / 2, starLight.height / 2)
  starLight.scale.set(ratioStarLight, ratioStarLight)

  starLight.x = star.x + star.width / 2
  starLight.y = star.y + star.height / 2

  gsap.to(starLight, {
    ease: Linear.easeIn,
    duration: 1,
    rotation: Math.PI,
    repeat: -1
  })

  // Bg

  const bg = new Graphics()
    .beginFill(0x000000, 0.8)
    .drawRect(-getGameWidth(), - getGameHeight(), 2 * getGameWidth(), 2 * getGameHeight())
    .endFill()

  // Timer

  const styleTimer = new TextStyle({
    fontFamily: 'Supersonic Rocketship',
    letterSpacing: 2,
    fontWeight: '400',
    fill: 0xffffff,
    fontSize: 34,
    align: 'center'
  })

  let counter = 10

  const textTimer = new Text(`${counter}`, styleTimer)

  const metricsTimer = TextMetrics.measureText(textTimer.text, textTimer.style)

  textTimer.x = title.width / 2 - metricsTimer.width / 2
  textTimer.y = getGameHeight() - metricsTimer.height

  setInterval(() => {
    textTimer.text = --counter

    if (counter === 0) {

      gameState.references.ui.skillMenu = undefined
      container.destroy()
    }
  }, 1000)

  container.addChild(bg, title, text, levelText, starLight, star, textTimer)

  // Skills

  skills.forEach((entry, index) => {
    const skill = new Sprite(gameState.resources[`skills-${entry.name}`])

    const width = Math.min(468, getGameWidth()) / 3

    const ratioSkill = width / skill.width

    skill.scale.set(ratioSkill, ratioSkill)

    skill.x = index * width * 1.5 + 0.25 * width
    skill.y = getGameHeight() / 1.75 - skill.height / 2

    skill.eventMode = 'static'

    skill.addEventListener('pointerup', () => {
      if (!gameState.room) return

      validateNewLevelMessage(gameState.room, index)

      container.destroy()
    })

    // Stars

    const starsContainer = new Container()

    for (let i = 0; i < 3; i++) {
      const star = new Sprite(gameState.resources[entry.level > i ? 'skills-star-full' : 'skills-star-empty'])

      const ratioStar = Math.min(20, 20 * getWidthRatio()) / star.width

      star.scale.set(ratioStar, ratioStar)

      star.x = i * 22
      star.y = 0

      starsContainer.addChild(star)
    }

    starsContainer.x = skill.x + skill.width / 2 - starsContainer.width / 2
    starsContainer.y = skill.y + skill.height * 0.9

    // Text

    const style = new TextStyle({
      fontFamily: 'Supersonic Rocketship',
      letterSpacing: 2,
      fontWeight: '400',
      fill: 0xffffff,
      fontSize: Math.min(Math.max(14, 14 * getWidthRatio()), 18),
      align: 'center',
      wordWrapWidth: skill.width
    })

    const textName = new Text(entry.name.replaceAll('-', ' ').toUpperCase(), style)

    const metrics = TextMetrics.measureText(textName.text, textName.style)

    textName.x = skill.x + skill.width / 2 - metrics.width / 2
    textName.y = skill.y - 30

    // Text

    const styleDescription = new TextStyle({
      fontFamily: 'Cees Hand',
      fontWeight: '400',
      fill: 0xffffff,
      fontSize: Math.min(Math.max(11, 11 * getWidthRatio()), 16),
      align: 'center',
      wordWrap: true,
      wordWrapWidth: skill.width
    })

    const text = new Text(entry.description, styleDescription)

    text.x = skill.x
    text.y = skill.y + skill.height + 30
    text.alpha = 0.6

    container.addChild(skill, text, textName, starsContainer)
  })

  gameState.references.ui.skillMenu = container

  container.zIndex = Number.MAX_SAFE_INTEGER

  gameState.app.stage.addChild(container)
}

export const hideSkillMenu = () => {
  if (gameState.references.ui.skillMenu) {
    gameState.references.ui.skillMenu.destroy()
    gameState.references.ui.skillMenu = undefined
  }
}

export const showWaitingDots = (playerSchema: PlayerSchema) => {
  if (!gameState.viewport) return

  const player = gameState.references.players.get(playerSchema.clientId)

  if (player && !gameState.references.ui.level.waitingDots.has(playerSchema.clientId)) {
    const dot1 = new Graphics()
      .beginFill(0x000000)
      .drawCircle(0, 0, 5)
      .endFill()
    const dot2 = new Graphics()
      .beginFill(0x000000)
      .drawCircle(12, 0, 5)
      .endFill()
    const dot3 = new Graphics()
      .beginFill(0x000000)
      .drawCircle(24, 0, 5)
      .endFill()

    dot1.alpha = 0
    gsap.to(dot1, {
      duration: 0.5,
      alpha: 1,
      yoyo: true,
      repeat: -1
    })

    dot2.alpha = 0
    gsap.to(dot2, {
      delay: 0.15,
      duration: 0.5,
      alpha: 1,
      yoyo: true,
      repeat: -1
    })

    dot3.alpha = 0
    gsap.to(dot3, {
      delay: 0.3,
      duration: 0.5,
      alpha: 1,
      yoyo: true,
      repeat: -1
    })

    const container = new Container()

    container.addChild(dot1, dot2, dot3)

    container.position.set(player.element.x - container.width / 2, player.element.y - player.element.height)

    container.zIndex = Number.MAX_SAFE_INTEGER - 10

    gameState.references.ui.level.waitingDots.set(playerSchema.clientId, container)

    gameState.viewport.addChild(container)
  }
}

export const hideWaitingDots = (playerSchema: PlayerSchema) => {
  const dots = gameState.references.ui.level.waitingDots.get(playerSchema.clientId)

  if (dots) {
    dots.destroy()

    gameState.references.ui.level.waitingDots.delete(playerSchema.clientId)
  }
}

export const showIcecube = (playerSchema: PlayerSchema) => {
  if (!gameState.viewport) return

  const player = gameState.references.players.get(playerSchema.clientId)

  if (player && !gameState.references.ui.level.icecube.has(playerSchema.clientId)) {
    const icecube = new Sprite(gameState.resources['icecube'])

    const ratio = player.element.height / icecube.height * 1.4

    icecube.scale.set(ratio, ratio)

    icecube.position.set(player.element.x - icecube.width / 2, player.element.y - icecube.height * 0.75)

    icecube.zIndex = Number.MAX_SAFE_INTEGER - player.element.zIndex + 1

    gameState.references.ui.level.icecube.set(playerSchema.clientId, icecube)

    gameState.viewport.addChild(icecube)
  }
}

export const hideIcecube = (playerSchema: PlayerSchema) => {
  const icecube = gameState.references.ui.level.icecube.get(playerSchema.clientId)

  if (icecube) {
    icecube.destroy()

    gameState.references.ui.level.icecube.delete(playerSchema.clientId)
  }
}

export const showContinueMenu = async (scoreValue: number, gems: number) => {
  if (!gameState.level || !gameState.room || !gameState.app) return

  gameState.external.showContinueMenu(true, (accepted) => {
    if (!gameState.room) return

    if (accepted) {
      payToReviveMessage(gameState.room)
    } else {
      showScoreMenu(scoreValue, gems)
    }
  })
}

export const showScoreMenu = async (scoreValue: number, gems: number) => {
  if (!gameState.app) return

  gameState.external.showScoreMenu(scoreValue, gems)

  gameState.external.clearGame()
}

export const showPauseMenu = () => {
  if (!gameState.level || !gameState.room || !gameState.app) return

  if (gameState.external.showPauseMenu) gameState.external.showPauseMenu(true, (muted) => toggleMuteMusic(muted))
}

export const hideContinueMenu = () => {
  console.error('TODO hide continue menu')
}

export const hideScoreMenu = () => {
  console.error('TODO hide score menu')
}

export const hidePauseMenu = () => {
  console.error('TODO hide pause menu')
}
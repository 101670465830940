import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {cardFlipSound} from '../../../../../../../sound/sound'
import {formatClassName} from '../../../../../../../utils/global'
import {CosmeticData} from '../../../../../../data/cosmetics'
import styles from './rewards.module.scss'

export type Rarity = 'common' | 'rare' | 'fabled' | 'legendary' | 'special'
export type RewardType = 'gems' | 'cosmetic' | 'weapon' | 'tokens' | 'generic'

export type Reward = {
  type: RewardType
  quantity: number
  rarity: Rarity
  name?: string
  category?: keyof CosmeticData
  show?: boolean
  rate?: number
  imageURL?: string
}

export type RewardsProps = HtmlHTMLAttributes<HTMLDivElement> & {
  rewards: Reward[]
  flipped?: boolean
}

export const Rewards = ({className, rewards, flipped, ...props}: RewardsProps) => {
  const [show, setShow] = useState(Array.from({length: rewards.length}, () => flipped))

  useEffect(() => {
    if (flipped) {
      setShow(Array.from({length: rewards.length}, () => flipped))

      return
    }

    const timeouts = rewards.map((reward, index) => setTimeout(() => {
      show[index] = true

      setShow([...show])

      cardFlipSound.play()
    }, 500 + index * 500))

    return () => {
      timeouts.map(clearTimeout)
    }
  }, [flipped, rewards])

  return (
    <div className={formatClassName(styles, `rewards ${className}`)} {...props}>
      {
        rewards.map((reward, index) =>
          <div key={`reward-${index}`} className={formatClassName(styles, `reward ${show[index] ? 'show' : ''}`)} >
            <div className={formatClassName(styles, `front ${reward.rarity === 'special' ? 'special' : ''}`)}></div>
            <div className={formatClassName(styles, `back ${reward.rarity === 'special' ? 'special' : ''} ${(reward.type === 'weapon' || reward.type === 'tokens') ? reward.type : reward.type === 'cosmetic' ? reward.rarity : ''}`)}>
              <div className={formatClassName(styles, 'image-container')}>
                {
                  reward.type === 'gems' && <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
                }
                {
                  reward.type === 'tokens' && <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/token.png`} alt="token" />
                }
                {
                  reward.type === 'cosmetic' && <img className={formatClassName(styles, 'image cosmetic')} src={`${OOGY_VISUAL_CDN_URL}/ui/cosmetics/${reward.category?.toLowerCase()}/${reward.name?.toLowerCase()}.png`} alt={reward.name} />
                }
                {
                  reward.type === 'weapon' && <img className={formatClassName(styles, 'image weapon')} src={`${OOGY_VISUAL_CDN_URL}/ui/weapons/${reward.name?.toLowerCase()}.png`} alt={reward.name} />
                }
                {
                  reward.type === 'generic' && <img className={formatClassName(styles, 'image generic')} src={reward.imageURL} alt={reward.name} />
                }
              </div>
              {
                (reward.type === 'gems' || reward.type === 'tokens') &&
                <div className={formatClassName(styles, 'text')}>
                  {
                    reward.quantity
                  }{reward.type === 'gems' ? ' GEM' : ' TOKEN'}{reward.quantity > 1 && 'S'}
                </div>
              }
              {
                (reward.type === 'generic') &&
                <div className={formatClassName(styles, 'text')}>
                  {reward.name}
                </div>
              }
              {
                reward.rate !== undefined &&
                <div className={formatClassName(styles, 'rate')}>
                  {
                    reward.rate
                  }%
                </div>
              }
            </div>
          </div>
        )
      }
    </div>
  )
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../../utils/global'
import {GenericModal, GenericModalProps} from '../../../../../../../components/modal/modal'
import {DailyRewardsData, getDailyRewardsData} from '../../../../../../../data/daily-rewards'
import {useHubContext} from '../../../../../../../state/context'
import styles from './daily-rewards.module.scss'

export type DailyRewardsProps = GenericModalProps & {
  onClose: () => void
}

const SVG = () => <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
  <path d="M6.11022 12.531C5.77364 12.531 5.45389 12.3964 5.21828 12.1608L0.455633 7.39815C-0.0324116 6.9101 -0.0324116 6.1023 0.455633 5.61426C0.943678 5.12621 1.75148 5.12621 2.23952 5.61426L6.11022 9.48496L14.7604 0.834784C15.2484 0.346739 16.0562 0.346739 16.5443 0.834784C17.0323 1.32283 17.0323 2.13063 16.5443 2.61867L7.00217 12.1608C6.76656 12.3964 6.44681 12.531 6.11022 12.531Z" fill="white" />
</svg>

const Content = () => {
  const {state: {accountInfo}} = useHubContext()

  const [data, setData] = useState<DailyRewardsData>()

  useEffect(() => {
    (async () => {
      setData(await getDailyRewardsData())
    })()
  }, [])

  return <div className={formatClassName(styles, 'content')}>
    earn extra gems by blasting everyday
    <div className={formatClassName(styles, 'calendar')}>
      <div className={formatClassName(styles, 'line')}>
        <div className={formatClassName(styles, 'day')}>DAY 1</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 2</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 3</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 4</div>
      </div>
      <div className={formatClassName(styles, 'line')}>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 0 ? 'active' : ''}`)}>
          <img className={formatClassName(styles, 'gems')} src={`${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-pew.png`} alt="daily-rewards-pew" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 0 ? <SVG /> : data ? `+${data?.rewards[0]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 1 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 1 ? 'active' : ''}`)}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 1 ? <SVG /> : data ? `+${data?.rewards[1]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 2 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 2 ? 'active' : ''}`)}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 2 ? <SVG /> : data ? `+${data?.rewards[2]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 3 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 3 ? 'active' : ''}`)}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 3 ? <SVG /> : data ? `+${data?.rewards[3]}` : '...'}
          </div>
        </div>
      </div>
      <div className={formatClassName(styles, 'line end')}>
        <div className={formatClassName(styles, `separator-v ${(accountInfo?.dailyRewards.score ?? 0) >= 4 ? 'active' : ''}`)}></div>
      </div>
      <div className={formatClassName(styles, 'line')}>
        <div className={formatClassName(styles, 'day')}>DAY 8+</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 7</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 6</div>
        <div className={formatClassName(styles, 'separator-h hidden')}></div>
        <div className={formatClassName(styles, 'day')}>DAY 5</div>
      </div>
      <div className={formatClassName(styles, 'line')}>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 7 ? 'active' : ''}`)}>
          <img className={formatClassName(styles, 'shinny')} src={`${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-shinny.png`} alt="shinny" />
          <img className={formatClassName(styles, 'gems')} src={`${OOGY_VISUAL_CDN_URL}/ui/gems.png`} alt="gems" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 7 ? <SVG /> : data ? `+${data?.rewards[7]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 7 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 6 ? 'active' : ''}`)}>
          <img className={formatClassName(styles, 'shinny')} src={`${OOGY_VISUAL_CDN_URL}/ui/daily-rewards-shinny.png`} alt="shinny" />
          <img className={formatClassName(styles, 'gems')} src={`${OOGY_VISUAL_CDN_URL}/ui/gems.png`} alt="gems" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 6 ? <SVG /> : data ? `+${data?.rewards[6]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 6 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 5 ? 'active' : ''}`)}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 5 ? <SVG /> : data ? `+${data?.rewards[5]}` : '...'}
          </div>
        </div>
        <div className={formatClassName(styles, `separator-h ${(accountInfo?.dailyRewards.score ?? 0) >= 5 ? 'active' : ''}`)}></div>
        <div className={formatClassName(styles, `circle ${(accountInfo?.dailyRewards.score ?? 0) >= 4 ? 'active' : ''}`)}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <div className={formatClassName(styles, 'label')}>
            {(accountInfo?.dailyRewards.score ?? 0) >= 4 ? <SVG /> : data ? `+${data?.rewards[4]}` : '...'}
          </div>
        </div>
      </div>
    </div>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Daily Rewards
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const DailyRewards = ({className, onClose, show, ...props}: DailyRewardsProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `daily-rewards ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content />}
      onClickBackground={onClose}
      show={show}
      {...props}
    />
  )
}
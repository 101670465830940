import React from 'react'
import * as ReactDOM from 'react-dom/client'
import {Hub} from './hub/hub'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faDiscord, faTwitter, faXTwitter} from '@fortawesome/free-brands-svg-icons'
import {
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faVolumeLow,
  faVolumeXmark
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faTwitter,
  faVolumeXmark,
  faVolumeLow,
  faDiscord,
  faCirclePlus,
  faCircleNotch,
  faXTwitter,
  faCircleQuestion
)

const app = document.getElementById('react')

if (app) {
  const root = ReactDOM.createRoot(app)

  root.render(
    <Hub />
  )
}

import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React from 'react'
import {formatClassName} from '../../../../../../utils/global'
import {Button} from '../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../components/modal/modal'
import styles from './how-to-play.module.scss'

export type HowToPlayProps = GenericModalProps & {
  onClose: () => void
}

const Content = ({onClose}: {
  onClose: () => void
}) =>
  <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'subtitle')}>Desktop</div>
    <div className={formatClassName(styles, 'tuto')}>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'text')}>Use arrow keys to move</div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/keyboard-arrows.png`} alt="keyboard mouse" />
      </div>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'text')}>Use mouse to aim</div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/mouse.png`} alt="mouse" />
      </div>
    </div>
    <div className={formatClassName(styles, 'subtitle')}>Mobile</div>
    <div className={formatClassName(styles, 'tuto')}>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'text')}>Move with</div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/pink-stick.png`} alt="pink stick" />
      </div>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'text')}>Aim with</div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/blue-stick.png`} alt="blue stick" />
      </div>
    </div>

    <Button className={formatClassName(styles, 'small gray')} onClick={onClose}>
      GOT IT
    </Button>
  </div>

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    How to play
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const HowToPlay = ({className, onClose, ...props}: HowToPlayProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `how-to-play ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onClose={onClose} />}
      onClickBackground={onClose}
      {...props}
    />
  )

}
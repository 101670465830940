import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {playMainMusic} from '../../../../../../sound/sound'
import {formatClassName, numberFormater} from '../../../../../../utils/global'
import {cleanWonChests, storeChestRequest} from '../../../../../api/hub'
import {Badge} from '../../../../../components/badge/badge'
import {Button} from '../../../../../components/buttons/button'
import {TwitterTweetButton} from '../../../../../components/twitter/twitter-intent-button'
import {useHubContext} from '../../../../../state/context'
import {hubState} from '../../../../../state/hub'
import {Background} from '../background/background'
import {Chests, chestColor} from '../chests/chests'
import styles from './score.module.scss'

export type ScoreProps = HtmlHTMLAttributes<HTMLDivElement> & {
  score: number
  gems: number
  onClose: () => void
}

export const Score = ({className, score, gems, onClose, ...props}: ScoreProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [loading, setLoading] = useState(false)

  const selectChest = async (index: number, type: ChestType) => {
    if (!accountInfo) return

    const freeSlot = Object.entries(accountInfo.chests).find(([, entry]) => entry.unlocked && !entry.type)

    if (!freeSlot) {
      if (hubState.showModal) {
        hubState.showModal({
          title: 'No empty slot',
          text: 'Open, burn or buy a new chest slot (if available) to keep an additional chest'
        })
      }

      return
    }

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: `Keep the "${type.toUpperCase()}" chest?`,
        acceptText: 'yes',
        refuseText: 'no',
        onAccept: async () => {
          setLoading(true)

          const result = await storeChestRequest(index)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setLoading(false)
        }
      })
    }
  }

  const onClosePrepared = () => {
    if (Object.values(accountInfo?.wonChests ?? {}).length > 0 && hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Are you sure?',
        text: 'If you skip, you will lose all the remaining chests earned this round',
        acceptText: 'yes',
        refuseText: 'no',
        onAccept: () => {
          cleanWonChests().then(() => {
            if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
          })

          playMainMusic()
          onClose()
        }
      })
    } else {
      playMainMusic()
      onClose()
    }
  }

  useEffect(() => {
    playMainMusic()
  }, [])

  return (
    <div className={formatClassName(styles, `score reveal-element ${className}`)} {...props}>
      <Background className='high' />
      <div className={formatClassName(styles, 'score-container')}>
        <div className={formatClassName(styles, 'title')}>
          Game Over
        </div>
        <div className={formatClassName(styles, 'container')}>
          <div className={formatClassName(styles, 'top-line')}>
            <img src={`${OOGY_VISUAL_CDN_URL}/ui/trophy.png`} alt="trophy" /> {numberFormater(score)}
            <TwitterTweetButton
              className='small'
              text='Had a blast playing Oogy Blast! @oogyNFT'
              related="oogyNFT"
              hashtags='oogyNFT'
            />
          </div>

          <div className={formatClassName(styles, 'middle-line')}>
            <div className={formatClassName(styles, 'rewards')}>
              <div className={formatClassName(styles, 'gems')}>
                <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />{gems} EARNED
              </div>
              {Object.entries(accountInfo?.wonChests ?? {}).length > 0
                ? <>
                  <div className={formatClassName(styles, 'chests')}>
                    {Object.entries(accountInfo?.wonChests ?? {}).map(([index, type]) =>
                      <div key={`chest-${index}`} className={formatClassName(styles, `chest ${chestColor[type]}`)} onClick={() => selectChest(Number(index), type)}>
                        <div className={formatClassName(styles, 'chest-container')} key={`chest-container-${index}`}>
                          <Badge className={`${chestColor[type]} ${formatClassName(styles, 'badge')}`}>{type}</Badge>
                          <img className={formatClassName(styles, 'light')} src={`${OOGY_VISUAL_CDN_URL}/ui/star-light.png`} alt="light" />
                          <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/chests/${type}-chest.png`} alt={`${type}-chest`} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>Select chests to keep</div>
                </>
                : <div className={formatClassName(styles, 'no-chest')}>
                  <div>
                    no chests earned.<br />
                    better luck next time!
                  </div>
                </div>
              }
            </div>
          </div>

          <Chests className={formatClassName(styles, 'chests')} />

          <Button className={formatClassName(styles, 'skip')} onClick={onClosePrepared} loading={loading}>SKIP</Button>
        </div>
      </div>
    </div>
  )
}
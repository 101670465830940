import axios from 'axios'
import {ChestType, Weapon} from 'oogy-blast/src/enums'
import {OOGY_DATA_CDN_URL} from 'oogy-blast/src/utils/constants'
import {Rarity} from '../pages/dashboard/sub-pages/game/chests/rewards/rewards'

export type WeaponsEntry = {
  order: number
  backgroundColor: string
  rarity: Rarity,
  levels: {
    characteristics: {
      power: number
      cooldown: number
      bullets: number
      bulletSpeed: number
      lifetime: number
    }
    priceToUnlock: {
      gems: number
    }
  }[]
  chestDropRates?: {
    [type in ChestType]: number
  }
  availableFrom?: number
  availableTo?: number
  hiddenInShop?: boolean
}

export type WeaponsData = {
  [name in Weapon]: WeaponsEntry
}

let CACHE: WeaponsData
let CACHE_TIME = 0

export const getWeaponsData = async () => {
  if (CACHE_TIME < Date.now() - 600000) {
    if (CACHE) CACHE_TIME = Date.now()

    const response = await axios.get<WeaponsData>(`${OOGY_DATA_CDN_URL}/weapons/weapons.json`)

    if (response.status === 200) {
      CACHE_TIME = Date.now()
      CACHE = response.data
    }
  }

  return CACHE
}
import {SpinePlayer} from '@esotericsoftware/spine-player'
import CssFilterConverter from 'css-filter-converter'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../../../utils/global'
import {setCharacterSkin} from '../../../../../../../../../utils/visual'
import {buyCosmeticRequest, equipCosmeticRequest} from '../../../../../../../../api/hub'
import {Button} from '../../../../../../../../components/buttons/button'
import {AccountInfo} from '../../../../../../../../data/account'
import {CosmeticData, CosmeticEntry, getCosmeticsData} from '../../../../../../../../data/cosmetics'
import {useHubContext} from '../../../../../../../../state/context'
import {hubState} from '../../../../../../../../state/hub'
import styles from './cosmetics-tab.module.scss'

const COLOR_CACHE: {
  [color: string]: string
} = {}

export type CharacterCosmeticsTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  spinePlayer?: SpinePlayer
}

export const CharacterCosmeticsTab = ({className, spinePlayer, ...props}: CharacterCosmeticsTabProps) => {
  const {state: {accountInfo}, dispatch} = useHubContext()

  const [buying, setBuying] = useState(false)
  const [equipying, setEquipying] = useState(false)
  const [data, setData] = useState<CosmeticData>()
  const [selectedName, setSelectedName] = useState<string>()
  const [type, setType] = useState<keyof CosmeticData>('FUR')

  useEffect(() => {
    getCosmeticsData().then(setData)
  }, [])

  useEffect(() => {
    setSelectedName(undefined)

    if (spinePlayer) {
      setCharacterSkin(spinePlayer, {
        FUR: accountInfo?.character.cosmetics.FUR.equiped,
        EYES: accountInfo?.character.cosmetics.EYES.equiped,
        HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
        TOP: accountInfo?.character.cosmetics.TOP.equiped,
        MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped
      })
    }
  }, [type])

  const onTileClick = (entry: CosmeticEntry) => {
    setSelectedName(entry.name)

    if (spinePlayer) {
      const skin: {
        [type in keyof CosmeticData]?: string
      } = {
        FUR: accountInfo?.character.cosmetics.FUR.equiped,
        EYES: accountInfo?.character.cosmetics.EYES.equiped,
        HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
        TOP: accountInfo?.character.cosmetics.TOP.equiped,
        MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped
      }

      skin[type] = entry.name

      setCharacterSkin(spinePlayer, skin)
    }
  }

  const onBuy = (entry: CosmeticEntry) => {
    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: 'Buy cosmetic',
        text: `Do you really want to buy the cosmetic for ${entry.priceToUnlock.gems} gems?`,
        acceptText: 'Yes',
        refuseText: 'No',
        async onAccept() {
          setBuying(true)

          const result = await buyCosmeticRequest(type, entry.name)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setBuying(false)
        }
      })
    }
  }

  const onEquip = async (entry?: CosmeticEntry) => {
    setEquipying(true)

    const result = await equipCosmeticRequest(type, entry?.name)

    if (result?.data) {
      const copy = JSON.parse(JSON.stringify(accountInfo)) as AccountInfo

      copy.character.cosmetics[type].equiped = entry?.name

      dispatch({
        type: 'SET_ACCOUNT_INFO',
        accountInfo: copy
      })
    }

    //setSelectedName(undefined)
    setEquipying(false)
  }

  const colorToFilter = (color: string) => {
    if (!COLOR_CACHE[color]) {
      COLOR_CACHE[color] = CssFilterConverter.hexToFilter(color).color ?? 'none'
    }

    return COLOR_CACHE[color]
  }

  return (
    <div className={formatClassName(styles, `cosmetics-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'multi-button')}>
        <div className={formatClassName(styles, `${type === 'FUR' && 'selected'}`)} onClick={() => setType('FUR')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'text')}>FUR</div>
        </div>
        <div className={formatClassName(styles, `${type === 'TOP' && 'selected'}`)} onClick={() => setType('TOP')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'text')}>TOP</div>
        </div>
        <div className={formatClassName(styles, `${type === 'HEAD' && 'selected'}`)} onClick={() => setType('HEAD')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'text')}>HEAD</div>
        </div>
        <div className={formatClassName(styles, `${type === 'EYES' && 'selected'}`)} onClick={() => setType('EYES')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'text')}>EYES</div>
        </div>
        <div className={formatClassName(styles, `${type === 'MOUTH' && 'selected'}`)} onClick={() => setType('MOUTH')}>
          <div className={formatClassName(styles, 'bg')}></div>
          <div className={formatClassName(styles, 'text')}>MOUTH</div>
        </div>
      </div>
      <div className={formatClassName(styles, 'list')}>
        {data?.[type]
          .sort((a, b) => a.priceToUnlock.gems - b.priceToUnlock.gems)
          .filter(entry => !entry.availableFrom || accountInfo?.character.cosmetics[type].unlocked.includes(entry.name) || (Date.now() >= entry.availableFrom && Date.now() <= (entry.availableTo ?? 0)))
          .map(entry =>
            <div
              key={entry.name}
              className={formatClassName(styles, 'tile reveal-element')}
              onClick={() => onTileClick(entry)}
            >
              <div
                className={formatClassName(styles, 'bg')}
                style={{
                  filter: `brightness(0) saturate(100%) ${colorToFilter(entry.backgroundColor)}` ?? 'none',
                }}
              ></div>
              <div
                className={formatClassName(styles, 'image')}
                style={{
                  backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/cosmetics/${type.toLowerCase()}/${entry.name.toLowerCase()}.png')`,
                }}
              ></div>
              <div className={formatClassName(styles, 'info')}>
                {
                  !accountInfo?.character.cosmetics[type].unlocked.includes(entry.name)
                    ? selectedName === entry.name ?
                      <Button className='xxsmall blue' onClick={() => onBuy(entry)} loading={buying}>buy</Button>
                      : <>
                        <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
                        {entry.priceToUnlock.gems}
                      </>
                    : accountInfo?.character.cosmetics[type].equiped === entry.name
                      ? type === 'FUR'
                        ? <img src={`${OOGY_VISUAL_CDN_URL}/ui/check.png`} alt="check" />
                        : <Button className='xxsmall' onClick={() => onEquip()} loading={equipying}>unequip</Button>
                      : selectedName === entry.name && <Button className='xxsmall' onClick={() => onEquip(entry)} loading={equipying}>equip</Button>
                }
              </div>
            </div>
          )}
      </div>
    </div>
  )
}
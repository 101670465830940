import React, { HtmlHTMLAttributes } from 'react'
import { formatClassName } from '../../../../../../../../../utils/global'
import { nFormatter } from '../../../../../../../../../utils/math'
import { getClashLeaderboard } from '../../../../../../../../api/hub'
import { useHubContext } from '../../../../../../../../state/context'
import { ClashData } from '../../type'
import styles from './leaderboard-tab.module.scss'

export type LeaderboardTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  clubClash: ClashData
  stats: Partial<Awaited<ReturnType<typeof getClashLeaderboard>>>
}

export const LeaderboardTab = ({ className, clubClash, stats, ...props }: LeaderboardTabProps) => {
  const { state: { accountInfo } } = useHubContext()

  const formatRank = (rank?: number) => {
    if (rank === 1) {
      return 'first'
    } else if (rank === 2) {
      return 'second'
    } else if (rank === 3) {
      return 'third'
    }
  }

  return (
    <div className={formatClassName(styles, `leaderboard-tab reveal-element ${className}`)} {...props}>
      <div className={formatClassName(styles, 'entry my')}>
        <div className={formatClassName(styles, 'bg')} ></div>
        <div className={formatClassName(styles, `rank ${formatRank(stats?.data?.rank)}`)} >
          {stats?.data?.rank === 0 ? '-' : stats?.data?.rank}
        </div>
        <div className={formatClassName(styles, 'key')}>
          You
        </div>
        <div >
          {stats?.data?.highestScore === 0 ? 'no score' : nFormatter(stats?.data?.highestScore ?? 0, 2)}
        </div>
        <div className={formatClassName(styles, 'club')} >
          {
            (accountInfo && accountInfo.clubId !== undefined) &&
            <img src={clubClash.clubs[accountInfo.clubId].imageURL} alt={accountInfo.clubId} />
          }
        </div>
        <div className={formatClassName(styles, 'violet')}>
          {stats?.data?.highestScore === 0 ? 'no score' : nFormatter(stats?.data?.score ?? 0, 2)}
        </div>
      </div>
      <div className={formatClassName(styles, 'list-container')}>
        <div className={formatClassName(styles, 'list')}>
          {
            stats
              ? (stats.data?.top ?? []).map((entry, index) =>
                <div className={formatClassName(styles, 'entry')} key={entry.publicKey}>
                  <div className={formatClassName(styles, 'bg')} ></div>
                  <div className={formatClassName(styles, `rank ${formatRank(index + 1)}`)}>
                    {index + 1}
                  </div>
                  <div className={formatClassName(styles, 'key')}>
                    {entry.username}
                  </div>
                  <div>
                    {nFormatter(entry.highestScore, 2)}
                  </div>
                  <div className={formatClassName(styles, 'club')} >
                    <img src={clubClash.clubs[entry.clubId].imageURL} alt={entry.clubId} />
                  </div>
                  <div className={formatClassName(styles, 'violet')}>
                    {nFormatter(entry.score, 2)}
                  </div>
                </div>
              )
              : <div className={formatClassName(styles, 'loader')}>Loading...</div>
          }
        </div>
      </div>
      <div className={formatClassName(styles, 'info')}>
        the leaderboard is computed each minute
      </div>
    </div>
  )
}
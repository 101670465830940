import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {Fragment, HtmlHTMLAttributes, useState} from 'react'
import Countdown, {CountdownRendererFn} from 'react-countdown'
import {clickButtonSound, clickRefusedButtonSound, hoverButtonSound} from '../../../../../../sound/sound'
import {formatClassName} from '../../../../../../utils/global'
import {Badge} from '../../../../../components/badge/badge'
import {AccountInfo, ChestEntry} from '../../../../../data/account'
import {useHubContext} from '../../../../../state/context'
import {BuyChest} from './buy-chest/buy-chest'
import {Chest} from './chest/chest'
import styles from './chests.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({days, hours, minutes}) =>
  <span>
    {addZeroToNumber(hours + days * 24)}H{addZeroToNumber(minutes)}M
  </span>

const emptyChests: AccountInfo['chests'] = {
  0: {
    unlocked: false,
    cost: {
      gems: 250
    }
  },
  1: {
    unlocked: false,
    cost: {
      gems: 250
    }
  },
  2: {
    unlocked: false,
    cost: {
      gems: 250
    }
  },
  3: {
    unlocked: false,
    cost: {
      gems: 250
    }
  }
}

export const chestColor: {
  [chestType in ChestType]: string
} = {
  [ChestType.Common]: 'yellow',
  [ChestType.Rare]: 'pink',
  [ChestType.Fabled]: 'violet',
  [ChestType.Zombie]: 'green'
}

export type ChestsProps = HtmlHTMLAttributes<HTMLDivElement>

export const Chests = ({className, onPointerOver, onPointerUp, ...props}: ChestsProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [selectedChestIndex, setSelectedChestIndex] = useState<number>(0)
  const [selectedChest, setSelectedChest] = useState<ChestEntry>()
  const [showBuyChest, setShowBuyChest] = useState(false)
  const [selectedSlotId, setSelectedSlotId] = useState<number>()

  const chests = accountInfo?.chests ?? emptyChests

  const onPointerOverPrepared = (event: React.PointerEvent<HTMLDivElement>) => {
    if (onPointerOver) onPointerOver(event)
    hoverButtonSound.play()
  }

  const onPointerUpPrepared = (event: React.PointerEvent<HTMLDivElement>, chest: ChestEntry, index: number) => {
    if (onPointerUp) onPointerUp(event)

    if (chest.type) {
      setSelectedChestIndex(index)
      setSelectedChest(chest)
      clickButtonSound.play()
    } else if (!chest.unlocked) {
      setSelectedSlotId(index)
      setShowBuyChest(true)
      clickButtonSound.play()
    } else {
      clickRefusedButtonSound.play()
    }
  }

  return (
    <>
      {selectedChest && <Chest onHide={() => setSelectedChest(undefined)} index={selectedChestIndex} chest={selectedChest} className={formatClassName(styles, 'selected-chest')} />}
      <BuyChest show={showBuyChest} chestSlotId={selectedSlotId} onClose={() => setShowBuyChest(false)} />
      <div className={formatClassName(styles, `chests ${className}`)} {...props}>
        {
          Object.values(chests).map((chest, index) =>
            <div key={`chest-${index}`} className={formatClassName(styles, `chest ${chest?.type ? chestColor[chest.type] : 'empty'}`)}
              onPointerUp={(event) => onPointerUpPrepared(event, chest, index)}
              onPointerOver={onPointerOverPrepared}
            >
              <div className={formatClassName(styles, 'chest-container')}>
                {
                  chest?.unlocked
                    ?
                    chest.type
                      ? <Fragment key={`chest-container-${index}`}>
                        {
                          (chest.timer !== undefined && chest.timer > Date.now() - 86_400_000) &&
                          <div className={formatClassName(styles, 'timer')}>
                            <img className={formatClassName(styles, 'lock')} src={`${OOGY_VISUAL_CDN_URL}/ui/lock.png`} alt="lock" />

                            <div className={formatClassName(styles, 'countdown')}>
                              <Countdown date={chest.timer + 86_400_000} renderer={renderer} />
                            </div>
                          </div>
                        }
                        <Badge className={`${chestColor[chest.type]} ${formatClassName(styles, 'badge')}`}>{chest.type}</Badge>
                        <img className={formatClassName(styles, 'light')} src={`${OOGY_VISUAL_CDN_URL}/ui/star-light.png`} alt="light" />
                        <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/chests/${chest.type}-chest.png`} alt={`${chest.type}-chest`} />
                        <div className={formatClassName(styles, 'open-now')}>OPEN NOW</div>
                      </Fragment>
                      : <Fragment key={`chest-${index}`}>
                        <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/empty-chest.png`} alt="empty-chest" />
                        <div className={formatClassName(styles, 'open-slot')}>EMPTY</div>
                      </Fragment>
                    : <Fragment key={`chest-${index}`}>
                      <img className={formatClassName(styles, 'image')} src={`${OOGY_VISUAL_CDN_URL}/ui/empty-chest.png`} alt="empty-chest" />
                      <div className={formatClassName(styles, 'open-slot')}>OPEN NEW CHEST SLOT</div>
                    </Fragment>
                }
              </div>
              {chest.cost &&
                <div className={formatClassName(styles, 'price')}>
                  {chest.cost?.gems !== undefined &&
                    <>
                      <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
                      {chest.cost.gems}
                    </>
                  }
                  {(chest.cost?.gems !== undefined && chest.cost.sol !== undefined) && <span>+</span>}
                  {chest.cost?.sol !== undefined &&
                    <>
                      <img src={`${OOGY_VISUAL_CDN_URL}/ui/sol.png`} alt="sol" />
                      {chest.cost.sol}
                    </>
                  }
                </div>
              }
            </div>
          )
        }
      </div>
    </>
  )
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useState} from 'react'
import Countdown, {CountdownRendererFn} from 'react-countdown'
import {formatClassName} from '../../../../../../../utils/global'
import {buyEnergyRequest} from '../../../../../../api/hub'
import {Button} from '../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import styles from './buy-energy.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({hours, minutes, seconds, total, api}) => {
  if (total > 0) api.start()

  return <span>
    {addZeroToNumber(minutes + hours * 60)}M{addZeroToNumber(seconds)}S
  </span>
}

export type BuyEnergyProps = GenericModalProps & {
  onClose: () => void
}

const Content = () => {
  const {state: {accountInfo}} = useHubContext()

  const [loading, setLoading] = useState<'vessels' | 'gems' | undefined>()

  const buyEnergy = async (mode: 'vessels' | 'gems') => {
    setLoading(mode)

    const result = await buyEnergyRequest(mode)

    if (result?.data && hubState.refreshAccountInfo) {
      hubState.refreshAccountInfo()
    }

    setLoading(undefined)
  }

  const refreshEnergy = () => {
    setTimeout(() => {
      if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
    }, 2000)
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'energy')} >
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/energy.png`} alt="energy" />
      <div className={formatClassName(styles, 'description')}  >
        You have
        <div>
          {accountInfo?.energy.quantity ?? '...'}/12
        </div>
        <span>
          +1 in <Countdown date={(accountInfo?.energy.lastUpdate ?? Date.now()) + 7_200_000} renderer={renderer} onComplete={refreshEnergy} />
        </span>
      </div>
    </div>
    buy 3 energy for
    <Button
      className={formatClassName(styles, 'vessel yellow')}
      disabled={(accountInfo?.vessels ?? 0) < 5 || accountInfo?.energy.quantity === 12}
      onClick={() => buyEnergy('vessels')}
      loading={loading === 'vessels'}
    >
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessel" />
      5 VESSELS
    </Button>
    or
    <Button
      className={formatClassName(styles, 'gems blue')}
      disabled={(accountInfo?.gems ?? 0) < 50 || accountInfo?.energy.quantity === 12}
      onClick={() => buyEnergy('gems')}
      loading={loading === 'gems'}
    >
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />
      50 GEMS
    </Button>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Buy energy
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const BuyEnergy = ({className, onClose, ...props}: BuyEnergyProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `buy-energy ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content />}
      onClickBackground={onClose}
      {...props}
    />
  )

}
@import "scss-variables";

.pause-menu {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    overflow: hidden;
    gap: 1rem;

    .sound {
      display: flex;
      justify-content: center;
      gap: 1rem;
      box-sizing: border-box;
      color: #d2cff0;
      font-size: 1.5rem;

      &:hover {
        cursor: pointer;
      }

      svg {
        &.enabled {
          color: #db43a3;
        }
      }
    }

    .player {
      width: 100%;
      height: 8rem;
    }

    button {
      width: min(12rem, 100%);
    }
  }
}

import {Spine} from '@pixi-spine/runtime-4.1'
import {sound} from '@pixi/sound'
import {SoundName} from '../enums'
import {playSpatialSound} from '../sound'
import {gameState} from '../state'

export const showLightning = (x: number, y: number, mode: 'normal' | 'green') => {
  if (!gameState.viewport) return

  const spine = new Spine(gameState.resources[mode === 'normal' ? 'lightning-effect' : 'boss_1_lightning'].spineData)

  const random = Math.random() < 0.5 ? 1 : -1

  spine.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'ATTACK') {
        setTimeout(() => {
          try {
            spine.destroy()
          } catch { /* empty */}
        })
      }
    }
  })

  spine.state.setAnimation(0, 'ATTACK')

  spine.position.set(x, y)
  spine.skeleton.scaleX = random

  spine.zIndex = Number.MAX_SAFE_INTEGER - 2

  gameState.viewport.addChild(spine)

  const localPlayer = gameState.level?.state.localPlayer

  if (!localPlayer) return

  const lightningSound = sound.find(SoundName.SkillLightning)

  if (lightningSound) {
    playSpatialSound(localPlayer, spine, lightningSound)
  }
}
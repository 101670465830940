@import "scss-variables";

.statistics-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;

  .tile {
    position: relative;
    height: 4rem;

    cursor: pointer;

    &:hover {
      .bg {
        opacity: 1;
      }
    }

    .bg {
      position: absolute;
      width: 100%;
      height: 100%;
      inset: 0;
      margin: auto;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(0) saturate(100%) invert(15%) sepia(15%) saturate(3814%) hue-rotate(237deg) brightness(94%)
        contrast(97%);
      opacity: 0.8;
      transition: all 150ms;
    }

    .container {
      display: flex;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 0.5rem;
      gap: 0.5rem;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 1;
        border-radius: 4px;
        background: #532a77;
        width: 3rem;

        img {
          height: 90%;
        }

        .level {
          font-size: 0.8rem;
          position: absolute;
          width: 1rem;
          height: 1rem;
          top: 0.2rem;
          left: 0.2rem;
          border-radius: 4px;
          background: rgba(137, 79, 188, 0.55);
          backdrop-filter: blur(2px);
          text-shadow: 0px 0.5px 0px #000;
          display: flex;
          padding: 2px 1px 1px 0px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
      }

      .info {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.3rem;
        letter-spacing: 1px;

        .name {
          font-family: "Cees Hand";
          color: #d4a4ff;
          font-size: 1rem;
          text-shadow: none;
          white-space: nowrap;
        }

        .value {
          color: #fff;
          text-shadow: 0px 0.5px 0px #000;
          font-family: "Supersonic Rocketship";
          font-weight: 400;
          font-size: 1.2rem;
        }
      }
    }
  }
}

import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../../utils/global'
import {openChestSlotRequest} from '../../../../../../api/hub'
import {Button} from '../../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {ChestsData, getChestsData} from '../../../../../../data/chests'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import styles from './buy-chest.module.scss'

export type BuyChestProps = GenericModalProps & {
  onClose: () => void
  chestSlotId?: number
}

export const BuyChest = ({className, onClose, chestSlotId, ...props}: BuyChestProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [buying, setBuying] = useState(false)
  const [data, setData] = useState<ChestsData>()

  useEffect(() => {
    getChestsData().then(setData)
  }, [])

  const title = <div className={formatClassName(styles, 'title')}>
    Open new chest slot
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

  const openSlot = async () => {
    if (!chestSlotId) return

    setBuying(true)

    const result = await openChestSlotRequest(chestSlotId)

    if (result?.data && hubState.refreshAccountInfo) {
      hubState.refreshAccountInfo()
    }

    setBuying(false)

    onClose()
  }

  const notEnoughGems = data !== undefined && accountInfo?.gems !== undefined && data.openSlotPrice.gems > accountInfo.gems

  const content = <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'tile')} >
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/empty-chest.png`} alt="empty-chest" />
    </div>
    <div className={formatClassName(styles, 'description')}>
      this is a one time purchase that permanently unlocks an additional chest slot
    </div>
    <Button className={formatClassName(styles, `gems ${notEnoughGems ? 'gray' : 'blue'}`)} onClick={openSlot} loading={buying || !data} disabled={notEnoughGems}>
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />
      <div className={formatClassName(styles, `${notEnoughGems ? 'error' : ''}`)}>{data?.openSlotPrice.gems ?? '...'} GEMS</div>
    </Button>
    {notEnoughGems &&
      <div className={formatClassName(styles, 'not-enough-gems')}>
        not enough gems
      </div>
    }
  </div>

  return (
    <GenericModal
      className={formatClassName(styles, `buy-chest ${className}`)}
      title={title}
      content={content}
      onClickBackground={onClose}
      {...props}
    />
  )

}
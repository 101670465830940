@import "scss-variables";

.connect-modal-overlay {
  background-color: #3333;
  padding: 1rem;
  font-family: "Cees Hand" !important;

  .connect-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #fffc;
    backdrop-filter: blur(0.5rem);
    border-radius: 1rem;
    padding: 1rem;
    width: 100vw;
    height: 100vh;
    max-width: min(700px, calc(100vw - 2rem));
    max-height: min(500px, calc(100vh - 2rem));
    box-shadow: $color-darker-gray 0px 8px 24px;
    box-sizing: border-box;
    color: black;
    pointer-events: all;

    img {
      width: auto;
    }

    .wallet {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      font-size: 32px;

      .install-wallet {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        font-family: "Cees Hand";
        font-size: 32px;
        max-width: 300px;

        small {
          font-size: 18px;
        }

        a {
          color: $color-blue;
          text-decoration: none;
        }
      }

      .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin: 4rem;
        width: 100%;
        max-width: 350px;
        text-align: center;

        button {
          display: flex;
          background-color: transparent;
          border: none;
          align-items: center;
          justify-content: center;
          position: relative;
          white-space: nowrap;
          background: linear-gradient(
            180deg,
            rgba(231, 90, 179, 1) 0%,
            rgba(231, 90, 179, 1) 50%,
            rgba(219, 67, 163, 1) 50%,
            rgba(219, 67, 163, 1) 100%
          );
          border: 1px solid black;
          padding: 0.5rem 1rem;
          border-radius: 999rem;

          color: white;
          text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.83);
          font-family: Supersonic Rocketship;
          font-weight: 400;
          font-size: 1rem;
          letter-spacing: 0.05rem;
          text-transform: uppercase;
          width: 12rem;

          i {
            display: none;
          }
        }

        .change-wallet button {
          background: rgb(215, 215, 215);
          background: linear-gradient(
            180deg,
            rgba(215, 215, 215, 1) 0%,
            rgba(215, 215, 215, 1) 50%,
            rgba(181, 180, 181, 1) 50%,
            rgba(181, 180, 181, 1) 100%
          );
        }
      }
    }
  }

  .ledger-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: normal;
    gap: 0.5rem;
    user-select: none;
    cursor: pointer;

    .toggle {
      pointer-events: none;
    }
  }
}

import {useConnection} from '@solana/wallet-adapter-react'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../../../utils/global'
import {getCompressedAssets} from '../../../../../../api/nft'
import {Button} from '../../../../../../components/buttons/button'
import {Input} from '../../../../../../components/input/input'
import {GenericModal, GenericModalProps} from '../../../../../../components/modal/modal'
import {useHubContext} from '../../../../../../state/context'
import {hubState} from '../../../../../../state/hub'
import {depositVessels} from '../../utils'
import styles from './deposit-vessels.module.scss'
import {DepositingVessels} from './depositing/depositing-vessels'

export type DepositVesselsProps = GenericModalProps

const Content = ({callback}: {
  callback: (show: boolean, nbWaiting: number, nbValidated: number, nbFailed: number) => void
}) => {
  const {state: {accountInfo, publicKey}} = useHubContext()
  const {connection} = useConnection()

  const [depositing, setDepositing] = useState(false)
  const [vessels, setVessels] = useState<string>()
  const [availableVessels, setAvailableVessels] = useState<number>()

  useEffect(() => {
    if (!publicKey) return

    getCompressedAssets(publicKey, '3njr1Xyu4qY2Vg31YUECh9LiHw9etS5g6CdmwYaFXfx4').then((res) => setAvailableVessels(res.length))
  }, [publicKey])

  const deposit = async () => {
    if (isNaN(Number(vessels)) || Number(vessels) <= 0 || !publicKey || !hubState.showConfirm) return

    if (Number(vessels) > 10) {
      toast.info('You can only deposit 10 vessels in one time')

      return
    }

    if (Number(vessels) <= 0) {
      toast.info('You need to deposit at least 1 vessel')

      return
    }

    setDepositing(true)

    hubState.showConfirm({
      title: 'Vessels deposit',
      text: `Are you sure you want to deposit ${vessels} vessels?`,
      async onAccept() {
        if (!hubState.signAllTransactions) return

        await depositVessels(Number(vessels), connection, publicKey, hubState.signAllTransactions, callback)

        if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()

        setAvailableVessels((await getCompressedAssets(publicKey, '3njr1Xyu4qY2Vg31YUECh9LiHw9etS5g6CdmwYaFXfx4')).length)

        setDepositing(false)
      },
      onRefuse() {
        setDepositing(false)
      },
      acceptText: 'deposit',
      refuseText: 'cancel'
    })
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'balance')}>
      <div>Balance</div>
      <div className={formatClassName(styles, 'vessels')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessel" />
        {accountInfo?.vessels ?? '...'}
      </div>
    </div>
    <div className={formatClassName(styles, 'input')}>
      <div className={formatClassName(styles, 'subtitle')}>Deposit amount (max 10)</div>
      <Input
        className={formatClassName(styles, 'amount')}
        max={availableVessels !== undefined ? Math.min(10, availableVessels) : 10}
        value={vessels} onChange={e => setVessels(e.target.value)}
        placeholder={availableVessels === undefined ? 'Checking your actual number of vessels...' : `You have ${availableVessels} vessels available`}
        disabled={availableVessels === undefined}
      />
      {
        availableVessels !== undefined && !isNaN(Number(vessels)) &&
        (availableVessels < Number(vessels)
          ? <div className={formatClassName(styles, 'error')}>you don't have enough vessels ({availableVessels} available)</div>
          : Number(vessels) > 10) && <div className={formatClassName(styles, 'error')}>you can only deposit 10 vessels in one time</div>
      }
    </div>
    <div className={formatClassName(styles, 'description')}>
      please note: once you have deposited vessels into oogy blast, they will be burned and <span>you cannot withdraw</span> them.
    </div>
    <Button className={formatClassName(styles, 'vessel yellow')} onClick={deposit} loading={depositing}>
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/vessel.png`} alt="vessel" />
      MAKE DEPOSIT
    </Button>
  </div>
}


const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Deposit vessels
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const DepositVessels = ({className, onClose, ...props}: DepositVesselsProps) => {
  const [showDepositing, setShowDepositing] = useState(false)
  const [nbWaiting, setNbWaiting] = useState(0)
  const [nbValidated, setNbValidated] = useState(0)
  const [nbFailed, setNbFailed] = useState(0)

  const callback = (show: boolean, nbWaiting: number, nbValidated: number, nbFailed: number) => {
    setShowDepositing(show)
    setNbWaiting(nbWaiting)
    setNbValidated(nbValidated)
    setNbFailed(nbFailed)
  }

  return (
    <>
      <GenericModal
        className={formatClassName(styles, `deposit-vessels ${className}`)}
        title={<Title onClose={onClose} />}
        content={<Content callback={callback} />}
        onClickBackground={onClose}
        {...props}
      />
      <DepositingVessels show={showDepositing} nbWaiting={nbWaiting} nbValidated={nbValidated} nbFailed={nbFailed} onClose={() => setShowDepositing(false)} className='no-blur' />
    </>
  )
}
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React from 'react'
import Countdown, { CountdownRendererFn } from 'react-countdown'
import { formatClassName } from '../../../../../../../../utils/global'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../../state/context'
import styles from './confirm-clash.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({ days, hours, minutes, seconds }) =>
  <span>
    {
      days === 0 && hours === 0 && minutes === 0 && seconds === 0
        ? 'available'
        : <>{addZeroToNumber(hours + days * 24)}H{addZeroToNumber(minutes)}M</>
    }
  </span>


export type ConfirmClashProps = GenericModalProps & {
  onClose: () => void
  callback?: (confirmed: boolean) => void
}

const Content = ({ callback }: { callback?: (confirmed: boolean, mode: 'score' | 'clash') => void }) => {
  const { state: { accountInfo } } = useHubContext()

  const play = () => {
    if (callback) callback(true, 'clash')
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'balance')} >
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/tab-clash.png`} alt="skull" />
      <div className={formatClassName(styles, 'description')}  >
        You have
        <div>
          {accountInfo?.clash.quantity ?? '...'}/1 FREE TRY
        </div>
        <span>
          +1 try in <Countdown date={(accountInfo?.clash.lastUpdate ?? Date.now()) + 86_400_000} renderer={renderer} />
        </span>
      </div>
    </div>
    <div className={formatClassName(styles, 'cost')}>
      Cost to play
      <div className={formatClassName(styles, 'balance-entries')}>
        <div className={formatClassName(styles, 'balance-entry')}>
          1 TRY
        </div>
      </div>
    </div>
    <div className={formatClassName(styles, 'buttons')}>
      {
        (accountInfo?.clash.quantity ?? 0) === 0
          ? <Button
            className={formatClassName(styles, 'gems blue')}
            onClick={play}
          >
            <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />
            PLAY FOR 100 GEMS
          </Button>
          : <Button className='small' onClick={play} disabled={(accountInfo?.clash.quantity ?? 0) < 1}>
            PLAY FOR FREE
          </Button>
      }
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Confirm Clash
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const ConfirmClash = ({ className, onClose, callback, ...props }: ConfirmClashProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `confirm-clash ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content callback={callback} />}
      onClickBackground={onClose}
      {...props}
    />
  )
}
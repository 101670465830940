@import "scss-variables";

.badge {
  display: inline-block;
  padding: 0.3em 0.5em;
  border-radius: 0.7em;
  border: 0.2em solid #ffbe8f;
  background: #ff891c;
  box-shadow: 0px 0.2em 0px 0.2em #4a2d01;

  color: #fff;
  text-shadow: 0px 0.2em 0px #4a2d01;
  font-family: Supersonic Rocketship;
  font-size: 1em;
  font-weight: 400;

  &.pink {
    border-color: #ff8fc5;
    background: #db43a3;
    box-shadow: 0px 0.2em 0px 0.2em #4b0931;
  }

  &.violet {
    border-color: #b592ff;
    background: #a265e0;
    box-shadow: 0px 0.2em 0px 0.2em #011a4a;
  }

  &.green {
    border-color: #165124;
    background: #62971e;
    box-shadow: 0px 0.2em 0px 0.2em #182716;
  }
}

import React, {InputHTMLAttributes} from 'react'
import {formatClassName} from '../../../utils/global'
import styles from './toggle.module.scss'

export type ToggleProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> & {
  containerClassName?: string
}

export const Toggle = ({containerClassName, ...props}: ToggleProps) => {
  return <div className={formatClassName(styles, `toggle ${containerClassName ?? ''}`)}>
    <label className={formatClassName(styles, 'switch')}>
      <input type="checkbox" {...props} />
      <span className={formatClassName(styles, 'slider round')}></span>
    </label>
  </div>
}
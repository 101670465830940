@import "scss-variables";

.twitter-intent-button {
  color: white;
  background: black !important;

  &:hover {
    background: lighten(black, 3) !important;
  }
}

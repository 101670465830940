import {useNavigate} from 'react-router-dom'
import {clearSessionInfo} from '../../utils/session'
import {useHubContext} from '../state/context'

export const useHub = () => {
  const {dispatch, state: {disconnectWallet}} = useHubContext()
  const navigate = useNavigate()

  const disconnect = () => {
    dispatch({type: 'SET_SESSION_TOKEN', sessionToken: undefined})
    dispatch({type: 'SET_PUBLIC_KEY', publicKey: undefined})
    dispatch({type: 'SET_TWITTER_USERNAME', twitterUsername: undefined})
    dispatch({type: 'SET_DISCORD_USERNAME', discordUsername: undefined})
    //dispatch({type: 'SET_CHAIN', chain: undefined})

    if (disconnectWallet) {
      disconnectWallet()
    }

    clearSessionInfo()

    navigate('/')
  }

  return {
    disconnect
  }
}
@import "scss-variables";

.buy-chest {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.5rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;

    button {
      width: min(100%, 18rem);
    }

    .tile {
      display: flex;
      align-items: center;
      border-radius: 13px;
      background: linear-gradient(180deg, rgba(176, 111, 227, 0.98) 0%, #713fdb 100%);
      box-shadow:
        0px 4px 7px 0px rgba(233, 146, 255, 0.86) inset,
        0px 3px 0px 0px #582499;
      height: 5rem;
      gap: 2rem;
      padding: 1rem;
      margin-bottom: 1rem;

      img {
        width: auto;
        height: 80%;
        filter: brightness(0.1);
      }
    }

    .description {
      color: #d2cff0;
      text-align: center;
      font-family: "More Sugar";
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: -0.05rem;
      font-weight: 400;

      span {
        color: #fff;
      }
    }

    .gems {
      width: min(100%, 18rem);

      .error {
        color: #ff6262;
      }

      img {
        height: 100%;
        margin-right: 1rem;
      }
    }
  }
}

import {RoomInfo} from 'oogy-blast'
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {ChangeEventHandler, useState} from 'react'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../../../../../utils/global'
import {ButtonIcon} from '../../../../../../../../components/buttons/button'
import {Input} from '../../../../../../../../components/input/input'
import {GenericModal, OverlayModalProps} from '../../../../../../../../components/modal/modal'
import {hubState} from '../../../../../../../../state/hub'
import styles from './create-room-modal.module.scss'

export type CreateRoomModalProps = OverlayModalProps & {
  onCreate: (roomInfo: RoomInfo, password?: string) => Promise<void>
  onClose: () => void
}

const Content = ({onCreate}: {
  onCreate: (roomInfo: RoomInfo, password?: string) => Promise<void>
}) => {
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [maxPlayers, setMaxPlayers] = useState<RoomInfo['maxPlayers']>(2)
  const [creating, setCreating] = useState(false)

  const createRoom = async () => {
    if (creating) {
      return
    }

    if (name.length < 7) {
      toast.error('Room name too short, min 6 characters')

      return
    }

    setCreating(true)

    if (hubState.showLoading) {
      hubState.showLoading(true)
    }

    const roomInfo: RoomInfo = {
      name,
      mode: 'score',
      maxPlayers
    }

    try {
      await onCreate(roomInfo, password)
    } catch (err) {
      toast.error('Impossible to create the room, please try again later or contact the support team')
    } finally {
      if (hubState.showLoading) {
        hubState.showLoading(false)
      }
    }

    setCreating(false)
  }

  const nameChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value)
  }

  const passwordChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.target.value)
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'form')}>
      <div className={formatClassName(styles, 'entry')}>
        <div className={formatClassName(styles, 'title')}>Room name*</div>
        <Input type="text" maxLength={32} placeholder='Room name (min 6 chars)' value={name} onChange={nameChange} autoFocus />
      </div>
      <div className={formatClassName(styles, 'entry')}>
        <div className={formatClassName(styles, 'title')}>Password</div>
        <Input type="password" maxLength={32} placeholder='Leave empty for a public room' value={password} onChange={passwordChange} />
      </div>
      <div className={formatClassName(styles, 'entry')}>
        <div className={formatClassName(styles, 'title')}>Number of players*</div>
        <div className={formatClassName(styles, 'multi-button')}>
          <div className={formatClassName(styles, `${maxPlayers === 2 && 'selected'}`)} onClick={() => setMaxPlayers(2)}>
            <div className={formatClassName(styles, 'bg')}></div>
            <div className={formatClassName(styles, 'text')}>2</div>
          </div>
          <div className={formatClassName(styles, `${maxPlayers === 3 && 'selected'}`)} onClick={() => setMaxPlayers(3)}>
            <div className={formatClassName(styles, 'bg')}></div>
            <div className={formatClassName(styles, 'text')}>3</div>
          </div>
          <div className={formatClassName(styles, `${maxPlayers === 4 && 'selected'}`)} onClick={() => setMaxPlayers(4)}>
            <div className={formatClassName(styles, 'bg')}></div>
            <div className={formatClassName(styles, 'text')}>4</div>
          </div>
        </div>
      </div>
    </div>
    <ButtonIcon className={formatClassName(styles, 'create-room-button')} icon="circle-plus" onClick={createRoom} disabled={creating}>create</ButtonIcon>
  </div>
}

const Title = ({onClose}: {onClose: () => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Create your room
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const CreateRoomModal = ({onCreate, onClose, className, ...props}: CreateRoomModalProps) => {
  return <GenericModal
    className={formatClassName(styles, `create-room-modal ${className}`)}
    title={<Title onClose={onClose} />}
    content={<Content onCreate={onCreate} />}
    {...props}
  />
}
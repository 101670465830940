@import "scss-variables";

.buy-or-upgrade {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.5rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;

    button {
      width: min(100%, 18rem);
    }

    .arrow-down {
      height: 2rem;
    }

    .weapon {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 7rem;

      img {
        position: absolute;
        width: 100%;
        max-width: 7rem;
        rotate: -15deg;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      .shadow {
        position: absolute;
        width: 136px;
        height: 36.615px;
        border-radius: 100%;
        opacity: 0.5;
        background: #8e58c0;
        top: 0;
        bottom: 0;
        margin: auto;
        margin-top: 17%;
      }

      .pew-pew-1,
      .pew-pew-2,
      .pew-pew-3,
      .pew-pew-4 {
        position: absolute;
        color: rgba(209, 156, 255, 0.47);
        font-family: "More Sugar";
        font-size: 1rem;
        font-weight: 400;
        text-shadow: none;
      }

      .pew-pew-1 {
        left: 10%;
        top: 10%;
        rotate: 15deg;
      }

      .pew-pew-2 {
        left: 12%;
        bottom: 10%;
        rotate: -18deg;
      }

      .pew-pew-3 {
        right: 12%;
        top: 11%;
        rotate: -13deg;
      }

      .pew-pew-4 {
        right: 11%;
        bottom: 11%;
        rotate: 12deg;
      }
    }

    .stats {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      max-width: 20rem;

      .title {
        color: #fff;
        font-family: "Cees Hand";
        font-size: 1rem;
      }

      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        overflow-y: auto;
        overflow-x: hidden;

        .tile {
          position: relative;
          height: 3rem;

          &:hover {
            .bg {
              opacity: 1;
            }
          }

          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            margin: auto;
            background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
            background-repeat: no-repeat;
            background-size: cover;
            //filter: brightness(0) saturate(100%) invert(15%) sepia(15%) saturate(3814%) hue-rotate(237deg)
            //  brightness(94%) contrast(97%);
            opacity: 0.8;
            transition: all 150ms;
          }

          .container {
            display: flex;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            padding: 0.5rem;
            gap: 0.5rem;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              z-index: 1;
              border-radius: 4px;
              background: #532a77;
              width: 2rem;

              img {
                width: 90%;
              }
            }

            .info {
              position: relative;
              z-index: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              line-height: 1.3rem;
              letter-spacing: 1px;

              .name {
                font-family: "Cees Hand";
                color: #d4a4ff;
                font-size: 1rem;
                text-shadow: none;
                white-space: nowrap;
              }

              .value {
                color: #fff;
                text-shadow: 0px 0.5px 0px #000;
                font-family: "Supersonic Rocketship";
                font-size: 1.1rem;
                font-weight: 400;

                span {
                  color: #86ff8b;
                }
              }
            }
          }
        }
      }
    }

    .gems {
      display: flex;
      position: relative;
      font-size: 1rem;
      width: min(100%, 18rem);
      gap: 1rem;

      .error {
        color: #ff6262;
      }

      img {
        height: 100%;
      }

      > div {
        flex: 1;
      }
    }

    .not-enough-gems {
      color: #d2cff0;
      font-family: "Cees Hand";
      font-size: 1rem;
    }
  }
}

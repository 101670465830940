import { WalletNotSelectedError, useConnection } from '@solana/wallet-adapter-react'
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionInstruction } from '@solana/web3.js'
import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { formatClassName } from '../../../../../../../utils/global'
import { checkInvoicePaymentRequest, getInvoiceIdRequest } from '../../../../../../api/hub'
import { GenericModal, GenericModalProps } from '../../../../../../components/modal/modal'
import { useHubContext } from '../../../../../../state/context'
import { hubState } from '../../../../../../state/hub'
import styles from './buy-gems.module.scss'

export type BuyGemsProps = GenericModalProps & {
  onClose: () => void
}

const Content = () => {
  const { state: { publicKey } } = useHubContext()
  const { connection } = useConnection()

  const [loading, setLoading] = useState(false)

  const buyGems = async (quantity: number, sol: number) => {
    if (loading) return

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: `Buy ${quantity} gems`,
        text: `Are you sure you want to buy ${quantity} gems for ${sol} SOL?`,
        acceptText: 'buy',
        refuseText: 'cancel',
        onAccept: async () => {
          if (!publicKey || !hubState.sendTransaction || !hubState.showLoading) return

          setLoading(true)

          try {
            const invoiceResponse = await getInvoiceIdRequest(quantity)

            if (!invoiceResponse || invoiceResponse.status !== 200) {
              toast.error('Impossible to generate an invoice id')

              return
            }

            const invoiceData = invoiceResponse.data

            const blockhashInfo = await connection.getLatestBlockhashAndContext()

            const transferTransaction = new Transaction().add(
              SystemProgram.transfer({
                fromPubkey: new PublicKey(publicKey),
                toPubkey: new PublicKey(invoiceData.receiverPublicKey),
                lamports: LAMPORTS_PER_SOL * invoiceData.price,
              })
            )

            transferTransaction.add(
              new TransactionInstruction({
                keys: [{ pubkey: new PublicKey(publicKey), isSigner: true, isWritable: true }],
                data: Buffer.from(invoiceData.invoiceId, 'utf-8'),
                programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
              })
            )

            transferTransaction.minNonceContextSlot = blockhashInfo.context.slot
            transferTransaction.recentBlockhash = blockhashInfo.value.blockhash
            transferTransaction.lastValidBlockHeight = blockhashInfo.value.lastValidBlockHeight

            hubState.showLoading(true, undefined, 'Buying gems...')

            const signature = await hubState.sendTransaction(transferTransaction, connection, {
              minContextSlot: blockhashInfo.context.slot
            })

            const checkResponse = await checkInvoicePaymentRequest(invoiceData.invoiceId, signature)

            if (!checkResponse || checkResponse.status !== 200) {
              toast.error('Impossible to validate the invoice, please contact the support team')
            } else {
              if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()

              toast.success(`You successfully bought ${quantity} gems!`)
            }
          } catch (err) {
            console.dir(err)

            if (err instanceof WalletNotSelectedError) {
              toast.error('Wallet not connected, please try to disconnect and reconnect')
            }

            if (typeof err === 'string') {
              toast.error(err)
            }
          }

          hubState.showLoading(false)
          setLoading(false)
        }
      })
    }
  }

  return <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'tile')} onClick={() => buyGems(100, 0.05)}>
      <div className={formatClassName(styles, 'details')}>
        <div className={formatClassName(styles, 'gems gems-1')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
        </div>
        100 GEMS
      </div>
      <div className={formatClassName(styles, 'price')}>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/sol.png`} alt="sol" />
        0.05
      </div>
    </div>
    <div className={formatClassName(styles, 'tile')} onClick={() => buyGems(500, 0.2)}>
      <div className={formatClassName(styles, 'details')}>
        <div className={formatClassName(styles, 'gems gems-2')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
        </div>
        500 GEMS
      </div>
      <div className={formatClassName(styles, 'price')}>
        <div className={formatClassName(styles, 'discount')}>
          -20%
        </div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/sol.png`} alt="sol" />
        0.2
      </div>
    </div>
    <div className={formatClassName(styles, 'tile')} onClick={() => buyGems(2000, 0.5)}>
      <div className={formatClassName(styles, 'details')}>
        <div className={formatClassName(styles, 'gems gems-3')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />
        </div>
        2000 GEMS
      </div>
      <div className={formatClassName(styles, 'price')}>
        <div className={formatClassName(styles, 'discount')}>
          -50%
        </div>
        <img src={`${OOGY_VISUAL_CDN_URL}/ui/sol.png`} alt="sol" />
        0.5
      </div>
    </div>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Buy gems
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const BuyGems = ({ className, onClose, ...props }: BuyGemsProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `buy-gems ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content />}
      onClickBackground={onClose}
      {...props}
    />
  )

}
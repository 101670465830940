import {toast} from 'react-toastify'
import {getServersList, pingServer} from '../../../../../../api/hub'

export const getColyseusClientURL = async () => {
  if (window.location.hostname.includes('dev-')) {
    return 'wss://dev-i0-blast.oogy.io'
  }

  const response = await getServersList()

  if (response?.status !== 200) {
    toast.error('Impossible to retrieve the servers info')

    return
  }

  const servers = response.data

  if (servers['local']) {
    return 'ws://localhost:3000'
  }

  const availableServers = new Set(Object.keys(servers).map(serverName => serverName.split('-')[0]))

  const availableServersWithPing: {
    serverName: string
    ping: number
  }[] = []

  await Promise.all(
    Array.from(availableServers.values()).map(async (availableServer) => {
      const ping = await pingServer(availableServer)

      if (ping) {
        availableServersWithPing.push({
          serverName: availableServer,
          ping
        })
      }
    })
  )

  const bestServer = availableServersWithPing.sort((a, b) => a.ping - b.ping).shift()

  if (!bestServer) {
    toast.error('Impossible to find the optimal server, please try again later')

    return
  }

  return `wss://${bestServer.serverName}-i0-blast.oogy.io`
}
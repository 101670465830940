import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes, useState} from 'react'
import {clickButtonSound, hoverButtonSound} from '../../../../../../sound/sound'
import {formatClassName, numberFormater} from '../../../../../../utils/global'
import {useHubContext} from '../../../../../state/context'
import {BuyEnergy} from './buy-energy/buy-energy'
import {BuyGems} from './buy-gems/buy-gems'
import {DepositVessels} from './deposit-vessels/deposit-vessels'
import {Settings} from './settings/settings'
import styles from './top-bar.module.scss'
import {Wallet} from './wallet/wallet'

export type TopBarProps = HtmlHTMLAttributes<HTMLDivElement>

const SVG = () => <svg xmlns="http://www.w3.org/2000/svg" width="78" height="23" viewBox="0 0 78 23" fill="none">
  <path d="M0.349121 18.4032V4.14896C0.349121 2.0803 2.02611 0.40332 4.09477 0.40332H20.8564C21.7985 0.40332 22.7059 0.758293 23.3979 1.39749L26.3159 4.0928C27.0079 4.732 27.9153 5.08697 28.8574 5.08697H73.6844C76.4365 5.08697 78.2489 7.95562 77.0671 10.4411L72.5162 20.0117C71.8955 21.3171 70.579 22.1488 69.1335 22.1488H4.09476C2.0261 22.1488 0.349121 20.4719 0.349121 18.4032Z" fill="#7E64A4" fillOpacity="0.4" />
</svg>

export const TopBar = ({className, ...props}: TopBarProps) => {
  const {state: {accountInfo}} = useHubContext()

  const [showSettings, setShowSettings] = useState(false)
  const [showWallet, setShowWallet] = useState(false)
  const [showDepositVessels, setShowDepositVessels] = useState(false)
  const [showBuyEnergy, setShowBuyEnergy] = useState(false)
  const [showBuyGems, setShowBuyGems] = useState(false)

  const openDepositVessels = () => {
    setShowWallet(false)
    setShowDepositVessels(true)
  }

  const onPointerOver = () => {
    hoverButtonSound.play()
  }

  const onPointerUp = (callback: () => void) => {
    callback()

    clickButtonSound.play()
  }

  const weekString = accountInfo?.actualWeek ?? ''

  return (
    <>
      <Settings show={showSettings} onClose={() => setShowSettings(false)} />
      <Wallet show={showWallet} onClose={() => setShowWallet(false)} openDepositVessels={openDepositVessels} />
      <DepositVessels show={showDepositVessels} onClose={() => setShowDepositVessels(false)} />
      <BuyEnergy show={showBuyEnergy} onClose={() => setShowBuyEnergy(false)} />
      <BuyGems show={showBuyGems} onClose={() => setShowBuyGems(false)} />
      <div className={formatClassName(styles, `top-bar ${className}`)} {...props}>
        <div className={formatClassName(styles, 'element-left')}>
          <div className={formatClassName(styles, 'element hover')} onPointerUp={() => onPointerUp(() => setShowBuyGems(true))} onPointerOver={onPointerOver}>
            <div className={formatClassName(styles, 'icon')}>
              <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />
            </div>
            <div className={formatClassName(styles, 'quantity')}>{(accountInfo?.gems !== undefined) ? numberFormater(accountInfo.gems) : '...'}</div>
            <div className={formatClassName(styles, 'buy')}>+</div>
            <SVG />
          </div>
          <div className={formatClassName(styles, 'element hover')} onPointerUp={() => onPointerUp(() => setShowBuyEnergy(true))} onPointerOver={onPointerOver}>
            <div className={formatClassName(styles, 'icon')}>
              <img src={`${OOGY_VISUAL_CDN_URL}/ui/energy.png`} alt="energy" />
            </div>
            <div className={formatClassName(styles, 'quantity')}>{(accountInfo?.energy.quantity !== undefined) ? accountInfo?.energy.quantity : '...'}/12</div>
            <div className={formatClassName(styles, 'buy')}>+</div>
            <SVG />
          </div>
          <div className={formatClassName(styles, 'element')} onPointerOver={onPointerOver}>
            <div className={formatClassName(styles, 'icon')}>
              <img src={`${OOGY_VISUAL_CDN_URL}/ui/token.png`} alt="token" />
            </div>
            <div className={formatClassName(styles, 'quantity')}>{accountInfo?.tokens[weekString] ?? 0}</div>
            <SVG />
          </div>
        </div>
        <div className={formatClassName(styles, 'element-right')}>
          <div className={formatClassName(styles, 'bag hover')} onPointerUp={() => onPointerUp(() => setShowWallet(true))} onPointerOver={onPointerOver}>
            <img src={`${OOGY_VISUAL_CDN_URL}/ui/bag.png`} alt="bag" />
          </div>
          <div className={formatClassName(styles, 'settings hover')} onPointerUp={() => onPointerUp(() => setShowSettings(true))} onPointerOver={onPointerOver}>
            <img src={`${OOGY_VISUAL_CDN_URL}/ui/settings.png`} alt="settings" />
          </div>
        </div>
      </div>
    </>
  )
}
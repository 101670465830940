import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {HtmlHTMLAttributes} from 'react'
import {clickButtonSound} from '../../../../../../sound/sound'
import {formatClassName} from '../../../../../../utils/global'
import {useHubContext} from '../../../../../state/context'
import styles from './navigation.module.scss'
import {Tabs} from './tabs'

export type NavigationProps = HtmlHTMLAttributes<HTMLDivElement>

export const Navigation = ({className, ...props}: NavigationProps) => {
  const {dispatch, state: {tab}} = useHubContext()

  const backgroundImage = tab === 'leaderboard'
    ? `url('${OOGY_VISUAL_CDN_URL}/ui/nav-1.png')`
    : tab === 'clash'
      ? `url('${OOGY_VISUAL_CDN_URL}/ui/nav-2.png')`
      : tab === 'battle'
        ? `url('${OOGY_VISUAL_CDN_URL}/ui/nav-3.png')`
        : `url('${OOGY_VISUAL_CDN_URL}/ui/nav-4.png')`

  const navigate = (tab: Tabs) => {
    dispatch({
      type: 'SET_NAVIGATION',
      tab
    })
  }

  const onPointerUp = (tab: Tabs) => {
    clickButtonSound.play()

    navigate(tab)
  }

  return (
    <div
      className={formatClassName(styles, `navigation ${className}`)}
      {...props}
    >
      <div className={formatClassName(styles, 'bg')} style={{backgroundImage: `url('${OOGY_VISUAL_CDN_URL}/ui/nav-bg.svg')`}}></div>
      <div className={formatClassName(styles, 'active-bg')} style={{backgroundImage}}></div>
      <div className={formatClassName(styles, 'tabs')}>
        <div className={formatClassName(styles, `tab ranking hover ${tab === 'leaderboard' ? 'active' : ''}`)} onPointerUp={() => onPointerUp('leaderboard')}>
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/tab-ranking.png`} alt="tab-ranking" />
          <img className={formatClassName(styles, 'arrow-right')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-right" />
          <div className={formatClassName(styles, 'title')}>RANKING</div>
        </div>
        <div className={formatClassName(styles, `tab clash hover ${tab === 'clash' ? 'active' : ''}`)} onPointerUp={() => onPointerUp('clash')}>
          <img className={formatClassName(styles, 'arrow-left')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-left" />
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/tab-clash.png`} alt="tab-clash" />
          <img className={formatClassName(styles, 'arrow-right')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-right" />
          <div className={formatClassName(styles, 'title')}>CLASH</div>
        </div>
        <div className={formatClassName(styles, `tab battle hover ${tab === 'battle' ? 'active' : ''}`)} onPointerUp={() => onPointerUp('battle')}>
          <img className={formatClassName(styles, 'arrow-left')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-left" />
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/tab-battle.png`} alt="tab-battle" />
          <img className={formatClassName(styles, 'arrow-right')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-right" />
          <div className={formatClassName(styles, 'title')}>BATTLE</div>
        </div>
        <div className={formatClassName(styles, `tab character hover ${tab === 'character' ? 'active' : ''}`)} onPointerUp={() => onPointerUp('character')}>
          <img className={formatClassName(styles, 'arrow-left')} src={`${OOGY_VISUAL_CDN_URL}/ui/nav-arrow.png`} alt="nav-arrow-left" />
          <img className={formatClassName(styles, 'icon')} src={`${OOGY_VISUAL_CDN_URL}/ui/tab-character.png`} alt="tab-character" />
          <div className={formatClassName(styles, 'title')}>CHARACTER</div>
        </div>
      </div>
    </div>
  )
}
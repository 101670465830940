@import "scss-variables";

.battle-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5rem 0rem 8rem 0rem;
  box-sizing: border-box;

  .player,
  .monster,
  .particles {
    z-index: 5;
    position: absolute;
    pointer-events: none;
  }

  .player {
    z-index: 7;
    bottom: 35%;
    left: 0;
    right: 0;
    height: 30%;
  }

  .monster {
    bottom: 50%;
    left: 10%;
    width: 25%;
    height: 25%;
  }

  .particles {
    z-index: 5;
    bottom: 35%;
    left: 0;
    right: 0;
    opacity: 0.5;
  }

  .battle {
    position: relative;
    z-index: 7;
    width: 70%;
    filter: drop-shadow(0px 0px 14px rgba(218, 113, 254, 0.74));
    opacity: 0;
    margin-top: auto;

    &:hover {
      cursor: pointer;
    }

    &.bounce {
      animation: bounceIn 1s forwards 250ms;
    }

    &.pulse {
      opacity: 1;
      animation: pulseButton 5s infinite;
    }
  }

  .chests {
    position: relative;
    z-index: 7;
    padding: 1rem;
  }

  .daily-rewards-modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1rem;

    .icon {
      height: 5rem;
    }

    .title {
      color: #fff;
      text-align: center;
      text-shadow: 0px 1px 0px #000;
      font-family: Supersonic Rocketship;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.66px;
      text-transform: uppercase;
    }

    .description {
      color: #fff;
      text-shadow: 0px 1px 0px #000;
      font-family: More Sugar;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.8px;
    }

    .rewards {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      text-align: center;
      color: #fff;
      text-shadow: 0px 1px 0px #000;
      font-family: Supersonic Rocketship;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.96px;

      img {
        height: 2rem;
      }
    }
  }

  .top-line {
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;

    .tile {
      display: flex;
      flex: 1;
      gap: 0.5rem;
      align-items: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      padding: 0.5rem 1rem;
      cursor: pointer;

      .icon {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 3rem;
        height: 3rem;
        border-radius: 0.25rem;
      }

      .content {
        display: flex;
        flex-direction: column;

        .title {
          color: #fff;
          text-align: center;
          text-shadow: 0px 2px 0px #000;
          font-family: "Supersonic Rocketship";
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.45px;
          text-transform: uppercase;
        }

        .description {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          color: #fff;
          text-shadow: 0px 1px 0px #000;
          font-family: "More Sugar";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;

          img {
            height: 1rem;
          }
        }
      }
    }
  }
}

@keyframes pulseButton {
  40% {
    transform: scale3d(1, 1, 1);
    filter: drop-shadow(0px 0px 14px rgba(218, 113, 254, 0.74));
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
    filter: drop-shadow(0px 0px 20px rgba(218, 113, 254, 0.74));
  }

  60% {
    transform: scale3d(1, 1, 1);
    filter: drop-shadow(0px 0px 14px rgba(218, 113, 254, 0.74));
  }
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
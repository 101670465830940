@import "scss-variables";

.club-entry {
  position: relative;
  cursor: pointer;

  &.selected {
    &::before {
      opacity: 1;
      // border: 1px solid white;
      // border-radius: 1rem;
      border: 1px solid rgba(255, 255, 255, 0.70);
    }
  }

  &.transparent {
    opacity: 0.5;
  }

  &.disabled {
    pointer-events: none;
  }

  &:hover {
    &:not(.selected) {
      &::before {
        opacity: 0.65;
      }
    }
  }

  &::before {
    position: absolute;
    inset: 0;
    border-radius: 14px;
    border: 0.6px solid #c985ff;
    opacity: 0.5;
    background: linear-gradient(180deg, rgba(206, 143, 255, 0.98) 0%, #7944ea 100%);
    box-shadow:
      0px 6px 0px 0px rgba(0, 0, 0, 0.25),
      0px 6px 12px 0px #e992ff inset,
      0px 5px 0px 0px #4e1f89;
    content: "";
  }

  .details {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 1rem;

    img {
      height: 3rem;
    }

    .name {
      color: #fff;
      padding-top: 4px;
      text-shadow: 0px 1.5px 0px #000;
      font-family: "Supersonic Rocketship";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.54px;
      text-transform: uppercase;
    }

    .members {
      color: #fff;
      padding-top: 4px;
      text-shadow: 0px 1px 0px #000;
      font-family: "Cees Hand";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: auto;
    }
  }
}
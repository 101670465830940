// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {ArraySchema, MapSchema, type} from '@colyseus/schema'
import {AuraSchema} from './AuraSchema'
import {CosmeticsSchema} from './CosmeticsSchema'
import {LivingEntitySchema} from './LivingEntitySchema'
import {SkillSchema} from './SkillSchema'
import {WeaponSchema} from './WeaponSchema'

export class PlayerSchema extends LivingEntitySchema {
  @type('boolean') public moving!: boolean
  @type('boolean') public movingWithRotation!: boolean
  @type('number') public shootingAngle!: number
  @type('number') public score!: number
  @type('number') public monstersKilled!: number
  @type('number') public gems!: number
  @type(['string']) public chests: ArraySchema<string> = new ArraySchema<string>();
  @type('number') public usedLives!: number
  @type('number') public attractingRadius!: number
  @type('number') public experience!: number
  @type('number') public nextLevelExperience!: number
  @type('number') public level!: number
  @type('number') public revivedCount!: number
  @type('boolean') public dead!: boolean
  @type('boolean') public waitingSkillUnlock!: boolean
  @type('string') public color!: string
  @type('string') public name!: string
  @type('string') public clientId!: string
  @type('boolean') public ready!: boolean
  @type('boolean') public frozen!: boolean
  @type('boolean') public connected!: boolean
  @type('boolean') public host!: boolean
  @type(CosmeticsSchema) public cosmetics: CosmeticsSchema = new CosmeticsSchema();
  @type(WeaponSchema) public weapon: WeaponSchema = new WeaponSchema();
  @type({map: AuraSchema}) public auras: MapSchema<AuraSchema> = new MapSchema<AuraSchema>();
  @type({map: SkillSchema}) public skills: MapSchema<SkillSchema> = new MapSchema<SkillSchema>();
}

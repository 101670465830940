import {Spine} from '@pixi-spine/runtime-4.1'
import {Sound} from '@pixi/sound'
import {AnimatedSprite, Container, Graphics} from 'pixi.js'
import {Monster} from './enums'
import {MonsterSchema} from './server/schema/MonsterSchema'
import {gameState} from './state'
import {drawProgressBar} from './utils'

export const initMonster = (monster: MonsterSchema) => {
  if (!gameState.viewport || !gameState.resources) return

  const container = new Container()

  let element: Spine | AnimatedSprite
  let ratio = 1

  if (monster.isBoss) {
    element = new Spine(gameState.resources[monster.type].spineData)

    element.state.setAnimation(0, 'IDLE', true)

    ratio = monster.width / element.width

    element.scale.set(ratio, ratio)

    container.position.set(monster.x + monster.width / 2, monster.y + monster.height / 2 + monster.boundingHeight / 2)
  } else {
    element = AnimatedSprite.fromFrames(Object.values(gameState.resources[monster.type].data.animations)[0] as any)

    ratio = Math.min(monster.width / element.width, monster.height / element.height)

    element.pivot.set(element.width / 2, element.height / 2)

    element.scale.set(ratio, ratio)

    element.animationSpeed = 0.5
    element.loop = true
    element.play()

    element.position.set(monster.width / 2, monster.height / 2)

    container.position.set(monster.x, monster.y)
  }

  element.zIndex = 30 + Math.floor(monster.y)

  const widthResized = monster.boundingWidth * 0.6
  const heighResized = widthResized / 5

  if (!monster.isBoss) {
    const graphics = new Graphics()
      .beginFill(0x333333, 0.3)
      .drawEllipse(
        monster.boundingX + monster.boundingWidth / 2,
        monster.boundingY + monster.boundingHeight + heighResized / 2,
        widthResized,
        heighResized
      )
      .endFill()

    graphics.cacheAsBitmap = true

    container.addChild(graphics)
  }

  element.name = `monster-${monster.uid}`
  container.addChild(element)

  if (monster.isElite || monster.isBoss) {
    const width = element.width * 0.6
    const height = 12

    const gap = 3

    const {container: lifeContainer, bar} = drawProgressBar(width, height, gap, 0x111111, 0xff3333)

    lifeContainer.position.set(
      monster.isBoss ? -width / 2 : (element.width) * 0.2,
      monster.isBoss ? 10 : monster.boundingY + monster.boundingHeight + 10
    )

    lifeContainer.name = `life-${monster.uid}`
    container.addChild(lifeContainer)

    gameState.references.ui.level.healthBars.set(monster.uid, {
      container: lifeContainer,
      bar
    })
  }

  return container
}

export const addMonster = (monsterSchema: MonsterSchema) => {
  if (!gameState.viewport || !gameState.level) return

  const monstersList = gameState.references.monsters

  const monsterContainer = initMonster(monsterSchema)

  if (monsterContainer) {
    monsterContainer.zIndex = 11

    const data = {
      container: monsterContainer,
      schema: monsterSchema
    }

    monstersList.set(monsterSchema.uid, data)

    //setTimeout(() => {
    //  const graphic = new Graphics()
    //    .beginFill(0xff0000).drawRect(monsterSchema.x, monsterSchema.y, monsterSchema.width, monsterSchema.height).endFill()
    //    .beginFill(0x0000ff).drawRect(monsterContainer.x, monsterContainer.y, monsterContainer.width, monsterContainer.height).endFill()
    //    .beginFill(0x00ff00).drawRect(monsterSchema.x + monsterSchema.boundingX, monsterSchema.y + monsterSchema.boundingY, monsterSchema.boundingWidth, monsterSchema.boundingHeight).endFill()
    //  graphic.zIndex = 12
    //  gameState.viewport!.addChild(graphic)
    //}, 0)

    gameState.viewport.addChild(monsterContainer)

    return data
  }
}


/**
 * Kill the monster
 * @param monster The monster Spine to kill
 * @param sound The sound to play with the Kill animation
 */
export const killMonster = (schema: MonsterSchema, sound?: Sound) => {
  if (!gameState.references.monsters) return

  const localMonster = gameState.references.monsters.get(schema.uid)

  if (localMonster) {
    gameState.references.monsters.delete(schema.uid)

    const sprite = localMonster.container

    if (sound) sound.play()

    sprite.destroy()
  }
}

export const setMonsterAnimation = (monsterUid: string, animation: string, loop = false, playPreviousAnimationAfterCompletion = false) => {
  if (!gameState.references.monsters) return

  const localMonster = gameState.references.monsters.get(monsterUid)

  if (localMonster) {
    const element = localMonster.container.getChildByName(`monster-${monsterUid}`) as Spine

    if (element?.state.setAnimation && element?.state.hasAnimationByName(animation)) {
      const previousAnimation = element.state.getCurrent(0)

      element.state.setAnimation(0, animation, loop)

      if (playPreviousAnimationAfterCompletion && previousAnimation.animation?.name) {
        element.state.addAnimation(previousAnimation.trackIndex, previousAnimation.animation.name, previousAnimation.loop)
      }
    }
  }
}

/**
 * Update the monsters positions
 * @param delta The delta (time passed since previous update)
 */
export const updateMonsters = (delta: number) => {
  if (!gameState.viewport || !gameState.level || gameState.level.state.ended || !gameState.references.monsters) return

  const delta01 = delta * 0.01

  for (const [, monster] of gameState.references.monsters) {
    if (!monster) continue

    const container = monster.container
    const element = container.getChildByName(`monster-${monster.schema.uid}`) as Spine | AnimatedSprite

    //const graphic = new Graphics()
    //  .beginFill(0xff0000).drawRect(monster.schema.x, monster.schema.y, monster.schema.width, monster.schema.height).endFill()
    //  .beginFill(0x0000ff).drawRect(element.x, element.y, element.width, element.height).endFill()
    //  .beginFill(0x00ff00).drawRect(monster.schema.x + monster.schema.boundingX, monster.schema.y + monster.schema.boundingY, monster.schema.boundingWidth, monster.schema.boundingHeight).endFill()
    //graphic.zIndex = 12
    //gameState.viewport!.addChild(graphic)

    const newX = monster.schema.isBoss ? monster.schema.x + monster.schema.width / 2 : monster.schema.x
    const newY = monster.schema.isBoss ? monster.schema.y + monster.schema.height / 2 + monster.schema.boundingHeight / 2 : monster.schema.y

    const {
      dx,
      dy,
      speed
    } = monster.schema

    const globalPosition = gameState.viewport.toGlobal(container)

    if (globalPosition.x <= -200 || globalPosition.x > gameState.viewport.screenWidth + 200 || globalPosition.y <= -200 || globalPosition.y > gameState.viewport.screenHeight + 200) {
      container.renderable = false
      container.visible = false

      container.position.set(
        newX,
        newY
      )

      continue
    } else {
      container.renderable = true
      container.visible = true
    }

    const {
      x,
      y
    } = container

    let computedX = x + dx * speed * delta
    let computedY = y + dy * speed * delta

    const differenceX = newX - computedX
    const differenceY = newY - computedY

    computedX += differenceX * delta01
    computedY += differenceY * delta01

    container.zIndex = 30 + computedY + monster.schema.boundingHeight

    element.scale.x = monster.schema.orientation === 'l' ? Math.abs(element.scale.x) : -Math.abs(element.scale.x)

    if (monster.schema.type === Monster.Yeti || monster.schema.type === Monster.Rainbow) {
      element.scale.x = -element.scale.x
    }

    if (Math.abs(differenceX) > 100 || Math.abs(differenceX) < 0.02) {
      computedX = newX
    }

    if (Math.abs(differenceY) > 100 || Math.abs(differenceY) < 0.02) {
      computedY = newY
    }

    container.position.set(
      computedX,
      computedY
    )
  }
}
@import "scss-variables";

.loadout {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;
    gap: 1rem;

    button {
      img {
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: max-content;
      gap: 2rem;
      width: 100%;
      padding-bottom: 0.1rem;

      &.info {
        color: #fff;
        text-align: center;
        text-shadow: 0px 2.25px 0px rgba(0, 0, 0, 0.83);
        font-family: "Supersonic Rocketship";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.945px;
        text-transform: uppercase;
      }

      &.details {
        text-align: left;
        color: #fff;
        font-family: "Cees Hand";
        font-size: 16.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .tile {
        position: relative;
        aspect-ratio: 1/1;
        display: flex;
        align-items: end;
        justify-content: center;
        opacity: 0.8;

        &:hover:not(.locked) {
          cursor: pointer;
          opacity: 1;
        }

        &.locked {
          pointer-events: none;
          cursor: not-allowed;
        }

        .buy {
          position: absolute;
          inset: 0;
          z-index: 10;
          pointer-events: all;
          cursor: pointer;

          button {
            position: absolute;
            font-size: 1rem;
            height: 1.5rem;
            bottom: 0.5rem;
            left: 0.5rem;
            right: 0.5rem;
            padding: 0;
          }
        }

        .bg {
          position: absolute;
          inset: 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/square-bg.svg);
        }

        .image {
          position: absolute;
          inset: 0;
          background-repeat: no-repeat;
          background-size: 75%;
          background-position: center;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          position: relative;
          z-index: 1;
          height: 2rem;

          color: #fff;
          font-family: "Supersonic Rocketship";
          font-weight: 400;
          font-size: 1.25rem;
          text-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.83);
          letter-spacing: 1px;

          img {
            height: 1.2rem;
          }
        }
      }
    }
  }
}
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react'
import {WalletModalProvider} from '@solana/wallet-adapter-react-ui'
import {LedgerWalletAdapter, SolflareWalletAdapter} from '@solana/wallet-adapter-wallets'
import React, {useMemo} from 'react'
import {Outlet} from 'react-router-dom'
import {useHubContext} from '../../state/context'

export const SolanaWalletPage = () => {
  const {state: {sessionToken}} = useHubContext()

  const wallets = useMemo(
    () => [
      //new SolanaMobileWalletAdapter({
      //  appIdentity: {
      //    name: 'Pirate Squad - Bubble'
      //  },
      //  authorizationResultCache: createDefaultAuthorizationResultCache(),
      //  cluster: WalletAdapterNetwork.Mainnet,
      //  addressSelector: createDefaultAddressSelector(),
      //  onWalletNotFound: (adapter) => {

      //  }
      //}),
      new SolflareWalletAdapter(),
      new LedgerWalletAdapter()
    ],
    []
  )

  return (
    <ConnectionProvider endpoint="https://rpc.hellomoon.io/9ebf282c-577a-45d4-bf84-6a597eda3091">
      <WalletProvider wallets={wallets} autoConnect={!!sessionToken}>
        <WalletModalProvider>
          <Outlet />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}
@import "scss-variables";

.deposit-vessels {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.5rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;

    .input {
      .subtitle {
        text-align: left;
        color: #db43a3;
        font-family: "Cees Hand";
        font-size: 0.85rem;
      }

      .amount {
        width: 100%;
      }

      .error {
        font-size: 1rem;
        color: #ff6262;
      }
    }

    .description {
      color: #d2cff0;
      text-align: center;
      font-family: "More Sugar";
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: -0.05rem;
      font-weight: 400;

      span {
        color: #fff;
      }
    }

    .balance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2rem;

      .vessels {
        display: flex;
        align-items: center;
        height: 100%;
      }

      img {
        height: 100%;
        margin-right: 1rem;
      }
    }

    .vessel {
      margin-top: 1rem;

      img {
        height: 160%;
        margin-right: 1rem;
      }
    }
  }
}

@import "scss-variables";

/* The switch - the box around the slider */
.toggle {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;

  .switch {
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .slider {
      background-color: $color-blue;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $color-blue;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(1em);
      -ms-transform: translateX(1em);
      transform: translateX(1em);
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &.round {
        border-radius: 1em;
      }

      &.round:before {
        border-radius: 50%;
      }
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 0.8em;
      width: 0.8em;
      left: 0.1em;
      bottom: 0.1em;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

import copy from 'copy-to-clipboard'
import React, {ChangeEventHandler, ReactElement, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {CSSTransition} from 'react-transition-group'
import {CSSTransitionProps} from 'react-transition-group/CSSTransition'
import {formatClassName} from '../../../utils/global'
import {Button} from '../buttons/button'
import {Input, InputProps} from '../input/input'
import styles from './modal.module.scss'

export type OverlayModalProps = Partial<CSSTransitionProps> & {
  children?: JSX.Element
  show?: boolean
  onClickBackground?: () => void
  containerClassName?: string
  onExited?: () => void
}

export type GenericModalProps = OverlayModalProps & {
  show?: boolean
  title?: ReactElement
  content?: ReactElement
  image?: string
  footer?: ReactElement
  zIndex?: number
  className?: string
  onExited?: () => void
}

export const OverlayModal = ({show, children, zIndex, className, onClickBackground, onExited, containerClassName, ...props}: OverlayModalProps) => {
  const modalRef = useRef(null)

  return (
    <CSSTransition
      in={show}
      nodeRef={modalRef}
      timeout={150}
      classNames={{
        enter: formatClassName(styles, 'modal-enter'),
        enterActive: formatClassName(styles, 'modal-enter-active'),
        exit: formatClassName(styles, 'modal-exit'),
        exitActive: formatClassName(styles, 'modal-exit-active'),
      }}
      onExited={onExited}
      unmountOnExit
      {...props}
    >
      <div ref={modalRef} className={formatClassName(styles, `modal ${className ? className : ''}`)} style={{zIndex}} >
        <div className={formatClassName(styles, 'background')} onClick={onClickBackground}></div>
        <div className={formatClassName(styles, `container ${containerClassName ? containerClassName : ''}`)}>
          {children}
        </div>
      </div>
    </CSSTransition>
  )
}

export const GenericModal = ({
  image,
  title,
  content,
  footer,
  ...props
}: GenericModalProps) => {
  return <OverlayModal containerClassName={`full-size ${image ? 'image' : ''}`} {...props}>
    <>
      {image && <img src={image} />}
      <div className={formatClassName(styles, 'window')}>
        <div className={formatClassName(styles, 'title')}>{title}</div>
        <div className={formatClassName(styles, 'content')}>
          {content}
        </div>
        {
          footer &&
          <div className={formatClassName(styles, 'footer')}>
            {footer}
          </div>
        }
      </div>
    </>
  </OverlayModal>
}

export type LoadingModalProps = OverlayModalProps & {
  progress?: number
  loadingText?: string
}

export const LoadingModal = ({
  progress,
  loadingText = 'Loading...',
  className,
  ...props
}: LoadingModalProps) => <OverlayModal {...props} >
    <div className={formatClassName(styles, `modal loading-modal ${className ?? ''}`)}>
      <div className={formatClassName(styles, 'loading-content')}>{loadingText}{progress !== undefined ? <><br />{`${progress}%`}</> : ''}</div>
    </div>
  </OverlayModal>

export type ModalProps = Omit<GenericModalProps, 'footer'> & {
  onClose: () => void
  text?: string
  closeText?: string
  copyText?: string
}

export const Modal = ({closeText = 'close', onClose, copyText, text, ...props}: ModalProps) => {
  const content = <>
    {text}
  </>

  const footer =
    <>
      {copyText &&
        <Button onClick={() => {
          const copied = copy(copyText)
          if (copied) {
            toast.success('Text copied!', {
              pauseOnFocusLoss: false
            })
          } else {
            toast.warn('Impossible to copy the text! Copy manually :-)', {
              pauseOnFocusLoss: false
            })
          }
        }} className='small'>copy</Button>
      }
      <Button className={formatClassName(styles, 'gray small')} onClick={onClose}>{closeText}</Button>
    </>

  return <GenericModal
    footer={footer}
    content={content}
    {...props}
  />
}

export type ConfirmModalProps = Omit<GenericModalProps, 'footer'> & {
  onAccept: () => void
  onRefuse: () => void
  text?: string
  acceptText?: string
  refuseText?: string
}

export const ConfirmModal = ({acceptText = 'ok', refuseText, onAccept, onRefuse, text, ...props}: ConfirmModalProps) => {
  const content = <>
    {text}
  </>

  const footer =
    <>
      {refuseText && <Button className={formatClassName(styles, 'gray small')} onClick={onRefuse}>{refuseText}</Button>}
      <Button onClick={onAccept} className='small'>{acceptText}</Button>
    </>

  return <GenericModal
    footer={footer}
    content={content}
    {...props}
  />
}

export type InputModalProps = Omit<GenericModalProps, 'footer'> & {
  onAccept: (value?: string) => void
  onRefuse: () => void
  text?: string
  inputProps?: InputProps
  acceptText?: string
  refuseText?: string
}

export const InputModal = ({inputProps, acceptText = 'ok', refuseText, onAccept, onRefuse, text, ...props}: InputModalProps) => {
  const [value, setValue] = useState('')

  const inputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value)
  }

  const content = <>
    {text}
    <Input
      {...inputProps}
      className={formatClassName(styles, `${inputProps?.className} input`)}
      value={value}
      onChange={inputChange}
      autoFocus
    />
  </>

  const footer =
    <>
      {refuseText && <Button className={formatClassName(styles, 'gray small')} onClick={onRefuse}>{refuseText}</Button>}
      <Button onClick={() => onAccept(value)} className='small'>{acceptText}</Button>
    </>

  return <GenericModal
    footer={footer}
    content={content}
    {...props}
  />
}
.player {
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;

  canvas {
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: unset;
  }

  .screen-size {
    padding: 1rem;
    text-align: center;
  }

  .game {
    position: absolute;
    width: 100%;
    height: 100%;

    &.hidden {
      visibility: hidden;
    }
  }

  .ui-container {
    position: absolute;
    flex-direction: column;
    width: min(480px, 100%);
    height: 100%;
    color: #333;
    justify-content: center;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
    pointer-events: none;

    .main-ui {
      display: none;
      width: 100%;
      height: 100%;
      animation: 300ms fadeIn forwards;
      box-shadow: 0px 0px 3rem #0007;
      pointer-events: all;

      &.fade-in {
        display: flex;
      }
    }

    .pause-menu,
    .continue-menu {
      pointer-events: all;
    }
  }

  .top-bar {
    z-index: 12;
  }

  .navigation {
    z-index: 10;
  }
}

@keyframes fadeIn {
  0% {
    display: flex;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

import {HubContextType} from '../hub/state/context'

export const getSessionInfo = (): SessionInfo | undefined => {
  const rawInfo = localStorage.getItem('sessionInfo') ?? undefined

  if (!rawInfo) return

  const info = JSON.parse(rawInfo) as SessionInfo

  return {
    ...info,
    discordServerJoined: Boolean(info.discordServerJoined)
  }
}

export type SessionInfo = {
  isLedger?: boolean
  sessionToken: string
  publicKey: string
  twitterUsername?: string
  discordUsername?: string
  discordServerJoined?: boolean
  username?: string
  userUuid?: string
  lastServerURL?: HubContextType['lastServerURL']
  roomSession?: HubContextType['roomSession']
  accountInfo?: HubContextType['accountInfo']
}

export const setSessionInfo = (sessionInfo: SessionInfo) => localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo))

export const clearSessionInfo = () => localStorage.removeItem('sessionInfo')

export const isSessionTokenValid = () => getSessionInfo() !== undefined

export const setSessionInfoProperty = <K extends keyof SessionInfo, V extends SessionInfo[K]>(propertyName: K, value: V) => {
  const sessionInfo = getSessionInfo()

  if (sessionInfo && value) {
    sessionInfo[propertyName] = value

    setSessionInfo(sessionInfo)
  }
}

export const removeSessionInfoProperty = (propertyName: keyof SessionInfo) => {
  const sessionInfo = getSessionInfo()

  if (sessionInfo && sessionInfo[propertyName]) {
    delete sessionInfo[propertyName]

    setSessionInfo(sessionInfo)
  }
}

export const maintenanceVisible = (service: string, id: number) => !localStorage.getItem(`maintenance-${service}-${id}`)

export const hideMaintenance = (service: string, id: number) => {
  localStorage.setItem(`maintenance-${service}-${id}`, 'hidden')
}
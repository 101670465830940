import {Skin, SpinePlayer} from '@esotericsoftware/spine-player'
import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_ANIM_CDN_URL} from 'oogy-blast/src/utils/constants'
import {CosmeticData} from '../hub/data/cosmetics'
import {sleep} from './global'

const container = document.createElement('div')

container.style.display = 'none'
container.id = 'spine-cache'

document.body.appendChild(container)

// CHARACTER

const generateSpineCharacter = async (containerId: string) =>
  new Promise<SpinePlayer>((resolve, reject) => {
    new SpinePlayer(containerId, {
      jsonUrl: `${OOGY_ANIM_CDN_URL}/player/player.json`,
      atlasUrl: `${OOGY_ANIM_CDN_URL}/player/player.atlas`,
      preserveDrawingBuffer: true,
      animation: 'IDLE',
      premultipliedAlpha: false,
      showControls: false,
      showLoading: false,
      alpha: true,
      success(spine) {
        if (!spine.skeleton) {
          reject()

          return
        }

        const skin = new Skin('combined')

        const fur = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('FUR/ORANGE'))

        skin.addSkin(fur[Math.floor(Math.random() * fur.length)])

        spine.skeleton.setSkin(skin)

        resolve(spine)
      }
    })
  })

const CHARACTER_CACHE_SIZE = 1
const CHARACTER_CACHE: SpinePlayer[] = []

Array.from({length: CHARACTER_CACHE_SIZE}, async () => CHARACTER_CACHE.push(await generateSpineCharacter(container.id)))

export const getCharacterSpineAt = async (index: number, containerId: string, skin?: {
  [type in keyof CosmeticData]?: string
}) => {
  const parent = document.getElementById(containerId)
  let spine: SpinePlayer

  let i = 0
  do {
    spine = CHARACTER_CACHE[index]
    await sleep(50 * (i + 1))
  } while (!spine?.skeleton && ++i < 10)

  if (!parent || !spine.skeleton) {
    return
  }

  if (skin) {
    const skinCombined = new Skin('combined')

    if (skin.FUR) {
      const eyes = spine.skeleton.data.findSkin(`FUR/${skin.FUR ?? 'ORANGE'}`)
      if (eyes) skinCombined.addSkin(eyes)
    }

    if (skin.EYES) {
      const fur = spine.skeleton.data.findSkin(`EYES/${skin.EYES}`)
      if (fur) skinCombined.addSkin(fur)
    }

    if (skin.HEAD) {
      const head = spine.skeleton.data.findSkin(`HEAD/${skin.HEAD}`)
      if (head) skinCombined.addSkin(head)
    }

    if (skin.TOP) {
      const top = spine.skeleton.data.findSkin(`TOP/${skin.TOP}`)
      if (top) skinCombined.addSkin(top)
    }

    if (skin.MOUTH) {
      const mouth = spine.skeleton.data.findSkin(`MOUTH/${skin.MOUTH}`)
      if (mouth) skinCombined.addSkin(mouth)
    }

    spine.skeleton.setSkin(skinCombined)
  } else {
    const skinCombined = new Skin('combined')

    const eyes = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('EYES/'))
    const fur = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('FUR/'))
    const head = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('HEAD/'))
    const top = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('TOP/'))
    const mouth = spine.skeleton.data.skins.filter(skin => skin.name.startsWith('MOUTH/'))

    skinCombined.addSkin(eyes[Math.floor(Math.random() * eyes.length)])
    skinCombined.addSkin(fur[Math.floor(Math.random() * fur.length)])
    skinCombined.addSkin(head[Math.floor(Math.random() * head.length)])
    skinCombined.addSkin(top[Math.floor(Math.random() * top.length)])
    skinCombined.addSkin(mouth[Math.floor(Math.random() * mouth.length)])

    spine.skeleton.setSkin(skinCombined)
  }

  spine.skeleton.setSlotsToSetupPose()

  spine.speed = 1

  parent.appendChild(spine.dom)

  return spine
}

export const setCharacterSkin = (spine: SpinePlayer, skin?: {
  [type in keyof CosmeticData]?: string
}) => {
  if (!spine.skeleton) {
    return
  }

  if (skin) {
    const skinCombined = new Skin('combined')

    if (skin.FUR) {
      const fur = spine.skeleton.data.findSkin(`FUR/${skin.FUR ?? 'ORANGE'}`)
      if (fur) skinCombined.addSkin(fur)
    }

    if (skin.EYES) {
      const eyes = spine.skeleton.data.findSkin(`EYES/${skin.EYES}`)
      if (eyes) skinCombined.addSkin(eyes)
    }

    if (skin.HEAD) {
      const head = spine.skeleton.data.findSkin(`HEAD/${skin.HEAD}`)
      if (head) skinCombined.addSkin(head)
    }

    if (skin.TOP) {
      const top = spine.skeleton.data.findSkin(`TOP/${skin.TOP}`)
      if (top) skinCombined.addSkin(top)
    }

    if (skin.MOUTH) {
      const mouth = spine.skeleton.data.findSkin(`MOUTH/${skin.MOUTH}`)
      if (mouth) skinCombined.addSkin(mouth)
    }

    spine.skeleton.setSkin(skinCombined)
  }

  spine.skeleton.setSlotsToSetupPose()
}

// CHEST

const generateSpineChest = (containerId: string) =>
  new Promise<SpinePlayer>((resolve) => {
    new SpinePlayer(containerId, {
      jsonUrl: `${OOGY_ANIM_CDN_URL}/chests/chests.json`,
      atlasUrl: `${OOGY_ANIM_CDN_URL}/chests/chests.atlas`,
      preserveDrawingBuffer: true,
      animation: 'IDLE',
      premultipliedAlpha: false,
      showControls: false,
      showLoading: false,
      alpha: true,
      skin: `CHEST/${ChestType.Common.toUpperCase()}`,
      viewport: {
        transitionTime: 0,
        animations: {
          IDLE: {
            x: -1013.1109306967403 / 4,
            y: -1073.5128877709024 / 4,
            width: 2048.8517435866456 / 4,
            height: 2168.33028118626 / 4,
            padBottom: 0,
            padTop: 0,
            padLeft: 0,
            padRight: 0,
          },
          OPEN: {
            x: -1013.1109306967403 / 4,
            y: -1073.5128877709024 / 4,
            width: 2048.8517435866456 / 4,
            height: 2168.33028118626 / 4,
            padBottom: 0,
            padTop: 0,
            padLeft: 0,
            padRight: 0,
          }
        }
      },
      success(spine) {
        resolve(spine)
      }
    })
  })

const CHEST_CACHE_SIZE = 1
const CHEST_CACHE: SpinePlayer[] = []
Array.from({length: CHEST_CACHE_SIZE}, async () => CHEST_CACHE.push(await generateSpineChest(container.id)))

export const getChestSpineAt = async (index: number, containerId: string, chestType: ChestType) => {
  const parent = document.getElementById(containerId)
  let spine: SpinePlayer

  let i = 0
  do {
    spine = CHEST_CACHE[index]
    await sleep(50 * (i + 1))
  } while (!spine?.skeleton && ++i < 10)

  if (!parent || !spine.skeleton) {
    return
  }

  spine.skeleton?.setSkinByName(`CHEST/${chestType.toUpperCase()}`)
  spine.animationState?.setAnimation(0, 'IDLE', true)

  parent.appendChild(spine.dom)

  return spine
}

export const resetChestSpine = () => {
  CHEST_CACHE.forEach((spine) => {
    spine.setViewport('IDLE')
    spine.animationState?.setAnimation(0, 'IDLE', true)
  })
}

// Monster
const generateSpineMonster = (containerId: string) =>
  new Promise<SpinePlayer>((resolve) => {
    new SpinePlayer(containerId, {
      preserveDrawingBuffer: true,
      premultipliedAlpha: false,
      showControls: false,
      showLoading: false,
      alpha: true,
      jsonUrl: `${OOGY_ANIM_CDN_URL}/main-screen/monster.json`,
      atlasUrl: `${OOGY_ANIM_CDN_URL}/main-screen/monster.atlas`,
      animation: 'animation',
      success(spine) {
        resolve(spine)
      }
    })
  })

const MONSTER_CACHE_SIZE = 1
const MONSTER_CACHE: SpinePlayer[] = []
Array.from({length: MONSTER_CACHE_SIZE}, async () => MONSTER_CACHE.push(await generateSpineMonster(container.id)))

export const getMonsterSpineAt = async (index: number, containerId: string) => {
  const parent = document.getElementById(containerId)
  let spine: SpinePlayer

  let i = 0
  do {
    spine = MONSTER_CACHE[index]
    await sleep(50 * (i + 1))
  } while (!spine?.skeleton && ++i < 10)

  if (!parent || !spine.skeleton) {
    return
  }

  parent.appendChild(spine.dom)

  return spine
}
import {Spine} from '@pixi-spine/runtime-4.1'
import {sound} from '@pixi/sound'
import gsap from 'gsap'
import {AOE, SoundName} from './enums'
import {AOESchema} from './server/schema'
import {playSpatialSound} from './sound'
import {gameState} from './state'

export const addAOE = (aoeSchema: AOESchema) => {
  switch (aoeSchema.type) {
    case AOE.Electricity:
      showElectricityAOE(aoeSchema, undefined, SoundName.SkillDefensiveZone)
      break
    case AOE.Poison:
      showPoisonAOE(aoeSchema)
      break
  }
}

export const removeAOE = (aoeSchema: AOESchema) => {
  const aoe = gameState.references.ui.level.aoe.get(aoeSchema.uid)

  if (aoe) {
    try {
      gameState.references.ui.level.aoe.delete(aoeSchema.uid)

      aoe.destroy()
    } catch (err) { /* empty */}
  }
}

const showElectricityAOE = (aoeSchema: AOESchema, tint?: number, soundName?: SoundName) => {
  if (!gameState.viewport) return

  const aoe = new Spine(gameState.resources['electricity-aoe'].spineData)

  if (tint) {
    aoe.tint = tint
  }

  const auraRatio = (aoeSchema.width / aoe.width) * 2

  aoe.pivot.x -= aoe.height * 0.03
  aoe.pivot.y -= aoe.height * 0.29

  aoe.alpha = 0.1
  aoe.scale.set(auraRatio, auraRatio)
  aoe.x = aoeSchema.x
  aoe.y = aoeSchema.y

  aoe.zIndex = 12

  aoe.state.setAnimation(0, 'IDLE', true)

  gsap.to(aoe, {
    delay: (aoeSchema.waveCooldown / 1000) * (aoeSchema.maxWaves - 1),
    duration: aoeSchema.waveCooldown / 1000,
    alpha: 0,
    onComplete() {
      aoe.destroy()
    }
  })

  gameState.references.ui.level.aoe.set(AOE.Electricity, aoe)

  gameState.viewport.addChild(aoe)

  if (soundName) {
    const localPlayer = gameState.level?.state.localPlayer

    if (!localPlayer) return

    const sparksSound = sound.find(soundName)

    if (sparksSound) {
      playSpatialSound(localPlayer, aoe, sparksSound)
    }
  }
}

const showPoisonAOE = (aoeSchema: AOESchema) => {
  showElectricityAOE(aoeSchema, 0x33ff33)
}
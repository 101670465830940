import {WalletAdapterProps} from '@solana/wallet-adapter-base'
import {WalletContextState} from '@solana/wallet-adapter-react'
import {InputProps} from '../components/input/input'
import {AccountInfo} from '../data/account'
import {ConnectResponseType} from '../hooks/types'

export const hubState: {
  sessionToken?: string
  setSessionToken?: (sessionToken: string) => void
  sessionConnect?: () => ConnectResponseType | undefined
  showModal?: (props: {
    title: string
    text?: string
    copyText?: string
    image?: string
    closeText?: string
    onClose?: () => void
  }) => void
  showConfirm?: (props: {
    title: string
    text?: string
    image?: string
    acceptText?: string
    refuseText?: string
    onAccept?: () => void
    onRefuse?: () => void
  }) => void
  showInputModal?: (props: {
    title: string
    text?: string
    inputProps?: InputProps
    image?: string
    acceptText?: string
    refuseText?: string
    onAccept: (value?: string) => void
    onRefuse?: () => void
  }) => void
  showLoading?: (loading: boolean, progress?: number, loadingText?: string, showPercentage?: boolean) => void
  signMessage?: ((message: Uint8Array) => Promise<Uint8Array>) | undefined
  signTransaction?: WalletContextState['signTransaction']
  signAllTransactions?: WalletContextState['signAllTransactions']
  sendTransaction?: WalletAdapterProps['sendTransaction']
  refreshAccountInfo?: () => Promise<AccountInfo | undefined>
  clearGame?: () => void
  disconnect?: () => void
} = {}
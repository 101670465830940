export enum Monster {
  Bat = 'bat',
  FlyingDeer = 'flying_deer',
  Ghost = 'ghost',
  Ogre = 'ogre',
  Rainbow = 'rainbow',
  Spider = 'spider',
  // new
  Blue1 = 'blue_monster_1',
  Blue2 = 'blue_monster_2',
  Blue3 = 'blue_monster_3',
  Pink1 = 'pink_monster_1',
  Pink2 = 'pink_monster_2',
  Pink3 = 'pink_monster_3',
  Red1 = 'red_monster_1',
  Red2 = 'red_monster_2',
  Crier = 'crier',
  Klaus = 'klaus',
  // Bosses
  Boss1 = 'boss_1',
  Boss2 = 'boss_2',
  Yeti = 'yeti',
}

export enum Bullet {
  Yellow = 'yellow',
  Blue = 'blue',
  Red = 'red',
  Pink = 'pink',
  Slime = 'slime',
  Card = 'card',
  Rocket = 'rocket',
  Boss1 = 'boss_1',
  Boss2 = 'boss_2',
  Yeti = 'yeti',
  XSMAS = 'xmas',
  Carrot = 'carrot',
  Flame = 'flame',
  Riffle = 'riffle'
}

export enum Weapon {
  CommonBlaster = 'COMMON_BLASTER',
  EpicBlaster = 'EPIC_BLASTER',
  FabledBlaster = 'FABLED_BLASTER',
  Shotgun = 'SHOTGUN',
  MachineGun = 'MACHINE_GUN',
  NinjaStar = 'NINJA_STAR',
  XMAS23 = 'XM_AS_23',
  Carrot = 'CAR_98',
  Flamethrower = 'FLAMETHROWER',
  GanAutoRifle = 'GAN_AUTO_RIFLE'
}

export enum Skill {
  Defensive = 'defensive',
  Lightning = 'lightning',
  HealingDrone = 'healing-drone',
  RocketDrone = 'rocket-drone'
}

export enum Aura {
  Heal = 'heal',
  Shield = 'shield'
}

export enum AOE {
  Invisible = 'invisible',
  Poison = 'poison',
  Electricity = 'electricity',
}

export enum Item {
  SmallXP = 'small-xp',
  BigXP = 'big-xp',
  MysteryBox = 'mystery-box',
  Heal = 'heal',
  Shield = 'shield',
  Bomb = 'bomb',
  Gem = 'gem',
  ChestCommon = 'chest-common',
  ChestRare = 'chest-rare',
  ChestFabled = 'chest-fabled',
  ChestZombie = 'chest-zombie'
}

export enum ChestType {
  Common = 'common',
  Rare = 'rare',
  Fabled = 'fabled',
  Zombie = 'zombie'
}

export enum SoundName {
  // Music
  MainTheme = 'main-theme',
  MainTheme2 = 'main-theme-2',
  GameEnded = 'game-ended',
  GamePause = 'game-pause',
  GameToggle = 'game-toggle',
  // UI
  //ButtonClick = 'click',
  // Game
  ItemBomb = 'item-bomb',
  ItemShield = 'item-shield',
  ItemMysteryBox = 'item-mystery-box',
  SkillDefensiveZone = 'skill-defensive',
  SkillHealingDrone = 'skill-healing-drone',
  SkillRocketDrone = 'skill-rocket-drone',
  SkillLightning = 'skill-lightning',
  WeaponBlaster = 'weapon-blaster',
  WeaponNinjaStar = 'weapon-ninja-star',
  WeaponShotgun = 'weapon-shotgun',
  WeaponFlame = 'weapon-flame',
  Impact = 'impact',
  // Bosses
  YetiStomp = 'stomp',
  YetiIceSpike = 'ice-spike',
  YetiIceBlast = 'ice-blast',
  YetiSummon = 'summon'
}
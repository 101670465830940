import axios from 'axios'
import {ChestType} from 'oogy-blast/src/enums'
import {OOGY_DATA_CDN_URL} from 'oogy-blast/src/utils/constants'
import {Rarity} from '../pages/dashboard/sub-pages/game/chests/rewards/rewards'

export type CosmeticEntry = {
  name: string
  priceToUnlock: {
    gems: number
  }
  backgroundColor: string
  rarity: Rarity
  chestDropRates?: {
    [type in ChestType]: number
  }
  availableFrom?: number
  availableTo?: number
}

export type CosmeticData = {
  EYES: CosmeticEntry[]
  FUR: CosmeticEntry[]
  HEAD: CosmeticEntry[]
  TOP: CosmeticEntry[]
  MOUTH: CosmeticEntry[]
}

let CACHE: CosmeticData
let CACHE_TIME = 0

export const getCosmeticsData = async () => {
  if (CACHE_TIME < Date.now() - 600000) {
    if (CACHE) CACHE_TIME = Date.now()

    const response = await axios.get<CosmeticData>(`${OOGY_DATA_CDN_URL}/cosmetics/cosmetics.json`)

    if (response.status === 200) {
      CACHE_TIME = Date.now()
      CACHE = response.data
    }
  }

  return CACHE
}
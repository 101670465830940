@import "scss-variables";

.lobby {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;
    height: 80vh;

    button {
      img {
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    .room-list {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 0.5rem;
      width: 100%;

      h2 {
        margin-top: 0;
        font-weight: normal;
        text-align: center;
      }

      .status {
        text-align: center;
      }

      .rooms {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;
        overflow-y: auto;

        .headers {
          display: flex;

          > * {
            color: #db43a3;
            font-family: "Cees Hand";
            font-size: 0.7rem;
            font-weight: 700;
            text-align: left;
          }

          .header-name {
            flex: 0.4;
          }

          .header-players {
            flex: 0.2;
          }

          .header-ping {
            flex: 0.2;
          }

          .header-empty {
            flex: 0.2;
          }
        }

        .room {
          display: flex;
          box-sizing: border-box;
          background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
          background-repeat: no-repeat;
          background-size: cover;
          padding: 0.5rem;

          > div {
            display: flex;
            align-items: center;
          }

          .room-name {
            flex: 0.4;
            margin-bottom: 4px;
          }

          .room-players {
            flex: 0.2;
          }

          .room-ping {
            flex: 0.2;
          }

          .room-button {
            flex: 0.2;

            button {
              width: 100%;
            }
          }
        }
      }

      .actions {
        display: flex;
        gap: 1rem;

        .exit-button {
          color: #333;

          &:hover:not(:disabled),
          &.selected {
            background-color: #33333311 !important;
          }
        }

        .create-room-button {
          flex: 1;
        }
      }
    }
  }
}

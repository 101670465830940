import React, { HtmlHTMLAttributes } from 'react'
import { formatClassName } from '../../../../../../../../utils/global'
import { Club } from '../type'
import styles from './club-entry.module.scss'

export type ClubEntryProps = HtmlHTMLAttributes<HTMLDivElement> & {
  club: Club
  clubId: string
  score?: number
  onSelectClub?: (club: Club, clubId: string) => void
}

export const ClubEntry = ({ className, club, clubId, score, onSelectClub, ...props }: ClubEntryProps) => {
  return <div {...props} className={formatClassName(styles, `club-entry ${className}`)} onClick={() => onSelectClub && onSelectClub(club, clubId)}>
    <div className={formatClassName(styles, 'details')}>
      <img src={club.imageURL} alt={club.name} />
      <span className={formatClassName(styles, 'name')}>{club.name}</span>
      <span className={formatClassName(styles, 'members')}>
        {club.members} members
        {
          score !== undefined && <> / {score} points</>
        }
      </span>

    </div>
  </div>
}
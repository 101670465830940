@import "scss-variables";

.game-mode {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;

    button {
      img {
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    .single,
    .multi {
      display: flex;
      width: 100%;
      padding: 0 1rem;

      .image,
      .button-container {
        flex: 1;
      }

      .image {
        position: relative;
        height: 8rem;
        max-width: 40%;

        img {
          height: 100%;
        }
      }

      .button-container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        button {
          width: 100%;

          img {
            height: 1rem;
          }
        }
      }
    }

    .multi {
      .gems {
        flex-direction: column;
        font-size: 1.2rem;
      }
    }
  }
}
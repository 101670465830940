@import "scss-variables";

.weekly-prize-tab {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #1e0f2c;
  z-index: 5;

  .weekly-prize {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 11rem);
    padding-top: 5rem;
    gap: 1rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 1rem;
      text-align: center;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      box-sizing: border-box;
      height: 4rem;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 1.75rem;

      &.violet-image {
        background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/violet-modal-title.png);
      }

      img {
        height: 135%;
      }

      .countdown {
        color: #fff;
        text-shadow: 0px 1px 0px #000;
        font-family: More Sugar;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .container {
      display: flex;
      width: calc(100% - 2rem);
      height: 0;
      flex-direction: column;
      position: relative;
      flex: 1;
      gap: 1rem;

      color: #fff;
      font-family: "Cees Hand";
      font-size: 1.1rem;
      font-weight: 700;

      .tabs {
        display: flex;
        color: #666666;
        gap: 0.5rem;

        .tab {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          cursor: pointer;

          button {
            width: 100%;
            padding: 0.25rem 0.5rem;
            font-size: 1.1rem;
          }
        }
      }

      .player {
        pointer-events: none;
        height: 35%;
      }
    }
  }
}

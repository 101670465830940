@import "scss-variables";

.button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  background: linear-gradient(
    180deg,
    rgba(231, 90, 179, 1) 0%,
    rgba(231, 90, 179, 1) 50%,
    rgba(219, 67, 163, 1) 50%,
    rgba(219, 67, 163, 1) 100%
  );
  border: 1px solid black;
  padding: 1rem 2rem;
  border-radius: 999rem;
  height: 4rem;

  color: white;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.83);
  font-family: Supersonic Rocketship;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;

  * {
    pointer-events: none;
  }

  &.small {
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
    height: 3rem;
  }

  &.xsmall {
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
    height: 2rem;
  }

  &.xxsmall {
    font-size: 0.85rem;
    padding: 0.25rem 0.5rem;
    height: 1.5rem;
  }

  &:hover:not(:disabled),
  &.selected {
    cursor: pointer;

    background: linear-gradient(
      180deg,
      lighten(rgba(231, 90, 179, 1), 4) 0%,
      lighten(rgba(231, 90, 179, 1), 4) 50%,
      lighten(rgba(219, 67, 163, 1), 4) 50%,
      lighten(rgba(219, 67, 163, 1), 4) 100%
    );
  }

  &:disabled {
    cursor: not-allowed;
    color: #ffffffaa;
    text-shadow: none;

    background: rgb(215, 215, 215);
    background: linear-gradient(
      180deg,
      rgba(215, 215, 215, 1) 0%,
      rgba(215, 215, 215, 1) 50%,
      rgba(181, 180, 181, 1) 50%,
      rgba(181, 180, 181, 1) 100%
    );
  }

  &.loading {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.gray {
    background: linear-gradient(
      180deg,
      rgba(215, 215, 215, 1) 0%,
      rgba(215, 215, 215, 1) 50%,
      rgba(181, 180, 181, 1) 50%,
      rgba(181, 180, 181, 1) 100%
    );
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(
        180deg,
        lighten(rgba(215, 215, 215, 1), 4) 0%,
        lighten(rgba(215, 215, 215, 1), 4) 50%,
        lighten(rgba(181, 180, 181, 1), 4) 50%,
        lighten(rgba(181, 180, 181, 1), 4) 100%
      );
    }
  }

  &.blue {
    background: linear-gradient(180deg, #669af7 0%, #669af7 50%, #526be4 50%, #526be4 100%);
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(
        180deg,
        lighten(#669af7, 4) 0%,
        lighten(#669af7, 4) 50%,
        lighten(#526be4, 4) 50%,
        lighten(#526be4, 4) 100%
      );
    }
  }

  &.yellow {
    background: linear-gradient(180deg, #eadb5f 0%, #eadb5f 50%, #decf45 50%, #decf45 100%);
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(
        180deg,
        lighten(#eadb5f, 4) 0%,
        lighten(#eadb5f, 4) 50%,
        lighten(#decf45, 4) 50%,
        lighten(#decf45, 4) 100%
      );
    }
  }

  &.violet {
    background: linear-gradient(180deg, #8f43db 0%, #8f43db 50%, #833acb 50%, #833acb 100%);
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(
        180deg,
        lighten(#8f43db, 4) 0%,
        lighten(#8f43db, 4) 50%,
        lighten(#833acb, 4) 50%,
        lighten(#833acb, 4) 100%
      );
    }
  }

  &.pink {
    background: linear-gradient(180deg, #ffb8e5 0%, #ffb8e5 50%, #f996d5 50%, #f996d5 100%);
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(
        180deg,
        lighten(#ffb8e5, 4) 0%,
        lighten(#ffb8e5, 4) 50%,
        lighten(#f996d5, 4) 50%,
        lighten(#f996d5, 4) 100%
      );
    }
  }

  &.shaded-black {
    background: linear-gradient(180deg, #8d8142 0%, #141313 50%, #8d8142 100%);
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background: linear-gradient(180deg, lighten(#8d8142, 4) 0%, lighten(#141313, 4) 50%, lighten(#8d8142, 4) 100%);
    }
  }

  &.success {
    background-color: $color-success;
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background-color: darken($color: $color-success, $amount: 10);
    }
  }

  &.warning {
    background-color: $color-warning;
    color: white;

    &:hover:not(:disabled),
    &.selected {
      background-color: darken($color: $color-warning, $amount: 10);
    }
  }

  &.no-color {
    color: $text-color;
    background-color: transparent;

    &:hover:not(:disabled),
    &.selected {
      background-color: $color-light-gray;
    }

    &:disabled {
      cursor: not-allowed;
      color: #aaa !important;

      svg {
        color: #aaa !important;
      }
    }
  }

  &.no-padding {
    padding: 0;
  }

  &.icon {
    width: auto;
    gap: 1rem;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.floating {
      svg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }

      .children {
        display: none;
      }
    }
  }
}

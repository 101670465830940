import {Spine} from '@pixi-spine/runtime-4.1'
import {gameState} from '../state'

export const showIceWave = (x: number, y: number, params: {
  size: number
}) => {
  if (!gameState.viewport) return

  const spine = new Spine(gameState.resources['yeti-icewave'].spineData)

  spine.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'animation') {
        setTimeout(() => {
          try {
            console.log(spine.height)
            spine.destroy()
          } catch { /* empty */}
        })
      }
    }
  })

  spine.state.setAnimation(0, 'animation')

  const ratio = params.size / 1082

  spine.scale.set(ratio, ratio)
  spine.position.set(x, y)

  spine.zIndex = 12

  gameState.viewport.addChild(spine)

  //const localPlayer = gameState.level?.state.localPlayer

  //if (!localPlayer) return

  //const lightningSound = sound.find(SoundName.SkillLightning)

  //if (lightningSound) {
  //  playSpatialSound(localPlayer, spine, lightningSound)
  //}
}
@import "scss-variables";

.score {
  width: 100%;
  height: 100%;
  z-index: 5;

  .score-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 6rem);
    padding-top: 5rem;
    gap: 1rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 1rem;
      text-align: center;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      box-sizing: border-box;
      height: 4rem;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 2rem;

      img {
        height: 135%;
      }
    }

    .container {
      display: flex;
      width: calc(100% - 2rem);
      height: 0;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 1.1rem;
      font-weight: 700;

      .top-line {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-family: "Supersonic Rocketship";
        font-size: 3rem;
        font-weight: 400;
        letter-spacing: 1.5px;

        > img {
          height: 4rem;
        }

        > button {
          margin-left: auto;
          width: 5rem;
        }
      }

      .middle-line {
        display: flex;
        flex: 1;

        .rewards {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          align-items: center;
          justify-content: center;
          flex: 1;

          .gems {
            display: flex;
            gap: 1rem;
            align-items: center;
            font-family: "Supersonic Rocketship";
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0.66px;
            text-transform: uppercase;

            img {
              height: 2rem;
            }
          }

          .no-chest {
            position: relative;
            width: 30vh;
            text-align: center;

            text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.83);
            font-family: "Supersonic Rocketship";
            font-size: 17.6px;
            font-weight: 400;
            letter-spacing: 0.528px;
            text-transform: uppercase;

            .player {
              position: relative;
              z-index: 1;
              width: 100%;
              height: 15vh;
              rotate: -90deg;
              pointer-events: none;
            }

            .shadow {
              position: absolute;
              width: 136px;
              height: 36.615px;
              border-radius: 100%;
              opacity: 0.5;
              background: #ec2727;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              margin-bottom: 1rem;
            }
          }

          .chests {
            display: flex;
            gap: 1rem;
            align-items: center;

            .chest {
              position: relative;
              display: flex;
              flex-direction: column;
              width: min(150px, 30vw);
              height: min(150px, 30vw);
              border-radius: 0.5rem;
              opacity: 0;

              animation: bounceIn 1s forwards 250ms;

              cursor: pointer;

              .chest-container {
                position: relative;
                flex: 1;

                .badge {
                  position: absolute;
                  top: 25%;
                  left: 0;
                  font-size: 0.5rem;
                  text-transform: uppercase;
                  z-index: 3;
                }

                .light,
                .image {
                  position: absolute;
                  height: auto;
                  left: 0;
                  right: 0;
                  top: 0.25rem;
                  margin: auto;
                }

                .light {
                  width: 100%;
                  opacity: 0.5;
                  top: -0.3rem;
                  z-index: 1;
                  animation: rotating 3s linear infinite;
                }

                .image {
                  height: 100%;
                  z-index: 2;
                }
              }
            }
          }
        }
      }

      .chests {
        padding-top: 1rem;
      }

      .skip {
        width: min(22rem, 70%);
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.75);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useState } from 'react'
import { formatClassName, replaceUpperCaseBy } from '../../../../../../../../../../utils/global'
import { upgradeCharacterRequest } from '../../../../../../../../../api/hub'
import { Button } from '../../../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../../../components/modal/modal'
import { CharacterStatisticsData } from '../../../../../../../../../data/character'
import { useHubContext } from '../../../../../../../../../state/context'
import { hubState } from '../../../../../../../../../state/hub'
import styles from './upgrade.module.scss'

export type UpgradeStatProps = GenericModalProps & {
  onClose: () => void
  data?: CharacterStatisticsData
  name?: keyof CharacterStatisticsData['stats']
  level?: number
}

export const UpgradeStat = ({ className, onClose, data, name, level, ...props }: UpgradeStatProps) => {
  const { state: { accountInfo } } = useHubContext()

  const [upgrading, setUpgrading] = useState(false)

  const title = <div className={formatClassName(styles, 'title')}>
    Upgrade {replaceUpperCaseBy(name ?? '', ' ')}
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

  const entry = name ? data?.stats?.[name] : undefined
  const actualLevel = entry?.levels[(level ?? 1) - 1]
  const newLevel = entry?.levels[level ?? 0]
  const neededGems = entry?.levels[level ?? 0]?.priceToUnlock.gems
  const notEnoughGems = neededGems !== undefined && accountInfo?.gems !== undefined && neededGems > accountInfo.gems
  const maxLevelReached = level !== undefined && entry?.levels[level] === undefined

  const upgrade = async () => {
    if (notEnoughGems || maxLevelReached || !name || !entry) return

    if (hubState.showConfirm) {
      hubState.showConfirm({
        title: `Upgrade ${name}`,
        text: `Do you really want to upgrade ${name} for ${entry.levels[level ?? 0].priceToUnlock.gems} gems?`,
        acceptText: 'Yes',
        refuseText: 'No',
        async onAccept() {
          setUpgrading(true)

          const result = await upgradeCharacterRequest(name)

          if (result?.data && hubState.refreshAccountInfo) {
            hubState.refreshAccountInfo()
          }

          setUpgrading(false)

          onClose()
        }
      })
    }
  }

  const content = <div className={formatClassName(styles, 'content')}>
    <div className={formatClassName(styles, 'tile')}>
      <div className={formatClassName(styles, 'bg')} ></div>
      <div className={formatClassName(styles, 'container')}>
        <div className={formatClassName(styles, 'icon')}>
          <img src={`${OOGY_VISUAL_CDN_URL}/ui/character/${name}.png`} alt={`${name}`} />
          <div className={formatClassName(styles, 'level')}>
            {(level ?? 1)}
          </div>
        </div>
        <div className={formatClassName(styles, 'info')}>
          <div className={formatClassName(styles, 'name')}>{replaceUpperCaseBy(name ?? '', ' ')}</div>
          <div className={formatClassName(styles, 'value')}>{actualLevel?.value ?? '...'}</div>
        </div>
      </div>
    </div>
    {!maxLevelReached &&
      <>
        <img className={formatClassName(styles, 'arrow-down')} src={`${OOGY_VISUAL_CDN_URL}/ui/arrow-down.png`} alt="arrow-down" />
        <div className={formatClassName(styles, 'tile')}>
          <div className={formatClassName(styles, 'bg')} ></div>
          <div className={formatClassName(styles, 'container')}>
            <div className={formatClassName(styles, 'icon')}>
              <img src={`${OOGY_VISUAL_CDN_URL}/ui/character/${name}.png`} alt={`${name}`} />
              <div className={formatClassName(styles, 'level')}>
                {(level ?? 0) + 1}
              </div>
            </div>
            <div className={formatClassName(styles, 'info')}>
              <div className={formatClassName(styles, 'name')}>{replaceUpperCaseBy(name ?? '', ' ')}</div>
              <div className={formatClassName(styles, 'value')}>{newLevel?.value ?? '...'}</div>
            </div>
          </div>
        </div>
      </>
    }
    <Button className={formatClassName(styles, `gems ${(maxLevelReached || notEnoughGems) ? 'gray' : 'blue'}`)} disabled={maxLevelReached || notEnoughGems} loading={upgrading} onClick={upgrade}>
      {(!maxLevelReached && !notEnoughGems) && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
      <div>
        {maxLevelReached
          ? <>
            <div>MAX LEVEL</div>
            <div>REACHED</div>
          </>
          : <>
            <div>UPGRADE FOR</div>
            <div className={formatClassName(styles, `${notEnoughGems ? 'error' : ''}`)}>{entry?.levels[level ?? 0].priceToUnlock.gems ?? '...'} GEMS</div>
          </>
        }
      </div>
      {(!maxLevelReached && !notEnoughGems) && <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gem" />}
    </Button>
    {notEnoughGems &&
      <div className={formatClassName(styles, 'not-enough-gems')}>
        not enough gems
      </div>
    }
  </div>

  return (
    <GenericModal
      className={formatClassName(styles, `upgrade ${className}`)}
      title={title}
      content={content}
      onClickBackground={onClose}
      {...props}
    />
  )

}
@import "scss-variables";

.leaderboard-tab {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #1e0f2c;
  z-index: 5;

  .leaderboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 11rem);
    padding-top: 5rem;
    gap: 1rem;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 1.2rem 1rem 1rem 1rem;
      text-align: center;
      background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/modal-title.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      width: 100%;
      box-sizing: border-box;
      height: 4rem;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 2rem;

      img {
        height: 170%;
        margin-bottom: 12px;
      }
    }

    .container {
      display: flex;
      width: calc(100% - 2rem);
      height: 0;
      flex-direction: column;
      position: relative;
      gap: 1rem;
      flex: 1;

      color: #fff;
      text-shadow: 0px 2px 0px #000;
      font-family: "Cees Hand";
      font-size: 1.1rem;
      font-weight: 700;

      .info {
        font-size: 12px;
        text-align: center;
        color: #666;
      }

      .entry {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;
        //box-sizing: border-box;
        //background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input-test.svg);
        //background-repeat: no-repeat;
        //background-size: 100% 100%;
        border-radius: 0;
        padding: 1rem 1rem 0.6rem 1rem;

        > div {
          z-index: 1;
        }

        &.my {
          .bg {
            opacity: 1;
          }
        }

        .bg {
          position: absolute;
          width: 100%;
          height: 100%;
          inset: 0;
          margin: auto;
          background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/input.svg);
          background-repeat: no-repeat;
          background-size: cover;
          opacity: 0.4;
        }

        .rank {
          color: white;
          background-color: transparent;
          background-position: center;
          border-radius: 0;
          background-size: contain;
          background-repeat: no-repeat;

          &.first {
            color: #ffe500;
          }

          &.second {
            color: #ebebeb;
          }

          &.third {
            color: #be6c61;
          }
        }

        .key {
          flex: 1;
        }
      }

      .list-container {
        display: flex;
        flex: 1;
        overflow-y: auto;
        position: relative;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }

        .list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          height: fit-content;

          .loader {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 24px;

            width: 100%;
            height: 100%;

            grid-column: 2;

            color: white;
          }
        }
      }

      .my-stats {
        text-align: center;

        .my-rank {
          font-weight: bold;
          color: #60bbf8;
        }

        .my-score {
          font-weight: bold;
          color: #60bbf8;
        }
      }

      .tabs {
        display: flex;
        color: #666666;
        gap: 1rem;

        .tab {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          font-size: 1.75rem;
          font-weight: 800;
          cursor: pointer;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

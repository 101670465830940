@import "scss-variables";

.daily-rewards {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1.25rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    gap: 1rem;

    .calendar {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      padding: 1rem 0;

      .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 80%;

        &.end {
          justify-content: end;
          padding: 1rem 3rem 1rem 0rem;
        }

        .day {
          width: 3rem;
          color: #fff;
          text-align: center;
          text-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.83);
          font-family: Supersonic Rocketship;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.48px;
          text-transform: uppercase;
        }

        .circle {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          border: 1px solid rgba(219, 67, 163, 0.25);
          background: rgba(253, 128, 233, 0.25);

          &.active {
            border: 1px solid var(--12, #db43a3);
            background: rgba(253, 128, 233, 0.25);

            .label {
              padding: 0.4rem;
            }
          }

          img {
            z-index: 1;
            width: 60%;

            &.gems {
              width: 80%;
            }
          }

          .label {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: -12px;
            right: -12px;
            background-color: #db43a3;
            padding: .4rem .4rem .1rem .4rem;
            border-radius: 5rem;

            color: #fff;
            font-family: "Cees Hand";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .shinny {
            z-index: 0;
            position: absolute;
            width: 160%;
            height: 160%;
            mix-blend-mode: color-dodge;
          }
        }

        .separator-h {
          width: 1rem;
          height: 1px;
          background-color: rgba(219, 67, 163, 0.25);

          &.active {
            background-color: #db43a3;
          }

          &.hidden {
            background-color: transparent;
          }
        }

        .separator-v {
          width: 1px;
          height: 1rem;
          background-color: rgba(219, 67, 163, 0.25);

          &.active {
            background-color: #db43a3;
          }
        }
      }
    }
  }
}
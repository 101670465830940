import { OOGY_VISUAL_CDN_URL } from 'oogy-blast/src/utils/constants'
import React, { useState } from 'react'
import { formatClassName } from '../../../../../../../../utils/global'
import { confirmClubRequest } from '../../../../../../../api/hub'
import { Button } from '../../../../../../../components/buttons/button'
import { GenericModal, GenericModalProps } from '../../../../../../../components/modal/modal'
import { hubState } from '../../../../../../../state/hub'
import styles from './confirm-club.module.scss'

export type ConfirmClubProps = GenericModalProps & {
  onClose: () => void
}

const Content = ({ onClose }: {
  onClose: () => void
}) => {
  const [loading, setLoading] = useState(false)

  const confirmClub = async () => {
    setLoading(true)

    const result = await confirmClubRequest()

    if (result?.status === 200 && hubState.refreshAccountInfo) {
      hubState.refreshAccountInfo()
    }

    onClose()

    setLoading(false)
  }

  return <div className={formatClassName(styles, 'content')}>
    <Button
      className={formatClassName(styles, 'blue')}
      onClick={confirmClub}
      loading={loading}
    >
      Sign Up
    </Button>
  </div>
}

const Title = ({ onClose }: { onClose: () => void }) =>
  <div className={formatClassName(styles, 'title')}>
    Participate in Atlas Clash
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={onClose} />
  </div>

export const ConfirmClub = ({ className, onClose, show, ...props }: ConfirmClubProps) =>
  <GenericModal
    className={formatClassName(styles, `confirm-club ${className}`)}
    title={<Title onClose={onClose} />}
    content={<Content onClose={onClose} />}
    onClickBackground={onClose}
    show={show}
    {...props}
  />
@import "scss-variables";

.drop-table {
  .title {
    position: relative;
    color: white;
    width: 100%;
    text-shadow: 0px 2px 0px #000;

    img {
      position: absolute;
      top: -2px;
      right: 0;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .rewards {
    justify-content: center;
    overflow-y: auto;
    height: 100%;
  }

  .description {
    color: #fff;
    text-align: center;
    font-family: "More Sugar";
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: -0.05rem;
    font-weight: 400;
    padding-bottom: 0.5rem;

    span {
      color: #fe49bb;
    }
  }
}

import axios from 'axios'
import {ReadApiAsset} from './types'

export const getCompressedAssets = async (ownerAddress: string, collection: string) => {
  const res = await axios.post('https://rpc.hellomoon.io/9ebf282c-577a-45d4-bf84-6a597eda3091',
    {
      jsonrpc: '2.0',
      id: 'get-compressed-nfts',
      method: 'searchAssets',
      params: {
        ownerAddress,
        page: 1,
        limit: 1000,
        compressed: true,
        grouping: [
          'collection', collection
        ]
      }
    }, {
    headers: {
      'Content-Type': 'application/json',
    }
  })

  const {result} = await res.data

  const nfts: ReadApiAsset[] = result?.items ?? []

  return nfts
}
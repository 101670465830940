import React, {HtmlHTMLAttributes, useEffect, useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {formatClassName} from '../../../../../utils/global'
import {getAccountInfoRequest, getCreditsRequest, tryOrReconnect} from '../../../../api/hub'
import {OverlayModal} from '../../../../components/modal/modal'
import {useHubContext} from '../../../../state/context'
import {hubState} from '../../../../state/hub'
import styles from './player.module.scss'

import {initGame} from 'oogy-blast'
import {playMainMusic} from '../../../../../sound/sound'
import {Background} from './background/background'
import {ContinueMenu} from './continue-menu/continue-menu'
import {Homescreen} from './homescreen/homescreen'
import {Navigation} from './navigation/navigation'
import {PauseMenu} from './pause-menu/pause-menu'
import {Score} from './score/score'
import {Lobby} from './tabs/battle/lobby/lobby'
import {TopBar} from './top-bar/top-bar'

export type PlayerProps = HtmlHTMLAttributes<HTMLDivElement>

export const Player = ({className, ...props}: PlayerProps) => {
  const {game} = useParams()
  const {dispatch} = useHubContext()

  const gameRef = useRef<HTMLCanvasElement>(null)
  const gameContainerRef = useRef<HTMLDivElement>(null)

  const [showBackground, setShowBackground] = useState(true)
  const [showTopBar, setShowTopBar] = useState(true)
  const [showNavigation, setShowNavigation] = useState(true)
  const [showHomescreen, setShowHomescreen] = useState(true)
  const [showPauseMenu, setShowPauseMenu] = useState(false)
  const [showContinueMenu, setShowContinueMenu] = useState(false)
  const [showLobby, setShowLobby] = useState(false)
  const [showScore, setShowScore] = useState(false)

  const [gameScore, setGameScore] = useState(0)
  const [gameGems, setGameGems] = useState(0)

  const [muteCallback, setMuteCallback] = useState<(muted: boolean) => void>()
  const [onRevive, setOnRevive] = useState<(revived: boolean) => void>()

  const [showScreenSize, setShowScreenSize] = useState(false)
  const [showScreenOrientation, setShowScreenOrientation] = useState(false)

  const [ready, setReady] = useState(false)
  const [loading, setLoading] = useState(true)
  const [inGame, setInGame] = useState(false)
  const [gameLaunched, setGameLaunched] = useState(false)

  //const getNumberOfAvailableNFT = async () => {
  //  if (!publicKey) return

  //  const nfts = (await getCompressedAssets(publicKey, 'HPyb9JaYa6xHK28pgxek7cad9i1GwUK7JaFgRqGBa1HG')) ?? []

  //  return nfts.length
  //}

  const checkScreenSize = () => {
    if (!gameContainerRef.current) return

    setShowScreenOrientation(gameContainerRef.current.offsetWidth > gameContainerRef.current.offsetHeight && isMobile)
    setShowScreenSize(gameContainerRef.current.offsetWidth < 200 || gameContainerRef.current.offsetHeight < 350 || gameContainerRef.current.offsetWidth > 10000 || gameContainerRef.current.offsetHeight > 10000)

    setReady(true)
  }

  const showHomescreenPrepared = (show: boolean) => {
    setInGame(!show)
    setShowHomescreen(show)
    setShowTopBar(show)
    setShowNavigation(show)
    setShowBackground(show)
    setShowLobby(false)
    setShowScore(false)
  }

  const showScoreMenuPrepared = (score: number, gems: number) => {
    if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()

    dispatch({
      type: 'SET_ROOM'
    })

    dispatch({
      type: 'SET_ROOM_METADATA'
    })

    dispatch({
      type: 'SET_ROOM_SESSION'
    })

    setGameScore(score)
    setGameGems(gems)
    setShowTopBar(true)
    setShowScore(true)
    setInGame(false)
    setShowHomescreen(false)
    setShowNavigation(false)
    setShowBackground(false)
    setShowLobby(false)
  }

  useEffect(() => {
    //if (hubState.clearGame) hubState.clearGame()

    window.addEventListener('resize', checkScreenSize)

    checkScreenSize()

    playMainMusic()

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  useEffect(() => {
    if (!gameRef.current || !ready || showScreenOrientation || showScreenSize || gameLaunched || !hubState.showLoading || !hubState.showModal) return

    hubState.showLoading(true)

    initGame(
      gameRef.current,
      {
        tryOrReconnect,
        clearGame: () => {
          dispatch({
            type: 'SET_ROOM'
          })

          dispatch({
            type: 'SET_ROOM_METADATA'
          })

          dispatch({
            type: 'SET_ROOM_SESSION'
          })

          if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
        },
        // GETTERS
        getSessionToken: () => hubState.sessionToken,
        getCreditsRequest,
        getAccountInfo: getAccountInfoRequest,
        // UI 
        showLoading: hubState.showLoading,
        showModal: hubState.showModal,
        showHomescreen: showHomescreenPrepared,
        showPauseMenu(show: boolean, muteCallback: (muted: boolean) => void) {
          setMuteCallback(() => muteCallback)
          setShowPauseMenu(show)
        },
        showContinueMenu(show: boolean, onRevive?: (accepted: boolean) => void) {
          setOnRevive(() => onRevive)
          setShowContinueMenu(show)
        },
        showScoreMenu: showScoreMenuPrepared,
      },
      (progress, exited) => {
        if (hubState.showLoading && !exited) {
          if (progress === 100) {
            hubState.showLoading(false)
            setLoading(false)
          } else {
            hubState.showLoading(true, Math.floor(progress))
          }
        }
      }).then((clearGame) => {
        hubState.clearGame = clearGame
      }).catch(err => {
        toast.error('Error while retreiving the game state, please try again later or contact the support team')

        console.error(err)
      })

    setGameLaunched(true)
  }, [gameRef, game, showScreenOrientation, showScreenSize, ready, gameLaunched])

  return (
    <div className={formatClassName(styles, `player ${className}`)} {...props} onContextMenu={(event) => {
      event.preventDefault()
    }}>
      <OverlayModal show={showScreenOrientation}>
        <div className={formatClassName(styles, 'screen-size')}>
          <p>
            {'Please use your mobile in portrait orientation to play the game'}
          </p>
        </div>
      </OverlayModal>
      <OverlayModal show={showScreenSize && !showScreenOrientation}>
        <div className={formatClassName(styles, 'screen-size')}>
          <p>
            {'You screen is too small (width < 200px or height < 350px) or too big (width > 10000px, height > 10000px).'}
          </p>
          <p>
            {'To play the game, please modify the size of your window (desktop) or connect with a compatible device.'}
          </p>
        </div>
      </OverlayModal>
      <div className={formatClassName(styles, 'ui-container')}>
        <div className={formatClassName(styles, `main-ui ${(!loading && !inGame) ? 'fade-in' : ''}`)}>
          {showBackground && <Background />}
          {showTopBar && <TopBar className={formatClassName(styles, 'top-bar')} />}
          {showHomescreen && <Homescreen />}
          <Lobby show={showLobby} clientInfo={{
            clientWidth: window.innerWidth,
            clientHeight: window.innerHeight
          }} onClose={() => setShowLobby(false)} />
          {showScore && <Score score={gameScore} gems={gameGems} onClose={() => {
            showHomescreenPrepared(true)
          }} />}
          {/*{showClassicLeaderboard && <LeaderboardTab onClose={() => setShowClassicLeaderboard(false)} />}*/}
          {showNavigation && <Navigation className={formatClassName(styles, 'navigation')} />}
        </div>
        <PauseMenu
          className={formatClassName(styles, 'pause-menu')}
          show={showPauseMenu}
          onClose={() => setShowPauseMenu(false)}
          onQuit={() => showHomescreenPrepared(true)}
          muteCallback={muteCallback}
        />
        <ContinueMenu className={formatClassName(styles, 'continue-menu')} show={showContinueMenu} onClose={(accepted: boolean) => {
          setShowContinueMenu(false)

          if (onRevive) onRevive(accepted)
        }} />
      </div>
      <div ref={gameContainerRef} className={formatClassName(styles, `game ${showScreenSize ? 'hidden' : ''}`)}>
        <canvas ref={gameRef}></canvas>
      </div>
    </div>
  )
}
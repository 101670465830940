// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.19
// 

import {MapSchema, Schema, type} from '@colyseus/schema'
import {AOESchema} from './AOESchema'
import {BulletSchema} from './BulletSchema'
import {ItemSchema} from './ItemSchema'
import {MonsterSchema} from './MonsterSchema'

export class LevelSchema extends Schema {
  @type({map: ItemSchema}) public items: MapSchema<ItemSchema> = new MapSchema<ItemSchema>();
  @type({map: BulletSchema}) public bullets: MapSchema<BulletSchema> = new MapSchema<BulletSchema>();
  @type({map: MonsterSchema}) public monsters: MapSchema<MonsterSchema> = new MapSchema<MonsterSchema>();
  @type({map: AOESchema}) public aoe: MapSchema<AOESchema> = new MapSchema<AOESchema>();
  @type('boolean') public canMove!: boolean
  @type('number') public worldSize!: number
  @type('string') public background!: string
}

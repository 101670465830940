@import "scss-variables";

.dashboard {
  display: flex;
  position: relative;
  color: $text-color;
  font-family: "Cees Hand";
  font-size: 20px;
  width: 100%;
  background-image: url(https://cdn.piratesquadnft.com/clients/oogy-blast/game/visual/ui/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &.player-view {
    height: 100vh;

    .container {
      .content {
        padding: 0;
        overflow: hidden;
        max-height: 100vh;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0 auto;
    max-width: 100vw;
    min-height: 100vh;

    &.limited-height {
      overflow: hidden;
      max-height: 100vh;
    }

    .content {
      display: flex;
      flex: 1;
      width: 100%;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;

      .permission {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
      }

      .connection {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        max-width: 480px;
        width: 100%;
        box-shadow: 0px 0px 3rem #0007;

        .button-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          position: relative;
          margin-top: 3rem;
          z-index: 10;

          .connect-button {
            height: auto;
            font-size: 1.5rem;
            padding: 1rem 4rem;
          }

          .terms {
            a {
              color: white;
              font-weight: bold;
            }

            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            /* Hide the browser's default checkbox */
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 25px;
              width: 25px;
              background-color: #eee;
            }

            /* On mouse-over, add a grey background color */
            &:hover input ~ .checkmark {
              background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            input:checked ~ .checkmark {
              background-color: white;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Show the checkmark when checked */
            input:checked ~ .checkmark:after {
              display: block;
            }

            /* Style the checkmark/indicator */
            .checkmark:after {
              left: 9px;
              top: 3px;
              width: 5px;
              height: 12px;
              border: solid #de299b;
              border-width: 0 4px 4px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }

        .logo {
          position: absolute;
          z-index: 10;
          top: 10%;
          width: 75%;
        }

        .developed-by {
          position: absolute;
          display: flex;
          flex-direction: column;
          bottom: 1rem;
          align-items: center;
          gap: 1rem;
          color: black;
          font-size: smaller;
          opacity: 0.85;
          z-index: 10;

          img {
            width: 2.5rem;
          }

          a {
            text-decoration: none;
            color: black;
            font-weight: 600;
          }
        }
      }
    }
  }
}

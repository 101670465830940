import {Client, Room} from 'colyseus.js'
import {ClassicRoomState, RoomInfo, startClassicGame} from 'oogy-blast'
import {Dispatch} from 'react'
import {toast} from 'react-toastify'
import {getColyseusClientURL} from '../hub/pages/dashboard/sub-pages/game/classic/utils'
import {NewRoomAvailable} from '../hub/pages/dashboard/sub-pages/game/tabs/battle/lobby/lobby'
import {Actions} from '../hub/state/actions'
import {HubContextType} from '../hub/state/context'
import {hubState} from '../hub/state/hub'
import {makeid} from './global'

export const joinOrReconnect = async (roomAvailable: NewRoomAvailable, dispatch: Dispatch<Actions>, sessionToken: string, serverURL: string, roomSession?: HubContextType['roomSession'], password?: string, loadout?: number) => {
  if (!roomSession && !password && roomAvailable?.metadata?.private && hubState.showInputModal) {
    hubState.showInputModal({
      title: 'Enter the room password',
      inputProps: {
        type: 'password',
        maxLength: 32,
        placeholder: 'password...'
      },
      async onAccept(password) {
        await joinOrReconnect(roomAvailable, dispatch, sessionToken, serverURL, roomSession, password, loadout)
      }
    })

    return
  }

  try {
    const client = new Client(serverURL)

    let room: Room<ClassicRoomState>

    if (roomSession) {
      room = await client.reconnect<ClassicRoomState>(roomSession.reconnectionToken)

      await startClassicGame(room)

      dispatch({
        type: 'SET_ROOM_SESSION',
        roomSession: {
          roomId: room.id,
          sessionId: room.sessionId,
          roomAvailable,
          reconnectionToken: room.reconnectionToken
        }
      })

      return
    } else {
      room = await client.joinById<ClassicRoomState>(roomAvailable.roomId, {
        clientWidth: window.innerWidth,
        clientHeight: window.innerHeight,
        password,
        sessionToken,
        clubClash: roomAvailable.metadata.mode === 'clash',
        clubClashLoadout: loadout
      })

      room.onMessage('start-game', async () => {
        await startClassicGame(room)

        Howler.stop()
      })
    }

    dispatch({
      type: 'SET_ROOM',
      room: room
    })

    dispatch({
      type: 'SET_ROOM_METADATA',
      roomMetadata: roomAvailable.metadata
    })

    dispatch({
      type: 'SET_ROOM_SESSION',
      roomSession: {
        roomId: room.id,
        sessionId: room.sessionId,
        roomAvailable,
        reconnectionToken: room.reconnectionToken
      }
    })
  } catch (err) {
    if (hubState.showModal) {
      hubState.showModal({
        title: 'Error',
        text: `${err}`
      })
    }
    console.error(err)
  }
}

export const createSoloRoom = async (sessionToken: string, mode: 'score' | 'clash', dispatch: Dispatch<Actions>, loadout?: number) => {
  return await createClassicRoom({
    mode,
    name: makeid(32),
    maxPlayers: 1
  }, sessionToken, dispatch, undefined, undefined, loadout)
}

export const createClassicRoom = async (roomInfo: RoomInfo, sessionToken: string, dispatch: Dispatch<Actions>, password?: string, serverURL?: string, loadout?: number) => {
  try {
    const url = serverURL ? serverURL : await getColyseusClientURL()

    const client = new Client(url)

    if (!client) {
      toast.error('Impossible to create a client, verify your internet connection or contact the support team')

      return
    }

    const room = await client.create<ClassicRoomState>('classic', {
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
      roomInfo,
      password,
      sessionToken,
      clubClash: roomInfo.mode === 'clash',
      clubClashLoadout: loadout
    })

    let started = false

    room.onMessage('start-game', async () => {
      if (started) return

      started = true

      await startClassicGame(room)

      Howler.stop()
    })

    const roomAvailable = (await client.getAvailableRooms()).find(roomAvailable => roomAvailable.roomId === room.id) as NewRoomAvailable

    dispatch({
      type: 'SET_ROOM',
      room
    })

    dispatch({
      type: 'SET_ROOM_METADATA',
      roomMetadata: roomAvailable?.metadata
    })

    dispatch({
      type: 'SET_ROOM_SESSION',
      roomSession: {
        roomId: room.id,
        sessionId: room.sessionId,
        roomAvailable: roomAvailable,
        reconnectionToken: room.reconnectionToken
      }
    })

    setTimeout(() => {
      if (!started) {
        room.send('is-game-started')
      }
    }, 500)

    return url
  } catch (err) {
    if (hubState.showModal) {
      hubState.showModal({
        title: 'Error',
        text: `${err}`
      })
    }
    console.error(err)
  }
}
import {Spine} from '@pixi-spine/runtime-4.1'
import {sound} from '@pixi/sound'
import {Aura, SoundName} from './enums'
import {PlayerSchema} from './server/schema'
import {playSpatialSound} from './sound'
import {gameState} from './state'

export const addAura = (playerSchema: PlayerSchema, type: Aura) => {
  switch (type) {
    case Aura.Heal:
      showHealAura(playerSchema)
      break
    case Aura.Shield:
      showShieldAura(playerSchema)
      break
  }
}

export const removeAura = (playerSchema: PlayerSchema, type: Aura) => {
  const auras = gameState.references.ui.level.auras.get(playerSchema.clientId)

  if (!auras) return

  const aura = auras.get(type)

  if (aura) {
    try {
      auras.delete(type)
      aura.destroy()
    } catch (err) { /* empty */}
  }
}

export const showHealAura = (playerSchema: PlayerSchema) => {
  if (!gameState.viewport) return

  const playerSpine = gameState.references.players.get(playerSchema.clientId)?.element
  let auras = gameState.references.ui.level.auras.get(playerSchema.clientId)

  if (!auras) {
    auras = new Map()

    gameState.references.ui.level.auras.set(playerSchema.clientId, auras)
  }

  if (!playerSpine) return

  const aura = new Spine(gameState.resources['heal-aura'].spineData)

  const auraRatio = Math.max(playerSpine.width / aura.width, playerSpine.height / aura.height) * 1.2

  aura.pivot.y -= playerSpine.height / 2

  aura.alpha = 0.75
  aura.scale.set(auraRatio, auraRatio)
  aura.x = playerSpine.x
  aura.y = playerSpine.y

  aura.zIndex = Number.MAX_SAFE_INTEGER

  aura.state.addListener({
    complete(entry) {
      if (entry.animation?.name === 'IDLE') {
        setTimeout(() => {
          try {
            aura.destroy()
            auras?.delete(Aura.Heal)
            // eslint-disable-next-line no-empty
          } catch {}
        })
      }
    }
  })

  aura.state.setAnimation(0, 'IDLE')

  auras.set(Aura.Heal, aura)

  gameState.viewport.addChild(aura)

  const localPlayer = gameState.level?.state.localPlayer

  if (!localPlayer) return

  const healSound = sound.find(SoundName.SkillHealingDrone)

  if (healSound) {
    playSpatialSound(localPlayer, aura, healSound)
  }
}

export const showShieldAura = (playerSchema: PlayerSchema) => {
  if (!gameState.viewport) return

  const playerSpine = gameState.references.players.get(playerSchema.clientId)?.element
  let auras = gameState.references.ui.level.auras.get(playerSchema.clientId)

  if (!auras) {
    auras = new Map()

    gameState.references.ui.level.auras.set(playerSchema.clientId, auras)
  }

  if (!playerSpine) return

  const aura = new Spine(gameState.resources['power-aura'].spineData)

  const auraRatio = Math.max(playerSpine.width / aura.width, playerSpine.height / aura.height) * 2

  aura.pivot.y -= playerSpine.height / 2

  aura.alpha = 0.75
  aura.scale.set(auraRatio, auraRatio)
  aura.x = playerSpine.x
  aura.y = playerSpine.y

  aura.zIndex = 12

  aura.state.setAnimation(0, 'IDLE', true)

  auras.set(Aura.Shield, aura)

  gameState.viewport.addChild(aura)

  const localPlayer = gameState.level?.state.localPlayer

  if (!localPlayer) return

  const shieldSound = sound.find(SoundName.ItemShield)

  if (shieldSound) {
    playSpatialSound(localPlayer, aura, shieldSound)
  }
}

/**
 * Update auras
 * @param delta The delta (time passed since previous update)
 */
export const updateAuras = (delta: number) => {
  if (!gameState.level || gameState.level.state.ended) return

  gameState.references.players.forEach((_, clientId) => {
    const auras = gameState.references.ui.level.auras.get(clientId)

    if (!auras || auras.size === 0) return

    const playerSpine = gameState.references.players.get(clientId)?.element

    if (!playerSpine) return

    auras.forEach((aura) => {
      aura.x = playerSpine.x
      aura.y = playerSpine.y
    })
  })
}
import {Spine} from '@pixi-spine/runtime-4.1'
import {Skill} from './enums'
import {PlayerSchema, SkillSchema} from './server/schema'
import {gameState} from './state'
import {computeMovement} from './utils'

export const addSkill = (playerSchema: PlayerSchema, skillSchema: SkillSchema, type: Skill) => {
  let skills = gameState.references.skills.get(playerSchema.clientId)

  if (!skills) {
    skills = new Map()

    gameState.references.skills.set(playerSchema.clientId, skills)
  }

  let element: Spine | undefined

  switch (type) {
    case Skill.HealingDrone:
    case Skill.RocketDrone:
      element = showDrone(playerSchema, skillSchema, type)
      break
  }

  const data = {
    element,
    schema: skillSchema
  }

  skills.set(type, data)

  return data
}

const showDrone = (playerSchema: PlayerSchema, skillSchema: SkillSchema, skill: Skill) => {
  if (!gameState.viewport) return

  const playerSpine = gameState.references.players.get(playerSchema.clientId)?.element

  if (!playerSpine) return

  const drone = new Spine(gameState.resources[skill].spineData)

  const droneRatio = (playerSpine.width / drone.width) * 1.25

  drone.scale.set(droneRatio, droneRatio)
  drone.x = skillSchema.x
  drone.y = skillSchema.y

  drone.zIndex = Number.MAX_SAFE_INTEGER - 2

  drone.state.setAnimation(0, 'FLYING', true)

  gameState.viewport.addChild(drone)

  return drone
}

/**
 * Update skills
 * @param delta The delta (time passed since previous update)
 */
export const updateSkills = (delta: number) => {
  if (!gameState.level || gameState.level.state.ended) return

  gameState.references.players.forEach((player, clientId) => {
    if (!player) return

    const skills = gameState.references.skills.get(clientId)

    if (!skills) return

    skills.forEach((skill, type) => {
      updateSkill(player.schema, skill.schema, type, delta)
    })
  })
}

export const updateSkill = (playerSchema: PlayerSchema, skillSchema: SkillSchema, type: Skill, delta: number) => {
  UPDATED_SKILL_LOGIC[type](playerSchema, skillSchema, delta)
}

export const removeSkill = (playerSchema: PlayerSchema, type: Skill) => {
  REMOVE_SKILL_LOGIC[type](playerSchema)
}

const UPDATED_SKILL_LOGIC: {
  [type in Skill]: (playerSchema: PlayerSchema, skillSchema: SkillSchema, delta: number) => void
} = {
  [Skill.Defensive](playerSchema: PlayerSchema, skillSchema: SkillSchema, delta: number) {
    //showDefensiveAura(playerSchema, skillSchema)
  },
  [Skill.Lightning]() {
    //console.log('update lightning')
  },
  [Skill.HealingDrone](playerSchema: PlayerSchema, skillSchema: SkillSchema, delta: number) {
    const skill = gameState.references.skills.get(playerSchema.clientId)?.get(Skill.HealingDrone)

    if (!skill || !skill.element) return

    const {
      computedX,
      computedY
    } = computeMovement(skill.element, skill.schema, delta * 0.05)

    skill.element.position.set(computedX, computedY)
  },
  [Skill.RocketDrone](playerSchema: PlayerSchema, skillSchema: SkillSchema, delta: number) {
    const skill = gameState.references.skills.get(playerSchema.clientId)?.get(Skill.RocketDrone)

    if (!skill || !skill.element) return

    const {
      computedX,
      computedY
    } = computeMovement(skill.element, skill.schema, delta * 0.05)

    skill.element.position.set(computedX, computedY)
  }
}

const REMOVE_SKILL_LOGIC: {
  [type in Skill]: (playerSchema: PlayerSchema) => void
} = {
  [Skill.Defensive](playerSchema: PlayerSchema) {
    //removeAura(playerSchema, Aura.Defensive)
  },
  [Skill.Lightning]() {
    //console.log('remove lightning')
  },
  [Skill.HealingDrone]() {
    //console.log('')
  },
  [Skill.RocketDrone]() {
    //console.log('')
  }
}
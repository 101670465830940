import React, { HtmlHTMLAttributes, useEffect, useState } from 'react'
import Countdown, { CountdownRendererFn } from 'react-countdown'
import { nextMonday } from '../../../../../../../utils/date'
import { formatClassName } from '../../../../../../../utils/global'
import { Button } from '../../../../../../components/buttons/button'
import { hubState } from '../../../../../../state/hub'
import { Background } from '../../background/background'
import { PreviousWeekTab } from './tabs/previous-week/previous-week-tab'
import { YourEntryTab } from './tabs/your-entry/your-entry-tab'
import styles from './weekly-prize.module.scss'

const addZeroToNumber = (number: number) => number < 10 ? `0${number}` : number
const renderer: CountdownRendererFn = ({ days, hours, minutes }) =>
  <span>
    {addZeroToNumber(days)}D {addZeroToNumber(hours)}H {addZeroToNumber(minutes)}M
  </span>

export type WeeklyPrizeTabProps = HtmlHTMLAttributes<HTMLDivElement> & {
  holder?: boolean
}

export const WeeklyPrizeTab = ({ className, holder, ...props }: WeeklyPrizeTabProps) => {
  const [tab, setTab] = useState<'your-entry' | 'previous-week'>('your-entry')

  useEffect(() => {
    if (hubState.refreshAccountInfo) hubState.refreshAccountInfo()
  }, [])

  const endOfWeeklyPrize = nextMonday()

  return (
    <div className={formatClassName(styles, `weekly-prize-tab ${className}`)} {...props}>
      <Background className='high' />
      <div className={formatClassName(styles, 'weekly-prize')}>
        <div className={formatClassName(styles, `title ${!holder ? 'violet-image' : ''}`)}>
          {holder ? 'Holder ' : 'Public '}
          Weekly Prize {tab === 'your-entry' && <span className={formatClassName(styles, 'countdown')}>
            <Countdown date={endOfWeeklyPrize} renderer={renderer} />
          </span>}
        </div>
        <div className={formatClassName(styles, 'container')}>
          <div className={formatClassName(styles, 'tabs')}>
            <div className={formatClassName(styles, 'tab')} onClick={() => { setTab('your-entry') }}>
              <Button className={formatClassName(styles, `xsmall ${tab === 'your-entry' ? !holder ? 'violet' : '' : 'gray'}`)}>YOUR ENTRY</Button>
            </div>
            <div className={formatClassName(styles, 'tab')} onClick={() => { setTab('previous-week') }}>
              <Button className={formatClassName(styles, `xsmall ${tab === 'previous-week' ? !holder ? 'violet' : '' : 'gray'}`)}>PREVIOUS WEEK</Button>
            </div>
          </div>
          {tab === 'your-entry' && <YourEntryTab holder={holder} />}
          {tab === 'previous-week' && <PreviousWeekTab holder={holder} />}
        </div>
      </div>
    </div>
  )
}
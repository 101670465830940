
export const nextMonday = (d = new Date) => {
  const next = new Date(d)
  next.setUTCDate(next.getUTCDate() + (7 - (next.getUTCDay() + 6)) % 7)
  next.setUTCHours(0, 0, 0, 0)

  if (next <= d) {
    next.setUTCDate(next.getUTCDate() + 7)
  }

  return next
}
import {OOGY_VISUAL_CDN_URL} from 'oogy-blast/src/utils/constants'
import React, {useEffect, useState} from 'react'
import {formatClassName} from '../../../../../../utils/global'
import {getCharacterSpineAt} from '../../../../../../utils/visual'
import {Button} from '../../../../../components/buttons/button'
import {GenericModal, GenericModalProps} from '../../../../../components/modal/modal'
import {CharacterStatisticsData, getCharacterData} from '../../../../../data/character'
import {useHubContext} from '../../../../../state/context'
import styles from './continue-menu.module.scss'

export type ContinueMenuProps = GenericModalProps & {
  onClose: (accepted: boolean) => void
}

const Content = ({onClose}: {
  onClose: (accepted: boolean) => void
}) => {
  const {state: {accountInfo}} = useHubContext()

  const [data, setData] = useState<CharacterStatisticsData>()

  useEffect(() => {
    getCharacterData().then(setData)
  }, [])

  useEffect(() => {
    getCharacterSpineAt(0, 'continue_player-container', {
      FUR: accountInfo?.character.cosmetics.FUR.equiped,
      EYES: accountInfo?.character.cosmetics.EYES.equiped,
      HEAD: accountInfo?.character.cosmetics.HEAD.equiped,
      TOP: accountInfo?.character.cosmetics.TOP.equiped,
      MOUTH: accountInfo?.character.cosmetics.MOUTH.equiped
    }).then(spine => {
      if (!spine?.skeleton) return

      spine.speed = 0
    })
  }, [accountInfo])

  const notEnoughGems = data !== undefined && accountInfo?.gems !== undefined && (data.reviveCost.gems ?? 0) > accountInfo.gems

  return <div className={formatClassName(styles, 'content')}>
    <div
      className={formatClassName(styles, 'player')}
      id="continue_player-container"
    ></div>
    <div className={formatClassName(styles, 'shadow')}></div>
    <Button className={formatClassName(styles, `small gems ${notEnoughGems ? 'gray' : 'blue'}`)} onClick={() => onClose(true)} disabled={notEnoughGems}>
      <img src={`${OOGY_VISUAL_CDN_URL}/ui/gem.png`} alt="gems" />
      <div className={formatClassName(styles, `${notEnoughGems ? 'error' : ''}`)}>{data?.reviveCost.gems ?? '...'} GEMS</div>
    </Button>
    <Button className={formatClassName(styles, 'gray small')} onClick={() => onClose(false)}>
      SKIP
    </Button>
  </div>
}

const Title = ({onClose}: {onClose: (accepted: boolean) => void}) =>
  <div className={formatClassName(styles, 'title')}>
    Continue?
    <img src={`${OOGY_VISUAL_CDN_URL}/ui/cross.png`} alt="cross" onClick={() => onClose(false)} />
  </div>

export const ContinueMenu = ({className, onClose, ...props}: ContinueMenuProps) => {
  return (
    <GenericModal
      className={formatClassName(styles, `continue-menu ${className}`)}
      title={<Title onClose={onClose} />}
      content={<Content onClose={onClose} />}
      onClickBackground={() => onClose(false)}
      {...props}
    />
  )

}
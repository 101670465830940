import {removeSessionInfoProperty, setSessionInfoProperty} from '../../utils/session'
import {Actions} from './actions'
import {HubContextType} from './context'

export const hubReducer = (state: HubContextType, action: Actions): HubContextType => {
  switch (action.type) {
    case 'SET_IS_LEDGER':
      if (!action.isLedger) {
        removeSessionInfoProperty('isLedger')
      } else {
        setSessionInfoProperty('isLedger', action.isLedger)
      }

      return {
        ...state,
        isLedger: action.isLedger
      }
    case 'SET_TWITTER_USERNAME':
      if (!action.twitterUsername) {
        removeSessionInfoProperty('twitterUsername')
      } else {
        setSessionInfoProperty('twitterUsername', action.twitterUsername)
      }

      return {
        ...state,
        twitterUsername: action.twitterUsername
      }
    case 'SET_DISCORD_USERNAME':
      if (!action.discordUsername) {
        removeSessionInfoProperty('discordUsername')
      } else {
        setSessionInfoProperty('discordUsername', action.discordUsername)
      }

      return {
        ...state,
        discordUsername: action.discordUsername
      }
    case 'SET_DISCORD_SERVER_JOINED':
      if (!action.discordServerJoined) {
        removeSessionInfoProperty('discordServerJoined')
      } else {
        setSessionInfoProperty('discordServerJoined', action.discordServerJoined)
      }

      return {
        ...state,
        discordServerJoined: action.discordServerJoined
      }
    case 'SET_USERNAME':
      if (!action.username) {
        removeSessionInfoProperty('username')
      } else {
        setSessionInfoProperty('username', action.username)
      }

      return {
        ...state,
        username: action.username
      }
    case 'SET_SESSION_TOKEN':
      if (!action.sessionToken) {
        removeSessionInfoProperty('sessionToken')
      } else {
        setSessionInfoProperty('sessionToken', action.sessionToken)
      }

      return {
        ...state,
        sessionToken: action.sessionToken
      }
    case 'SET_PUBLIC_KEY':
      if (action.publicKey === undefined) {
        removeSessionInfoProperty('publicKey')
      } else {
        setSessionInfoProperty('publicKey', action.publicKey)
      }

      return {
        ...state,
        publicKey: action.publicKey
      }
    case 'SET_DISCONNECT_WALLET':
      return {
        ...state,
        disconnectWallet: action.disconnectWallet
      }
    case 'SET_SHOW_CONNECT_MODAL':
      return {
        ...state,
        showConnectModal: action.showConnectModal
      }
    case 'SET_USER_UUID':
      if (action.userUuid === undefined) {
        removeSessionInfoProperty('userUuid')
      } else {
        setSessionInfoProperty('userUuid', action.userUuid)
      }

      return {
        ...state,
        userUuid: action.userUuid
      }
    case 'SET_LAST_SERVER_URL':
      if (!action.lastServerURL) {
        removeSessionInfoProperty('lastServerURL')
      } else {
        setSessionInfoProperty('lastServerURL', action.lastServerURL)
      }

      return {
        ...state,
        lastServerURL: action.lastServerURL
      }
    case 'SET_ROOM':
      return {
        ...state,
        room: action.room
      }
    case 'SET_ROOM_METADATA':
      return {
        ...state,
        roomMetadata: action.roomMetadata
      }
    case 'SET_ROOM_SESSION':
      if (!action.roomSession) {
        removeSessionInfoProperty('roomSession')
      } else {
        setSessionInfoProperty('roomSession', action.roomSession)
      }

      return {
        ...state,
        roomSession: action.roomSession
      }
    case 'SET_ACCOUNT_INFO':
      if (!action.accountInfo) {
        removeSessionInfoProperty('accountInfo')
      } else {
        setSessionInfoProperty('accountInfo', action.accountInfo)
      }

      return {
        ...state,
        accountInfo: action.accountInfo
      }
    case 'SET_NAVIGATION':
      return {
        ...state,
        tab: action.tab
      }
    default:
      return state
  }
}
import {PlayOptions, Sound, sound} from '@pixi/sound'
import {StereoFilter} from '@pixi/sound/lib/filters'
import {SoundName} from './enums'
import {gameState} from './state'
import {distanceBetween, getGameHeight, getGameWidth} from './utils'
import {OOGY_SOUND_CDN_URL} from './utils/constants'

export const playMusic = (soundName: SoundName) => {
  Object.entries(SoundName).filter((entry) => entry[0].toLowerCase().includes('maintheme') && entry[0].toLowerCase() !== soundName.toLowerCase()).forEach((entry) => {
    if (sound.find(entry[1])?.isPlaying) sound.find(entry[1]).stop()
  })

  const currentMusic = sound.find(soundName)

  if (!currentMusic.isPlaying && currentMusic.isLoaded) currentMusic.play()
}

export const isMusicMuted = () => {
  return window.localStorage.getItem('music-muted') === 'true'
}

//export const isSFXMuted = () => {
//  return window.localStorage.getItem('sfx-muted') === 'true'
//}

export const toggleMuteMusic = (force?: boolean) => {
  const status = isMusicMuted()

  //Object.entries(SoundName).filter((entry) => !entry[0].toLowerCase().includes('maintheme')).forEach((entry) => {
  Object.entries(SoundName).forEach((entry) => {
    sound.find(entry[1]).muted = force !== undefined ? force : !status
  })

  window.localStorage.setItem('music-muted', String(force !== undefined ? force : !status))
}

//export const toggleMuteSFX = (force?: boolean) => {
//  const status = isSFXMuted()

//  Object.entries(SoundName).filter((entry) => entry[0] !== SoundName.MainTheme).forEach((entry) => {
//    sound.find(entry[1]).muted = force !== undefined ? force : !status
//  })

//  window.localStorage.setItem('sfx-muted', String(force !== undefined ? force : !status))
//}

export const loadSounds = (onProgress?: (progress: number) => void, onComplete?: () => void) => {
  if (sound.exists(SoundName.MainTheme)) {
    if (onProgress) onProgress(100)
    if (onComplete) onComplete()

    return
  }

  const nbSounds = Object.keys(SoundName).length
  let soundLoaded = 0

  const loaded = (error: Error) => {
    //if (gameState.exited) return
    if (error) console.error(error)

    if (error && !gameState.exited) {
      gameState.exited = true

      gameState.external.clearGame()

      gameState.external.showLoading(false)

      gameState.external.showModal({
        title: 'Error',
        text: 'Impossible to load the sound resources, please check your internet connection and refresh the page'
      })
    }

    ++soundLoaded

    if (soundLoaded === nbSounds && onComplete) {
      onComplete()
    } else if (onProgress) {
      onProgress((soundLoaded / nbSounds) * 100)
    }
  }

  try {
    loadMusics(loaded)
    loadItems(loaded)
    loadGame(loaded)
    loadWeapons(loaded)
    loadSkills(loaded)
    loadBosses(loaded)
  } catch (err) {
    console.error(err)
  }

  if (isMusicMuted()) {
    toggleMuteMusic(true)
  }

  //if (isSFXMuted()) {
  //  toggleMuteSFX(true)
  //}
}

export const playSpatialSound = (a: {
  x: number
  y: number
}, b: {
  x: number
  y: number
},
  sound: Sound,
  localPlayerSound = false,
  options?: PlayOptions
) => {
  const volume = 1 - Math.max(0, Math.min(1, distanceBetween(a.x, a.y, b.x, b.y) / Math.max(getGameWidth(), getGameHeight())))

  const pan = Math.min(1, Math.max(-1, (b.x - a.x) / getGameWidth()))

  sound.play({
    volume: volume * (localPlayerSound ? 1 : 0.5),
    filters: [new StereoFilter(pan)],
    ...options
  })
}

const loadMusics = (loaded: (error: Error) => void) => {
  sound.add(SoundName.MainTheme, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/music/theme-2.mp3',
    volume: 0.08,
    loop: true,
    loaded
  })

  sound.add(SoundName.MainTheme2, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/music/clash.mp3',
    volume: 0.08,
    loop: true,
    loaded
  })
}

const loadItems = (loaded: (error: Error) => void) => {
  sound.add(SoundName.ItemBomb, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/items/bomb.mp3',
    volume: 0.10,
    loaded
  })

  sound.add(SoundName.ItemShield, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/items/shield.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.ItemMysteryBox, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/ui/open-chest.mp3',
    volume: 0.1,
    loaded
  })
}

const loadSkills = (loaded: (error: Error) => void) => {
  sound.add(SoundName.SkillDefensiveZone, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/skills/electricity-sparks.mp3',
    volume: 0.1,
    loaded
  })

  sound.add(SoundName.SkillHealingDrone, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/skills/heal.mp3',
    volume: 0.2,
    loaded
  })

  sound.add(SoundName.SkillLightning, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/skills/lightning.mp3',
    volume: 0.3,
    loaded
  })

  sound.add(SoundName.SkillRocketDrone, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/skills/rocket-explosion.mp3',
    volume: 0.3,
    loaded
  })
}

const loadBosses = (loaded: (error: Error) => void) => {
  sound.add(SoundName.YetiIceBlast, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/bosses/yeti/ice-blast.mp3',
    volume: 0.1,
    loaded
  })

  sound.add(SoundName.YetiIceSpike, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/bosses/yeti/ice-spike.mp3',
    volume: 0.1,
    loaded
  })

  sound.add(SoundName.YetiStomp, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/bosses/yeti/stomp.mp3',
    volume: 0.1,
    loaded
  })

  sound.add(SoundName.YetiSummon, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/bosses/yeti/summon.mp3',
    volume: 0.1,
    loaded
  })
}

const loadWeapons = (loaded: (error: Error) => void) => {
  sound.add(SoundName.WeaponBlaster, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/weapons/blaster.mp3',
    volume: 0.02,
    loaded
  })

  sound.add(SoundName.WeaponNinjaStar, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/weapons/ninja-star.mp3',
    volume: 0.04,
    loaded
  })

  sound.add(SoundName.WeaponShotgun, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/weapons/shotgun.mp3',
    volume: 0.02,
    loaded
  })

  sound.add(SoundName.WeaponFlame, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/weapons/flame.mp3',
    volume: 0.08,
    loaded
  })

  sound.add(SoundName.Impact, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/weapons/impact.mp3',
    volume: 0.2,
    loaded
  })
}

const loadGame = (loaded: (error: Error) => void) => {
  sound.add(SoundName.GameEnded, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/game/ended.mp3',
    volume: 0.20,
    loaded
  })

  sound.add(SoundName.GamePause, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/ui/button/click.mp3',
    volume: 0.25,
    loaded
  })

  sound.add(SoundName.GameToggle, {
    preload: true,
    url: OOGY_SOUND_CDN_URL + '/ui/button/switch.mp3',
    volume: 0.25,
    loaded
  })
}